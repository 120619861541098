import React from "react";
import { Alert } from "../alert";

/**
 * Contract for the input props of Form Validation Message.
 */
export interface IFormValidationMessageProps {
	hide: undefined | boolean;
	propNames: string[];
	propValidation: {
		[propName: string]: boolean;
	};
	propMessages: {
		[propName: string]: string;
	};
}

/**
 * Standard form Validation message display,
 * to be used on the bottom of Forms.
 *
 * @param props IFormValidationMessageProps
 * @returns React.FC<IFormValidationMessageProps>
 */
export const FormValidationMessage: React.FC<IFormValidationMessageProps> = (props) => {
	return props?.hide ? null : (
		<Alert
			category={"danger"}
			text={
				<div>
					Some fields might require attention:
					<ul className="mb-0">
						{props?.propNames.map((validationPropName, index) =>
							props?.propValidation[validationPropName] ? null : (
								<li key={index}>{props?.propMessages[validationPropName]}</li>
							)
						)}
					</ul>
				</div>
			}
		/>
	);
};
