import * as React from "react";

import { RouterProvider } from "react-router-dom";
import { BrowserRouter } from "./components/router/browserRouter";

/**
 * Application initial layer.
 * Injects the RouterProvider.
 *
 * @returns React.FC
 */
const App: React.FC = () => {
	return <RouterProvider router={BrowserRouter} />;
};

export default App;
