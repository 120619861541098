import React, { useRef } from "react";
import { ServiceHub } from "../../../service";
import { useNodeForm } from "../../../hooks";
import { NodeForm } from "../../common/node";
import { NodeType } from "../../../contracts/models/strikeEnums";
import { ApplicationRoutePaths } from "../../router";
import { AccessControlLayer } from "../../common/accessControl";

/**
 * Re-usable Question creation form
 *
 * @returns React.FC
 */
export const QuestionForm: React.FC = () => {
	// APIs handled within
	const articleDataAPI = useRef(ServiceHub.articleDataAPI.start());
	const articleFormService = useRef(ServiceHub.articleForm.start());

	const {
		// Main form state elements
		formData,
		setHasError,
		canSubmit
	} = useNodeForm(NodeType.Question);

	/**
	 * Executes the necessary commands for Creating a Question,
	 * or updating an existing.
	 */
	const createQuestion = () => {
		articleDataAPI.current
			.createQuestion(formData)
			.then((result: any) => {
				if (result instanceof Error) {
					ServiceHub.message.error(result.message);
					throw result;
				}

				articleFormService.current.resetFormConfig();

				if (!result.Id) {
					// Redirect the user to the dashboard
					ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.dashboard());
					return;
				}

				// Redirects the user to the respective route
				ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.question(result.Id, result.Title));
			})
			.finally(() => {
				articleFormService.current.setSubmitting(false);
			});
	};

	function onSubmit(event: React.FormEvent<HTMLFormElement>) {
		if (event.preventDefault) event.preventDefault();

		articleFormService.current.setSubmitting(true);

		if (!canSubmit) {
			setHasError(true);
			ServiceHub.message.warning("Please, before submitting, verify and fill all required fields.");
			articleFormService.current.setSubmitting(false);
			return;
		}

		setHasError(false);

		// Submit logic
		createQuestion();

		return;
	}

	return !formData ? null : <NodeForm type={NodeType.Question} onSubmit={onSubmit} />;
};
