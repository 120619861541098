import React from "react";
import PageHeader from "../Header/page.header";
import { Divider } from "semantic-ui-react";
import { TitleType } from "../../contracts/models/strikeEnums";
import {
	getQueryEventAuthAccess,
	shapeEventRegistrationInfoRequest,
	shapeUserEventRegistration,
	utcToLocalDateTimeString,
	validateEmail
} from "../../lib/strikeLibrary";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { useState } from "react";
import { Button } from "../common/button";
import { useEffect } from "react";
import { useRef } from "react";
import { ServiceHub } from "../../service";
import { Link, useParams } from "react-router-dom";
import { RichTextNode } from "../common/node";
import { useAuthUser } from "../../hooks";
import { ShowEventMessage } from "../event/show.event.message";
import { useMemo } from "react";
import { useSuperAdminAccessControl } from "../../hooks/auth/useSuperAdminAccessControl";
import { ApplicationRoutePaths } from "../router";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import ErrorPage from "../ui/ErrorPage";
import { EventNotificationTemplateType } from "../../enums";
import { IEventRegistrationInfoRequestModel, IEventRegistrationInfoResponseModel } from "../../contracts";

/**
 * Component to show Event details and registration options
 * @returns EventViewPage component
 */
const EventViewPage: React.FC = () => {
	const [rsvpKey, setRsvpKey] = useState<string | undefined>("");
	const [typeOfRsvpKey, setTypeOfRsvpKey] = useState<string | undefined>("");
	const [isFormSent, setIsFormSent] = useState(false);
	const eventManageAPI = useRef(ServiceHub.eventManageAPI.start());

	const eventsAPI = useRef(ServiceHub.eventsAPI.start());
	const params = useParams();
	const [eventLoaded, setEventLoaded] = useState(false);
	const [eventLoading, setEventLoading] = useState(true);
	const [eventLoadingAccess, setEventLoadingAccess] = useState(true);
	const [eventDetails, setEventDetails] = useState<any>("");
	const [showEventTypeOptions, setShowEventTypeOptions] = useState(false);
	const { userDisplayName, userPrincipalName, userEmail } = useAuthUser();
	const [mixedType, setMixedType] = useState<string>("");
	const { isSuperAdmin, requested, requesting } = useSuperAdminAccessControl();
	const [eventPublished, setEventPublished] = useState(false);
	const [authMessage, setAuthMessage] = useState<boolean>(false);
	const [authMessageCode, setAuthMessageCode] = useState(null);
	const [eventRegistrationInfo, setEventRegistrationInfo] = useState<IEventRegistrationInfoResponseModel>();
	const [isResetRegistration, setIsResetRegistration] = useState<boolean>(false);
	const id = "";
	const errorMessage = `If you need access, please request it to :
	<a href="mailto:strikeonlineteam@microsoft.com" rel="noopener noreferrer">
		<b>strikeonlineteam</b>
	</a>`;
	const [templatesItems, setTemplatesItems] = useState([]);

	const options_rsvp: IChoiceGroupOption[] = [
		{
			key: "attend",
			text: "Yes, I will attend",
			"aria-label": "Yes, I will attend, radio button 1 of 2"
		},
		{
			key: "not_attend",
			text: "No, I will not attend",
			"aria-label": "No, I will not attend, radio button 2 of 2"
		}
	];

	// this extra option its only nneded for Mixed type of event
	const options_typeOfRsvp: IChoiceGroupOption[] = [
		{ key: "virtual", text: "Virtual" },
		{ key: "in_person", text: "In Person" }
	];

	const onChangeRsvp = React.useCallback(
		(ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
			setRsvpKey(option.key);
			setShowEventTypeOptions(option.key === "not_attend" ? false : true);
			setIsFormSent(false);
		},
		[rsvpKey]
	);

	const onChangeTypeOfRsvp = React.useCallback(
		(ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
			setTypeOfRsvpKey(option.key);
			setMixedType(option.key);
			setIsFormSent(false);
		},
		[typeOfRsvpKey]
	);

	/**
	 * Handle API call when user clicks on 'Submit' button
	 */
	const handleSubmit = () => {
		if (validateEmail(userPrincipalName)) {
			let eventData = shapeUserEventRegistration(
				id,
				eventDetails.Id,
				userPrincipalName,
				userDisplayName,
				rsvpKey,
				typeOfRsvpKey
			);
			eventsAPI.current.userEventRegistration(eventData).then((result: any) => {
				if (result instanceof Error) {
					ServiceHub.message.error(result.message);
					throw result;
				}

				if (!result) {
					// Redirect the user to the event dashboard
					// show registration error
					return;
				}

				setIsFormSent(true);
				setRsvpKey("");
				setTypeOfRsvpKey("");
				// show registration successfully
			});
		}
	};

	/**
	 * reset the registration
	 */
	const resetRegistration = () => {
		setIsResetRegistration(true)
	};

	useEffect(() => {
		if (eventDetails.Id && userEmail) {
			let queryEventAuthAcces = getQueryEventAuthAccess(userEmail, eventDetails.Id);
			/**
			 * Check user auth for event from API
			 */
			const getUserAuth = async () => {
				setEventLoadingAccess(true);
				try {
					let res = await NodeAPIServiceInstance.getUserEventAuth(
						queryEventAuthAcces,
						userEmail,
						eventDetails.Id
					);
					if (await res) {
						if (res) {
							setAuthMessage(true);
							setAuthMessageCode("allowed");
						}
					}
				} catch (e: any) {
					setAuthMessage(false);
					setAuthMessageCode(e.response.status);
					setEventLoadingAccess(false);
				}
			};
			getUserAuth();
		}
	}, [eventDetails.Id, authMessage]);

	useEffect(() => {
		if (params.id) {
			setEventLoaded(false);
			setEventLoading(true);
			eventsAPI.current
				.getEvent(params.id)
				.then((result: any) => {
					if (result instanceof Error) {
						ServiceHub.message.error(result.message);
						throw result;
					}
					setEventDetails(result);
				})
				.finally(() => {
					setEventLoaded(true);
					setEventLoading(false);
				});

			eventManageAPI.current.getNotificationTemplates(params.id).then((items) => {
				if (Array.isArray(items)) {
					setTemplatesItems(items);
				}
			});

			let eventRegistrationInfoRequest = shapeEventRegistrationInfoRequest(Number(params.id));
			userEventRegistrationCheck(eventRegistrationInfoRequest);
		}
	}, [params.id, location]);


	/**
	* method to call api and check user registration status
	*/
	const userEventRegistrationCheck = async (eventFormInfoModel: IEventRegistrationInfoRequestModel) => {
		try {
			const result: IEventRegistrationInfoResponseModel = await eventsAPI.current.getUserEventRegistrationInfo(eventFormInfoModel);
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
			let registrationInfoResponseModel: IEventRegistrationInfoResponseModel;
			registrationInfoResponseModel = result;
			setEventRegistrationInfo(() => {
				return {
					EventId: registrationInfoResponseModel.EventId,
					Rsvp: registrationInfoResponseModel.Rsvp,
					TypeOfRSVP: registrationInfoResponseModel.TypeOfRSVP,
					IsConfirmed: registrationInfoResponseModel.IsConfirmed,
					UserEmail: registrationInfoResponseModel.UserEmail,
					IsRegistered: registrationInfoResponseModel.IsRegistered
				};
			});
		} catch (error) {
			throw error;
		}
	};


	/**
	 * Memo flag to prevent publishing event without email templates setup
	 */
	const areTemplatesReady = useMemo(() => {
		if (!templatesItems || templatesItems.length === 0) {
			return false;
		}
		const hasAllTemplatesCreated = Object.keys(EventNotificationTemplateType).some((key) => {
			return !templatesItems.some((template) => template.Type === key);
		});

		const hasTemplatesEmpty = templatesItems.some(
			(template) => template.Subject === "" || template.EmailContent === ""
		);

		return !hasTemplatesEmpty && hasAllTemplatesCreated;
	}, [templatesItems]);

	const resolvedButtonDisabled = useMemo(() => {
		if (eventDetails && eventDetails.Type === "Mixed" && showEventTypeOptions) {
			if (rsvpKey !== "" && typeOfRsvpKey !== "") {
				return false;
			} else {
				return true;
			}
		}

		if (eventDetails && eventDetails.Type === "Mixed" && !showEventTypeOptions) {
			if (rsvpKey !== "") {
				return false;
			} else {
				return true;
			}
		}

		if (eventDetails && eventDetails.Type !== "Mixed") {
			if (rsvpKey !== "") {
				return false;
			} else {
				return true;
			}
		}
		return true;
	}, [rsvpKey, typeOfRsvpKey, showEventTypeOptions]);

	/**
	 * Handling user confirmation message based on Event types
	 */
	const resolvedEventType = useMemo(() => {
		if (eventDetails.Type === "Virtual") {
			return "Virtual";
		} else if (eventDetails.Type === "Mixed" && mixedType === "virtual") {
			return "Virtual";
		}
		return "";
	}, [eventDetails.Type, mixedType]);

	const resolvedWailistEnable = useMemo(() => {
		return eventDetails.IsWaitListEnabled;
	}, [eventDetails.IsWaitListEnabled]);

	/**
	 * Passing Event Id to API to publish specific event.
	 */
	const handlePublishEvent = () => {
		if (eventDetails.Id) {
			eventManageAPI.current.publishEvent(eventDetails.Id).then((result: any) => {
				if (result instanceof Error) {
					ServiceHub.message.error(result.message);
					throw result;
				}

				if (!result) {
					// Redirect the user to the event dashboard
					ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
					return;
				}

				setEventPublished(true);
				ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
			});
		}
	};

	if (authMessage) {
		return (
			<React.Fragment>
				{eventLoaded ? (
					<div className="strike-node-wrapper">
						<PageHeader Title={eventDetails.Title} Type={TitleType.Event} showActions />
						<Divider />
						<div className="event-view">
							<div
								className="event-content"
								tabIndex={0}
								aria-label={`${eventDetails.Title} event description`}
							>
								<RichTextNode>{eventDetails.Description}</RichTextNode>
							</div>
						</div>
						{!eventDetails.IsSpecial ? (
							<div className="event-details">
								<div className="event-details-headline">
									<h4 tabIndex={0} aria-label="Event Details headline">
										Event Details
									</h4>
								</div>
								<div
									className="event-details-info"
									tabIndex={0}
									aria-label="Event Start Date and Time details"
								>
									<strong>Date and Time Start: </strong>
									{utcToLocalDateTimeString(eventDetails.DateStart)}
								</div>
								<div
									className="event-details-info"
									tabIndex={0}
									aria-label="Event End Date and Time details"
								>
									<strong>Date and Time End: </strong>{" "}
									{utcToLocalDateTimeString(eventDetails.DateEnd)}
								</div>

								{/* temporary disabled */}
								{/* {eventDetails.PresentedBy ? (
						<div className="event-details-info" tabIndex={0} aria-label="Event Presented By details">
							<strong>Presented By: </strong> {eventDetails.PresentedBy}
						</div>
					) : null} */}
								<div
									className="event-details-info"
									tabIndex={0}
									aria-label="Event Location and Venue details"
								>
									<strong>Location: </strong> {eventDetails.Venue}
								</div>
								{eventDetails.Food ? (
									<div
										className="event-details-info"
										tabIndex={0}
										aria-label="Event Food related details"
									>
										<strong>What about food?: </strong> {eventDetails.Food}
									</div>
								) : null}
							</div>
						) : null}
						<div>
							{!isResetRegistration && eventRegistrationInfo?.IsRegistered && eventRegistrationInfo.TypeOfRSVP != "not_attend" ? (
								<div>
									<div
										className="event-thankyou alert alert-info"
										role="alert"
										aria-live="assertive"
									>
										{`You have already registered for this event as a ${eventRegistrationInfo.TypeOfRSVP} attendee, and your registration is ${eventRegistrationInfo.IsConfirmed ? "confirmed" : "not confirmed yet."}.`}
									</div>

									{eventRegistrationInfo.IsConfirmed && eventRegistrationInfo.TypeOfRSVP === "in_person" && <div
										className="event-thankyou alert alert-danger"
										role="alert"
										aria-live="assertive"
									>
										{`You can update your RSVP before the event. If you change it after confirming and in-person capacity is full, you'll be placed on the waitlist. Would you like to proceed with updating your RSVP?`}
									</div>}

									{!eventRegistrationInfo.IsConfirmed || eventRegistrationInfo.TypeOfRSVP === "virtual" && <div
										className="event-thankyou alert alert-danger"
										role="alert"
										aria-live="assertive"
									>
										{`You can update your RSVP before the event. Would you like to proceed with updating your RSVP? `}

									</div>}
									<Button
										onClick={resetRegistration}
										type="submit"
										text="Yes"
									/>
								</div>
							) : <div
								className="event-registration-options"
								tabIndex={0}
								aria-label="Event Registration options"
							>
								<div className="event-form">
									<label id="rsvp-label" htmlFor="RSVP">
										RSVP <sup>*</sup>
									</label>
									<ChoiceGroup
										id="RSVP"
										role="radiogroup"
										aria-labelledby="rsvp-label"
										required={true}
										selectedKey={rsvpKey}
										options={options_rsvp}
										onChange={onChangeRsvp}
										disabled={!eventDetails.IsPublished}
									/>
								</div>

								{eventDetails && eventDetails.Type === "Mixed" && showEventTypeOptions ? (
									<div className="event-form">
										<ChoiceGroup
											label="Type of RSVP"
											required={showEventTypeOptions}
											selectedKey={typeOfRsvpKey}
											options={options_typeOfRsvp}
											onChange={onChangeTypeOfRsvp}
											ariaLabelledBy={"Type of RSVP"}
											disabled={!eventDetails.IsPublished}
										/>
									</div>
								) : null}
								<div className="event-button">
									<Button
										disabled={resolvedButtonDisabled}
										onClick={handleSubmit}
										type="submit"
										text="Submit"
									/>
								</div>
								{isFormSent ? (
									<ShowEventMessage
										eventType={resolvedEventType}
										wailistEnabled={resolvedWailistEnable}
										type={null}
									/>
								) : null}
							</div>}
						</div>
						{!eventDetails.IsPublished && isSuperAdmin ? (
							<div className="event-details-publish-button">
								<Button
									text="Publish Event"
									onClick={handlePublishEvent}
									disabled={!areTemplatesReady}
								/>
							</div>
						) : null}
						{!eventDetails.IsPublished && isSuperAdmin && !areTemplatesReady ? (
							<div className="event-details-publish-message">
								<p className="alert alert-warning" role="alert">
									All Email Templates required to be completed before Publishing an Event.{" "}
									<Link to={ApplicationRoutePaths.eventSettings(eventDetails.Id)}>
										Go to Event Templates
									</Link>
								</p>
							</div>
						) : null}
					</div>
				) : null}
			</React.Fragment>
		);
	}
	if (!authMessage && authMessageCode === 403) {
		return (
			<ErrorPage header="Sorry, but you are not authorized to view this page." message={errorMessage} isHtml />
		);
	}
};

export default EventViewPage;
