import React, { useEffect } from "react";
import { QuestionForm } from "./shared/question.form";

/**
 * Page for rendering the QuestionForm ready for a
 * new entry, as a component initial state. Also,
 * allows for rendering with pre-loaded data, in that sense,
 * the route param should be identifying the respective Node.
 *
 * @returns React.FC
 */
const EditQuestionPage: React.FC = () => {
	useEffect(()=>{
		document.title='Edit Question - Strike Community'
	})
	return <QuestionForm />;
};

export default EditQuestionPage;
