import React, { useRef } from "react";
import { ServiceHub } from "../../../service";
import { useNodeForm } from "../../../hooks";
import { NodeForm } from "../../common/node";
import { NodeType } from "../../../contracts/models/strikeEnums";
import { ApplicationRoutePaths } from "../../router";
import { setIsOpenActionPostMessage } from "../../../redux/reducers/nodes";
import { useDispatch } from "react-redux";

/**
 * Re-usable Article creation form
 *
 * @returns React.FC
 */
export const ArticleForm: React.FC = () => {
	const dispatch = useDispatch();
	// APIs handled within
	const nodeAdminAPI = useRef(ServiceHub.nodeAdminAPI.start());
	const articleFormService = useRef(ServiceHub.articleForm.start());

	const {
		// Main form state elements
		formData,
		setHasError,
		canSubmit,
		isNewArticle
	} = useNodeForm(NodeType.Kbentry);

	/**
	 * Executes the necessary commands for Creating an Article,
	 * or updating an existing.
	 */
	const createArticle = () => {
		nodeAdminAPI.current
			.createArticle(formData)
			.then((result: any) => {
				if (result instanceof Error) {
					ServiceHub.message.error(result.message);
					throw result;
				}

				if (!result.Id) {
					// Redirect the user to the dashboard
					articleFormService.current.resetFormConfig();
					ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.dashboard());
					return;
				}

				// Action Post modal should be displayed to the user here instead of navigate to article
				if (isNewArticle) {
					dispatch(setIsOpenActionPostMessage(true));
				} else {
					// Redirects the user to the respective route
					ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.article(result.Id, result.Title));
				}

				articleFormService.current.resetFormConfig();
			})
			.finally(() => {
				articleFormService.current.setSubmitting(false);
			});
	};

	function onSubmit(event: React.FormEvent<HTMLFormElement>) {
		if (event.preventDefault) event.preventDefault();

		articleFormService.current.setSubmitting(true);

		if (!canSubmit) {
			setHasError(true);
			ServiceHub.message.warning("Please, before submitting, verify and fill all required fields.");
			articleFormService.current.setSubmitting(false);
			return;
		}

		setHasError(false);

		// Submit logic
		createArticle();

		return;
	}

	return !formData ? null : <NodeForm type={NodeType.Kbentry} onSubmit={onSubmit} />;
};
