import { useAuthUser } from "../auth/useAuthUser";

/**
 * Defines the type of the Function returned by the useNodeOwner hook.
 */
export declare type IsUserOwnerCallback = (node: { Id: number; AuthEmail?: undefined | string }) => boolean;

/**
 * Gets a function to check whether the Logged User owns a Node.
 */
export const useNodeOwner = (): IsUserOwnerCallback => {
	const { userPrincipalName } = useAuthUser();

	/**
	 * Checks whether the user UPN passing is the owner of the current Node loaded
	 */
	const isUserOwner = (node: { Id: number; AuthEmail?: undefined | string }) => {
		return !node.Id || (node.AuthEmail !== "" && userPrincipalName !== "" && userPrincipalName === node.AuthEmail);
	};

	return isUserOwner;
};
