import React from "react";
import { RichTextEditor } from "@coherence-design-system/rich-text-editor";

/**
 * Allows the Div to be used as a RichTextInput entry point.
 */
export declare type DivEventHandler = (
	event: React.FormEvent<HTMLElement> | React.FocusEventHandler<HTMLDivElement> | string
) => void;

/**
 * Allows Input/TextAreas to be used as a RichTextInput entry point.
 */
export declare type InputTextEventHandler = (
	event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | string
) => void;

/**
 * Controls the properties offered by Strike's
 * Rich Text Input component.
 */
interface IRichTextInput {
	id?: undefined | string;
	placeholder?: undefined | string;
	value?: undefined | string;
	label?: undefined | string;
	onChange?: undefined | DivEventHandler | InputTextEventHandler;
	onBlur?: undefined | ((event: React.FocusEvent<Element, Element>) => void);
	className?: undefined | string;
	characterMax?: undefined | number;
	sourceMode?: undefined | boolean;
	required?: undefined | boolean;
	error?: undefined | boolean;
}

/**
 * Structures the component to act as a Rich Text
 * editor.
 * Also called RTE.
 *
 * @returns React.FC<IRichTextInput>
 */
const RichTextInput: React.FC<IRichTextInput> = (props) => {
	const { id, placeholder, value, onChange, characterMax, label, ...otherProps } = props;
	const conditions = {
		id: id ?? undefined,
		placeholder: placeholder ?? undefined,
		value: value ?? undefined,
		characterMax: characterMax ?? 9999,
		label: label ?? "",
		onChange: onChange ?? undefined
	};

	return props.sourceMode ? (
		<div
			contentEditable
			role="textarea"
			className={`mt-1rem padding-1rem ${props.className ?? ""}`}
			onBlur={conditions.onChange as DivEventHandler}
			aria-label={`Viewing HTML Source. Content: ${conditions.value}`}
		>
			{conditions.value ?? ""}
		</div>
	) : (
		<RichTextEditor
			characterMax={conditions.characterMax ?? undefined}
			label={conditions.label}
			id={conditions.id}
			placeholder={conditions.placeholder}
			value={conditions.value}
			onChange={conditions.onChange as InputTextEventHandler}
			styles={{
				commandBarContainer: {
					borderColor: "var(--input-border-color)"
				}
			}}
			ariaLabel={`Viewing Rich Text Editor. Content: ${conditions.value}`}
			onBlur={props.onBlur}
			{...otherProps}
		/>
	);
};

export { RichTextInput };
