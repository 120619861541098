import * as React from "react";
import { Breadcrumb } from "@fluentui/react/lib/Breadcrumb";
import { useEffect, useState } from "react";
import { NodeAPIServiceInstance } from "../../../service/NodeAPI";
import { IStrikeNodeBreadcrumb } from "../../../contracts/models";
import { ServiceHub } from "../../../service";
import { ApplicationRoutePaths } from "../../router";

/**
 * nodeId from showArticle/showQuestion to track the Breadcrumb route
 */
interface IShowArticleProps {
	nodeId: number;
}

/**
 * Breadcrum Basic to show tree route
 * @returns
 */
export const Breadcrumbs: React.FunctionComponent<IShowArticleProps> = ({ nodeId }) => {
	const [breadcrumbRouteNodes, setBreadcrumbRouteNodes] = useState([]);

	useEffect(() => {
		if (nodeId !== 0 && nodeId !== undefined && nodeId !== null) {
			getBreadcrumbRoute();
		}
	}, [nodeId]);

	/**
	 * Api Call to get all the Parent Nodes for this specific nodeId
	 */
	const getBreadcrumbRoute = async () => {
		const res = await NodeAPIServiceInstance.getBreadcrumbRoute(nodeId.toString());
		if (res) {
			createBreadcrumbRouteWithResponse(res);
		}
	};

	/**
	 * Navigation to parent spaces
	 * @param item: we are passing the nodeElement which cames from BackEnd, to construct the navigation
	 */
	const onBreadcrumbItemClicked = (item: IStrikeNodeBreadcrumb) => {
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.spaces(item.NodeId.toString(), item.Title));
	};

	/**
	 * Creation of the Breadcrumb items
	 * @param res
	 */
	const createBreadcrumbRouteWithResponse = (res) => {
		const items = res
			.filter((item) => !item.IsCurrentItem)
			.map((item) => {
				return {
					text: item.Title,
					key: item.Key,
					onClick: item.IsCurrentItem ? undefined : () => onBreadcrumbItemClicked(item),
					isCurrentItem: false
				};
			});
		setBreadcrumbRouteNodes(items);
	};

	return (
		<Breadcrumb
			className="breadcrumb-container"
			items={breadcrumbRouteNodes}
			maxDisplayedItems={5}
			ariaLabel="Page Breadcrumbs"
			overflowAriaLabel="More links"
			focusZoneProps={{ handleTabKey: 1 }}
		/>
	);
};
