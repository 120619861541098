import { IFollowRelation } from "../../contracts";
import {
	addFollower,
	removeFollower,
	resetFollowers,
	resetFollowersToShow,
	setFollowers,
	setFollowersToShow,
	setQueried,
	setQuerying,
	resetAll,
	setIsFavourite
} from "../../redux/reducers/follow";
import { followSelectors } from "../../redux/selectors/follow";
import store from "../../redux/store";
import BaseHttpAPI from "../base/BaseHttpAPI";

export class FollowAPI extends BaseHttpAPI {
	constructor() {
		super("node/follow");
	}

	/**
	 * Sets Length of count of the users to render offset
	 * @param followersToShow
	 */
	async setFollowersToShow(followersToShow: number) {
		store.dispatch(setFollowersToShow(followersToShow));
	}

	/**
	 * resets offset pagination to the initial state offset value
	 */
	async resetFollowersToShow() {
		store.dispatch(resetFollowersToShow());
	}

	/**
	 * Used to find userName from follow relation in the state layer
	 * @param upn
	 * @returns
	 */
	getFollowerNameByUpn(upn: string): string {
		return followSelectors.getFollowerNameById(store.getState(), upn);
	}

	/**
	 * Used to find userName on Node Top Contributors
	 * @param upn
	 * @returns
	 */
	getTopContributorNameByUpn(upn: string): string {
		return followSelectors.getTopContributorNameById(store.getState(), upn);
	}

	/**
	 * Manage to set all followers object data in redux state layer
	 * @param followers
	 */
	async setFollowers(followers: IFollowRelation[]) {
		store.dispatch(setFollowers(followers));
	}

	/**
	 * Resets the Followers data in state
	 */
	async resetFollowers() {
		store.dispatch(resetFollowers());
	}

	/**
	 * Boolean flag to manage querying state in component
	 * @param querying
	 */
	async setQuerying(querying: boolean) {
		store.dispatch(setQuerying(querying));
	}

	/**
	 * Boolean flag to manage status about data queried in component
	 * @param queried
	 */
	async setQueried(queried: boolean) {
		store.dispatch(setQueried(queried));
	}

	/**
	 * Used to add a single follower on button click action
	 * @param follower
	 */
	async addFollower(follower: IFollowRelation) {
		store.dispatch(addFollower(follower));
	}

	/**
	 * Used to rollback and manage to unfollow action
	 * @param followerUpn
	 */
	async removeFollower(followerUpn: number | string) {
		store.dispatch(removeFollower(followerUpn));
	}

	/**
	 * Used to reset all component state values
	 */
	async resetAll() {
		store.dispatch(resetAll());
	}

	/**
	 * Boolean flag to check if node isFavourite or not 
	 * @param isFavourite
	 */
	async setIsFavourite(isFavourite: boolean) {
		store.dispatch(setIsFavourite(isFavourite));
	}
}
