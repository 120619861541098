// Necessary interface imports
import { RouteObject } from "react-router-dom";
import { RoutePathStrings } from "./applicationRoutePaths";

// Page Component imports
import QuestionEditPage from "../../question/edit.question";
import Question from "../../question/question";
import QuestionsDashboard from "../../dashboard/questions/dashboard.questions";

/**
 * Question Route Map.
 * Used internally to organize groups of similar RouteObjects.
 */
export const QuestionRoutes: RouteObject[] = [
	// View Question
	{
		path: RoutePathStrings.question,
		Component: Question
	},
	// Create Question
	{
		path: RoutePathStrings.questionCreate,
		Component: QuestionEditPage
	},
	// Edit Question
	{
		path: RoutePathStrings.questionEdit,
		Component: QuestionEditPage
	},
	// Questions Dashboard
	{
		path: RoutePathStrings.questionsDashboard,
		Component: QuestionsDashboard
	}
];
