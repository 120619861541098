import React from 'react';
import ErrorPage from './ErrorPage';


type Props = {
    header?: string;
    message: string;
};

export const AuthErrorPage = ({ error }) => {
    return <ErrorPage
        header="Uh oh, it looks like we were not able to authenticate you to log in"
        message={error.errorCode} />;
}

export default AuthErrorPage;