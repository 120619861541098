/**
 * Loader indicator Component
 */
import * as React from "react";
import useLoaderIndicatorState from "./loaderIndicator.utils";

import "./loaderBar.css";

/**
 * Controls the params of the Loader Indicator component.
 */
export interface ILoaderIndicatorProps {
	visible?: undefined | boolean;
}

/**
 * Loader Indicator Component showing a progress bar
 * Visibility managed by redux
 * @returns loaderComponent
 */

const LoaderIndicator: React.FC<ILoaderIndicatorProps> = (props) => {
	const { loaderVisible } = useLoaderIndicatorState(props.visible ?? undefined);

	return loaderVisible ? (
		<div className="loader-bar-container">
			<div className="loader-bar"></div>
		</div>
	) : null;
};

export default LoaderIndicator;
