import { useEffect, useRef } from "react";
import { ServiceHub } from "../../service";
import { spacesSelectors } from "../../redux/selectors/spaces";
import { useSelector } from "react-redux";
import { ITreeItem } from "@coherence-design-system/tree-view";
import { SpacesAPIService } from "../../service/space/SpacesAPI";
import { ISpaceItem } from "../../contracts";

/**
 * Contract for the Spaces Slice hook.
 */
interface ISpaceSliceHook {
	service: SpacesAPIService;
	spaces: ISpaceItem[];
	treeView: ITreeItem[];
	selectedId: null | number | string;
	selectedItem: { id: string; text: string };
	loading: boolean;
	loaded: boolean;
	open: boolean;
	setOpen: (open: boolean) => void;
}

/**
 * Prepares the Spaces List, and returns a controller
 * hook which can access Redux state layer for Spaces Slice.
 *
 * @returns ISpaceSliceHook
 */
export const useSpaceSlice = (): ISpaceSliceHook => {
	const spaceAPI = useRef(ServiceHub.spacesAPI.start());
	const spaceTree: ITreeItem[] = useSelector(spacesSelectors.getParentSpacesDropdownOptions);
	const selectedSpaceId: null | number | string = useSelector(spacesSelectors.getSelectedId);
	const selectedItem: { id: string; text: string } = useSelector(spacesSelectors.getSelectedItem);
	const spaces: ISpaceItem[] = useSelector(spacesSelectors.getSpacesList);
	const loading: boolean = useSelector(spacesSelectors.getLoading);
	const loaded: boolean = useSelector(spacesSelectors.getLoaded);
	const open: boolean = useSelector(spacesSelectors.getOpen);

	useEffect(() => {
		/**
		 * Gets the spaces list to keep it ready for user navigation.
		 */
		async function loadSpaces() {
			if (!loading && !loaded) {
				spaceAPI.current.setLoading(true);

				spaceAPI.current
					.getSpaces()
					.then(async (items) => {
						spaceAPI.current.setSpaces(items);
						spaceAPI.current.setLoading(false);
						spaceAPI.current.setLoaded(true);
					})
					.catch((ex) => {
						spaceAPI.current.setLoading(false);
						spaceAPI.current.setLoaded(true);
					});
			}
		}

		// 1. Pre-loads the spaces list for using
		loadSpaces();
	}, [loading, loaded, spaceAPI.current]);

	return {
		service: spaceAPI.current,
		treeView: spaceTree,
		selectedId: selectedSpaceId,
		selectedItem,
		loading,
		loaded,
		open,
		spaces,
		setOpen: (open: boolean) => spaceAPI.current.setOpen(open)
	};
};
