import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ITreeItem, TreeView } from "@coherence-design-system/tree-view";

import { NodeTreeItem } from "../../contracts";
import { useTreeViewState } from "../../hooks";
import { ArticleTreeViewShimmer } from "../common/shimmer";
import { ServiceHub } from "../../service";
import { ApplicationRoutePaths } from "../router";
import { SearchBox } from "@fluentui/react";
import { useTreeNavigationSearch } from "../../hooks/treeNavigation/useTreeNavigation";
import { useDispatch } from "react-redux";
import { _setTreeNavSearchText } from "../../redux/reducers/appTreeView";

interface Props {
	sideColumnHeight: number;
}

/**
 * Prepares the URL to be used by the navigator
 *
 * @param item The article item to prepare a URL to.
 */
function prepareUrl(item: ITreeItem): string {
	const resolvedPath = ApplicationRoutePaths.article(item.id, item.title);

	return resolvedPath;
}

/**
 * Component which displays the treeView Links for the aside component in the page layout
 * Has a Traker to manage the actual position in the list
 */
export const TreeViewNavigation: React.FunctionComponent<Props> = ({ sideColumnHeight }) => {
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useDispatch();
	const viewNodeId = params.id;
	const nodeTitle = params?.title ?? "";

	const treeViewService = useTreeViewState(viewNodeId);
	const treeNavSearch = useTreeNavigationSearch();
	const treeNavigationSearchText = treeNavSearch.treeNavSearchText;
	const { rawData, expandedItems, loading, loaded } = treeViewService;
	const searchTimeout = useRef<NodeJS.Timeout>(null);

	const parentNodes = useMemo(() => {
		const _parent = rawData.filter((item) => undefined === item.Parentid || item.Parentid === null);

		return _parent;
	}, [rawData]);

	const getChildren = useCallback(
		(parentid: number | string): NodeTreeItem[] => {
			const foundChildren = rawData.filter((item) => item.Parentid === parseInt(`${parentid}`));

			return foundChildren;
		},
		[rawData]
	);

	const getItem = useCallback(
		(itemId: string) => {
			return rawData.find((rawItem) => rawItem.Id.toString() === itemId);
		},
		[rawData]
	);

	const getItemExpanded = useCallback(
		(itemId: string) => {
			return treeViewService.getIsExpanded(itemId);
		},
		[treeViewService]
	);

	const getItemSelected = useCallback(
		(itemId: string) => {
			return viewNodeId === itemId;
		},
		[viewNodeId]
	);

	/**
	 * Handles the event of an item click
	 */
	const onItemClick = useCallback(
		async (ev: React.MouseEvent<HTMLElement>, item: ITreeItem) => {
			if (item.id === viewNodeId) {
				return;
			}

			const navigateURL = prepareUrl(item);

			treeViewService.reset();

			ServiceHub.appManagementAPI.navigate(navigateURL);
		},
		[viewNodeId]
	);

	/**
	 * Handles the event of expanding/collapsing
	 */
	const onItemToggled = useCallback(
		async (item: ITreeItem, isExpanded: boolean) => {
			let newSelectedList = [...expandedItems];

			if (isExpanded && expandedItems.includes(item.id.toString())) {
				newSelectedList = newSelectedList.filter((filtering) => filtering === item.id);
			} else {
				newSelectedList.push(item.id.toString());
			}

			treeViewService.setExpandedItems(newSelectedList);
		},
		[expandedItems]
	);

	/**
	 * Converts a basic requested data to the ITreeView object interface
	 */
	const convertItemToTreeItem = useCallback(
		(data: NodeTreeItem[]): ITreeItem[] => {
			if (!data || data.length === 0) return [];

			const preparedSet: ITreeItem[] = [];

			data.forEach((rawItem) => {
				const isItemSelected = getItemSelected(rawItem.Id.toString());
				const isItemExpanded = getItemExpanded(rawItem.Id.toString());

				const currentItem: ITreeItem = {
					id: rawItem.Id.toString(),
					title: rawItem.Title,
					children: [],
					isSelected: isItemSelected,
					isExpanded: isItemSelected || isItemExpanded,
					onClick: onItemClick,
					onItemToggled: onItemToggled
				};

				const childrenCollection = getChildren(currentItem.id);

				currentItem.children =
					!childrenCollection || childrenCollection.length === 0
						? []
						: convertItemToTreeItem(childrenCollection);

				preparedSet.push(currentItem);
			});

			return preparedSet;
		},
		[getItemSelected, getChildren, getItemExpanded, onItemClick, onItemToggled]
	);

	/**
	 * Funtion to expand the upstream (parent) links from the current item
	 */
	const expandUpstream = useCallback(
		(deepestId: string, _expandedItemsTracker = expandedItems) => {
			const newExpandedItems = [..._expandedItemsTracker];
			const item = getItem(deepestId);

			if (!item) {
				return;
			}

			// Guarantees the pre-selection of the current item, in case not yet present
			if (!newExpandedItems.includes(item.Id.toString())) {
				newExpandedItems.push(item.Id.toString());
			}

			// If we validate this sentence, it means we have a root item
			// Which is the ending condition for the recursion
			if (!item.Parentid || item.Parentid === null) {
				treeViewService.setExpandedItems(newExpandedItems);

				return;
			}

			const itemParent = rawData.find((nd) => nd.Id === item.Parentid);

			if (!itemParent) {
				return;
			}

			if (!newExpandedItems.includes(itemParent.Id.toString())) {
				newExpandedItems.push(itemParent.Id.toString());
			}

			expandUpstream(itemParent.Id.toString(), newExpandedItems);
		},
		[getItem, expandedItems, rawData]
	);

	/**
	 * Careful: This is the root of the construction for the TreeView component.
	 * Starts from the parent collection.
	 */
	const rootTreeData = useMemo(() => {
		if (!loaded) return [{ id: "0", title: "Loading..." }];

		const treeViewData = convertItemToTreeItem(parentNodes);

		return treeViewData;
	}, [convertItemToTreeItem, parentNodes, loaded]);

	/**
	 * Expands the node and its upstream elements,
	 * Once the procedure has not been done yet at this stage.
	 */
	useEffect(() => {
		async function expand() {
			// Monitor the changes of expandedItems
			if (viewNodeId && rawData.length > 0 && expandedItems.length === 0) {
				expandUpstream(viewNodeId);
			}
		}

		expand();
	}, [viewNodeId, expandedItems, rawData, expandUpstream]);

	/**
	 * Effect for adding the missing URL part, when the navigation achieves the article with the
	 * ID only.
	 */
	useEffect(() => {
		if ((!nodeTitle || nodeTitle === "") && viewNodeId && rawData.length > 0) {
			const item = getItem(viewNodeId);
			navigate(prepareUrl({ id: item.Id.toString(), title: item.Title }), { replace: true });
		}
	}, [nodeTitle, viewNodeId, rawData, getItem]);

	const handleTreeNavSearch = (event?: React.ChangeEvent<HTMLInputElement>, searchValue?: string) => { 
		dispatch(_setTreeNavSearchText(searchValue));
	}

	/**
	 * Recursive method to allow find results on children
	 * @param items 
	 * @param search 
	 * @returns 
	 */
	const filterItems = (items: ITreeItem[], search: string): ITreeItem[] => {
		return items.filter((item) => {
			const matchTitle = item['title'].includes(treeNavigationSearchText);
			const matchChidren = item.children ? filterItems(item.children, treeNavigationSearchText).length > 0 : false;
			return matchTitle || matchChidren;
		});
	}

	const processedRootTreeData = useMemo(() => {
		if(treeNavigationSearchText) {
			const filteredItems = filterItems(rootTreeData, treeNavigationSearchText);
			return filteredItems;
		} else {
			return rootTreeData;
		}
	}, [treeNavigationSearchText, rootTreeData, parentNodes, convertItemToTreeItem]);

	return (
		<>	
			<div className="treeview-navigation-search-container">
				<SearchBox
					className="tree-navigation-search-input"
					placeholder="Search Node by keyword"
					onChange={handleTreeNavSearch}
					autoComplete="off"
					value={treeNavigationSearchText}
				/>
			</div>
			<div className="tree-view-navigation-article-container">
				<div className="treeview-navigation-container">
					{loading && sideColumnHeight === 0 ? (
						<ArticleTreeViewShimmer />
					) : (
						<>
							<TreeView
								data={processedRootTreeData}
								disabledItemInteractions={{
									allowActionsMenu: false,
									allowCheckbox: false,
									allowOnClick: false
								}}
								allowOnClickIfItemHasCheckbox={false}
							/>
						</>
					)}
				</div>
			</div>
		</>
	);
};
