import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { filterSelectors } from "../../redux/selectors/filter";

import { useEffect } from "react";
import { ServiceHub } from "../../service";
import { ApplicationRoutePaths } from "../router";
import { useLocation } from "react-router-dom";
import { Filter } from "./filter";

interface IFilterBarProps {}

/**
 * Filter wrapper component to handle conditions where show filter
 */
export const FilterBar: React.FunctionComponent<IFilterBarProps> = () => {
	const isFilterVisible = useSelector(filterSelectors.getIsFilterVisible);
	const location = useLocation();

	/**
	 * Specific logic for /topics/etc/etc path names
	 */
	const topicsAllowed = useMemo(
		() =>
			location.pathname.includes(ApplicationRoutePaths.topicsDashboard()) &&
			location.pathname !== ApplicationRoutePaths.topicsDashboard(),
		[location]
	);

	/**
	 * Exact routes allowed matching
	 */
	const allowedRoutes = useMemo(
		() => [
			ApplicationRoutePaths.root(),
			ApplicationRoutePaths.articlesDashboard(),
			ApplicationRoutePaths.questionsDashboard(),
		],
		[]
	);

	useEffect(() => {
		const cleanPathName =
			location.pathname.endsWith("/") && location.pathname.length > 1
				? location.pathname.substring(0, location.pathname.length - 1)
				: location.pathname;

		if (
			allowedRoutes.includes(cleanPathName) ||
			cleanPathName.includes(ApplicationRoutePaths.spacesDashboard()) ||
			topicsAllowed
		) {
			ServiceHub.filterAPI.start().setFilterVisible(true);
		} else {
			ServiceHub.filterAPI.start().setFilterVisible(false);
		}
	}, [allowedRoutes, location, topicsAllowed]);

	return <>{isFilterVisible ? <Filter /> : null}</>;
};
