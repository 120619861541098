import React from "react";

import LoaderIndicator from "../../loaderIndicator/loaderIndicator";
import { Message } from "../../common/message";
import { ToastContainer } from "react-toastify";

/**
 * Strike Layout Shared components.
 * Includes mainstream utilities below:
 * - Loader Indicator
 * - Message Modal
 * - Toast Container (quick feedbacks)
 *
 * @returns A Function component to be used by Layout main component.
 */
export const LayoutSharedComponents: React.FC = () => {
	return (
		<>
			<ToastContainer />
			<LoaderIndicator />
			<Message />
		</>
	);
};
