import axios from "axios";
import { GetAppEnvironmentConfigs } from "../../appConfigs";
import { IStrikeAuthoringApiResponse } from "../../contracts/models";

/**
 * Abstract Base HTTP API for using with verbs:
 * * get
 * * post
 * * put
 * * delete
 */
export default abstract class BaseHttpAPI {
	protected endpointPrefix = "";

	constructor(apiEndPointPrefix: string) {
		this.endpointPrefix = apiEndPointPrefix;
	}

	/**
	 * Manage error message
	 * @param error
	 * @returns
	 */
	protected constructErrorMessage(error: any): string {
		const errorObject = error?.response?.data?.errors ?? null;
		const errorProps = errorObject !== null ? Object.keys(errorObject) : [];
		const errorMessages = errorObject !== null ? Object.values(errorObject) : [];

		const bindErrorInfo = () =>
			errorProps.map((prop, index) => {
				return `${prop}: ${errorMessages[index]}`;
			});

		return errorObject !== null ? bindErrorInfo() : error.message;
	}

	/**
	 * Builds base url with env configs
	 */
	protected get BaseUrl() {
		const environmentConfigs = GetAppEnvironmentConfigs();
		if (environmentConfigs) {
			return environmentConfigs.endpoints.strikeApiBaseUrl;
		}
		console.error("Cannot retrieve StrikeApiBaseUrl because the environment configs doesn't exist");
		return "";
	}

	/**
	 * Prepares the URL for the request,
	 * Allowing to use only partials.
	 *
	 * @param partialUrl
	 * @returns
	 */
	protected prepareUrl(partialUrl: string): string {
		const fullUrl =
			`${this.BaseUrl.endsWith("/") ? this.BaseUrl : `${this.BaseUrl}/`}` +
			`${
				!this.endpointPrefix
					? ""
					: this.endpointPrefix.endsWith("/")
					? this.endpointPrefix
					: `${this.endpointPrefix}/`
			}` +
			`${partialUrl}`;

		return fullUrl;
	}

	/**
	 * Executes a forced re-configuration of Axios interceptors,
	 * clearing the response ones.
	 *
	 * @returns
	 */
	clearResponseInterceptors(): BaseHttpAPI {
		axios.interceptors.response.clear();

		return this;
	}

	/**
	 * Gets a file based on its storage URL.
	 * For now, supports responses with Content-Type "application/octet-stream".
	 * Needs further implementation for other file transfer protocols.
	 *
	 * @param url The URL of the file being downloaded
	 * @returns A blob with the content of it
	 */
	protected getFile(url: string): Promise<Blob> {
		return new Promise<Blob>((resolve: (value: Blob | PromiseLike<Blob>) => void, reject: (error: any) => void) => {
			axios
				.get<IStrikeAuthoringApiResponse<string>>(this.prepareUrl(url), {
					responseType: "blob"
				})
				.then((response) => {
					if (response.headers["content-type"] === "application/octet-stream") {
						if (!(response?.data instanceof Blob)) throw Error(`Invalid file content: ${response?.data}`);

						const fileBlob = response.data as Blob;

						return resolve(fileBlob);
					} else {
						throw new Error("Unable to handle unknown content-type of file response");
					}
				})
				.catch((error) => reject(error));
		});
	}

	/**
	 * Executes http get to a given url
	 * @param url
	 * @param abortController
	 * @returns
	 */
	protected get<TResponse>(url: string, abortController?: undefined | AbortController): Promise<TResponse> {
		return new Promise<TResponse>(
			(resolve: (value: TResponse | PromiseLike<TResponse>) => void, reject: (error: any) => void) => {
				axios
					.get<IStrikeAuthoringApiResponse<TResponse>>(
						this.prepareUrl(url),
						abortController?.signal
							? {
									signal: abortController?.signal ?? new AbortController().signal
							  }
							: undefined
					)
					.then((response) => {
						resolve(!response.data.Data ? (response.data as TResponse) : (response.data.Data as TResponse));
						// TODO: Review standard resolve(!response.data.Data);
					})
					.catch((error) => reject(error));
			}
		);
	}

	/**
	 * Executes http post to a given url
	 */
	protected post<TRequestBody, TResponse>(url: string, requestBody: TRequestBody): Promise<TResponse> {
		return new Promise<TResponse>((resolve, reject) => {
			axios
				.post<IStrikeAuthoringApiResponse<TResponse>>(this.prepareUrl(url), requestBody)
				.then((response) => {
					resolve(!response.data.Data ? (response.data as TResponse) : (response.data.Data as TResponse));
					// TODO: Review standard resolve(!response.data.Data);
				})
				.catch((error) => reject(error));
		});
	}

	/**
	 * Executes http put method to a given url
	 */
	protected put<TRequestBody, TResponse>(url: string, requestBody: TRequestBody): Promise<TResponse> {
		return new Promise<TResponse>((resolve, reject) => {
			axios
				.put<IStrikeAuthoringApiResponse<TResponse>>(this.prepareUrl(url), requestBody)
				.then((response) => {
					resolve(!response.data.Data ? (response.data as TResponse) : (response.data.Data as TResponse));
					// TODO: Review standard resolve(!response.data.Data);
				})
				.catch((error) => reject(error));
		});
	}

	/**
	 * Executes http patch method to a given url
	 */
	protected patch<TRequestBody, TResponse>(url: string, requestBody?: TRequestBody): Promise<TResponse> {
		return new Promise<TResponse>((resolve, reject) => {
			axios
				.patch<IStrikeAuthoringApiResponse<TResponse>>(this.prepareUrl(url), requestBody)
				.then((response) => {
					resolve(!response.data.Data ? (response.data as TResponse) : (response.data.Data as TResponse));
				})
				.catch((error) => reject(error));
		});
	}

	/**
	 * Executes http delete to a given url
	 */
	protected delete<TRequestBody>(url: string, data?: undefined | TRequestBody): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.delete(this.prepareUrl(url), { data, headers: { "Content-Type": "application/json" } })
				.then((response) => {
					resolve(!response.data.Data ? response.data : response.data.Data);
					// TODO: Review standard resolve(!response.data.Data);
				})
				.catch((error) => reject(error));
		});
	}
}
