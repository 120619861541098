/**
 * Redux config to manage the app
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { authInitialState } from "../../models/slices/";

/**
 * User Auth state layer, also named after a Redux Slice.
 */
export const authSlice = createSlice({
	name: AppSlices.auth,
	initialState: authInitialState,
	reducers: {
		setUserDisplayName: (state, action: PayloadAction<string>) => {
			state.user.displayName = action.payload;
		},
		setUserPrincipalName: (state, action: PayloadAction<string>) => {
			state.user.principalName = action.payload;
		},
		setUserEmail: (state, action: PayloadAction<string>) => {
			state.user.email = action.payload;
		},
		setUserId: (state, action: PayloadAction<string>) => {
			state.user.id = action.payload;
		},
		setCanAccessCreateArticleComponents: (state, action: PayloadAction<boolean>) => {
			state.canAccessCreateArticleComponents = action.payload;
		},
		setAccessRequesting: (state, action: PayloadAction<boolean>) => {
			state.accessRequesting = action.payload;
		},
		setAccessRequested: (state, action: PayloadAction<boolean>) => {
			state.accessRequested = action.payload;
		},
		setAuthoriableId: (state, action) => {
			state.user.authoriableId = action.payload;
		},
		setIsSuperAdmin: (state, action: PayloadAction<boolean>) => {
			state.isSuperAdmin = action.payload;
		},
		setSuperAccessRequesting: (state, action: PayloadAction<boolean>) => {
			state.superAccessRequesting = action.payload;
		},
		setSuperAccessRequested: (state, action: PayloadAction<boolean>) => {
			state.superAccessRequested = action.payload;
		}
	}
});

export const {
	setUserDisplayName,
	setUserPrincipalName,
	setUserId,
	setAuthoriableId,
	setUserEmail,
	setCanAccessCreateArticleComponents,
	setAccessRequesting,
	setAccessRequested,
	setIsSuperAdmin,
	setSuperAccessRequesting,
	setSuperAccessRequested
} = authSlice.actions;

export default authSlice.reducer;
