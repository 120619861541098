import { ISpaceFormData, ISpaceFormResponse } from "../../contracts";
import { IProcessCoverImage, NodePreprocessingAPI } from "../base/NodePreprocessingAPI";

/**
 * Space Admin tooling services class.
 */
export class SpaceAdminAPI extends NodePreprocessingAPI {
	constructor() {
		super("admin");
	}

	/**
	 * Create new space endpoint
	 * @param spaceBody
	 * @returns
	 */
	async createSpace(spaceBody: ISpaceFormData): Promise<boolean> {
		const spaceBodyCopy: any = { ...spaceBody, coverImagePath: spaceBody.coverImageName };
		await this.processCoverImage(spaceBodyCopy as IProcessCoverImage, (uploadedResult) => {
			spaceBodyCopy.coverImageName = uploadedResult;
		});
		return await this.post<ISpaceFormData, { HasError: boolean }>(`space`, spaceBodyCopy)
			.then((result) => {
				return !result.HasError;
			})
			.catch(() => false);
	}

	/**
	 * Edit existing space endpoint
	 * @param spaceId
	 * @param spaceBody
	 * @returns
	 */
	async editSpace(spaceId: string, spaceBody: ISpaceFormData): Promise<boolean> {
		const spaceBodyCopy: any = { ...spaceBody, coverImagePath: spaceBody.coverImageName };
		await this.processCoverImage(spaceBodyCopy as IProcessCoverImage, (uploadedResult) => {
			spaceBodyCopy.coverImageName = uploadedResult;
		});
		return await this.put<ISpaceFormData, { HasError: boolean }>(`space/${spaceId}`, spaceBodyCopy)
			.then((result) => true)
			.catch(() => false);
	}

	/**
	 * Delete existing space
	 * @param spaceId
	 * @param spaceBody
	 * @returns
	 */
	async deleteSpace(spaceId: string): Promise<boolean> {
		return await this.delete(`space/${spaceId}`)
			.then((result) => {
				return result.Value;
			})
			.catch(() => false);
	}

	/**
	 * Get Space data to edit
	 * @param spaceId
	 * @returns
	 */
	async getSpace(spaceId: string): Promise<ISpaceFormResponse> {
		return await this.get(`space/${spaceId}`)
			.then((result) => result as ISpaceFormResponse)
			.catch((error) => error);
	}
}
