import nodeActivityConstants from "../../../components/common/node/activity/constants";
import { ICommentSlice } from "../../../contracts/slices/comment/ICommentSlice";

/**
 * The Initial State of Node Management slice.
 */
export const commentInitialState: ICommentSlice = {
	data: {
		allNodeComments: [],
		likeCount: 0,
		likeByUsers: "",
		nodeCommentsTotal: 0,
		commentsToShow: nodeActivityConstants.maxOffset,
		editingIds: []
	},
	ui: {
		isToggledComments: false,
		isToggledCommentForm: false,
		isToggledCommentQuestionForm: false,
		isNodeAlreadyLiked: false,
		replyExpandedId: null,
		submitting: false
	},
	form: {
		commentText: "",
		nodeId: "",
		bodySourceMode: false
	}
};
