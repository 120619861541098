import React from "react";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from "@fluentui/react/lib/DetailsList";
import { utcToLocalDateTimeString } from "../../../lib/strikeLibrary";
import { useMemo } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { ServiceHub } from "../../../service";
import { CustomPanel } from "../../common/panel/Panel";
import { IconButton } from "../../common/button";
import { useEventForm } from "../../../hooks/event/useEventForm";
import { ApplicationRoutePaths } from "../../router";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { EventAllowUsers } from "./event.allowusers";
import { ConfirmationMessage } from "../../common/message";
import { useState } from "react";
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField } from "@fluentui/react";
import { AllowUsersType } from "../../../contracts/models/strikeEnums";

/**
 * Component to List Events and display actions
 * @returns React.FC
 */
export const EventSearchList: React.FC = () => {
	const eventManageAPI = useRef(ServiceHub.eventManageAPI.start());
	const eventFormService = useRef(ServiceHub.eventForm.start());
	const {
		eventsList,
		isAllowUsersPanelOpen,
		isOpenDeleteEventModal,
		isOpenPublishEventModal,
		eventFilterSearchText,
		eventFilterSearchTypeOption,
		eventFilterSearchStatus
	} = useEventForm();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [currentEventId, setCurrentEventId] = useState<null | string>(null);
	const [allowUserType, setAllowUserType] = useState<null | string>(null);

	/**
	 * Re=routing to Event Id Form for specific event Id
	 * @param eventId
	 * @param eventTitle
	 */
	const handleEventEdit = (eventId: string, eventTitle: string) => {
		navigate(ApplicationRoutePaths.eventEdit(eventId));
	};

	/**
	 * Handle Delete event by Id calling API
	 */
	const deleteEventById = () => {
		// TODO: implement delete feature
	};

	/**
	 * Opening Delete Confirmation Modal
	 * @param eventId
	 */
	const handleEventRemove = (eventId: string) => {
		eventFormService.current.setIsOpenDeleteEventModal(true);
		ServiceHub.appManagementAPI.setConfirmationCallback(deleteEventById);
		setCurrentEventId(eventId);
	};

	/**
	 * On close Delete Event Modal
	 */
	const onDeleteEventModalClose = () => {
		eventFormService.current.setIsOpenDeleteEventModal(false);
		setCurrentEventId(null);
	};

	/**
	 * Opening Allow Users Panel and setting Event Id to reuse inside
	 * @param eventId
	 */
	const handleAllowUsers = (eventId: string, type: string) => {
		eventFormService.current.setIsAllowUsersPanelOpen(true);
		eventFormService.current.resetEventAllowedUserList();
		eventFormService.current.setIsAllowUsersChanged(false);
		searchParams.set("eventId", eventId);
		searchParams.set("userType", type);
		setSearchParams(searchParams);

		const userType = searchParams.get("userType");
		if (userType === AllowUsersType.Register) {
			setAllowUserType(AllowUsersType.Register)
		} else {
			setAllowUserType(AllowUsersType.Voluntary)
		}
	};

	/**
	 * On Close Allow Users Panel
	 */
	const dismissPanel = () => {
		searchParams.delete("eventId");
		searchParams.delete("userType");
		setSearchParams(searchParams);
		eventFormService.current.setIsAllowUsersPanelOpen(false);
	};

	/**
	 * Redirection based on id to show Event Template Settings view
	 * @param eventId
	 */
	const handleEmailSettings = (eventId: string) => {
		navigate(ApplicationRoutePaths.eventSettings(eventId));
	};

	/**
	 * Passing Event Id to API to unpublish specific event
	 */
	const unpublishEventById = () => {
		if (currentEventId) {
			eventManageAPI.current.unpublishEvent(currentEventId).then((result: any) => {
				if (result instanceof Error) {
					ServiceHub.message.error(result.message);
					throw result;
				}

				if (!result) {
					// Redirect the user to the event dashboard
					ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
					return;
				}
				eventFormService.current.setIsOpenPublishEventModal(false);
				eventManageAPI.current
					.getEvents()
					.then(async (items) => {
						eventFormService.current.setEventsList(items);
					})
					.catch((ex) => {
						// TODO: Handle
					});
				ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
			});
		}
	};

	// /**
	//  * Set Event Id and open Publish Confirmation Modal
	//  * @param eventId
	//  */
	// const handleEventPublish = (eventId: string) => {
	// 	setCurrentEventId(eventId);
	// 	eventFormService.current.setIsOpenPublishEventModal(true);
	// };

	/**
	 * Set Event Id and open Unpublish Confirmation Modal
	 * @param eventId
	 */
	const handleEventUnpublish = (eventId: string) => {
		setCurrentEventId(eventId);
		eventFormService.current.setIsOpenPublishEventModal(true);
	};

	/**
	 * Closing Publish Confirmation Modal
	 */
	const onUnpublishEventModalClose = () => {
		eventFormService.current.setIsOpenPublishEventModal(false);
		setCurrentEventId(null);
	};

	/**
	 * Event Search Dashboard List Columns shape
	 * 
	 */
	const [focusedColumn, setFocusedColumn] = useState(null);
	const [columnsWidth, setColumnsWidth] = useState({
		column1: 40,
		column2: 180,
		column3: 60,
		column4: 150,
		column5: 100,
		column6: 110,
		column7: 100
	});
	//starts resize:

	const [focused, setFocused] = useState(null);
	const [dataItemKey, setDataItemKey] = useState(null);

	useEffect(() => {
		const gridContainer = document.querySelector('.ms-DetailsList-headerWrapper');

		const handleFocus = (event) => {
			const focusedElement = event.target as HTMLElement;
			const parent = focusedElement.parentElement;
			const grandparent = parent?.parentElement;

			if (focusedElement.getAttribute('role') === 'columnheader') {
				setFocused(focusedElement);
			} else if (grandparent?.getAttribute('role') === 'columnheader') {
				setFocused(grandparent);
			}
		};

		gridContainer?.addEventListener('focusin', handleFocus);

		return () => {
			gridContainer?.removeEventListener('focusin', handleFocus);
		};
	}, []);

	useEffect(() => {
		if (focused) {
			setDataItemKey(focused.getAttribute('data-item-key'));
		}
	}, [focused]);

	const adjustColumnWidth = (key, delta) => {
		const headerCell = document.querySelector(`[data-item-key="${key}"]`) as HTMLElement;
		if (!headerCell) return;

		const currentWidth = parseInt(window.getComputedStyle(headerCell).width, 10);
		headerCell.style.width = (currentWidth + delta) + 'px';

		const gridCells = document.querySelectorAll(`[role="gridcell"][data-automation-key="${key}"]`);
		gridCells.forEach((gridCell) => {
			if (gridCell instanceof HTMLElement) {
				const gridCellWidth = parseInt(window.getComputedStyle(gridCell).width, 10);
				gridCell.style.width = (gridCellWidth + delta) + 'px';
			} else {
				console.warn('gridCell is not an HTMLElement.');
			}
		});
	};

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (!dataItemKey) return;

			const delta = event.key === '+' ? 10 : event.key === '-' ? -10 : 0;
			if (delta !== 0) {
				const headerCellWidth = parseInt(
					window.getComputedStyle(document.querySelector(`[data-item-key="${dataItemKey}"]`)).width, 10
				);
				if (event.key === '-' && headerCellWidth - 10 > columnsWidth[dataItemKey]) {
					adjustColumnWidth(dataItemKey, delta);
				} else if (event.key === '+') {
					adjustColumnWidth(dataItemKey, delta);
				}
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [dataItemKey]);

	const [isDialogHidden, setIsDialogHidden] = useState(true);
	const columnToEdit = useRef<IColumn | null>(null);
	const textfieldRef = useRef(null);

	const hideDialog = () => setIsDialogHidden(true);
	const showDialog = () => setIsDialogHidden(false);

	const handleColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
		columnToEdit.current = column;
		showDialog();
	};

	const confirmDialog = () => {
		if (!textfieldRef.current) {
			console.warn('textfieldRef is not set.');
			return;
		}
		const newWidth = textfieldRef.current.value;
		const headerCell = document.querySelector(`[data-item-key="${columnToEdit.current.key}"]`) as HTMLElement;
		headerCell.style.width = (newWidth) + 'px';
		//
		const gridCells = document.querySelectorAll(`[role="gridcell"][data-automation-key="${columnToEdit.current.key}"]`);
		gridCells.forEach((gridCell) => {
			if (gridCell instanceof HTMLElement) {
				const gridCellWidth = parseInt(window.getComputedStyle(gridCell).width, 10);
				gridCell.style.width = (newWidth) + 'px';
			} else {
				console.warn('El elemento gridCell no es un HTMLElement.');
			}
		});
		//
		hideDialog();
	};


	/// resize ends

	const columns: IColumn[] = [
		{
			key: "column1",
			name: "ID",
			fieldName: "Id",
			minWidth: columnsWidth.column1,
			maxWidth: columnsWidth.column1,
			isResizable: true,
			onColumnClick: handleColumnClick,
			onRender: (item) => {
				return (
					<div>
						{item.Id}
					</div>
				);
			}
		},
		{
			key: "column2",
			name: "Name",
			fieldName: "Title",
			minWidth: columnsWidth.column2,
			maxWidth: columnsWidth.column2,
			isResizable: true,
			onColumnClick: handleColumnClick,
			onRender: (item) => {
				return (

					<Link to={ApplicationRoutePaths.event(item.Id)} title={`Navigate to ${item.Title}`}>
						{item.Title}
					</Link>
				);
			}
		},
		{
			key: "column3",
			name: "Type",
			fieldName: "Type",
			minWidth: columnsWidth.column3,
			maxWidth: columnsWidth.column3,
			isResizable: true,
			onColumnClick: handleColumnClick,
			onRender: (item) => {
				return (
					<div>
						{item.Type}
					</div>
				);
			}
		},
		{
			key: "column4",
			name: "Date",
			fieldName: "DateStart",
			minWidth: columnsWidth.column4,
			maxWidth: columnsWidth.column4,
			isResizable: true,
			onColumnClick: handleColumnClick,
			onRender: (item) => {
				return (
					<div>
						{utcToLocalDateTimeString(item.DateStart)}
					</div>
				);
			}
		},
		{
			key: "column5",
			name: "Presented By",
			fieldName: "PresentedBy",
			minWidth: columnsWidth.column5,
			maxWidth: columnsWidth.column5,
			isResizable: true,
			onColumnClick: handleColumnClick,
			onRender: (item) => {
				return (
					<div>
						{item.PresentedBy}
					</div>
				);
			}
		},
		{
			key: "column6",
			name: "Status",
			fieldName: "",
			minWidth: columnsWidth.column6,
			maxWidth: columnsWidth.column6,
			isResizable: true,
			onColumnClick: handleColumnClick,
			onRender: (item) => {
				return (
					<div>
						<span>{item.status}</span>
					</div>
				);
			}
		},
		{
			key: "column7",
			name: "",
			fieldName: "",
			minWidth: columnsWidth.column7,
			maxWidth: columnsWidth.column7,
			isResizable: true,
			ariaLabel:"Actions",
			onColumnClick: handleColumnClick,
			onRender: (item) => {
				return (
					<div className="event-search-list-actions">
						<IconButton
							iconName="Edit"
							ariaLabel="Edit"
							onClick={() => handleEventEdit(item.Id.toString(), item.Title)}
							title="Edit Event"
						/>
						{/* <IconButton
							iconName="Delete"
							ariaLabel="Delete"
							onClick={() => handleEventRemove(item.Id.toString())}
						/> */}
						<IconButton
							iconName="AddGroup"
							ariaLabel="Allow Register Users"
							onClick={() => handleAllowUsers(item.Id.toString(), 'Register')}
							title="Allow Access to Users"
							disabled={item.status === 'Completed'}
						/>
						<IconButton
							iconName="PeopleAdd"
							ariaLabel="AlloW Voluntary Users"
							onClick={() => handleAllowUsers(item.Id.toString(), 'Voluntary')}
							title="Allow Voluntary Users"
							disabled={item.status === 'Completed'}
						/>
						<IconButton
							iconName="MailOptions"
							ariaLabel="Event Email Settings"
							onClick={() => handleEmailSettings(item.Id.toString())}
							title="Notification Settings"
							disabled={item.status === 'Completed'}
						/>
						{!item.IsPublished ? (
							<IconButton
								iconName="PublishContent"
								ariaLabel="Publish Event"
								onClick={() =>
									ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.event(item.Id))
								}
								className="event-dashboard-publish-event"
								title="Publish Event"
								disabled={item.status === 'Completed'}
							/>
						) : (
							<IconButton
								iconName="UnpublishContent"
								ariaLabel="Unpublish Event"
								onClick={() => handleEventUnpublish(item.Id.toString())}
								className="event-dashboard-upublish-event"
								title="Unpublish Event"
								disabled={item.status === 'Completed'}
							/>
						)}
					</div>
				);
			}
		}
	];



	/**
	 * Resolving Dashboard 'Status' column based on dates
	 * @param endDate
	 * @param endStart
	 * @returns
	 */
	const getEventStatus = (endDate: Date, endStart: Date) => {
		const eventStartDate = new Date(endStart);
		const eventEndDate = new Date(endDate);
		const currentDate = new Date();

		if (currentDate < eventStartDate) {
			return "Not Started";
		} else if (currentDate > eventEndDate) {
			return "Completed";
		} else {
			return "Ongoing";
		}
	};

	/**
	 * Getting status value from dates
	 */
	const eventListProcessed = useMemo(() => {
		if (Array.isArray(eventsList) && eventsList.length > 0) {
			return eventsList.map((event) => ({
				...event,
				status: getEventStatus(event.DateEnd, event.DateStart)
			}));
		}

		return [];
	}, [eventsList]);

	/**
	 * Updating list of Events after filters
	 */
	const [resultsCount, setResultsCount] = useState(0);
	const filteredEventsList = useMemo(() => {
		return eventListProcessed
			.filter((event) => event.Title.toLowerCase().includes(eventFilterSearchText?.toLowerCase() || ""))
			.filter((event) => event.Type.toLowerCase().includes(eventFilterSearchTypeOption?.toLowerCase() || ""))
			.filter((event) => event.status.toLowerCase().includes(eventFilterSearchStatus?.toLowerCase() || ""))
			.sort((a,b) => new Date(b.DateStart).getTime() - new Date(a.DateStart).getTime());
	}, [eventListProcessed, eventFilterSearchText, eventFilterSearchTypeOption, eventFilterSearchStatus]);

	useEffect(() => {
		setResultsCount(filteredEventsList.length);
	}, [filteredEventsList]);


	/**
	 * Getting Event List
	 */
	useEffect(() => {
		eventFormService.current.setIsEventListLoading(true);
		eventFormService.current.setIsEventListLoaded(false);
		eventFormService.current.resetData();
		async function loadEvents() {
			eventManageAPI.current
				.getEvents()
				.then(async (items) => {
					eventFormService.current.setEventsList(items);
				})
				.finally(() => {
					eventFormService.current.setIsEventListLoading(false);
					eventFormService.current.setIsEventListLoaded(true);
				});
		}
		loadEvents();
	}, []);

	return (
		<div className="events-listing-page">
			<div aria-live="polite" style={{ position: 'absolute', left: '-9999px' }}>
				{filteredEventsList.length}{filteredEventsList.length === 1 ? "result" : "results"} found
			</div>

			<DetailsList
				items={filteredEventsList}
				columns={columns}
				setKey="set"
				layoutMode={DetailsListLayoutMode.justified}
				selectionPreservedOnEmptyClick={false}
				selectionMode={SelectionMode.none}
				ariaLabelForSelectionColumn="Toggle selection"
				checkButtonAriaLabel="select row"
				className="event-checkin-list-container-list"
			/>

			<CustomPanel isOpen={isAllowUsersPanelOpen} onDismiss={dismissPanel} headerText={`Event ${allowUserType} Users`}>
				<EventAllowUsers />
			</CustomPanel>

			<ConfirmationMessage
				isOpen={isOpenDeleteEventModal}
				onClose={onDeleteEventModalClose}
				onAction={deleteEventById}
				title="Delete Event"
				text="Are you sure you want to delete this event?"
			/>

			<ConfirmationMessage
				isOpen={isOpenPublishEventModal}
				onClose={onUnpublishEventModalClose}
				onAction={unpublishEventById}
				title="Unpublish Event"
				text="Are you sure you want to unpublish this event?"
			/>

			<Dialog
				hidden={isDialogHidden}
				onDismiss={hideDialog}
				dialogContentProps={{
					type: DialogType.normal,
					title: 'Column Resize',
				}}
			>
				<TextField
					componentRef={textfieldRef}
					ariaLabel="Enter column width"
					placeholder="Enter new column width"
				/>
				<DialogFooter>
					<PrimaryButton onClick={confirmDialog} text="Confirm" />
					<DefaultButton onClick={hideDialog} text="Cancel" />
				</DialogFooter>
			</Dialog>
		</div>
	);

};