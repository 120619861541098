import { ITopicRequest } from "../../contracts";
import { NodeActionTypes } from "../../contracts/models/strikeEnums";

/**
 * Provides easy creation of Topic Create Request objects.
 * Supports bulk-creation.
 */
export class TopicRequest implements ITopicRequest {
	actionType: NodeActionTypes;
	topics: string[];
	userUPN: string;

	constructor(topics: string[], userUPN: string, actionType: NodeActionTypes) {
		this.actionType = actionType;
		this.topics = topics;
		this.userUPN = userUPN;
	}
}
