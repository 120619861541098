import React, { useEffect }  from "react";
import { useAuthUser, useNodeActionsSlice } from "../../../../hooks";
import { TreeViewDropdown } from "../../dropdown";
import { ServiceHub } from "../../../../service";
import { Shimmer } from "../../shimmer";
import { ApplicationRoutePaths } from "../../../router";
import { useParams } from "react-router-dom";
import { showToast } from "../../../../lib/strikeLibrary";
import { Button } from "../../button";

/**
 * Interface for Node Revisions Component
 */
interface INodeReportPanelProps {
    NodeId: number;
    isComment?: boolean;
    setOpenReportPanel?: (value) => void | boolean;
}

interface DropdownItem {
	id: string;
	title: string;
}

/**
 * Component to show Node Report Panel and Reason Selector
 * @param props
 * @returns
 */
export const NodeReportPanel: React.FC<INodeReportPanelProps> = (props) => {
    const { reportReasons, selectedReportReasonItem } = useNodeActionsSlice();
    const svc = ServiceHub.commentAPI.start();
    const params = useParams();
    const authUser = useAuthUser();
    const [customReasonValue, setCustomReasonValue] = React.useState("");
    const [reportSuccessfully, setReportSuccessfully] = React.useState(false);

    function handleReportSuccess() {
        setReportSuccessfully(true);
		setTimeout(() => {
            setReportSuccessfully(false);
			props.setOpenReportPanel(false)
		}, 3000);
	}

    function onReportCommentConfirmation(){
		const itemToReport = svc.getEditingItemById(props.NodeId);
		const nodeTitle = params.title;
		const nodeUrl = props.isComment ? window.location.origin + ApplicationRoutePaths.articleComment(itemToReport.NodeId.toString(), itemToReport.CommentId.toString(), nodeTitle) : window.location.toString();
		const reportQueryObject = svc.getQueryObjectToReportComment(props.isComment ? itemToReport.NodeId : props.NodeId, props.isComment ? itemToReport.CommentId : props.NodeId,nodeUrl, authUser.userPrincipalName, customReasonValue ? customReasonValue : selectedReportReasonItem.text);

		svc.reportComment(reportQueryObject).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}

			//showToast(`Comment reported successfully.`, "success");
            handleReportSuccess();
		})
	}

    /**
	 * Gets Miscellaneous data to use in report reason dropdown selector
	 */
	async function getMiscData() {
		await ServiceHub.articleDataAPI
			.start()
			.getMiscellaneous()
			.then((result) => {
				if (!Array.isArray(result)) return;

                const reasonValues = result.filter((item: any) => item.DataKey === 'REPORT_REASONS');
                ServiceHub.appManagementAPI.setReportReasonsData(reasonValues)
			})
			.catch((ex) => {
				// TODO: Handle error
			});
	}

    useEffect(() => {
        setReportSuccessfully(false);
		getMiscData()
	}, []);

   	/**
	 * Handling selected Report Reason
	 * @param item
	 */
	function handleChangeReportReason(item: DropdownItem) {
		ServiceHub.appManagementAPI.setReportReasonValueId(item.id);
		ServiceHub.appManagementAPI.setReportReasonValueOption(item.title);
	}

    const handleCustomReasonChange = async (event) => {
        let value = event.target.value;
        setCustomReasonValue(value);
    }

	return (
		<div className="node-report-wrapper">
            <p>Please select a reason below or use the text box to input your own reason.</p>
            {reportReasons.length > 0 ?
                <TreeViewDropdown
                    id="reportReasonsValues"
                    options={reportReasons}
                    onChange={handleChangeReportReason}
                    placeholder="Select Report Reason"
                    selectedItem={selectedReportReasonItem}
                /> : <Shimmer />}
            {selectedReportReasonItem && selectedReportReasonItem.text === 'Other' ?
            <div className="node-report-custom-reason">
                <input
                    id="custom-reason-text"
                    value={customReasonValue}
                    placeholder="Write your own reason here."
                    type="search"
                    className="form-control-type full-width"
                    onChange={handleCustomReasonChange}
                    tabIndex={0}
                    autoComplete="off"
                    title="Input your own reason."
                    alt="Input your own reason."
                /> </div>: null }
            <div className="node-report-custom-reason-button">
                <Button text="Report" onClick={onReportCommentConfirmation} disabled={!selectedReportReasonItem} />
            </div>
            {reportSuccessfully ? <p className="alert alert-success report-sucess"> Report was sent successfully!</p> : null}
		</div>
	);
};
