// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-switcher {
	border: 0.0625em solid #e1e1e1;
	padding: 0.5em;
}

.button-switcher.active {
	background: #0065b3;
}

.button-switcher i.grey.icon {
	position: relative;
	left: 0.1em;
}

.button-switcher.active i.grey.icon {
	color: #ffffff !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/node.summary.switcher.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,cAAc;AACf;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":[".button-switcher {\r\n\tborder: 0.0625em solid #e1e1e1;\r\n\tpadding: 0.5em;\r\n}\r\n\r\n.button-switcher.active {\r\n\tbackground: #0065b3;\r\n}\r\n\r\n.button-switcher i.grey.icon {\r\n\tposition: relative;\r\n\tleft: 0.1em;\r\n}\r\n\r\n.button-switcher.active i.grey.icon {\r\n\tcolor: #ffffff !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
