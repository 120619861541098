/**
 * Constants values used on Node Activity flow
 */

const nodeActivityConstants = {
	maxOffset: 3,
	paginationOffset: 6
};

export default nodeActivityConstants;
