import { useEffect } from "react";
import { setUserDisplayName, setUserId, setUserPrincipalName, setUserEmail } from "../../redux/reducers/auth";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { UserProfileAPIService } from "../../service/userProfile/UserProfileAPI";
import { authSelectors } from "../../redux/selectors/auth";

const userProfile = new UserProfileAPIService();

/**
 * Hook that gets authenticated user information from store.
 *
 * @returns user authentication object
 */
const useAuthUser = () => {
	const userDisplayName = useSelector(authSelectors.getUserDisplayName);
	const userPrincipalName = useSelector(authSelectors.getUserPrincipalName);
	const userId = useSelector(authSelectors.getUserId);
	const userEmail = useSelector(authSelectors.getUserEmail);

	const setDisplayName = (displayName: string) => {
		store.dispatch(setUserDisplayName(displayName));
	};

	const setPrincipalName = (principalName: string) => {
		store.dispatch(setUserPrincipalName(principalName));
	};

	const setId = (id: string) => {
		store.dispatch(setUserId(id));
	};

	const setEmail = (email: string) => {
		store.dispatch(setUserEmail(email));
	};

	useEffect(() => {
		async function getInfo() {
			if (!userDisplayName || userDisplayName == "") {
				userProfile.GetCurrentUserDisplayName().then((data) => {
					setDisplayName(data);
				});
			}

			if (!userPrincipalName || userPrincipalName == "") {
				userProfile.GetCurrentUserUpn().then((data) => {
					setPrincipalName(data);
					const username = data.split("@");
					setId(username[0]);
				});
			}

			if (!userEmail || userEmail == "") {
				userProfile.GetCurrentUserEmail().then((data) => {
					setEmail(data);
				});
			}
		}
		getInfo();
	}, [userDisplayName, userPrincipalName, userEmail, userId]);

	return {
		userDisplayName,
		userPrincipalName,
		userEmail,
		userId
	};
};

export { useAuthUser };
