import { TagsModeType } from "../../contracts/models/strikeEnums";

/**
 * Definition of selectors related to Tags component
 */
export const tagsSelectors = {
	getTagsMode: (state): TagsModeType => state.tags.ui.tagsMode,
	getTagsIds: (state): string[] => state.tags.data.tagsIds,
	getTagsNames: (state): string[] => state.tags.data.tagsNames
};
