import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { commentsSelectors } from "../../../../redux/selectors/comments";
import { getQueryUserActivityObject, validateEmail } from "../../../../lib/strikeLibrary";
import { NodeActionTypes, NodeType } from "../../../../contracts/models/strikeEnums";
import { useAuthUser, useFollowers } from "../../../../hooks";
import {
	addLike,
	removeLike,
	resetCommentsToShow,
	setCommentsToShow,
	setIsNodeAlreadyLiked,
	setToggleCommentForm,
	setToggleCommentQuestionForm
} from "../../../../redux/reducers/comments";
import { authSelectors } from "../../../../redux/selectors/auth";
import { NodeAPIServiceInstance } from "../../../../service/NodeAPI";
import { UserActivityModel } from "../../../../contracts/models";
import nodeActivityConstants from "./constants";
import { useParams } from "react-router-dom";
import { ConfirmationMessage } from "../../message";
import { ServiceHub } from "../../../../service";
import { FollowType } from "../../../../enums";

/**
 * Interface for comments main actions component
 */
interface INodeActivityActionsProps {
	nodeId: number;
	type: NodeType;
}

/**
 * Comments Actions component
 * to handle Node Like, Comment and Show toggle
 * @param props
 * @returns
 */
export const NodeActivityActions: React.FC<INodeActivityActionsProps> = (props) => {
	const dispatch = useDispatch();
	const authoriableUserId = useSelector(authSelectors.getAuthoriableId);

	const [likeTriggered, setLikeTriggered] = useState(false);
	const [voteDown, setVoteDown] = useState(false);
	const isNodeLikeMatching = useSelector(commentsSelectors.getNodeAlreadyLiked);
	const [isNodeLikeMatchingHandler, setIsNodeLikeMatchingHandler] = useState(isNodeLikeMatching);

	const likeCount = useSelector(commentsSelectors.getNodeLikeCount);
	const allNodeComments = useSelector(commentsSelectors.getAllNodeComments);
	const isHideButton = useSelector((state) => commentsSelectors.getShouldHideAll(state, props.nodeId));
	const rootComments = useSelector((state) => commentsSelectors.getRootNodeComments(state, props.nodeId));
	const rootCommentsTypeAnswer = useSelector((state) =>
		commentsSelectors.getRootNodeCommentsByTypeAnswer(state, props.nodeId)
	);
	const rootCommentsTypeComment = useSelector((state) =>
		commentsSelectors.getRootNodeCommentsByTypeComment(state, props.nodeId)
	);
	const ipAddress = "";
	const { userPrincipalName } = useAuthUser();
	const likesArray = useSelector(commentsSelectors.getNodeLikeIds);
	const isQuestion = props.type === NodeType.Question;
	const { commentId } = useParams<{ commentId: string }>();
	const svc = ServiceHub.commentAPI.start();
	const [isOpenReportOutdatedConfirmation, setIsOpenReportOutdatedConfirmation] = useState<boolean>(false);
	const followersData = useFollowers(props.nodeId, FollowType.Node);
	const isUserFavourite = followersData.nodeIsFavourite;
	const favouriteAPI = useRef(ServiceHub.followAPI.start());


	useEffect(() => {
		if (commentId && allNodeComments.length > 0) {
			dispatch(setCommentsToShow(rootComments.length));
		}
	}, [commentId, allNodeComments]);

	/**
	 * Part of User Interface
	 * Triggering redux state to change boolean flag
	 * It is reseting pagination showing all comments (show all)
	 * or going back to initial state(hide all).
	 */
	const showAllCommentsToggle = () => {
		// Hide mode, when showing all the items
		if (isHideButton) {
			dispatch(resetCommentsToShow());
		} else {
			// Show all mode, when not
			dispatch(setCommentsToShow(rootComments.length));
		}

		// dispatch(setShowAllComments());
	};

	const toggleCommentForm = () => {
		dispatch(setToggleCommentForm());
	};

	const toggleCommentQuestionForm = () => {
		dispatch(setToggleCommentQuestionForm());
	};

	/**
	 * Handle live event activity and mocking like values on the fly.
	 * @param userActivityModel
	 */
	const logLikeEventActivity = async (userActivityModel: UserActivityModel) => {
		let res = await NodeAPIServiceInstance.logUserActivity(userActivityModel);
		if (await res) {
			if (res) {
				if (!voteDown) {
					dispatch(addLike(authoriableUserId));
				} else if (voteDown) {
					dispatch(removeLike(authoriableUserId));
				}

				monitorLikeState();
			}
		}
	};

	/**
	 * Handle live event action triggered by 'Like' button
	 */
	const handleNodeLikeEvent = (e) => {
		setLikeTriggered(true);

		if (validateEmail(userPrincipalName)) {
			const actionType = voteDown ? NodeActionTypes.VoteDown : NodeActionTypes.VoteUp;

			const queryLikeActivity = getQueryUserActivityObject(
				actionType,
				ipAddress,
				props.nodeId.toString(),
				userPrincipalName
			);
			logLikeEventActivity(queryLikeActivity);
		}
		const button = e.currentTarget;
		const icon = button.querySelector('i');
		const parent = button.parentNode;

		if (parent) {
			if (!voteDown) {
				if (icon) {
					icon.classList.remove('outline');
				}
				parent.classList.add('node-liked-button');
				parent.classList.remove('node-like-button');
			} else {
				if (icon) {
					icon.classList.add('outline');
				}
				parent.classList.remove('node-liked-button');
				parent.classList.add('node-like-button');
			}
		}

		setVoteDown(!voteDown);
	};

	/**
	 * Monitor if the authId for a user its present in the LikeByUsers list
	 * If there is match means user already liked it the node and action should be disabled.
	 */
	const monitorLikeState = () => {
		if (likesArray.length === 0 || !authoriableUserId || authoriableUserId === 0) {
			dispatch(setIsNodeAlreadyLiked(false));
			return;
		}
		dispatch(setIsNodeAlreadyLiked(likesArray.includes(parseInt(authoriableUserId))));
	};

	useEffect(() => {
		monitorLikeState();

		// redux true & like trigered false -> base case -> cant like
		if (isNodeLikeMatching && !likeTriggered) {
			setIsNodeLikeMatchingHandler(true);
		}
		// redux false & like triggered false -> base case -> can like
		else if (!isNodeLikeMatching && !likeTriggered) {
			setIsNodeLikeMatchingHandler(false);
		}
		// redux true & like triggered true -> was clicked here -> can dislike and like till component unmount
		else if (isNodeLikeMatching && likeTriggered) {
			setIsNodeLikeMatchingHandler(false);
		}
	}, [monitorLikeState]);

	/**
	 * Triggering API to report node as 'Outdated' reason
	 */
	function reportOutdatedNode() {
		const nodeUrl = window.location.toString();
		const reportQueryObject = svc.getQueryObjectToReportComment(props.nodeId, props.nodeId, nodeUrl, userPrincipalName, 'Outdated');

		svc.reportComment(reportQueryObject).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
			setIsOpenReportOutdatedConfirmation(false);
		})

	}

	/**
	 * Opening Report Outdated confirmation modal
	 */
	const handleReportOutdatedNode = () => {
		setIsOpenReportOutdatedConfirmation(true);
		ServiceHub.appManagementAPI.setConfirmationCallback(reportOutdatedNode);
	}

	/**
	 * Closing Report Outdated confirmation modal
	 */
	const onCloseReportOutdatedConfirmation = () => {
		setIsOpenReportOutdatedConfirmation(false);
	}

	/**
	 * Handles the Follow/Unfollow states of this follower section.
	 */
	async function handleFavouriteNode() {
		await ServiceHub.nodeAPI.favourite(props.nodeId, !isUserFavourite)
		.then((result) => {
			if (isUserFavourite) {
				favouriteAPI.current.setIsFavourite(false)
			} else {
				favouriteAPI.current.setIsFavourite(true)
			}
		});
	}

	const likeNumberAriaLabel = `${likeCount === 0 ? "No" : likeCount} like${likeCount === 1 ? "" : "s"}`;

	const totalCommentCountAriaLabel = ` comment${allNodeComments.length === 1 ? "" : "s"}`;

	const answersCountAriaLabel = `${rootCommentsTypeAnswer.length === 0
		? "No answers"
		: rootCommentsTypeAnswer.length === 1
			? `${rootCommentsTypeAnswer.length} answer`
			: `${rootCommentsTypeAnswer.length} answers`
		}`;

	const commentCountAriaLabel = `${rootCommentsTypeComment.length === 0
		? "No comments"
		: rootCommentsTypeComment.length === 1
			? `${rootCommentsTypeComment.length} comment`
			: `${rootCommentsTypeComment.length} comments`
		}`;

	return (
		<>
		<div className="node-activity-main-actions">
			<ul>
				<li className={isNodeLikeMatchingHandler ? "node-like-button disabled" : "node-like-button"}>
					<button disabled={isNodeLikeMatchingHandler} onClick={handleNodeLikeEvent}>
						<Icon
							name={isNodeLikeMatchingHandler ? "thumbs up" : "thumbs up outline"}
							className="node-like-icon"
							size="small"
						/>
						<span>Like</span>
					</button>
					<span
						className="comment-section-like-number"
						tabIndex={-1}
						aria-label={likeNumberAriaLabel}
						role="none"
					>
						&nbsp;{likeCount}
					</span>
				</li>
				{!isQuestion && rootComments.length > nodeActivityConstants.paginationOffset ? (
					<li className="comments-toggle-link">
						<Icon name="comments" size="small" className="comments-toggle-link-icon" />
						<button onClick={showAllCommentsToggle}>{isHideButton ? "Hide all" : "Show all"}</button>
						<span className="comment-section-comments-number">{allNodeComments.length}</span>
					</li>
				) : !isQuestion && allNodeComments.length ? (
					<li tabIndex={0} aria-label={totalCommentCountAriaLabel}>
						<strong>{allNodeComments.length}</strong>{" "}
						{allNodeComments.length === 1 ? "comment" : "comments"}
					</li>
				) : null}
				{isQuestion && rootComments.length > 0 ? (
					<li>
						<div
							className="comment-section-show-question-count"
							tabIndex={0}
							aria-label={answersCountAriaLabel}
							role="none"
						>
							<strong>{rootCommentsTypeAnswer.length} </strong>
							{rootCommentsTypeAnswer.length === 1 ? "answer" : "answers"}
						</div>
						<div
							className="comment-section-show-question-count-answer"
							tabIndex={0}
							aria-label={commentCountAriaLabel}
							role="none"
						>
							<strong>{rootCommentsTypeComment.length} </strong>
							{rootCommentsTypeComment.length === 1 ? "comment" : "comments"}
						</div>
					</li>
				) : null}
				<li className="favorite-option">
					<button onClick={handleFavouriteNode}>
						<Icon
							name={isUserFavourite ? 'heart' : 'heart outline'}
							className="favorite-icon"
							size="small"
						/>
						<span>{isUserFavourite ? 'Unfavorite' : 'Favorite'}</span>
					</button>
				</li>
				<li className="report-outdated-option">
					<button onClick={handleReportOutdatedNode}>
						<Icon
							name="exclamation triangle"
							className="report-outdated-icon"
							size="small"
						/>
						<span>Report Outdated</span>
					</button>
				</li>
			</ul>
		</div>
		<ConfirmationMessage
			isOpen={isOpenReportOutdatedConfirmation}
			onClose={onCloseReportOutdatedConfirmation}
			onAction={ServiceHub.appManagementAPI.getConfirmationCallback()}
			title="Report Outdated"
			text="Are you sure you want to report this content ast outdated?"
			confirmationText="Yes"
			dismissText="No"
		/>
		</>
	);
};
