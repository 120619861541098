/**
 * Instantiates a service class immediately after calling .start().
 * Keeps the information of the type as for using to create a new() instance of the service class.
 */
export class LazyServiceStarter<T> {
	private serviceType: new () => T = null;

	constructor(type: { new (): T }) {
		this.serviceType = type;
	}

	/**
	 * Starts up a given service,
	 * based on the type passed.
	 * The type must be an instantiable class.
	 *
	 * @returns The class instance.
	 */
	start() {
		return new this.serviceType();
	}
}
