import React from "react";
import { NodeActivityCommentForm } from "./nodeActivity.comment.form";
import { NodeActionTypes, NodeType } from "../../../../contracts/models/strikeEnums";

/**
 * Interface for Node Comment Reply
 * need commentId to know parent.
 */
interface INodeActivityCommentReplyProps {
	commentId: number;
	actionType: NodeActionTypes;
}

/**
 * Comment Reply component showing
 * textarea and send comment button
 *
 * @param props
 * @returns
 */
export const NodeActivityCommentReply: React.FC<INodeActivityCommentReplyProps> = (props) => {
	const headlineText =
		props.actionType === NodeActionTypes.CommentToAnswer ? "Reply to this answer:" : "Reply to this comment:";

	return (
		<div className="node-activity-reply-container" tabIndex={0} aria-label={headlineText}>
			<NodeActivityCommentForm
				headlineText={headlineText}
				buttonText="Reply"
				parentId={props.commentId}
				actionType={props.actionType}
				isNew
			/>
		</div>
	);
};
