import {
	addMenuItemsSelected,
	resetMenu,
	resetMenuItemSelected,
	resetSubMenus,
	setAppMenuData,
	setExpandedMenuItem,
	setMenuItemSelected,
	setMenuItemsSelected
} from "../../redux/reducers/appManagement";
import { appManagementSelectors } from "../../redux/selectors/appManagement";
import store from "../../redux/store";
import BaseHttpAPI from "../base/BaseHttpAPI";
import { ServiceHub } from "../base/ServiceHub";

/**
 * App Menu mini API, which allows the Server-side
 * building of the menu content
 */
export class AppMenuAPI extends BaseHttpAPI {
	constructor() {
		super("resource");
	}

	/**
	 * Gets the Application Menu from the App Backend services
	 *
	 * @returns a JSON with the MenuStructure within.
	 */
	public async getMenu() {
		return this.get("appMenu").then((data) => {
			if (data) {
				store.dispatch(setAppMenuData(data));
			}

			return data;
		});
	}

	/**
	 * Changes the expanded/collapsed state of a menu item by its ID.
	 *
	 * @param menuItemId Item ID to set as expanded/collapsed
	 * @param expanding Whether the item is expanding or collapsing
	 */
	setExpandedMenuItem(menuItemId: string, expanding: boolean): void {
		store.dispatch(setExpandedMenuItem({ id: menuItemId, expanded: expanding }));
	}

	/**
	 * Get Selected id from main menu
	 * @param selectedId
	 */
	setMenuItemSelected(selectedId: string): void {
		store.dispatch(setMenuItemSelected(selectedId));
	}

	/**
	 * Find Parent and add into array
	 * @param item
	 * @param originalCollection
	 * @param destinationCollection
	 * @returns
	 */
	findAndAddParents(item: any, originalCollection: any[], destinationCollection: any[]) {
		if (!item) {
			return;
		}
		const parentItem = originalCollection.find((collectionItem) => collectionItem.Id === item.ParentId);
		if (!parentItem) {
			return;
		}

		if (destinationCollection.some((foundItem) => foundItem.Id === parentItem.Id)) {
			destinationCollection.push(parentItem);
		}

		if (!parentItem?.ParentId || parentItem?.ParentId === "") {
			return;
		}

		this.findAndAddParents(parentItem, originalCollection, destinationCollection);
	}

	/**
	 * Get Selected ids from main menu to set expanded
	 * @param selectedId
	 */
	setMenuItemsSelected(selectedId: string): void {
		const selectedIdChain = [];

		const normalizedItemList = appManagementSelectors.getNormalizedList(store.getState());
		const currentItem = normalizedItemList.find((item) => item.Id === selectedId);
		const parents = [];

		if (!currentItem) {
			return;
		}

		selectedIdChain.push(currentItem.Id);

		if (currentItem.ParentId) {
			if (currentItem.ParentId === "menu-programs_1") {
				store.dispatch(resetSubMenus());
			}

			this.findAndAddParents(currentItem, normalizedItemList, parents);
			selectedIdChain.push(...parents.map((parentItem) => parentItem.Id));
		}

		store.dispatch(addMenuItemsSelected(selectedIdChain));
	}

	/**
	 * Utily method to receive the selected menu id once
	 * its clicked and add focus to the parent Nav Group Item
	 * to be compliant with Accessibility Tab Navigation
	 * @param selectedId
	 */
	setFocusOnParentElement(selectedId: string): void {
		const normalizedList = appManagementSelectors.getNormalizedList(store.getState());
		const selectedItem = normalizedList.find((item) => item.Id === selectedId);
		const rootItems = normalizedList.filter((rootItem) => rootItem.Root === true);

		if (selectedItem.ParentId) {
			const parentIdToAriaLabelMap: { [key: string]: string } = {};

			rootItems.forEach((rootItem) => {
				if (rootItem.Id && rootItem.Title) {
					parentIdToAriaLabelMap[rootItem.Id] = rootItem.Title;
				}
			});

			const ariaLabelMatch = parentIdToAriaLabelMap[selectedItem.ParentId];

			if (ariaLabelMatch) {
				if (selectedItem.Href === "#") {
					store.dispatch(resetSubMenus());
				} else {
					const mainMenuButton = document.querySelector(
						`[aria-label="${ariaLabelMatch}"]`
					) as HTMLButtonElement;
					if (mainMenuButton) {
						mainMenuButton.focus();
					}
				}
			}
		}
	}

	/**
	 * Reset All expanded items.
	 */
	resetMenu(): void {
		store.dispatch(resetMenu());
	}

	/**
	 * Reset Menu Item Selected
	 */
	resetMenuItemSelected(): void {
		store.dispatch(resetMenuItemSelected());
	}

	/**
	 * Reset All Menu items
	 */
	resetAllMenu(): void {
		store.dispatch(resetMenu());
		store.dispatch(resetMenuItemSelected());
	}

	/**
	 * Reset All SubMenu expanded items.
	 */
	resetSubMenus(): void {
		store.dispatch(resetSubMenus());
	}

	/**
	 * Get Expanded Item.
	 *
	 * @param menuItemId Item to check expanded state
	 */
	getIsMenuItemExpanded(menuItemId: string): boolean {
		return appManagementSelectors.getIsMenuItemExpandedById(store.getState(), menuItemId);
	}

	async checkAccess(): Promise<boolean> {
		return await ServiceHub.eventManageAPI.start().checkAdminAccess();
	}
}
