import { createSelector } from "@reduxjs/toolkit";
import { IStrikeNodeEntity, IStrikeNodesComments } from "../../contracts/models";
import nodeActivityConstants from "../../components/common/node/activity/constants";
import { CommentType } from "../../contracts/models/strikeEnums";

/**
 * Definition of selectors related to Node Comments
 */
export const commentsSelectors = {
	getAllNodeComments: (state): IStrikeNodesComments[] => state.comments.data.allNodeComments,
	getOrderedNodeComments: createSelector(
		(state) => state,
		(state): IStrikeNodesComments[] => {
			let commentsCopy = [...state.comments.data.allNodeComments];

			commentsCopy =
				commentsCopy?.sort((firstObject, secondObject) => {
					const dateA = new Date(firstObject.CommentOn);
					const dateB = new Date(secondObject.CommentOn);
					return dateA.getTime() - dateB.getTime();
				}) ?? [];

			return commentsCopy;
		}
	),
	getRootNodeComments: createSelector(
		(state) => state,
		(_, parentId: number | string) => parentId,
		(state, parentId: number | string): IStrikeNodesComments[] =>
			state.comments.data.allNodeComments.filter((comment: IStrikeNodesComments) => comment.ParentId === parentId)
	),
	getRootNodeCommentsByTypeAnswer: createSelector(
		(state) => state,
		(_, parentId: number | string) => parentId,
		(state, parentId: number | string): IStrikeNodesComments[] =>
			state.comments.data.allNodeComments.filter(
				(comment: IStrikeNodesComments) => comment.ParentId === parentId && comment.Type === CommentType.Answer
			)
	),
	getRootNodeCommentsByTypeComment: createSelector(
		(state) => state,
		(_, parentId: number | string) => parentId,
		(state, parentId: number | string): IStrikeNodesComments[] =>
			state.comments.data.allNodeComments.filter(
				(comment: IStrikeNodesComments) => comment.ParentId === parentId && comment.Type === CommentType.Comment
			)
	),
	getShouldHideAll: (state, parentId: string | number) => {
		const rootComments = commentsSelectors.getRootNodeComments(state, parentId);
		const commentsToShow = commentsSelectors.getCommentsToShow(state);
		const diff = rootComments.length - commentsToShow;

		// When the difference between total root comments and comments to show is less than the threshold, OR,
		// User has selected to show all comments
		return diff <= nodeActivityConstants.maxOffset || commentsToShow >= rootComments.length;
	},
	getCommentsTotalNumber: (state) => state.comments.data.nodeCommentsTotal,
	getToggledComments: (state): boolean => state.comments.ui.isToggledComments,
	getToggledCommentForm: (state): boolean => state.comments.ui.isToggledCommentForm,
	getToggledCommentQuestionForm: (state): boolean => state.comments.ui.isToggledCommentQuestionForm,
	getNodeAlreadyLiked: (state): boolean => state.comments.ui.isNodeAlreadyLiked,
	getNodeLikeCount: (state): number => state.comments.data.likeCount,
	getNodeLikeByUsers: (state): string => state.comments.data.likeByUsers,
	getNodeLikeIds: createSelector(
		(state) => state.comments.data.likeByUsers,
		(likeByUsersId): number[] => {
			if (!likeByUsersId) return [];
			const likesArray = likeByUsersId.split(",").map((item) => parseInt(item));
			return likesArray;
		}
	),
	getCommentText: (state): string => state.comments.form.commentText,
	getNodeId: (state): string => state.comments.form.nodeId,
	getReplyExpandedId: (state): string | number => state.comments.ui.replyExpandedId,
	getPostButtonDisabled: (state): boolean => state.comments.form.commentText === "" || state.comments.ui.submitting,
	getFormBodySourceMode: (state): boolean => state.comments.form.bodySourceMode,
	getCommentsToShow: (state): number => state.comments.data.commentsToShow,
	getEditingIds: (state): number[] => state.comments.data.editingIds,
	getEditingItemById: createSelector(
		(state) => state,
		(_, id: number | string) => id,
		(state, id: number | string): null | IStrikeNodesComments => {
			const result = state.comments.data.allNodeComments.filter((c: IStrikeNodesComments) => c.CommentId === id);

			return result.length > 0 ? result[0] : null;
		}
	)
};
