import axios from "axios";
import { GetAppEnvironmentConfigs } from "../appConfigs";
import {
	IEventFormInfo,
	IStrikeAuthoringApiResponse,
	IStrikeNodeEntity,
	IStrikeNodes,
	IStrikeNodesAnswer,
	IStrikeNodesComments,
	IStrikeNodesTopics,
	IStrikeTopicNode,
	IUserAcceptAnswer,
	IUserActivityModel,
	IUserAuthoriablesModel,
	QueryNodeModel,
	QueryTopicModel,
	UserAcceptAnswerModel,
	UserActivityModel,
	UserAuthoriablesModel
} from "../contracts/models";
import { EventAuthAccessModel, EventFormInfoModel } from "../contracts/models/request/userEventRequest";
import { IUserProfile } from "../contracts/userProfile";

/**
 * API for handling with Environment Configuration.
 * TODO: Inherit from BaseHttpAPI and remove repeated implementation from here.
 */
export default class StrikeCommunityApi {
	private static get BaseUrl() {
		const environmentConfigs = GetAppEnvironmentConfigs();
		if (environmentConfigs) {
			return environmentConfigs.endpoints.strikeApiBaseUrl;
		}
		console.error("Cannot retrieve StrikeApiBaseUrl because the environment configs doesn't exist");
		return "";
	}

	private static get<TResponse>(url: string): Promise<TResponse> {
		return new Promise<TResponse>((resolve, reject) => {
			axios
				.get<IStrikeAuthoringApiResponse<TResponse>>(url)
				.then((response) => {
					resolve(response.data.Data);
				})
				.catch((error) => reject(error));
		});
	}

	private static post<TRequestBody, TResponse>(url: string, requestBody: TRequestBody): Promise<TResponse> {
		return new Promise<TResponse>((resolve, reject) => {
			axios
				.post<IStrikeAuthoringApiResponse<TResponse>>(url, requestBody)
				.then((response) => {
					resolve(response.data.Data);
				})
				.catch((error) => reject(error));
		});
	}

	private static put<TRequestBody, TResponse>(url: string, requestBody: TRequestBody): Promise<TResponse> {
		return new Promise<TResponse>((resolve, reject) => {
			axios
				.put<IStrikeAuthoringApiResponse<TResponse>>(url, requestBody)
				.then((response) => {
					resolve(response.data.Data);
				})
				.catch((error) => reject(error));
		});
	}

	private static delete<TRequestBody>(url: string, requestBody?: undefined | TRequestBody): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			axios
				.delete(url, { data: requestBody, headers: { "Content-Type": "application/json" } })
				.then((response) => {
					resolve(response.data.Data);
				})
				.catch((error) => reject(error));
		});
	}

	public static getUserProfile(userUpn: string): Promise<IUserProfile> {
		return this.get(`${StrikeCommunityApi.BaseUrl}/userprofiles?upn=${userUpn}`);
	}

	//
	// Strike Node
	//

	/**
	 * @param queryNodeModel
	 * Get All node with filter of QueryNodeModel object
	 */
	public static getAllNodes(queryNodeModel: QueryNodeModel): Promise<IStrikeNodes> {
		const url = `${StrikeCommunityApi.BaseUrl}/node`;
		return this.post(url, queryNodeModel);
	}

	/**
	 * @param nodeId
	 * Get specific node details
	 */
	public static getNode(nodeId: number): Promise<IStrikeNodeEntity> {
		const url = `${StrikeCommunityApi.BaseUrl}/node/GetNode/${nodeId}`;
		return this.get(url);
	}

	/**
	 * Get all topics of all nodes
	 */
	public static getNodesAllTopics(): Promise<string[]> {
		const url = `${StrikeCommunityApi.BaseUrl}/node/getNodesAllTopics`;
		return this.get(url);
	}

	/**
	 * @param coverImageThumbnailPath
	 * Get Base 64 format Node Cover Image Thumbnail
	 */
	public static getNodeCoverImageThumbnail(coverImageThumbnailPath: string): Promise<string> {
		const url = `${StrikeCommunityApi.BaseUrl}/node/GetCoverImageThumbnail/${coverImageThumbnailPath}`;
		return this.get(url);
	}

	/**
	 * @param attachmentImagePath
	 * Get Base 64 format Image Attachment in a Node comment
	 */
	public static getNodeAttachmentImageThumbnail(attachmentImageThumbnailPath: string): Promise<string> {
		const url = `${StrikeCommunityApi.BaseUrl}/node/GetAttachmentImageThumbnail/${attachmentImageThumbnailPath}`;
		return this.get(url);
	}

	/**
	 * @param queryUserModel
	 * create Authoritables User
	 */
	public static createAuthoritablesUser(queryUserModel: UserAuthoriablesModel): Promise<IUserAuthoriablesModel> {
		const url = `${StrikeCommunityApi.BaseUrl}/userprofiles/CreateAuthoritablesUser`;
		return this.post(url, queryUserModel);
	}

	/**
	 * @param queryUserActivityModel
	 * log User Activity
	 */
	public static logUserActivityWorkflow(queryUserActivityModel: UserActivityModel): Promise<IUserActivityModel> {
		const url = `${StrikeCommunityApi.BaseUrl}/loguseractivity/logUserActivity`;
		return this.post(url, queryUserActivityModel);
	}

	/**
	 * @param nodeId
	 * Get answers from a specific question node
	 */
	public static getQuestionAllAnswers(nodeId: number): Promise<IStrikeNodesAnswer[]> {
		const url = `${StrikeCommunityApi.BaseUrl}/node/getNodeAllAnswers/${nodeId}`;
		return this.get(url);
	}

	/**
	 *
	 * @param nodeId
	 * Get comments for a specific node id
	 */
	public static getNodeComments(nodeId: number): Promise<IStrikeNodesComments[]> {
		const url = `${StrikeCommunityApi.BaseUrl}/node/getNodeComments/${nodeId}`;
		return this.get(url);
	}

	/**
	 * Check user auth to see events
	 */
	public static getUserEventAuth(
		queryEventAccess: EventAuthAccessModel,
		userEmail: string,
		eventId: string
	): Promise<boolean> {
		const url = `${StrikeCommunityApi.BaseUrl}/EventRegistration/ReviewAccess/${userEmail}/${eventId}`;
		return this.post(url, queryEventAccess);
	}

	/**
	 * Send User Event registration info.
	 */
	public static sendEventFormRegistration(queryEventFormInfo: EventFormInfoModel): Promise<IEventFormInfo> {
		const url = `${StrikeCommunityApi.BaseUrl}/EventRegistration//LogUserEventRegistrationResponse`;
		return this.post(url, queryEventFormInfo);
	}

	/**
	 * Check if user already registered to an Event
	 */
	public static checkEventFormRegistration(queryEventFormInfo: EventFormInfoModel): Promise<IEventFormInfo> {
		const url = `${StrikeCommunityApi.BaseUrl}/EventRegistration/IsEventRegistered`;
		return this.post(url, queryEventFormInfo);
	}

	/**
	 * @returns Topic Object to extract the array of spaces which we have in the Strike Community
	 */
	public static queryTopics(queryTopicModel: QueryTopicModel): Promise<IStrikeTopicNode> {
		const url = `${StrikeCommunityApi.BaseUrl}/node/getTopicsNodesCount`;
		return this.post(url, queryTopicModel);
	}

	/**
	 * @param nodeId
	 * Get Topics Id and Topics label for a specific node id
	 */
	public static getNodeTopics(nodeId: number): Promise<IStrikeNodesTopics> {
		const url = `${StrikeCommunityApi.BaseUrl}/node/getNodeTopics/${nodeId}`;
		return this.get(url);
	}

	/**
	 * @param queryUserAcceptAnswer
	 * Accept Answer event in a Question node.
	 */
	public static logUserAcceptAnswer(queryUserAcceptAnswer: UserAcceptAnswerModel): Promise<IUserAcceptAnswer> {
		const url = `${StrikeCommunityApi.BaseUrl}/loguseractivity/logAcceptAnswer`;
		return this.post(url, queryUserAcceptAnswer);
	}
}
