import { InteractionType } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, withMsal } from "@azure/msal-react";
import * as React from "react";
import App from "./App";
import { loginRequest } from "./auth";
import { MsalService } from "./auth";
import { UserProfileAPIService } from "./service/userProfile/UserProfileAPI";
import AuthLoading from "./components/ui/AuthLoading";
import { AuthErrorPage } from "./components/ui/AuthErrorPage";
import LoadingPage from "./components/ui/LoadingPage";
import { SetupAxiosInterceptors } from "./auth/axiosSetup";

const userProfile = new UserProfileAPIService();

/**
 * This class is a child component of "InitMsalAuthentication". MsalContext is passed
 * down from the parent and available as a prop here.
 */
class InitMsalApp extends React.Component<any> {
	state = {
		isMsalAuthenticationInitiated: false
	};

	userAccount = this.props.msalContext.accounts;
	isAuthenticated = MsalService.IsAuthenticated;

	userDisplayName = this.isAuthenticated && this.userAccount.length > 0 ? this.userAccount[0].name : "";
	userPrincipalName: string | null;

	isUserAuthor = false;

	constructor(props: any) {
		super(props);
		if (this.userDisplayName != "") {
			this.initMsalAuthentication();
		}
	}

	initMsalAuthentication = async () => {
		if (this.isAuthenticated && !this.state.isMsalAuthenticationInitiated) {
			//Setup interceptors to intercept all axios requests
			SetupAxiosInterceptors();
			//Initialize current UserProfileService
			this.userPrincipalName = await userProfile.InitializeCurrentUserProfile();
			if (!this.userPrincipalName) {
				throw Error("Error in user signing! Verify user profile initialization.");
			}
			//Verify login user is STRIKE Auther user or not
			//this.isUserAuthor = UserProfileService.IsCurrentUserAuthor();
			//Set MSAL Authenticationed is initialize
			this.setState({ isMsalAuthenticationInitiated: true });
		}
	};

	render() {
		// Ensure that this call is not triggered from an iframe (i.e. sign in callback from AzureAD)
		if (window === window.top) {
			if (this.userPrincipalName) {
				return (
					<div>
						<App />
					</div>
				);
			} else if (this.isAuthenticated && !this.userPrincipalName) {
				const loadingMessage = `User ${this.userDisplayName} is successfully authenticated, now configuring the app based on user authorization`;
				return <LoadingPage message={loadingMessage} />;
			}
		}
		//  else {
		// 	// return <UnauthenticatedPage />;
		// }
	}
}

/**
 * This class is using "withMsal" HOC and has access to authentication
 * state. It passes down the msalContext as a prop to its children.
 */
class InitMsalAuthentication extends React.Component<any> {
	render() {
		const authRequest = {
			...loginRequest
		};
		return (
			<div>
				<AuthenticatedTemplate>
					<InitMsalApp msalContext={this.props.msalContext} />
				</AuthenticatedTemplate>
				<MsalAuthenticationTemplate
					interactionType={InteractionType.Redirect}
					authenticationRequest={authRequest}
					loadingComponent={AuthLoading}
					errorComponent={AuthErrorPage}
				/>
			</div>
		);
	}
}

// Wrap class component to access authentication state as props
export const MsalAuthentication = withMsal(InitMsalAuthentication);
