import React from "react";
import { Shimmer } from "@fluentui/react";

/**
 * Article tree view specific Shimmer build-up.
 *
 * @returns React.FunctionComponent
 */
export const ArticleTreeViewShimmer: React.FunctionComponent = () => {
	return (
		<>
			<div className="shimmer title">
				<Shimmer width="80px" />
			</div>
			<div className="shimmer group">
				<div className="shimmer">
					<Shimmer width="65%" />
				</div>
				<div className="shimmer">
					<Shimmer width="42%" />
				</div>
				<div className="shimmer">
					<Shimmer width="60%" />
				</div>
				<div className="shimmer">
					<Shimmer width="65%" />
				</div>
				<div className="shimmer">
					<Shimmer width="70%" />
				</div>
				<div className="shimmer">
					<Shimmer width="55%" />
				</div>
				<div className="shimmer">
					<Shimmer width="65%" />
				</div>
				<div className="shimmer">
					<Shimmer width="50%" />
				</div>
				<div className="shimmer">
					<Shimmer width="65%" />
				</div>
				<div className="shimmer">
					<Shimmer width="42%" />
				</div>
				<div className="shimmer">
					<Shimmer width="60%" />
				</div>
				<div className="shimmer">
					<Shimmer width="65%" />
				</div>
			</div>
		</>
	);
};
