import React from "react";
import { Shimmer } from "@fluentui/react";

/**
 * Topics specific Shimmer build-up.
 *
 * @returns React.FunctionComponent
 */
export const TopicsShimmer: React.FunctionComponent = () => {
	return (
		<>
			<div className="shimmer title">
				<Shimmer width="80%" />
			</div>
			<div className="shimmer group flex">
				<Shimmer width="25%" />
				<Shimmer width="25%" />
				<Shimmer width="25%" />
				<Shimmer width="25%" />
			</div>
			<div className="shimmer group flex">
				<Shimmer width="25%" />
				<Shimmer width="25%" />
				<Shimmer width="25%" />
				<Shimmer width="25%" />
			</div>
			<div className="shimmer group flex">
				<Shimmer width="25%" />
				<Shimmer width="25%" />
				<Shimmer width="25%" />
				<Shimmer width="25%" />
			</div>
		</>
	);
};
