import { NodeTreeItem } from "../../contracts";
import { _reset, _resetData, _setExpandedItems, _setLoaded, _setLoading, _setRawData } from "../../redux/reducers/appTreeView";
import store from "../../redux/store";
import BaseHttpAPI from "../base/BaseHttpAPI";

/**
 * App Tree view mini API, which allows the Server-side
 * building of the menu content
 */
export class AppTreeViewAPI extends BaseHttpAPI {
	constructor() {
		super("node");
	}

	/**
	 * Gets the Application Menu from the App Backend services
	 *
	 * @returns a JSON with the MenuStructure within.
	 */
	public async getNodes(nodeId: string) {
		return this.get<NodeTreeItem[]>(`getNodeNavigations/${nodeId}`).then((data) => {
			if (data) {
				store.dispatch(_setRawData(data));
			}

			return data;
		});
	}

	setRawData(items: NodeTreeItem[]) {
		store.dispatch(_setRawData(items));
	}

	setExpandedItems(expandedItemIds: string[]) {
		store.dispatch(_setExpandedItems(expandedItemIds));
	}

	setLoaded(loaded: boolean) {
		store.dispatch(_setLoaded(loaded));
	}

	setLoading(loading: boolean) {
		store.dispatch(_setLoading(loading));
	}
	
	reset() {
		store.dispatch(_reset());
	}

	resetData() {
		store.dispatch(_resetData());
	}
}
