import React from "react";
import { UserPhoto } from "../../common/user";
import { useAuthUser } from "../../../hooks/auth/useAuthUser";
import { ButtonDropdown } from "../../common/button";
import { useMsal } from "@azure/msal-react";
import { ApplicationRoutePaths } from "../../router";
import { AccountInfo } from "@azure/msal-browser";

/**
 * Used to structure the configurations
 * of a possible Logout
 */
declare type LogoutRequest = {
	account?: undefined | AccountInfo;
	postLogoutRedirectUri: string;
	logoutHint?: undefined | string;
};

/**
 * Controls the shape of props offered by MenuUserSection component.
 */
export interface IMenuUserSectionProps {}

/**
 * Helps keep the classes and styles of MenuUserSection component protected.
 * Also, elevates the simplicity and maintainability of the UserPhoto rendered within.
 *
 * @param props Input props
 * @returns React.FC
 */
export const MenuUserSection: React.FC<IMenuUserSectionProps> = () => {
	const { userDisplayName, userPrincipalName } = useAuthUser();
	const msalInstance = useMsal();
	const authUser = useAuthUser();

	/**
	 * Executes the Sign Out method from MSAL
	 */
	function handleSignOut() {
		let logoutRequest: LogoutRequest;
		const activeAccount = msalInstance.instance.getActiveAccount();

		logoutRequest = {
			postLogoutRedirectUri: ApplicationRoutePaths.root(),
			logoutHint: ""
		};

		// Only proceeds when there is an active account
		if (activeAccount) {
			const homeAccount = msalInstance.instance.getAccountByHomeId(activeAccount.homeAccountId);
			const logoutHint = homeAccount.idTokenClaims.login_hint;

			logoutRequest.account = homeAccount;
			logoutRequest.logoutHint = logoutHint;
		}

		// Initiates the logout functionality
		msalInstance.instance.logoutRedirect(logoutRequest);
	}

	/**
	 * Logout event handler for the SignOut action button
	 *
	 * @param event Event object
	 */
	const onSignOut = (event) => {
		if (typeof event?.preventDefault === "function") {
			event.preventDefault();
		}

		handleSignOut();
	};

	return (
		<div className="nav navbar-nav navbar-right login-margin-right">
			<UserPhoto userPrincipalName={userPrincipalName} className="headIcon" size="lg" />
			<div className="ml-2 hide-on-mobile">
				<ButtonDropdown
					className="user-profile-dropdown"
					callToActionText={userDisplayName}
					items={[
						{
							key: "signout",
							text: "Sign Out",
							onClick: onSignOut
						}
					]}
				/>
			</div>
		</div>
	);
};
