import { IEventNotificationTemplate } from "../../contracts";
import {
	IEventAllowListEntry,
	IEventAllowListPostRequest,
	IEventAllowListResponse,
	IEventFormData,
	IEventFormDataResponse,
	IEventUserRegistrationRequest
} from "../../contracts/event/eventForm";
import { IEventNotificationTemplateRequest, IEventRegisteredUsers, IEventUserToCheckin } from "../../contracts/models";
import { NodePreprocessingAPI } from "../base/NodePreprocessingAPI";

/**
 * Event Management tooling services class.
 */
export class EventManageAPI extends NodePreprocessingAPI {
	constructor() {
		super("event");
	}

	/**
	 * Checks whether a logged in User has access to these service's endpoints.
	 * @returns Whether the access is allowed (true) or not (false).
	 */
	async checkAdminAccess(): Promise<boolean> {
		return await this.get<boolean>(`checkAdminAccess`)
			.then((result) => {
				return result;
			})
			.catch((error) => false);
	}

	/**
	 * Get Events endpoint
	 * @param eventBody
	 * @returns
	 */
	async getEvents(): Promise<IEventFormDataResponse[]> {
		return await this.get<IEventFormData[]>(``)
			.then((result) => {
				return result;
			})
			.catch((error) => error);
	}

	/**
	 * Get Specific Event by Id endpoint
	 * @param eventBody
	 * @returns
	 */
	async getEvent(eventId: string): Promise<IEventFormData> {
		return await this.get<IEventFormData>(`${eventId}`)
			.then((result) => {
				return result;
			})
			.catch((error) => error);
	}

	/**
	 * Create new event endpoint
	 * @param eventBody
	 * @returns
	 */
	async createEvent(eventBody: IEventFormData): Promise<boolean> {
		return await this.post<IEventFormData, { HasError: boolean }>(`create`, eventBody)
			.then((result) => {
				return !result.HasError;
			})
			.catch(() => false);
	}

	/**
	 * Edit event endpoint
	 * @param eventBody
	 * @returns
	 */
	async editEvent(eventId: string, eventBody: IEventFormData): Promise<boolean> {
		return await this.put<IEventFormData, { HasError: boolean }>(`edit/${eventId}`, eventBody)
			.then((result) => {
				return !result.HasError;
			})
			.catch(() => false);
	}

	/**
	 * Delete event endpoint
	 * @param eventId
	 * @returns
	 */
	async deleteEvent(eventId: string): Promise<boolean> {
		return await this.delete<{ HasError: boolean }>(`${eventId}`)
			.then((result) => {
				return !result.HasError;
			})
			.catch(() => false);
	}

	/**
	 * Set Event Allowed Users
	 * @param eventId
	 * @param allowListEntry
	 * @returns
	 */
	async SetEventAllowUsers(allowedUsers: IEventAllowListEntry): Promise<boolean> {
		return await this.patch<IEventAllowListEntry, boolean>(`/SetAllowedUsers`, allowedUsers)
			.then((result) => {
				return result;
			})
			.catch((error) => error);
	}

	/**
	 * Get Event Allowed Users
	 * @param eventId
	 * @param userType
	 * @returns
	 */
	async GetEventAllowUsers(eventId: string, userType: string): Promise<IEventAllowListResponse[]> {
		const parsedEventId = parseInt(eventId);
		if (isNaN(parsedEventId)) {
			throw new Error("Invalid event ID");
		}
		return await this.post<IEventAllowListPostRequest, IEventAllowListResponse[]>(`/GetAllowedUsers`, {eventId: parsedEventId, userType: userType})
			.then((result) => {
				return result;
			})
			.catch((error) => {
				throw error;
			});
	}

	// /**
	//  * Registration for a single user into a specific event
	//  * EventRegistration//LogUserEventRegistrationResponse
	//  * @param eventId
	//  * @returns
	//  */
	// async userEventRegistration(eventId: string, registrationData: IEventUserRegistrationRequest): Promise<boolean> {
	// 	return await this.post<IEventUserRegistrationRequest, boolean>(`${eventId}/registration`, registrationData);
	// }

	/**
	 * Gets the Collection of Notification Templates to a given Event by ID.
	 *
	 * @param eventId The Event to get the Notification Templates for
	 * @returns IEventNotificationTemplate[] or empty list.
	 */
	async getNotificationTemplates(eventId: string): Promise<IEventNotificationTemplate[]> {
		return await this.get<IEventNotificationTemplate[]>(`${eventId}/notification/templates`)
			.then((result) => {
				return result;
			})
			.catch((error) => error);
	}

	/**
	 * Publish Event by Id
	 * @param eventId
	 * @returns
	 */
	async publishEvent(eventId: string): Promise<boolean> {
		return await this.patch(`publish/${eventId}`)
			.then((result) => {
				return true;
			})
			.catch((error) => error);
	}

	/**
	 * Unpublish Event by Id
	 * @param eventId
	 * @returns
	 */
	async unpublishEvent(eventId: string): Promise<boolean> {
		return await this.patch(`unpublish/${eventId}`)
			.then((result) => {
				return true;
			})
			.catch((error) => error);
	}

	/**
	 * Get Registered Users by Event
	 * @param eventId
	 * @returns
	 */
	async getEventAttendeesList(eventId: string): Promise<IEventRegisteredUsers[]> {
		return await this.get<IEventRegisteredUsers[]>(`attendees/${eventId}`)
			.then((result) => {
				return result;
			})
			.catch((error) => error);
	}

	/**
	 * Checkin User for specific event
	 * @param eventId
	 * @returns
	 */
	async setEventAttendeeCheckin(userToCheckinObject: IEventUserToCheckin): Promise<IEventRegisteredUsers[]> {
		return await this.post<IEventUserToCheckin, boolean>(`attendees/checkIn/`, userToCheckinObject)
			.then((result) => {
				return result;
			})
			.catch((error) => error);
	}

	/**
	 * Creates an Event Notification Template,
	 * for a first-time saving operation.
	 *
	 * @param requestData The request data to apply the save.
	 * @returns Whether the operation succeeded (true), or failed (false).
	 */
	async createNotificationTemplate(requestData: IEventNotificationTemplateRequest): Promise<boolean> {
		if (!requestData || !requestData.eventId) return false;

		return await this.post<IEventNotificationTemplateRequest, { HasError: boolean }>(
			`${requestData.eventId}/notification/templates`,
			requestData
		)
			.then((result) => {
				return !result.HasError;
			})
			.catch(() => false);
	}

	/**
	 * Creates an Event Notification Template,
	 * for a first-time saving operation.
	 *
	 * @param requestData The request data to apply the save.
	 * @returns Whether the operation succeeded (true), or failed (false).
	 */
	async updateNotificationTemplate(
		templateId: number,
		requestData: IEventNotificationTemplateRequest
	): Promise<boolean> {
		if (!templateId || !requestData || !requestData.eventId) return false;

		return await this.put<IEventNotificationTemplateRequest, { HasError: boolean }>(
			`${requestData.eventId}/notification/templates/${templateId}`,
			requestData
		)
			.then((result) => {
				return !result.HasError;
			})
			.catch(() => false);
	}


	/**
	 * Registration And Checkin from Registrations Page
	 * @param eventId
	 * @returns
	 */
	async userEventRegistrationAndCheckIn(registrationData: IEventUserRegistrationRequest): Promise<boolean> {
		return await this.post<IEventUserRegistrationRequest, boolean>(
			`RegisterAndCheckIn`,
			registrationData
		);
	}
}
