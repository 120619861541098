import React from "react";

/**
 * The FormHint properties to control its input.
 */
export interface IFormHintProps {
	children: string | React.ReactElement | JSX.Element | string[] | React.ReactElement[] | JSX.Element[];
	className?: undefined | string;
}

/**
 * Standard form hint piece. Should be used within a form-group,
 * by passing a property to it.
 *
 * @param props Properties to enable the FormHint component.
 * @returns React.FC<IFormHintProps>
 */
export const FormHint: React.FC<IFormHintProps> = (props) => {
	const conditions = {
		className: `form-field-hint ${props?.className ?? ""}`
	};

	const renderChildren = (items: string[] | React.ReactElement[] | JSX.Element[]) => {
		return items?.map((child: React.ReactElement | JSX.Element | string, index: number) => {
			return typeof child === "string" ? child : React.cloneElement(child, { key: index });
		});
	};

	return (
		<div className={conditions.className}>
			{Array.isArray(props.children) ? renderChildren(props.children) : props.children}
		</div>
	);
};
