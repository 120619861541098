import React from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationRoutePaths } from "../../../router";
import { useSelector } from "react-redux";
import { tagsSelectors } from "../../../../redux/selectors/tags";

/**
 * Interface for comments main actions component
 */
interface INodeTopicsDisplayProps {}

/**
 * Topics Display component
 * To show group of tags/topics using name and linked using id
 * @param props
 * @returns
 */
export const NodeTopicsDisplay: React.FC<INodeTopicsDisplayProps> = (props) => {
	const navigate = useNavigate();
	const TopicsIds = useSelector(tagsSelectors.getTagsIds);
	const TopicsNames = useSelector(tagsSelectors.getTagsNames);

	/**
	 * Navigate to the specific Topic list using id and name
	 * as part of the url
	 */
	function handleTagClick(tagId: string, tagName: string) {
		navigate(ApplicationRoutePaths.topics(tagId, tagName));
	}

	return (
		<div className="strike-article-topics">
			{TopicsIds &&
				TopicsIds.map((id, index) => {
					const topicName = TopicsNames[index];
					const topicAriaLabel = `Topic: ${topicName}`;

					return (
						<button
							onClick={() => handleTagClick(id, topicName)}
							key={index}
							className="badge bg-soft-secondary strike-tag-button"
							aria-label={topicAriaLabel}
							role="button"
						>
							{topicName}
						</button>
					);
				})}
		</div>
	);
};
