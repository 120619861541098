import React from "react";
import { UserListBoxContent } from "./userList.content";
import { appManagementSelectors } from "../../../../redux/selectors/appManagement";
import { useSelector } from "react-redux";

/**
 * Controls the Properties assignable to UserListBox component.
 */
export interface IUserListBoxContributors {
	title: string;
	usersUpn: string[];
}

/**
 * Renders Node Id Top Contributors
 *
 * @param props UserListBoxContributors
 * @returns React.FC<IUserListBoxContributors>
 */
export const UserListBoxContributors: React.FC<IUserListBoxContributors> = (props) => {
	const isHighZoomLevel = useSelector(appManagementSelectors.getIsHighZoomLevel);

	return (
		<div
			className={`list-box ${isHighZoomLevel ? "high-zoom-level" : ""}`}
			tabIndex={0}
			aria-label={`Interactions: ${props.title}`}
		>
			<div className="list-box-top">
                <h4 aria-label="Top Contributors">Top contributors</h4>
			</div>
			<div className="ui divider" />
			<div className="list-box-content">
				<UserListBoxContent usersUpn={props.usersUpn} type={'top'}/>
			</div>
		</div>
	);
};