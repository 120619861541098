import React from "react";
import { Shimmer } from "..";
import { IShimmerElement, ShimmerElementType } from "@fluentui/react";
import { authorMentionShared } from "../../article/author/shared";

/**
 * Article Author mentioning Shimmer component.
 *
 * @returns React.FC
 */
export const AuthorMentionShimmer: React.FC = () => {
	const shimmerElements: IShimmerElement[] = [
		{
			width: authorMentionShared.userIconWidth,
			height: authorMentionShared.userIconHeight,
			type: ShimmerElementType.circle
		},
		{
			width: 15,
			type: ShimmerElementType.gap
		},
		{
			width: 200,
			height: 16,
			type: ShimmerElementType.line
		}
	];

	return (
		<>
			<Shimmer
				height={authorMentionShared.userIconHeight}
				width={shimmerElements.reduce((prev, curr) => prev + parseInt(`${curr.width}`), 0)}
				shimmerElements={shimmerElements}
			/>
		</>
	);
};
