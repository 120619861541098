/**
 * Escape Keyboard Event capturing abstraction layer.
 *
 * @param event The event
 * @param onCloseCallback The callback to run on capture
 */
export const handleCallOnEscape = (event: KeyboardEvent, onCloseCallback: () => void) => {
	if (event?.key === "Escape" && typeof onCloseCallback === "function") {
		onCloseCallback();
		event.stopPropagation();
		return;
	}
};

/**
 * Handles the Escape Key down event by applying the following commands:
 * - handleCallOnEscape.
 *
 * @param event The event to capture the key from
 * @param onCaptureCallback The callback to be ran through the handler.
 */
export const handleEscapeKeyDown = (event: Event, onCaptureCallback: () => void) => {
	const kbdEvent = event as KeyboardEvent;

	// Handles the call on Escape capture
	handleCallOnEscape(kbdEvent, onCaptureCallback);
};
