import React, { useLayoutEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { nodesSelectors } from "../../../redux/selectors/nodes";
import { UserProfileAPIService } from "../../../service/userProfile/UserProfileAPI";
import { UserIcon } from "../icon";
import { UserIconSize } from "../../../contracts";
import { IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

/**
 * Contract for the User Profile photo display component.
 */
export interface IUserPhotoProps {
	userPrincipalName: string;
	height?: undefined | string | number;
	width?: undefined | string | number;
	className?: undefined | string;
	alt?: undefined | string;
	title?: undefined | string;
	size?: undefined | UserIconSize;
	noMargin?: undefined | boolean;
}

/**
 * Displays a User Profile photo.
 * If the picture hasn't been loaded in the state layer,
 * It will request it first, then display it.
 *
 * @param props
 * @returns
 */
export const UserPhoto: React.FC<IUserPhotoProps> = (props) => {
	const { userPrincipalName } = props;
	const b64Upn = useMemo(() => (!userPrincipalName ? "" : btoa(userPrincipalName)), [userPrincipalName]);
	const userProfileService = useRef(new UserProfileAPIService());
	const userProfilePhoto = useSelector((state) => nodesSelectors.getUserPhoto(state, b64Upn));
	// const userProfilePhotoQuerying = useSelector((state) => nodesSelectors.getQuerying(state, b64Upn));
	// const userProfilePhotoQueried = useSelector((state) => nodesSelectors.getQueried(state, b64Upn));
	const resolvedHeight = useMemo(() => {
		const _h = props?.height ?? 44;

		return typeof _h === "string" && _h.includes("px") ? _h : `${_h}px`;
	}, [props.height]);
	const resolvedWidth = useMemo(() => {
		const _w = props?.width ?? 44;

		return typeof _w === "string" && _w.includes("px") ? _w : `${_w}px`;
	}, [props.width]);
	const resolvedAlt = useMemo(() => props?.alt ?? userPrincipalName, [props.alt, userPrincipalName]);
	const resolvedSize = useMemo(() => props?.size ?? "lg", [props.size]);
	const resolvedSizeSemantic = useMemo<IconSizeProp>(
		() => (!props?.size || props.size === "lg" ? "large" : "small"),
		[resolvedSize]
	);
	const resolvedTitle = useMemo(() => props?.title ?? userPrincipalName, [props.title, userPrincipalName]);
	const resolvedClassName = useMemo(() => {
		return `img-thumbnail rounded-circle avatar-${resolvedSize} ${props?.className ?? ""}`;
	}, [props.className]);

	const iconStyles = {
		padding: "0.1rem",
		border: "1px white solid",
		borderRadius: "50%",
		margin: `1px !important`
	};

	// Self-loading the user profile picture
	useLayoutEffect(() => {
		const abortController = new AbortController();

		async function getPhotoAsync() {
			await userProfileService.current.loadProfilePictures(userPrincipalName, abortController);
		}

		getPhotoAsync();

		() => {
			abortController?.abort();
		};
	}, [userProfileService, userPrincipalName]);

	return (
		<div className={`rounded-circle ${!props?.noMargin ? "mr-1" : ""}`}>
			{!userProfilePhoto || userProfilePhoto === "" ? (
				<UserIcon
					inverted
					size={resolvedSizeSemantic}
					style={iconStyles}
					title={resolvedTitle}
					alt={resolvedAlt}
					className={resolvedClassName}
					role="img"
				/>
			) : (
				<img
					style={{ height: `${resolvedHeight} !important`, width: `${resolvedWidth} !important` }}
					src={userProfilePhoto}
					title={resolvedTitle}
					alt={resolvedAlt}
					aria-label={resolvedAlt}
					role="img"
					className={resolvedClassName}
				/>
			)}
		</div>
	);
};
