import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { ServiceHub } from "../../service";
import { ApplicationRoutePaths, prepareUrlText } from "../../components/router";
import { useSpaceSlice } from "../space/useSpaceSlice";
import { useFilterSlice } from "../filter/useFilterSlice";

/**
 * Abstraction hook with Strike Dashboard Title definition rules.
 *
 * @returns String
 */
export const useDashboardTitle = (): string => {
	const params = useParams();
	const location = useLocation();
	const filterData = useFilterSlice();
	const [searchParams] = useSearchParams();
	const [dashboardTitle, setDashboardTitle] = useState("");
	// spaces item selection
	const spaceData = useSpaceSlice();
	const selectedSpaceItem = spaceData.selectedItem;

	// Reads query string parameter "topics"
	const urlTitle = useMemo(
		() => { 
			let titleValue = (!params?.title || params.title === "" ? "" : decodeURIComponent(params?.title));
			if (titleValue) {
				titleValue = titleValue.replaceAll(' ', '-');
			}
			return titleValue;
		},
		[params]
	);

	/**
	 *
	 *
	 * @param urlToCheck
	 * @param correctivePath
	 * @returns
	 */
	const checkAndCorrectLegacyURL = (
		urlToCheck: string,
		correctivePath: string
		// searchParams?: undefined | URLSearchParams
	): void => {
		const pathParts = !urlToCheck || urlToCheck === "" ? [] : urlToCheck.split("/");
		const extractedPath =
			pathParts.length > 0 && pathParts[pathParts.length - 1].includes(".html")
				? pathParts.slice(0, pathParts.length - 1).join("/")
				: urlToCheck;

		const correctedPath = `${extractedPath}${correctivePath !== "" ? `/${prepareUrlText(correctivePath)}` : ""}${
			searchParams?.size > 0 ? `?${searchParams.toString()}` : ""
		}`;

		if (location.pathname !== correctedPath && ServiceHub.appManagementAPI.getNavigate()) {
			ServiceHub.appManagementAPI.navigate(correctedPath, { replace: true });
		}

		return;
	};

	/**
	 * Prepares the Title based on existing information,
	 * for instance, URL-based title, or a given Node Space Name.
	 *
	 * @param nodeItem [Optional] The item to use as a base to the title definition.
	 */
	const dashboardTitleSelection = useCallback((): void => {
		// The segment below prevents that hard-coded .html directions take the
		// User to the incorrect route, or even prevent leaking Space Titles.
		if (urlTitle.includes(".html")) {
			checkAndCorrectLegacyURL(location.pathname, "");
			return;
		}

		if (filterData.searchTopic !== "" && filterData.searchTopic !== null) {
			setDashboardTitle("");
			return;
		}

		if (location.pathname.includes(ApplicationRoutePaths.topicsDashboard())) {
			setDashboardTitle(urlTitle);
			return;
		}

		// At this stage, no title will be required,
		// as we are in the root of the Dashboard.
		if (location.pathname === ApplicationRoutePaths.root()) {
			setDashboardTitle("");
			return;
		}

		// If the Space Slice has a pre-selected item available,
		// Selects the item text to use as the dashboard title.
		if (selectedSpaceItem && selectedSpaceItem?.text !== "") {
			// This is an URL correction, when title was not specified, adds it to the pathname
			if (
				(urlTitle !== "" || !urlTitle || urlTitle === "") &&
				prepareUrlText(selectedSpaceItem.text) !== urlTitle
			) {
				const url = location.pathname;

				checkAndCorrectLegacyURL(url, selectedSpaceItem.text);
				return;
			}

			if (selectedSpaceItem?.text !== dashboardTitle) {
				setDashboardTitle(selectedSpaceItem.text);
			}

			return;
		}

		// Reaching to this points indicates empty setting
		setDashboardTitle("");
	}, [urlTitle, searchParams, location, selectedSpaceItem]);

	/**
	 * Manages the title on the dashboard, also controls the DashboardTitle when we search, and get no results.
	 * Also used for cleaning-up filtering state, once redirecting to the root => /
	 */
	useEffect(() => {
		dashboardTitleSelection();
	}, [dashboardTitleSelection]);

	return dashboardTitle;
};
