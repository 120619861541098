/**
 * Redux config to manage space Form data
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { IEventNotificationTemplateRequest } from "../../contracts/models";
import { eventNotificationTemplatesInitialState } from "../../models/slices";
import { IEventNotificationTemplate } from "../../contracts";

/**
 * Event Notification Templates' state management layer, also named after a Redux Slice.
 */
export const eventNotificationTemplatesSlice = createSlice({
	name: AppSlices.eventNotificationTemplates,
	initialState: eventNotificationTemplatesInitialState,
	reducers: {
		setForm: (state, action: PayloadAction<IEventNotificationTemplateRequest>) => {
			state.form = action.payload;
		},
		reset: (state) => {
			state.form = eventNotificationTemplatesInitialState.form;
			state.ui.bodySourceMode = eventNotificationTemplatesInitialState.ui.bodySourceMode;
			state.ui.editing = eventNotificationTemplatesInitialState.ui.editing;
			state.ui.selectedType = eventNotificationTemplatesInitialState.ui.selectedType;
			state.ui.submitting = eventNotificationTemplatesInitialState.ui.submitting;
		},
		resetForm: (state) => {
			state.form = eventNotificationTemplatesInitialState.form;
		},
		setFormContentSourceMode: (state, action: PayloadAction<boolean>) => {
			state.ui.bodySourceMode = action.payload;
		},
		setFormSelectedTemplateType: (state, action: PayloadAction<string>) => {
			state.ui.selectedType = action.payload;
		},
		setFormIsEditing: (state, action: PayloadAction<boolean>) => {
			state.ui.editing = action.payload;
		},
		setFormIsSubmitting: (state, action: PayloadAction<boolean>) => {
			state.ui.submitting = action.payload;
		},
		setEventNotificationTemplateList: (state, action: PayloadAction<IEventNotificationTemplate[]>) => {
			state.list = action.payload;
		},
		resetEventNotificationTemplateList: (state) => {
			state.list = eventNotificationTemplatesInitialState.list;
		},
		setEventName: (state, action: PayloadAction<string>) => {
			state.ui.eventName = action.payload;
		}
	}
});

export const eventNotificationTemplatesActions = eventNotificationTemplatesSlice.actions;

export default eventNotificationTemplatesSlice.reducer;
