// Necessary interface imports
import { RouteObject } from "react-router-dom";
import { RoutePathStrings } from "./applicationRoutePaths";

// Page Component imports
import Article from "../../article/article";
import ArticleEditPage from "../../article/edit.article";
import ArticlesDashboard from "../../dashboard/articles/dashboard.articles";

/**
 * Article Route Map.
 * Used internally to organize groups of similar RouteObjects.
 */
export const ArticleRoutes: RouteObject[] = [
	// View Article
	{
		path: RoutePathStrings.article,
		Component: Article
	},
	// View Article Comment
	{
		path: RoutePathStrings.articleComment,
		Component: Article
	},
	// Create Article
	{
		path: RoutePathStrings.articleCreate,
		Component: ArticleEditPage
	},
	// Edit Article
	{
		path: RoutePathStrings.articleEdit,
		Component: ArticleEditPage
	},
	// Articles Dashboard
	{
		path: RoutePathStrings.articlesDashboard,
		Component: ArticlesDashboard
	}
];
