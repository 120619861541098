import { ISpaceItem } from "../../contracts";
import { spacesActions } from "../../redux/reducers/spaces";
import { spacesSelectors } from "../../redux/selectors/spaces";
import store from "../../redux/store";
import BaseHttpAPI from "../base/BaseHttpAPI";

/**
 * Strike Spaces (nodes) Data management API. Used by through ServiceHub.
 */
export class SpacesAPIService extends BaseHttpAPI {
	constructor() {
		super("");
	}

	/**
	 * Gets the list of Spaces available to the User logged in.
	 *
	 * @returns A promise with a collection of Spaces
	 */
	async getSpaces(): Promise<[]> {
		return await this.get("node/getSpaceEntities");
	}

	/**
	 * [Redux] Gets the currently selected Space Id.
	 *
	 * @returns The currently found state value.
	 */
	getSelectedId(): null | number | string {
		return spacesSelectors.getSelectedId(store.getState());
	}

	/**
	 * [Redux] Gets the currently open State.
	 *
	 * @returns The currently found state value.
	 */
	getOpen(): boolean {
		return spacesSelectors.getOpen(store.getState());
	}

	/**
	 * [Redux] Gets a Space item based on a passed Id.
	 *
	 * @param spaceId The Space ID to search in the data items.
	 *
	 * @returns The currently found item in state.
	 */
	getSpaceById(spaceId: string | number): ISpaceItem {
		return spacesSelectors.getSpaceById(store.getState(), spaceId);
	}

	/**
	 * [Redux] Sets the currently selected Space Id.
	 *
	 * @param id The new ID to set. Can be nulled to unselect data.
	 */
	setSelectedId(id: null | number | string): void {
		store.dispatch(spacesActions.setSelectedId(id));
	}

	/**
	 * [Redux] Sets whether Spaces are Loaded in the state.
	 *
	 * @param loaded The new state to set.
	 */
	setLoaded(loaded: boolean): void {
		store.dispatch(spacesActions.setLoaded(loaded));
	}

	/**
	 * [Redux] Sets whether Spaces are Loading in the state.
	 *
	 * @param loading The new state to set.
	 */
	setLoading(loading: boolean): void {
		store.dispatch(spacesActions.setLoading(loading));
	}

	/**
	 * [Redux] Sets the current Space Item expanded/collapsed, based on a boolean indicator.
	 *
	 * @param spaceId The space id to expand.
	 * @param expanded The next expanded state.
	 */
	setExpandedSpaceById(spaceId: number, expanded: boolean): void {
		store.dispatch(spacesActions.setExpandedSpaceById({ spaceId, expanded }));
	}

	/**
	 * [Redux] Sets the current Space List, based on ISpaceItem[].
	 *
	 * @param items The items to store.
	 */
	setSpaces(items: ISpaceItem[]): void {
		store.dispatch(spacesActions.setSpacesList(items));
	}

	/**
	 * [Redux] Sets whether Spaces Dropdown are open in the state, or not.
	 *
	 * @param open The new state to set.
	 */
	setOpen(open: boolean): void {
		store.dispatch(spacesActions.setOpen(open));
	}
}
