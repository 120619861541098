import {IStrikeNodesComments, ReportCommentRequestModel } from "../../contracts/models";
import {
	resetEditingIds,
	setCommentInList,
	setCommentText,
	setEditingIds,
	setNodeId
} from "../../redux/reducers/comments";
import { commentsSelectors } from "../../redux/selectors/comments";
import store from "../../redux/store";
import BaseHttpAPI from "../base/BaseHttpAPI";

/**
 * Handles data management necessities with Redux,
 * by using the store within.
 */
export class CommentAPI extends BaseHttpAPI {
	constructor() {
		super("");
	}

	/**
	 * Sets the Editing Comment ID in the form state.
	 *
	 * @param nodeId The comment node editing
	 */
	async setFormNodeId(nodeId: null | number): Promise<void> {
		store.dispatch(setNodeId(nodeId?.toString() ?? null));
	}

	/**
	 * Sets the Editing Comment form text field.
	 *
	 * @param text The text to be set to the form
	 */
	async setFormText(text: string): Promise<void> {
		store.dispatch(setCommentText(text));
	}

	/**
	 * Sets the current editing comment Ids.
	 *
	 * @param editingIds The editing comment Ids collection.
	 */
	async setEditingIds(editingIds: number[]): Promise<void> {
		store.dispatch(setEditingIds(editingIds));
	}

	/**
	 * Sets a comment in the list by its ID.
	 *
	 * @param itemId The item ID being updated
	 * @param comment The comment updated data.
	 */
	async setCommentById(itemId: number, comment: IStrikeNodesComments): Promise<void> {
		store.dispatch(setCommentInList({ CommentId: itemId, ...comment }));
	}

	/**
	 * Restores the editing Ids to its default state.
	 */
	async resetEditingIds(): Promise<void> {
		store.dispatch(resetEditingIds());
	}

	/**
	 * Gets the list of currently editing Ids from state.
	 *
	 * @returns Number collection
	 */
	getEditingIds(): number[] {
		return commentsSelectors.getEditingIds(store.getState());
	}

	/**
	 * Gets a specific editing element by its ID.
	 *
	 * @param id The ID of the editing element
	 * @returns IStrikeNodesComments
	 */
	getEditingItemById(id: number | string): IStrikeNodesComments {
		return commentsSelectors.getEditingItemById(store.getState(), id);
	}


	/**
	* Prepare query object to send in report request
	*/
		getQueryObjectToReportComment(nodeId: number, reportedNodeId: number, link: string, reportedBy: string, reason: string): ReportCommentRequestModel {
			return {
				nodeId: nodeId,
				reportedNodeId: reportedNodeId,
				link: link,
				reportedBy: reportedBy,
				reason: reason
			} as ReportCommentRequestModel;
		}


	/**
	 * Report Comment on Node
	 * @param reportQueryObject 
	 * @returns 
	 */
	async reportComment(reportCommentQueryObject: ReportCommentRequestModel): Promise<boolean> {
		return await this.post("node/report", reportCommentQueryObject)
			.then((result) => true)
			.catch(() => false);
	}
}
