import { NodeActionTypes, NodeType } from "../../../contracts/models/strikeEnums";
import { IArticleFormSlice } from "../../../contracts/slices";
import { CoverImageType } from "../../../enums";

/**
 * The Initial State of an Article Form slice.
 */
export const articleFormInitialState: IArticleFormSlice = {
	list: {
		coverImages: {
			items: [],
			currentPage: 1,
			pageLength: 15,
			totalCount: 0
		},
		coverImageTypes: [],
		parentArticles: [],
		spaces: [],
		topics: []
	},
	queried: {
		coverImages: false,
		coverImageTypes: false,
		parentArticles: false,
		spaces: false,
		topics: false,
		node: false
	},
	querying: {
		coverImages: false,
		coverImageTypes: false,
		parentArticles: false,
		spaces: false,
		topics: false,
		node: false
	},
	data: {
		id: "",
		parentId: null,
		authorUpn: "",
		type: NodeType.Kbentry,
		actionType: NodeActionTypes.NewKbentry,
		title: "",
		body: "",
		topics: [],
		addedTopics: [],
		spaceId: "",
		coverImageId: null,
		commentNodeId: null,
		coverImageType: CoverImageType.Default,
		coverImageMimeType: null,
		coverImageFile: null,
		coverImagePath: "",
		coverImageSize: null,
		private: false,
		sendEmailNotification: false
	},
	gallery: {
		imagePath: {}
	},
	expandedSpaces: [],
	bodySourceMode: false,
	parentArticleQuery: "",
	coverImageGalleryOpen: false,
	topicsText: "",
	loading: false,
	loaded: false,
	submitting: false
};
