import React from "react";
import { Shimmer } from "@fluentui/react";

/**
 * Topics-specific Shimmer build-up.
 *
 * @returns React.FunctionComponent
 */
export const TopicsShimmer: React.FunctionComponent = () => {
	return (
		<>
			<div className="shimmer flex group">
				<Shimmer width="50px" />
				<Shimmer width="70px" />
				<Shimmer width="35px" />
			</div>
		</>
	);
};
