import React from "react";
import { Panel, PanelType, IPanelProps } from "@fluentui/react/lib/Panel";

/**
 * Contract for the input props of the Custom Panel component.
 */
export interface ICustomPanelProps extends IPanelProps {
	isOpen: boolean;
	onDismiss: (event) => void;
	headerText?: string;
	closeOnOutClick?: undefined | boolean;
}

/**
 * Strike App default Panel (Custom Panel).
 * Uses FluentUI components internally.
 *
 * @returns React.FC<ICustomPanelProps>
 */
export const CustomPanel: React.FC<ICustomPanelProps> = ({ isOpen, onDismiss, headerText, children, ...props }) => {
	return (
		<Panel
			headerText={headerText}
			isOpen={isOpen}
			isLightDismiss={props?.closeOnOutClick}
			onDismiss={onDismiss}
			type={PanelType.medium}
			closeButtonAriaLabel="Close"
			styles={{
				scrollableContent: {
					height: "100%"
				}
			}}
			{...props}
		>
			{children}
		</Panel>
	);
};
