import React, { useEffect, useRef, useState, FormEvent, useMemo } from "react";
import { ButtonProps, DropdownItemProps, DropdownOnSearchChangeData, DropdownProps } from "semantic-ui-react";
import { ServiceHub } from "../../../service";
import { IMSGraphUserBasic } from "../../../contracts/models";
import { FormActions, FormContainer, FormField } from "../../common/form";
import { Dropdown } from "../../common/dropdown";
import { IEventAllowListRequest, IEventAllowListResponse } from "../../../contracts/event/eventForm";
import { EventAllowUsersList } from "./event.allowusers.list";
import { Button } from "../../common/button";
import { useSearchParams } from "react-router-dom";
import { useEventForm } from "../../../hooks/event/useEventForm";
import { ApplicationRoutePaths } from "../../router";

/**
 * Controls the input of the Share Content
 * Form component.
 */
interface IEventAllowUsersFormProps {
	eventId: string;
	userType: string;
	item?: undefined | any;
	onSubmit?: undefined | (() => void | Promise<void>);
	onDismiss?: undefined | (() => void | Promise<void>);
}

/**
 * Component string map:
 * Share Content Form.
 */
const strings = {
	fields: {
		addUser: {
			id: "addUser",
			label: "Add User",
			placeholder: "Add User"
		}
	},
	buttons: {
		add: {
			text: "Add"
		},
		dismiss: {
			text: "Dismiss"
		}
	}
};

/**
 * The Share Content form,
 * Used to share content at any state of the application.
 *
 * @param props IShareContentFormProps
 * @returns React.FC<IShareContentFormProps>
 */
export const EventAllowUsersForm: React.FC<IEventAllowUsersFormProps> = (props) => {
	const graphAPI = useRef(ServiceHub.graphAPI.start());
	const searchTimeout = useRef<NodeJS.Timeout>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentSearch, setCurrentSearch] = useState<string>("");
	const [allowedUsersItems, setAllowedUsersItems] = useState<null | DropdownItemProps[]>([]);
	const [allowUsersShapedList, setAllowUsersShapedList] = useState<IEventAllowListRequest[]>([]);
	const [allowUsersPreparedShapedList, setAllowUsersPreparedShapedList] = useState<IEventAllowListRequest[]>([]);
	const [allowedUsers, setAllowedUsers] = useState<null | string[]>([]);
	const [allowedUsersAliases, setAllowedUsersAliases] = useState<null | string[]>([]);
	const [userList, setUserList] = useState<DropdownItemProps[]>([]);
	const [loadedAllowedUsers, setLoadedAllowedUsers] = useState<{ Name: string; EmailId: string }[]>([]);
	const eventFormService = useRef(ServiceHub.eventForm.start());
	const eventManageAPI = useRef(ServiceHub.eventManageAPI.start());
	const [searchParams, setSearchParams] = useSearchParams();
	const eventId = searchParams.get("eventId");
	const userType = searchParams.get("userType");
	const [announcement, setAnnouncement] = useState("");

	const { allowedUserSelection, isAllowUsersChanged } = useEventForm();

	const isAddDisabled = allowedUsersItems === null || allowedUsersItems.length === 0;
	const resolvedOptions: DropdownItemProps[] = [...allowedUsersItems, ...userList];
	const [isAllowedUsersAdded, setIsAllowedUsersAdded] = useState<boolean>(false);

	const allowUserRequest = {
		eventId: eventId,
		allowedUsers: allowedUserSelection,
		userType: userType
	};

	const handleSubmitAllowedUsers = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
		event.preventDefault();
		setAllowUsersShapedList(allowUsersPreparedShapedList);
		onClearField();
		eventFormService.current.setIsAllowUsersChanged(true);
		// eventFormService.current.setEventAllowedUserList(allowUsersShapedList);
	};

	/**
	 * Clears the Select user from the Field.
	 */
	const onClearField = (): void => {
		setAllowedUsers([]);
		setAllowedUsersAliases([]);
		setAllowedUsersItems([]);
	};

	/**
	 * Handle Discard button dismiss to close panel and reset list
	 */
	const handleDismiss = () => {
		eventFormService.current.resetEventAllowedUserList();
		eventFormService.current.setIsAllowUsersPanelOpen(false);
	};

	/**
	 * Generic method to add users with list shape
	 * @param emailId
	 * @param name
	 */
	const addSingleUser = (emailId: string, name: string) => {
		setAllowUsersPreparedShapedList((prevUsers) => [...prevUsers, { emailId, name }]);
	};

	/**
	 * Handles the On Change event from the User to Share With.
	 *
	 * @param event The event happening
	 * @param data The instant DropdownProps state
	 */
	const onChangeUser = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps): void => {
		if (data.value !== null && Array.isArray(data.value) && data.value.length > 0) {
			let userPrincipalNames = [];
			let userAliases = [];
			let dropdownItems = [];
			let userName = "";
			let userEmail = "";

			data.value.forEach((item) => {
				const dropdownItem = data.options.find((option) => item.toString() === option.key);

				if (!dropdownItem) return;

				dropdownItems.push(dropdownItem);
				userName = dropdownItem.title;
				userEmail = dropdownItem.key;
				userPrincipalNames.push(dropdownItem.key, dropdownItem.title);
				userAliases.push(
					dropdownItem.key.toString().includes("@")
						? dropdownItem.key.toString().split("@")[0]
						: dropdownItem.key.toString()
				);
			});

			addSingleUser(userEmail, userName);
			setAllowedUsers(userPrincipalNames);
			setAllowedUsersItems(dropdownItems);
			setAllowedUsersAliases(userAliases);
		} else {
			onClearField();
		}
	};

	/**
	 * Handles the event of changing the Search text,
	 * When querying users from MS Graph.
	 *
	 * @param event The event happening.
	 * @param data The Search Change event state.
	 */
	const onSearchChange = (
		event: React.SyntheticEvent<HTMLElement, Event>,
		data: DropdownOnSearchChangeData
	): void => {
		const search = data.searchQuery;
		setCurrentSearch(search);
	};

	/**
	 * Prepares the MS Graph User data to be bound to the
	 * DropdownOptions.
	 *
	 * @param users The user collection to process.
	 * @returns The converted DropdownOptions.
	 */
	const onConvertGraphUsersToDropdownOptions = (users: IMSGraphUserBasic[]) => {
		if (undefined === users || users === null || !Array.isArray(users)) return;
		const dropdownOptions = users.map((item): DropdownItemProps => {
			const userNameUpn = `${item.displayName} <${item.userPrincipalName}>`;

			return {
				key: item.userPrincipalName,
				title: item.displayName,
				text: userNameUpn,
				"aria-label": `Add user: ${item.displayName}`,
				value: item.userPrincipalName
			} as DropdownItemProps;
		});

		setUserList(dropdownOptions);
	};

	/**
	 * When Searching the users is required,
	 * This method handles the processing.
	 * And check if there are results
	 */
	const onSearch = async () => {
		setIsLoading(true);
		const searchResults = await graphAPI.current.searchUsers(currentSearch);
		try {
			if (searchResults.length === 0) {
				setAnnouncement("");
				setTimeout(() => setAnnouncement("No results found."), 100);
			}
			onConvertGraphUsersToDropdownOptions(searchResults);
			setIsLoading(false);
		} catch {

		}

	};

	/**
	 * Handles the debounced search
	 * on Users by the search criteria.
	 */
	useEffect(() => {
		async function delayedSearch() {
			// Forcing a clean-up before a new timeout, since
			// It generates debounce effect
			if (searchTimeout.current !== null) clearTimeout(searchTimeout.current);

			searchTimeout.current = setTimeout(() => {
				onSearch();
			}, 500);
		}

		// Pre-processes the query, if specified
		delayedSearch();
	}, [currentSearch]);

	useEffect(() => {
		const prepareTableState = () => {
			const combinedElements = new Set<IEventAllowListResponse>([]);

			// 1. Lifting all original Server-loaded entries to use for Table
			if (loadedAllowedUsers && loadedAllowedUsers.length > 0) {
				loadedAllowedUsers.forEach((item) => {
					if (!combinedElements.has({ name: item.Name, emailId: item.EmailId })) {
						combinedElements.add({ name: item.Name, emailId: item.EmailId });
					}
				});
			}

			// 2. Lifting all Manually added entries to use for Table
			if (allowUsersShapedList && allowUsersShapedList.length > 0) {
				allowUsersShapedList.forEach((item) => {
					if (!combinedElements.has(item)) combinedElements.add(item);
				});
			}

			// 3. Remove the items which were marked for exclusion
			// combinedElements.delete()

			eventFormService.current.setEventAllowedUserList(Array.from(combinedElements));
		};

		prepareTableState();
	}, [loadedAllowedUsers, allowUsersShapedList, eventFormService]);

	/**
	 * Getting allowed users by event id
	 */
	useEffect(() => {
		if (eventId && userType) {
			eventManageAPI.current.GetEventAllowUsers(eventId, userType).then((result: any) => {
				if (result instanceof Error) {
					ServiceHub.message.error(result.message);
					throw result;
				}
				setLoadedAllowedUsers(result);
				if (!result) {
					// Redirect the user to the event dashboard
					ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
					return;
				}
			});
		}
	}, [eventId]);

	/**
	 * handle behavior once space in node
	 * its succeesfully updated
	 */
	function handleAllowUsersSuccess() {
		setIsAllowedUsersAdded(true);
		setTimeout(() => {
			setIsAllowedUsersAdded(false);
			eventFormService.current.setIsAllowUsersPanelOpen(false);
		}, 3000);
	}

	const saveEventAllowedUsers = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		eventManageAPI.current.SetEventAllowUsers(allowUserRequest).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}

			if (!result) {
				// Redirect the user to the event dashboard
				ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
				return;
			}

			handleAllowUsersSuccess();
			eventFormService.current.setIsAllowUsersChanged(false);
			ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
		});
	};

	return (
		<div className="event-allowsusers-page">
			<div role="alert" aria-live="assertive" style={{ position: "absolute", left: "-9999px" }}>
				{announcement}
			</div>

			<FormContainer onSubmit={saveEventAllowedUsers} hideShadow hideRequiredNotice transparentBg>
				<FormField id="AllowUsers" label="Select Users" onClear={onClearField} value={allowedUsers}>
					<Dropdown
						fluid
						search
						selection
						multiSelect
						id={strings.fields.addUser.id}
						selectedValue={allowedUsers}
						options={resolvedOptions}
						onChange={onChangeUser}
						onSearchChange={onSearchChange}
						loading={isLoading}
						ariaLabel={strings.fields.addUser.label}
						placeholder={strings.fields.addUser.placeholder}
						renderLabel={(item) => {
							return item.title;
						}}
						closeOnEscape
						closeOnBlur
						useEnhancements
					/>
				</FormField>
				<div className="event-alloweduser-actions">
					<Button text="Add Users" onClick={handleSubmitAllowedUsers} disabled={isAddDisabled} />
				</div>
				<EventAllowUsersList />

				<FormActions
					actions={[
						{
							type: "submit",
							text: "Save Users",
							disabled: !isAllowUsersChanged
						},
						{
							text: "Discard",
							variation: "secondary",
							onClick: handleDismiss
						}
					]}
				/>
			</FormContainer>

			{isAllowedUsersAdded ? (
				<p className="alert alert-success" role="alert">
					Users added to Event Successfully!
				</p>
			) : null}
		</div>
	);
};
