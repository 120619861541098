import store from "../../redux/store";
import { ISpaceFormData, ISpaceItem } from "../../contracts";
import { spacesSelectors } from "../../redux/selectors/spaces";
import { spacesActions } from "../../redux/reducers/spaces";
import { IGroup } from "@fluentui/react";
import { spaceFormActions } from "../../redux/reducers/spaceForm";

/**
 * Space Form microservice, which allows interacting with the Redux state layer.
 */
export class SpaceFormService {
	/**
	 * Set form space data to use on create and edit space
	 * @param data
	 */
	setData(data: ISpaceFormData): void {
		store.dispatch(spaceFormActions.setData(data));
	}

	/**
	 * resets space form data once its created or edited
	 */
	resetData(): void {
		store.dispatch(spaceFormActions.resetData());
	}

	/**
	 * Save on state the childs items for spaces, based on space data
	 * @param items
	 */
	setSpaceManagerItemsList(items: ISpaceItem[]): void {
		store.dispatch(spacesActions.setSpaceManagerItemsList(items));
	}

	/**
	 * Save on state to use in Manage Space List Parent spaces groups
	 * @param groups
	 */
	setSpaceManagerGroupsList(groups: IGroup[]): void {
		store.dispatch(spacesActions.setSpaceManagerGroupsList(groups));
	}

	/**
	 * Set flag status to control data flow rendering done of Spaces List items
	 * @param value
	 */
	setManageFormDataListIsLoaded(value: boolean): void {
		store.dispatch(spaceFormActions.setManageFormDataListIsLoaded(value));
	}

	/**
	 * Set flag status to control data flow rendering done of Spaces Headers items
	 * @param value
	 */
	setManageFormDataHeaderIsLoaded(value: boolean): void {
		store.dispatch(spaceFormActions.setManageFormDataHeaderIsLoaded(value));
	}

	/**
	 * Set flag status to control data flow rendering in progress of Spaces List items
	 * @param value
	 */
	setManageFormDataListIsLoading(value: boolean): void {
		store.dispatch(spaceFormActions.setManageFormDataListIsLoading(value));
	}

	/**
	 * Set flag status to control data flow rendering in progress of Spaces List items
	 * @param value
	 */
	setManageFormDataHeaderIsLoading(value: boolean): void {
		store.dispatch(spaceFormActions.setManageFormDataHeaderIsLoading(value));
	}

	/**
	 * Set flag to control opening of the Delete confirmation modal
	 * @param status
	 */
	setIsOpenSpaceManagerDeleteConfirmation(status: boolean) {
		store.dispatch(spacesActions.setIsOpenSpaceManagerDeleteConfirmation(status));
	}

	/**
	 * Set flag to control Delete modal to close and reset data
	 */
	onCloseSpaceManagerDeleteConfirmation() {
		store.dispatch(spacesActions.setIsOpenSpaceManagerDeleteConfirmation(false));
		store.dispatch(spacesActions.resetSpaceManagerSpaceToDelete());
	}

	/**
	 * Used to set the space name to delete and show in the confirmation modal
	 * @param space
	 */
	setSpaceManagerSpaceToDelete(space: string) {
		store.dispatch(spacesActions.setSpaceManagerSpaceToDelete(space));
	}

	/**
	 * Sets the id qeued to delete to use in the space deletion call
	 * @param space
	 */
	setSpaceManagerSpaceIdToDelete(space: string) {
		store.dispatch(spacesActions.setSpaceManagerSpaceIdToDelete(space));
	}

	/**
	 * Resets from queue space to delete
	 */
	resetSpaceManagerSpaceToDelete() {
		store.dispatch(spacesActions.resetSpaceManagerSpaceToDelete());
	}

	/**
	 * Get the list of spaces to use and process in the Manage Space feature
	 * @returns
	 */
	getSpacesList(): ISpaceItem[] {
		return spacesSelectors.getSpacesList(store.getState());
	}

	/**
	 * It create the space data based on id
	 * @param itemId
	 * @returns
	 */
	getSpaceById(itemId: string | number): ISpaceItem {
		return spacesSelectors.getSpaceById(store.getState(), itemId);
	}

	/**
	 * Used to set the parentSpace selected in space form
	 * @param parentId
	 */
	setManagerCurrentParentSpace(parentId: string) {
		store.dispatch(spacesActions.setManagerCurrentParentSpace(parentId));
	}

	/**
	 * Used to set the parentSpace selected in space form based on the Id
	 * @param parentId
	 */
	setSelectedParentId(parentId: string) {
		store.dispatch(spaceFormActions.setSelectedParentId(parentId));
	}

	/**
	 * Flag to handle rendering control and status for space form
	 * @param value
	 */
	setIsSpaceFormLoading(value: boolean) {
		store.dispatch(spaceFormActions.setLoading(value));
	}

	/**
	 * Flag to handle loading state of form data
	 * @param value
	 */
	setIsSpaceFormDataLoading(value: boolean) {
		store.dispatch(spaceFormActions.setIsDataLoading(value));
	}

	/**
	 * Flag to set loaded state of form data
	 * @param value
	 */
	setIsSpaceFormDataLoaded(value: boolean) {
		store.dispatch(spaceFormActions.setIsDataLoaded(value));
	}

	/**
	 * Reset Parent Id when user drop form
	 */
	resetSelectedParentId() {
		store.dispatch(spaceFormActions.resetSelectedParentId());
	}

	/**
	 * Save in state data image used to show space coverImage at the form
	 * @param dataImage
	 */
	setCoverImageFile(dataImage: string) {
		store.dispatch(spaceFormActions.setCoverImageFile(dataImage));
	}

	setDescriptionBodySourceMode(sourceMode: boolean): void {
		store.dispatch(spaceFormActions.setDescriptionBodySourceMode(sourceMode));
	}

	setBannerBodySourceMode(sourceMode: boolean): void {
		store.dispatch(spaceFormActions.setBannerBodySourceMode(sourceMode));
	}

	/**
	 * Process spaces data and transforms to use as DetailsList component friendly format
	 * @param items
	 * @returns
	 */
	transFormData = (items: ISpaceItem[]) => {
		this.setManageFormDataListIsLoading(true);
		this.setManageFormDataHeaderIsLoading(true);
		this.setManageFormDataListIsLoaded(false);
		this.setManageFormDataHeaderIsLoaded(false);

		const itemsList: ISpaceItem[] = [];
		const headingList: IGroup[] = [];
		let startIndex = 0;

		items.forEach((item) => {
			if (item.IsParent) {
				const children = items.filter((child) => child.ParentId === item.Id);
				headingList.push({
					key: item.Id.toString(),
					name: item.Title,
					startIndex: startIndex,
					count: children.length
				});
				startIndex += children.length;
				itemsList.push(...children);
			}
		});

		this.setManageFormDataListIsLoaded(true);
		this.setManageFormDataHeaderIsLoaded(true);
		this.setManageFormDataListIsLoading(false);
		this.setManageFormDataHeaderIsLoading(false);

		return { itemsList, headingList };
	};
}
