import { IEventNotificationTemplate } from "../../contracts";
import { IEventNotificationTemplateRequest } from "../../contracts/models";

/**
 * Event Notification Templates data access selectors.
 */
export const eventNotificationTemplatesSelectors = {
	getForm: (state): null | IEventNotificationTemplateRequest => {
		return state.eventNotificationTemplates.form;
	},
	getFormIsEditing: (state): boolean => state.eventNotificationTemplates.ui.editing,
	getFormIsSubmitting: (state): boolean => state.eventNotificationTemplates.ui.submitting,
	getFormBodySourceMode: (state): boolean => state.eventNotificationTemplates.ui.bodySourceMode,
	getFormSelectedType: (state): null | string => state.eventNotificationTemplates.ui.selectedType,
	getList: (state): IEventNotificationTemplate[] => state.eventNotificationTemplates.list,
	getEventName: (state): null | string => state.eventNotificationTemplates.ui.eventName
};
