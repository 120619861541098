import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceHub } from "../../service";
import { authSelectors } from "../../redux/selectors/auth";
import {
	setAccessRequested,
	setAccessRequesting,
	setCanAccessCreateArticleComponents,
	setIsSuperAdmin,
	setSuperAccessRequested,
	setSuperAccessRequesting
} from "../../redux/reducers/auth";
import store from "../../redux/store";

/**
 * Defines the return types for Super Admin Access Control hook.
 */
export interface ISuperAccessControlInfo {
	isSuperAdmin: boolean;
	userId: string;
	requesting: boolean;
	requested: boolean;
}

/**
 * Checks Super Admin role functions.
 *
 * @returns Super Admin Access Control object result.
 */
export const useSuperAdminAccessControl = (): ISuperAccessControlInfo => {
	const dispatch = useDispatch();
	const superAdminService = useRef(ServiceHub.superAdminAPI.start());
	const userId = useSelector(authSelectors.getUserId);
	const isSuperAdmin = useSelector(authSelectors.getIsSuperAdminAccess);
	const requestingSuperAccess = useSelector(authSelectors.getSuperAccessRequesting);
	const requestedSuperAccess = useSelector(authSelectors.getSuperAccessRequested);

	useEffect(() => {
		/**
		 * Requests the service for Access for Super Admin role,
		 */
		async function checkSuperAccess() {
			if (!requestingSuperAccess && !requestedSuperAccess) {
				dispatch(setSuperAccessRequesting(true));

				if (superAdminService.current) {
					const result = await superAdminService.current.reviewAccess();
					store.dispatch(setIsSuperAdmin(result));
				} else {
					store.dispatch(setIsSuperAdmin(false));
				}
				dispatch(setSuperAccessRequested(true));
				dispatch(setSuperAccessRequesting(false));
			}
		}

		checkSuperAccess();
	}, [superAdminService, requestingSuperAccess, requestedSuperAccess]);

	return { isSuperAdmin, userId, requesting: requestingSuperAccess, requested: requestedSuperAccess };
};
