//
// Configurations that we retrieve from the server or environment variables
//
import axios from "axios";
import { IResourceProviderOptions, IAzureAdConfigOptions } from "./contracts";

let environmentConfigs: IResourceProviderOptions = null;

const GetCurrentEnvironmentConfigs = async () => {
	try {
		const response = await axios.get<IResourceProviderOptions>(
			window.location.origin + "/api/home/resourceProviderOptions"
		);
		if (response && response.data) {
			return response.data;
		} else {
			console.error("Failed to load initial resource configurations from the server");
			return null;
		}
	} catch (error) {
		console.error("Failed to load initial resource configurations from the server. Details: " + error);
	}
};

// Setup test config so we don't have to start the backend
// Note: DO NOT INCLUDE ANY SECRETS HERE, any information here must be public only
// Note: this section should match the appsettings.development.json section

const TestResourceProviderOptions = {
	azureAD: {
		clientId: "ae46ed7c-9ba0-4316-a11c-845211830382",
		authority: "https://login.windows.net/72f988bf-86f1-41af-91ab-2d7cd011db47",
		redirectUri: "/",
		postLogoutRedirectUri: "/"
	} as IAzureAdConfigOptions,
	endpoints: {
		strikeApiBaseUrl: "https://strikecommunitytestapi.azurewebsites.net/api/v1",
		strikeApiScope: "https://microsoft.onmicrosoft.com/ae46ed7c-9ba0-4316-a11c-845211830382/user_impersonation"
	}
} as IResourceProviderOptions;

const GetTestEnvironmentConfigs = async (delay = 500) => {
	return new Promise<IResourceProviderOptions>((resolve, reject) => {
		setTimeout(() => {
			resolve(TestResourceProviderOptions);
		}, delay);
	});
};

export const GetInitializedAppEnvironmentConfigs = (): IResourceProviderOptions | null => {
	if (environmentConfigs != null) {
		return environmentConfigs;
	}
	return null;
};

export const InitializeAppEnvironmentConfigs = async (devMode: boolean): Promise<IResourceProviderOptions> => {
	if (!environmentConfigs) {
		if (devMode) {
			// Note: Only use this in local dev mode to quickly testing without loading the config from the backend
			environmentConfigs = await GetTestEnvironmentConfigs();
		} else {
			environmentConfigs = await GetCurrentEnvironmentConfigs();
		}
	}
	return environmentConfigs;
};

export const GetAppEnvironmentConfigs = () => environmentConfigs;
