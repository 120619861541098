import { useSelector } from "react-redux";
import { appManagementSelectors } from "../../redux/selectors/appManagement";

/**
 * Abstracts the selector logic for picking Global Loader information.
 *
 * @returns Piece of state with a Loader data composition.
 */
const useLoaderIndicatorState = (visible?: undefined | boolean) => {
	const loaderVisible = useSelector(appManagementSelectors.getLoaderVisible);

	return {
		loaderVisible: visible ?? loaderVisible
	};
};

export default useLoaderIndicatorState;
