import { useEffect, useLayoutEffect, useMemo, useState } from "react";

/**
 * Used for determining Screen Widths,
 * as per natural breakpoints.
 */
export enum ScreenWidthSize {
	Mobile = "480",
	Tablet = "992",
	Desktop = "1024"
}

/**
 * Hook for distinct Screen Width sizes,
 * and whether it is matching a range or not.
 *
 * @param desiredSize The size to check within the range (less or equal to)
 * @returns Boolean indicating whether in-range, or not
 */
export const useScreenSize = (desiredSize: ScreenWidthSize): boolean => {
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight
	});

	const isMatch = useMemo(() => screenSize.width <= parseInt(desiredSize), [desiredSize, screenSize.width]);

	useEffect(() => {
		const handleResize = () => {
			setScreenSize({
				width: window.innerWidth,
				height: window.innerHeight
			});
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return isMatch;
};
