import React, { useEffect } from "react";
import { ArticlesNodesList } from "./articles.nodelist";

/**
 * Dashboard Component, in which all nodes are displayed, serves as a platform to see entire comunities, courses, etc
 */

const ArticlesDashboard = (): React.ReactElement => {
	useEffect(() => {
		document.title = 'Article - Strike Community'
	}, [])
	return <ArticlesNodesList />;
};

export default ArticlesDashboard;
