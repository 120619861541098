// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-bar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.4375em;
    background-color: #212529 ;
    z-index: 9999;
}

.loader-bar {
    height: 0.4375em;
    width: 0;
    background-color: #0a53be;
    transition: width 0.3s;
    animation: animate 1 5s;
}

@keyframes animate {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/loaderIndicator/loaderBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,gBAAgB;IAChB,0BAA0B;IAC1B,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,QAAQ;IACR,yBAAyB;IACzB,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI;QACI,QAAQ;IACZ;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".loader-bar-container {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 0.4375em;\r\n    background-color: #212529 ;\r\n    z-index: 9999;\r\n}\r\n\r\n.loader-bar {\r\n    height: 0.4375em;\r\n    width: 0;\r\n    background-color: #0a53be;\r\n    transition: width 0.3s;\r\n    animation: animate 1 5s;\r\n}\r\n\r\n@keyframes animate {\r\n    0% {\r\n        width: 0;\r\n    }\r\n    100% {\r\n        width: 100%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
