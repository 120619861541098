import React from "react";
import { Callout, Text } from "@fluentui/react";

interface Props {
	toggleIsCalloutVisible: () => void;
	labelId: string;
	descriptionId: string;
	iconId: string;
}

/**
 *
 * Callout working as a Tooltio to be used in the Topic Dashboard
 * Callout its used due the html rendered
 */
export const TopicCallout: React.FunctionComponent<Props> = ({
	toggleIsCalloutVisible,
	labelId,
	descriptionId,
	iconId
}) => {
	return (
		<Callout
			className="topic-spaces-dashboard-callout"
			aria-labeledby={labelId}
			ariaDescribedBy={descriptionId}
			role="status"
			gapSpace={0}
			target={`#${iconId}`}
			onDismiss={toggleIsCalloutVisible}
			setInitialFocus
		>
			<Text block variant="small" id={descriptionId}>
				This page displays the Topics currently in use in the system in a paginated format and may be sorted
				alphabetically or in high to low order based on the volume of content associated with the Topic.
				<br />
				<b>Note: </b>
				The indicated content count number considers all the system that is tagged with this topic regardless of
				content state or accessibility by the user. Clicking through to a particular topic page will display all
				content associated with that topic that is in a published state and accessible by the user. This may be
				different that the total number shown on this all topics page.
			</Text>
		</Callout>
	);
};
