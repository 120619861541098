import { IStrikeNodeEntity } from "../contracts/models";

/**
 * Maps a Node item from Strike WebAPI.
 */
export default class StrikeNode implements IStrikeNodeEntity {
	Id?: number;
	Title: string;
	Body: string;
	AuthName: string;
	AuthEmail: string;
	CreatedOn: string;
	Type: string;
	Base64CoverImage: string;
	LikeCount?: number;
	LikeByUsers?: string;
	CoverImagePath: string;
	CoverImageId?: null | number;
	TopicsName: string;
	ContainerId: null | number;
	ParentId?: null | number;
	ParentTitle?: string;
	IsPublished?: null | boolean;

	constructor(
		id: number,
		title: string,
		body: string,
		authEmail: string = "",
		authName: string = "",
		createdOn: string = "",
		type: string = "",
		likeCount: number = 0,
		likeByUsers: string = "",
		base64CoverImage: string = "",
		coverImagePath: string = "",
		coverImageId: null | number = null,
		topicsName: string = "",
		containerId: null | number = null,
		parentId: null | number = null,
		parentTitle: string = "",
		isPublished: null | boolean = null
	) {
		this.Id = id ?? 0;
		this.Title = title;
		this.Body = body;
		this.AuthEmail = authEmail;
		this.AuthName = authName;
		this.CreatedOn = createdOn;
		this.Type = type;
		this.LikeCount = likeCount;
		this.LikeByUsers = likeByUsers;
		this.Base64CoverImage = base64CoverImage;
		this.CoverImagePath = coverImagePath ? coverImagePath : "";
		this.CoverImageId = coverImageId ? coverImageId : null;
		this.TopicsName = topicsName ? topicsName : "";
		this.ContainerId = containerId && !isNaN(containerId) ? containerId : null;
		this.ParentId = parentId ? parentId : null;
		this.ParentTitle = parentTitle ? parentTitle : "";
		this.IsPublished = isPublished ? isPublished : null;
	}
}
