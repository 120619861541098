import BaseHttpAPI from "../base/BaseHttpAPI";

let _fnQueue: (() => Promise<any>)[] = [];
let _fnResults: any[] = [];

/**
 * Service created to handle methods from a class
 * Useful to use when we need to get room from a specific
 * triggered action, when a batch process its happening on that moment.
 * More reliable to handle the status via class service, than normal state.
 */
export class ProcessingQueueAPI extends BaseHttpAPI {
	private processing: boolean = false;

	constructor() {
		super("");
	}

	setProcessing(state: boolean): void {
		this.processing = state;
	}

	getProcessing(): boolean {
		return this.processing;
	}

	private setFnQueue(fns: (() => Promise<any>)[]): void {
		if (fns.length === 0) {
			return;
		}

		fns.forEach((fnToAdd) => {
			if (!_fnQueue.includes(fnToAdd)) {
				_fnQueue.push(fnToAdd);
			}
		});
	}

	enqueueFn(fnToEnqueue: () => Promise<any>): void {
		this.setFnQueue([fnToEnqueue]);
	}

	getQueue(): (() => Promise<any>)[] {
		return _fnQueue;
	}

	getResults(): any[] {
		return _fnResults;
	}

	prepareQueue(fns: undefined | (() => Promise<any>)[]): void {
		if (!fns) return;

		fns.forEach((fn) => {
			this.enqueueFn(fn);
		});
	}

	getNextQueueItem(): null | (() => Promise<any>) {
		if (_fnQueue.length === 0) return null;

		const currentItem = _fnQueue.shift();

		return currentItem;
	}

	async checkAndProcessQueueItems(): Promise<void> {
		let currentItem: null | (() => Promise<any>) = null;

		if (_fnQueue.length > 0 && !this.getProcessing()) {
			currentItem = this.getNextQueueItem();

			if (!currentItem) return;

			this.setProcessing(true);

			await currentItem().then((result) => {
				_fnResults.push(result);
				this.setProcessing(false);

				this.checkAndProcessQueueItems();
			});
		}
	}

	clearQueue(): void {
		_fnQueue = [];
		_fnResults = [];
	}
}
