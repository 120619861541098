import React from "react";
import { DefaultIcon, IBasicIconProps } from "./default.icon";

/**
 * Favorite Icon.
 * Helps avoid Icon rendering repetitions by
 * abstracting part of the TSX work.
 *
 * @returns React.FC
 */
export const FavoriteIcon: React.FC<IBasicIconProps> = (props) => {
	return <DefaultIcon name="heart" {...props} />;
};
