import { createSelector } from "@reduxjs/toolkit";
import { NodeTreeItem } from "../../contracts";

/**
 * Definition of selectors which will be used in the AppTreeView Reducer to manage the state of the TreeView in the Article Section
 */
export const appTreeViewSelectors = {
	getExpanded: (state): string[] => {
		return state.appTreeView.expanded;
	},
	getIsExpanded: createSelector(
		(state) => state,
		(_, itemId: string) => itemId,
		(state, itemId: string): boolean => {
			const isExpanded = state.appTreeView.expanded.includes(itemId);

			return isExpanded;
		}
	),
	getData: (state): NodeTreeItem[] => {
		return state.appTreeView.rawData;
	},
	getIsLoaded: (state): boolean => state.appTreeView.loaded,
	getLoading: (state): boolean => state.appTreeView.loading,
	getSearchText: (state): string => state.appTreeView.searchText
};
