import React from "react";
import { Shimmer } from "@fluentui/react";

export interface IEventsShimmerProps {
	size?: number;
	width?: number;
	showColumns?: boolean;
	rows?: number;
	formColumns?: boolean;
	tabsColumns?: boolean;
}

/**
 * Events Shimmer Component.
 *
 * @returns React.FunctionComponent
 */
export const EventsShimmer: React.FC<IEventsShimmerProps> = ({ size = 5, ...props }) => {
	return (
		<>
			{Array.from({ length: size }, (v, k) => k).map((q) => {
				return (
					<>
						<div key={q}>
							<div className="shimmer">
								<Shimmer width="100" />
							</div>
						</div>
					</>
				);
			})}
			{props.showColumns ? (
				<>
					{Array.from({ length: props.rows }, (v, l) => l).map((r) => {
						return (
							<div className="events-shimmer-column-container" key={r}>
								<Shimmer className="events-shimmer-column" />
								<Shimmer className="events-shimmer-column" />
								<Shimmer className="events-shimmer-column" />
								<Shimmer className="events-shimmer-column" />
								<Shimmer className="events-shimmer-column" />
							</div>
						);
					})}
				</>
			) : null}
			{props.formColumns ? (
				<>
					{Array.from({ length: props.rows }, (v, l) => l).map((r) => {
						return (
							<div className="events-shimmer-column-container" key={r}>
								<Shimmer className="events-shimmer-form-column" />
								<Shimmer className="events-shimmer-form-column" />
							</div>
						);
					})}
				</>
			) : null}
			{props.tabsColumns ? (
				<>
					{Array.from({ length: props.rows }, (v, l) => l).map((r) => {
						return (
							<div className="events-shimmer-column-container" key={r}>
								<Shimmer className="events-shimmer-tabs-column" />
								<Shimmer className="events-shimmer-tabs-column" />
								<Shimmer className="events-shimmer-tabs-column" />
								<Shimmer className="events-shimmer-tabs-column" />
							</div>
						);
					})}
					<div className="events-shimmer-column-content">
						<Shimmer className="events-shimmer-tabs-column-content" />
					</div>
					<div className="events-shimmer-column-content">
						<Shimmer className="events-shimmer-tabs-column-content" />
					</div>
					<Shimmer className="events-shimmer-tabs-column-content-area" />
				</>
			) : null}
		</>
	);
};
