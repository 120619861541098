import { useRef } from "react";
import { useEffect } from "react";
import { ServiceHub } from "../../service";

/**
 * Queue a list of prepare functions based in a dedicated service methods
 * This is helpful when we have a batch list of functions triggered at the same time
 * and we need to complete other actions in the middle and not get blocked.
 * @param downloadFns
 * @returns
 */
export const useDownloadQueue = <T>(downloadFns?: undefined | (() => Promise<T>)[]) => {
	const queueService = useRef(ServiceHub.processingQueueAPI);
	const queue = () => queueService.current.getQueue();

	/**
	 * Using service method to enqueue functions
	 * @param fnToEnqueue
	 */
	const enqueueFn = (fnToEnqueue: () => Promise<T>) => {
		queueService.current.enqueueFn(fnToEnqueue);
	};

	/**
	 * Using service method to prepare queued functions
	 * @param fns
	 */
	const prepareQueue = async (fns: undefined | (() => Promise<T>)[]) => {
		queueService.current.prepareQueue(fns);
	};

	/**
	 * Using service method to check and Process Queued items
	 */
	const checkAndProcessQueueItems = () => {
		queueService.current.checkAndProcessQueueItems();
	};

	useEffect(() => {
		prepareQueue(downloadFns);

		() => {
			// queueService.current.clearQueue();
		};
	}, []);

	useEffect(() => {
		if (queue().length > 0) queueService.current.checkAndProcessQueueItems();
	}, [queueService.current, queue]);

	return {
		queue,
		results: () => queueService.current.getResults(),
		enqueue: enqueueFn,
		processQueue: checkAndProcessQueueItems,
		isProcessing: () => queueService.current?.getProcessing()
	};
};
