import { createSelector } from "@reduxjs/toolkit";
import { NodeRevisionsResponseModel } from "../../contracts/models";
import { ITreeItem } from "@coherence-design-system/tree-view";
import { IReportReasonValue } from "../../contracts/slices/node/reportValues";
import { ServiceHub } from "../../service";
import { ISecurityGroupMembersResponse } from "../../contracts/slices/node/askToExperts";

/**
 * Definition of selectors
 * for Nodes Management.
 */
export const nodesSelectors = {
	getList: (state) => {
		return state.nodes.list;
	},
	getUserPhoto: (state, userUpn) => {
		return state.nodes.photos?.[userUpn] ?? "";
	},
	getQuerying: (state, userUpn) => state.nodes.querying?.[userUpn] ?? false,
	getQueried: (state, userUpn) => state.nodes.queried?.[userUpn] ?? false,
	getNodeTopicsIds: (state): string[] => state.nodes.node.topicsIds,
	getNodeTopicsNames: (state): string[] => state.nodes.node.topicsNames,
	getIsOpenDeleteConfirmation: (state): boolean => state.nodes.ui.isOpenDeleteConfirmation,
	getIsOpenStickConfirmation: (state): boolean => state.nodes.ui.isOpenStickConfirmation,
	getIsOpenShareContent: (state): boolean => state.nodes.ui.isOpenShareContent,
	getIsSubmittingShareContent: (state): boolean => state.nodes.ui.isSubmittingShareContent,
	getIsActionConfirmed: (state): boolean => state.nodes.ui.isActionConfirmed,
	getNodeConfirmationTitle: (state): string => state.nodes.ui.deleteConfirmationModalTitle,
	getNodeConfirmationSubText: (state): string => state.nodes.ui.deleteConfirmationModalSubText,
	getNodeConfirmationButtonText: (state): string => state.nodes.ui.deleteConfirmationModalButtonText,
	getConfirmationModalAction: (state): null => state.nodes.ui.confirmationModalAction,
	getConfirmationStickyAction: (state): null => state.nodes.ui.confirmationStickyAction,
	getIsOpenActionPostMessage: (state): boolean => state.nodes.ui.isOpenActionPostMessage,
	getIsChangeSpacePanelOpen: (state): boolean => state.nodes.ui.isChangeSpacePanelOpen,
	getIsRevisionsPanelOpen: (state): boolean => state.nodes.ui.isRevisionsPanelOpen,
	getStickSpaceStatus: (state): boolean => state.nodes.ui.isNodeSpaceSticky,
	getStickSiteStatus: (state): boolean => state.nodes.ui.isNodeSiteSticky,
	getPrivateStatus: (state): boolean => state.nodes.ui.isNodePrivate,
	getIsOpenPrivateConfirmation: (state): boolean => state.nodes.ui.isOpenPrivateConfirmation,
	getDeletedStatus: (state): boolean => state.nodes.ui.isNodeDeleted,
	getNodeDetailAuthorEmail: (state): string => state.nodes.node.authorEmail,
	getReportReasonsData: (state): string[] => state.nodes.node.reportReasonsData,
	getNodeRevisions: (state): NodeRevisionsResponseModel[] => state.nodes.node.revisions,
	getRevisionById: createSelector(
		(state) => state,
		(_, itemId: number | string) => itemId,
		(state, itemId): NodeRevisionsResponseModel => {
			if (!itemId || itemId === "") return null;

			return state.nodes.node.revisions.find((item) => item.NodeId === parseInt(itemId));
		}
	),
	getRevisionsLoaded: (state): boolean => state.nodes.node.revisionsLoaded,
	getSelectedReportReasonById: createSelector(
		(state) => state,
		(state) => state.nodes.node.reportReasonValueId,
		(state, selectedReportValueId) => {
			let reportValueById;
			const selectedReportReasonValueId = selectedReportValueId;

			if (!selectedReportReasonValueId) return null;

			reportValueById = nodesSelectors.getReasonTypeById(state, selectedReportReasonValueId);

			if (!reportValueById || reportValueById?.Name === "") return null;

			return {
				id: selectedReportReasonValueId.toString(),
				text: reportValueById?.Name
			};
		}
	),
	getReportReasonValues: createSelector(
		(state) => state,
		(state): ITreeItem[] => {
			return state.nodes.node.reportReasonsData.map((item) => nodesSelectors.convertReportReasonsToOptions(state, item));
		}
	),
	getReasonTypeById: createSelector(
		(state) => state,
		(_, itemId: number | string) => itemId,
		(state, itemId): IReportReasonValue => {
			return state.nodes.node.reportReasonsData.find((item) => item.Id === itemId);
		}
	),
	convertReportReasonsToOptions: createSelector(
		(state) => state,
		(_, item: IReportReasonValue) => item,
		(state, item: IReportReasonValue): ITreeItem => {
			return {
				id: item.Id,
				title: item.Name,
				onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, treeItem: ITreeItem) => {
					ServiceHub.appManagementAPI.setSelectedReportReason(treeItem.id);
				}
			} as ITreeItem;
		}
	),
	getQuestionExperts: (state): ISecurityGroupMembersResponse[] => state.nodes.node.questionExperts,
};
