import { ISpaceFormSlice } from "../../../contracts/slices";
import { CoverImageType } from "../../../enums";
/**
 * The Initial State of an Space Form slice.
 */
export const spaceFormInitialState: ISpaceFormSlice = {
	selectedParentId: null,
	data: {
		id: "",
		parentId: null,
		authorUpn: "",
		name: "",
		description: "",
		banner: "",
		coverImageId: null,
		coverImageType: CoverImageType.Default,
		coverImageMimeType: null,
		coverImageFile: null,
		coverImageName: "",
		coverImageSize: null
	},
	loading: false,
	loaded: false,
	dataLoading: false,
	dataLoaded: false,
	dataManageListLoading: false,
	dataManageHeaderLoading: false,
	dataManageListLoaded: false,
	dataManageHeaderLoaded: false,
	coverImageFile: "",
	descriptionBodySourceMode: false,
	bannerBodySourceMode: false
};
