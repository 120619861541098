/**
 * // Configuration of interceptors for requests and responses with Axios
 */
import axios from "axios";
import { GetAppEnvironmentConfigs } from "../appConfigs";
import { AppManagementAPI } from "../service/AppManagementAPI";
import { MsalService } from "./msalService";
import { showToast } from "../lib/strikeLibrary";
import { HttpStatusCode } from "../contracts/httpStatusCodes";

let strikeAuthApiParam = {
		scopes: []
	},
	forceRefresh: boolean = false;

/*
  Error handler method to show toast messages using the error response object.
  Using HttpStatusCode dictionart for error codes.
*/
const handleError = (error) => {
	if (error.response) {
		switch (error.response.status) {
			case HttpStatusCode.NOT_FOUND: {
				showToast(error.response.data.ErrorMessage, "error");
				break;
			}

			default: {
				showToast(error.response.data.ErrorMessage, "error");
			}
		}
	}
};

export const SetupAxiosInterceptors = () => {
	// Setup interceptors to intercept all axios requests
	axios.interceptors.request.use(
		async (config) => {
			//define instance & enable the loader
			const appManagement = new AppManagementAPI();
			// removing loader trigger for profilePhoto fetching request
			if(!config.url.includes('profilePhoto')) {
				appManagement.setLoaderVisible(true);
			}

			// Add the authorization header with the access token to axios requests
			// If no token is found, assume no authorization header is required

			// Reading the API Scope from the Environment Configs
			const environmentConfigs = GetAppEnvironmentConfigs();

			if (environmentConfigs) {
				strikeAuthApiParam.scopes.push(environmentConfigs.endpoints.strikeApiScope);
			}

			if (strikeAuthApiParam.scopes.length > 0) {
				let token: string = await MsalService.GetAccessToken(strikeAuthApiParam, forceRefresh);
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
					forceRefresh = false;
				} else {
					console.error(`Unable to acquire access token for ${config.url}`);
				}
				return config;
			} else {
				console.error(`Unable to acquire strikeApiScope`);
				throw Error("Unable to acquire strikeApiScope! Verify App Environment configuration.");
			}
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		(res) => {
			const appManagement = new AppManagementAPI();
			//disable the loader
			appManagement.setLoaderVisible(false);
			return res;
		},
		(error) => {
			// handleError(error);
			// Setup interceptors to intercept all axios response errors
			// Cleanup the token that did not work from the cache on 401 response
			if (error.response && error.response.status === 401) {
				forceRefresh = true;
			}
			return Promise.reject(error);
		}
	);
};
