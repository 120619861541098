import React from "react";
import { Shimmer } from "@fluentui/react";

/**
 * Question-specific Shimmer build-up.
 *
 * @returns React.FunctionComponent
 */
export const QuestionShimmer: React.FunctionComponent = () => {
	return (
		<>
			<div className="shimmer title">
				<Shimmer width="80px" />
			</div>
			<div className="shimmer group">
				<div className="shimmer">
					<Shimmer width="65%" />
				</div>
				<div className="shimmer">
					<Shimmer />
				</div>
				<div className="shimmer">
					<Shimmer width="80%" />
				</div>
			</div>
			<div className="shimmer">
				<Shimmer type={"gap"} />
			</div>
			<div className="shimmer group">
				<div className="shimmer">
					<Shimmer />
				</div>
				<div className="shimmer">
					<Shimmer width="85%" />
				</div>
				<div className="shimmer">
					<Shimmer width="52%" />
				</div>
			</div>
			<div className="shimmer group">
				<div className="shimmer">
					<Shimmer width="90%" />
				</div>
				<div className="shimmer">
					<Shimmer width="74%" />
				</div>
				<div className="shimmer">
					<Shimmer width="70%" />
				</div>
			</div>
		</>
	);
};
