import React, { useMemo, useRef } from "react";
import { IContextualMenuItem, IIconProps } from "@fluentui/react";
import { ServiceHub } from "../../../../../service";
import { ButtonDropdown } from "../button.dropdown";
import { ApplicationRoutePaths } from "../../../../router";
import { useFilterSlice } from "../../../../../hooks/filter/useFilterSlice";
import { useDispatch } from "react-redux";
import { resetSearchTopics } from "../../../../../redux/reducers/filter";

/**
 * Renders a re-usable Action Button group for Strike App.
 * Includes actions:
 * - Topics
 * - Articles
 * - Questions
 *
 * @returns React.FC
 */
export const ButtonDropdownActions: React.FC = () => {
	const filterData = useFilterSlice();
	const buttonTitle = filterData.dashboardButtonTitle;
	const dispatch = useDispatch();
	const filterAPI = useRef(ServiceHub.filterAPI.start());

	/**
	 * Redirects the UI to the View Topics List page.
	 *
	 * @param event
	 */
	const handleViewTopics = (
		ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
		item?: IContextualMenuItem
	) => {
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.topicsDashboard());
		dispatch(resetSearchTopics());
		filterAPI.current.resetUserCustomDashboard();
	};

	/**
	 * Redirects the UI to the View only Articles.
	 *
	 */
	const handleViewArticles = (
		ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
		item?: IContextualMenuItem
	) => {
		if (ev.preventDefault) {
			ev.preventDefault();
		}
		filterAPI.current.resetUserCustomDashboard();
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.articlesDashboard());
	};

	/**
	 * Redirects the UI to the View only Questions.
	 *
	 */
	const handleViewQuestions = (
		ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
		item?: IContextualMenuItem
	) => {
		if (ev.preventDefault) {
			ev.preventDefault();
		}
		filterAPI.current.resetUserCustomDashboard();
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.questionsDashboard());
	};



	/**
	 * List of the buttons which will be rendered in the dropdown.
	 */
	const buttonItems = useMemo(
		() => [
			{
				key: "seeArticlesDashboard",
				text: "Articles",
				onClick: handleViewArticles,
				title: "Activate to View the Articles Dashboard",
				iconProps: {
					iconName: "Articles"
				}
			},
			{
				key: "seeQuestionsDashboard",
				text: "Questions",
				onClick: handleViewQuestions,
				title: "Activate to View the Questions Dashboard",
				iconProps: {
					iconName: "SurveyQuestions"
				}
			},
			{
				key: "seeTopicsDashboard",
				text: "Topics",
				onClick: handleViewTopics,
				title: "Activate to View the Topics List",
				iconProps: {
					iconName: "Tag"
				}
			}
		],
		[]
	);

	/**
	 * prepared memo to detect location and set button label name and icon
	 */
	const mainButtonLabel = useMemo(() => {
		if (location.pathname === '/articles') {
			return 'Articles'
		} else if (location.pathname === '/questions') {
			return 'SurveyQuestions'
		} else if (location.pathname === '/topics') {
			return 'Tag'
		} else {
            return 'PageList'
		}
	}, [location.pathname]);


	const labelIcon: IIconProps = { iconName: mainButtonLabel };

	return (
		<ButtonDropdown
			fullWidth
			iconProps={labelIcon}
			callToActionText={buttonTitle}
			className="filter-actions-dropdown"
			items={buttonItems}
		/>
	);
};
