import React from "react";
import LoaderIndicator from "../loaderIndicator/loaderIndicator";

/**
 * Type definition of the properties of the Loading Page.
 */
type LoadingPageProps = {
	header?: string;
	message: string;
};

/**
 * System Main Loading Page,
 * which displays a top loader
 * with text in its center.
 *
 * @param props
 * @returns
 */
const LoadingPage: React.FC<LoadingPageProps> = (props) => {
	const headerMessage = props.header || "Please wait a moment...";

	return (
		<div className="page-section">
			<section className="loading-container">
				<LoaderIndicator visible />
				<h4>{headerMessage}</h4>
				<div>{props.message}</div>
			</section>
		</div>
	);
};

export default LoadingPage;
