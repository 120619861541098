import React from "react";

export interface ILoadingState {
	isLoading: boolean;
}

export interface IReadOnlyState {
	isReadOnly: boolean;
}

export interface IVisibleState {
	isVisible: boolean;
}

export interface IEditingState {
	isDirty: boolean;
}

export enum ErrorType {
	none = 0,
	info = 1,
	warning = 2,
	error = 3
}

export interface IErrorState {
	errorType: ErrorType;
	errorMessage: string;
}

export const IErrorState_Default: IErrorState = {
	errorType: ErrorType.none,
	errorMessage: ""
};

export enum RequestState {
	notStarted = 0,
	inProgress = 1,
	complete = 2
}

export interface IRequestState {
	requestState: RequestState;
}

export interface IAlertProps {
	text: string | React.ReactElement;
	category?: string;
	html?: boolean;
}

export enum LayoutType {
	GRID = "GRID",
	NORMAL_LIST = "NORMAL_LIST"
}
