import React from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationRoutePaths } from "../router";

interface Props {
	Topics: string;
	TopicsIds: string;
}

export const Topics: React.FunctionComponent<Props> = (props) => {
	const re = /, ?|\n/gm; // Matches a comma followed by an optional space or a new line
	const navigate = useNavigate();
	const TopicsNames = props.Topics.split(re);

	/**
	 * Navigate to the specific Topic list using id and name
	 * as part of the url
	 */
	function handleTagClick(tagId: string, tagName: string) {
		navigate(ApplicationRoutePaths.topics(tagId, tagName));
	}

	return (
		<div>
			{props.TopicsIds &&
				props.TopicsIds.split(re).map((id, index) => {
					const topicTitle = TopicsNames[index];
					const ariaLabelTitle = `Topic: ${TopicsNames[index]}`;

					return (
						<button
							onClick={() => handleTagClick(id, topicTitle)}
							key={index}
							className="badge bg-soft-secondary strike-tag-button"
							title={topicTitle}
							aria-label={ariaLabelTitle}
						>
							{topicTitle}
						</button>
					);
				})}
		</div>
	);
};
