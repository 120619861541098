import { ISpacesSlice } from "../../../contracts/slices/spaces/ISpacesSlice";

/**
 * The Initial State of Spaces List Management slice.
 */
export const spacesInitialState: ISpacesSlice = {
	selectedId: null,
	expandedSpaces: [],
	loaded: false,
	loading: false,
	open: false,
	spaces: [],
	manager: {
		items: [],
		groups: [],
		ui: {
			deleteConfirmationOpen: false,
			spaceToDelete: "",
			spaceIdToDelete: "",
			currentParentSpace: ""
		}
	},
	spaceCoverImage: "",
	spaceName: "",
	spaceCoverImageLoaded: false,
	spaceDescription: "",
	spaceBanner: "",
	spaceDetailsLoaded: false
};
