/**
 * Redux config to manage the app
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NodeTreeItem } from "../../contracts";
import { appTreeViewInitialState } from "../../models/slices";
import { AppSlices } from "../slices";

/**
 * Application Tree View component state layer, also named Redux Slice.
 */
export const appTreeViewSlice = createSlice({
	name: AppSlices.appTreeView,
	initialState: appTreeViewInitialState,
	reducers: {
		_setRawData: (state, action: PayloadAction<NodeTreeItem[]>) => {
			state.rawData = action.payload;
		},
		_setExpandedItems: (state, action: PayloadAction<string[]>) => {
			state.expanded = action.payload;
		},
		_setLoaded: (state, action: PayloadAction<boolean>) => {
			state.loaded = action.payload;
		},
		_setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		_reset: (state) => {
			state.expanded = appTreeViewInitialState.expanded;
			state.loaded = appTreeViewInitialState.loaded;
			state.loading = appTreeViewInitialState.loading;
		},
		_resetData: (state) => {
			state.rawData = appTreeViewInitialState.rawData;
		},
		_setTreeNavSearchText: (state, action: PayloadAction<string>) => {
			state.searchText = action.payload;
		}
	}
});

export const { _setExpandedItems, _reset, _resetData, _setRawData, _setLoaded, _setLoading, _setTreeNavSearchText } = appTreeViewSlice.actions;

export default appTreeViewSlice.reducer;
