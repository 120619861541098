import React from "react";
import { Shimmer } from "@fluentui/react";

/**
 * Node Activity Shimmer
 *
 * @returns React.FunctionComponent
 */
export const NodeActivityShimmer: React.FunctionComponent = () => {
	return (
		<>
			<div className="shimmer group">
				<div className="shimmer">
					<Shimmer width="40%" />
				</div>
				<div className="shimmer">
					<Shimmer width="40%" />
				</div>
			</div>
		</>
	);
};
