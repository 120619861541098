import React, { useCallback, useMemo, useRef, useState } from "react";
import { Icon } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { commentsSelectors } from "../../redux/selectors/comments";
import { NodeActivityCommentBody } from "../common/node/activity/nodeActivity.comment.body";
import { NodeActivityCommentActions } from "../common/node/activity/nodeActivity.comment.actions";
import { NodeActivityCommentReply } from "../common/node/activity/nodeActivity.comment.reply";
import { NodeActivityComment } from "../common/node/activity/nodeActivity.comment";
import { CommentType, NodeActionTypes, NodeType } from "../../contracts/models/strikeEnums";
import { setAllNodeComments, setReplyItemExpandedById } from "../../redux/reducers/comments";
import { Button } from "../common/button";
import { useAuthUser } from "../../hooks/auth/useAuthUser";
import { getQueryUserAcceptAnswerObject, validateEmail } from "../../lib/strikeLibrary";
import { IStrikeNodesComments, UserAcceptAnswerModel } from "../../contracts/models";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import { ServiceHub } from "../../service";
import { NodeActivityCommentForm } from "../common/node/activity/nodeActivity.comment.form";

/**
 * Component ShowAnswers add answers content for a specific Question node
 * @prop answers
 */
interface Props {
	nodeId: number;
}

export const ShowAnswers: React.FunctionComponent<Props> = ({ nodeId }) => {
	const dispatch = useDispatch();
	const commentSvc = useRef(ServiceHub.commentAPI.start());
	const allNodeComments = useSelector(commentsSelectors.getOrderedNodeComments);
	const expandedId = useSelector(commentsSelectors.getReplyExpandedId);
	const filteredComments = useSelector((state) => commentsSelectors.getRootNodeComments(state, nodeId));
	const { userPrincipalName } = useAuthUser();
	const ipAddress = "";
	const [revertableAnswers, setRevertableAnswers] = useState([]);

	/**
	 * Ordering comments from oldest to newest
	 */
	const orderedComments = useMemo(
		() =>
			filteredComments?.sort((firstObject, secondObject) => {
				const dateA = firstObject.AnswerAccepted;
				const dateB = secondObject.AnswerAccepted;
				return dateA && !dateB ? -1 : 1;
			}) ?? [],
		[filteredComments]
	);

	/**
	 * Utility to expand the comment box for each comment
	 * when 'Reply' button clicked.
	 * @param itemId
	 */
	const handleReplyExpand = (itemId: number) => {
		dispatch(setReplyItemExpandedById(itemId === expandedId ? null : itemId));
	};

	/**
	 * Call to API to log Accept Answer and get fresh Answers
	 * @param userAcceptAnswer
	 */
	const logAcceptAnswerActivity = async (userAcceptAnswer: UserAcceptAnswerModel) => {
		const res = await NodeAPIServiceInstance.logUserAcceptAnswer(userAcceptAnswer);
		if (res) {
			const response = await NodeAPIServiceInstance.getNodeComments(nodeId);
			if (response) {
				dispatch(setAllNodeComments(response));
			}
		}
	};

	/**
	 * Handle Accept Answer button process
	 * Query data to pass to api to log event
	 * @param answerId
	 */
	const sendAcceptedAnswer = (answerId: number, isAnswerAccepted: boolean) => {
		const actionType = isAnswerAccepted ? NodeActionTypes.Accept : NodeActionTypes.Unaccept;

		const updatedRevertableAnswers = isAnswerAccepted
			? [...revertableAnswers, answerId]
			: revertableAnswers.filter((id) => id !== answerId);

		setRevertableAnswers(updatedRevertableAnswers);

		if (validateEmail(userPrincipalName)) {
			let queryAcceptAnswerActivity = getQueryUserAcceptAnswerObject(
				actionType,
				ipAddress,
				answerId.toString(),
				userPrincipalName,
				nodeId.toString(),
				isAnswerAccepted
			);
			logAcceptAnswerActivity(queryAcceptAnswerActivity);
		}
	};

	const isEditing = useCallback(
		(comment: IStrikeNodesComments) => commentSvc.current.getEditingIds().includes(comment.CommentId),
		[commentSvc]
	);

	const renderAnswerBody = useCallback(
		(comment: IStrikeNodesComments) => {
			const nodeActionType = comment.Type as NodeActionTypes; // === NodeType.Question ? NodeActionTypes.Answer : NodeActionTypes.Comment;
			let _headlineTextPart = "";

			switch (nodeActionType) {
				case NodeActionTypes.Answer: {
					_headlineTextPart = "Answer";
					break;
				}
				case NodeActionTypes.Comment: {
					_headlineTextPart = "Comment";
					break;
				}
				default: {
					_headlineTextPart = "Reply";
					break;
				}
			}

			_headlineTextPart = `Editing ${_headlineTextPart}:`;

			return isEditing(comment) ? (
				<div className={"node-activity-comment"}>
					<NodeActivityCommentForm
						headlineText={_headlineTextPart}
						buttonText={"Confirm"}
						actionType={nodeActionType}
						parentId={comment.ParentId}
						id={comment.CommentId}
						onCancel={async () => {
							ServiceHub.commentAPI.start().resetEditingIds();
						}}
					/>
				</div>
			) : (
				<NodeActivityCommentBody comment={comment} />
			);
		},
		[isEditing]
	);

	return (
		<div>
			<div className="node-question-answers">
				{orderedComments.map((comment, index) => (
					<ul
						className={`node-question-answers-box ${
							comment.AnswerAccepted ? "answer-accepted box-shadow-close" : ""
						}`}
						key={comment.CommentId}
					>
						<li
							id={comment.CommentId.toString()}
							className={`node-activity-comment-wrapper ${
								orderedComments.length - 1 === index ? "last-comment" : ""
							}`}
						>
							{comment.Type === "answer" && comment.AnswerAccepted ? (
								<div
									className="ui left ribbon large label question-ribbon green-question-ribbon"
									tabIndex={0}
									aria-label="Accepted Answer item"
									role="group"
								>
									<Icon iconName="ReceiptCheck" className="node-question-ui-icons" />
									Accepted Answer
								</div>
							) : comment.Type === "answer" ? (
								<div
									className="ui left ribbon large label question-ribbon green-question-ribbon"
									tabIndex={0}
									aria-label="Answer item"
									role="group"
								>
									<Icon iconName="FeedbackRequestSolid" className="node-question-ui-icons" />
									Answer
								</div>
							) : (
								<div
									className="ui left ribbon large label question-ribbon blue-question-ribbon"
									tabIndex={0}
									aria-label="Comment item"
									role="group"
								>
									<Icon iconName="CommentActive" className="node-question-ui-icons" />
									Comment
								</div>
							)}

							{renderAnswerBody(comment)}
							{/* <NodeActivityCommentBody comment={comment} /> */}

							{comment.Type === CommentType.Answer &&
							!comment.AnswerAccepted &&
							comment.CommentByEmail !== userPrincipalName ? (
								<div className="node-question-answer-accept">
									<Button
										text="Accept Answer"
										onClick={() => sendAcceptedAnswer(comment.CommentId, true)}
									/>
								</div>
							) : null}

							{comment.AnswerAccepted && revertableAnswers.includes(comment.CommentId) ? (
								<div className="node-question-answer-accept">
									<Button
										text="Revert Accept Answer"
										onClick={() => sendAcceptedAnswer(comment.CommentId, false)}
									/>
								</div>
							) : null}

							<NodeActivityCommentActions
								likesNumber={comment.LikeCount}
								likeByUsers={comment.LikeByUsers}
								handleReplyExpand={handleReplyExpand}
								id={comment.CommentId}
								nodeType={comment.Type}
								isEditing={isEditing(comment)}
							/>
							{expandedId === comment.CommentId ? (
								<NodeActivityCommentReply
									commentId={comment.CommentId}
									actionType={
										comment.Type === CommentType.Answer
											? NodeActionTypes.CommentToAnswer
											: NodeActionTypes.AnswerToComment
									}
								/>
							) : null}
							<NodeActivityComment
								comments={allNodeComments}
								parentId={comment.CommentId}
								nodeType={NodeType.Question}
							/>
						</li>
					</ul>
				))}
			</div>
		</div>
	);
};
