import React from "react";
import { IconButton as FluentIconButton, CommandBarButton } from "@fluentui/react";
import { ScreenWidthSize, useScreenSize } from "../../../hooks";

/**
 * Controls the input properties of Icon Button component
 */
export interface IIconButtonProps {
	iconName: string;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
	text?: undefined | string;
	className?: undefined | string;
	tabIndex?: undefined | number;
	ariaLabel?: undefined | string;
	title?: undefined | string;
	disabled?: undefined | boolean;
}

/**
 * Reusable Icon button for Strike application.
 */
export const IconButton: React.FC<IIconButtonProps> = (props) => {
	const iconProps = { iconName: props.iconName ?? "" };
	const isTablet = useScreenSize(ScreenWidthSize.Tablet);

	return (
		<CommandBarButton
			tabIndex={props?.tabIndex ?? 0}
			text={isTablet ? undefined : props?.text ?? undefined}
			alt={props?.text ?? undefined}
			title={props?.title ? props.title : props?.text ?? undefined}
			iconProps={iconProps}
			onClick={props?.onClick}
			className={`iconbutton-default ${props?.className} ${isTablet ? "tablet" : ""}`}
			aria-label={props?.ariaLabel ?? undefined}
			disabled={props?.disabled ? props.disabled : undefined}
		/>
	);
};
