import React from "react";
import { EventCheckInDetails } from "./shared/event.show.event.checkin";

/**
 * Component Event Check-In to handle Events Check-In actions.
 */
const EventsCheckInPage: React.FC<{}> = () => {

	return <EventCheckInDetails />;

};

export default EventsCheckInPage;
