import React, { useMemo } from "react";
import {
	Shimmer as FluentShimmer,
	ShimmerElementsGroup,
	IShimmerElement,
	ShimmerElementType,
	createTheme,
	mergeStyleSets
} from "@fluentui/react";

/**
 * Regulates the properties accepted by Shimmer implementation.
 */
export interface IShimmerProps {
	width?: string | number;
	height?: string | number;
	type?: ShimmerElementType;
	shimmerElements?: IShimmerElement[];
	className?: undefined | string;
	title?: undefined | string;
	alt?: undefined | string;
}

const customThemeForShimmer = createTheme({
	palette: {
		// palette slot used in Shimmer for all the space around the shimmering elements
		white: "transparent"
	}
});

const classNames = mergeStyleSets({
	themedBackgroundWrapper: {
		// using the palette color to match the gaps and borders of the shimmer.
		background: customThemeForShimmer.palette.white
	}
});

/**
 * Shimmer wrapper implementation for the application.
 *
 * @param props
 * @returns JSX.Element
 */
export const Shimmer: React.FunctionComponent<IShimmerProps> = (props) => {
	const regExpOnlyDigits = new RegExp(/[^\d]/, "gi");
	const {
		className = "",
		shimmerElements = [],
		title = "",
		alt = "",
		type = ShimmerElementType.line,
		width = "44",
		height = "14"
	} = props;
	const resolvedWidth = useMemo(() => {
		const _w = width?.toString();

		return parseInt(_w.replace(regExpOnlyDigits, ""));
	}, [width]);
	const resolvedHeight = useMemo(() => {
		const _h = height?.toString();

		return type === ShimmerElementType.circle ? resolvedWidth : parseInt(_h.replace(regExpOnlyDigits, ""));
	}, [height, resolvedWidth]);

	/**
	 * Builds the shimmer as an Element group,
	 * allowing for flex use of it.
	 *
	 * @returns An element group (ShimmerElementsGroup)
	 */
	const getElementGroup = () => {
		return (
			<ShimmerElementsGroup
				flexWrap
				shimmerElements={shimmerElements}
				className={className ?? undefined}
				backgroundColor="var(--body-background-color)"
			/>
		);
	};

	return (
		<span className={`shimmer inline ${classNames.themedBackgroundWrapper}`}>
			{shimmerElements?.length > 0 ? (
				<FluentShimmer
					customElementsGroup={getElementGroup()}
					width={resolvedWidth}
					title={title}
					alt={alt}
					style={{
						...(type === ShimmerElementType.circle ? { borderRadius: "50%" } : {}),
						overflow: "hidden"
					}}
				/>
			) : (
				<FluentShimmer
					width={resolvedWidth}
					height={resolvedHeight}
					shimmerElements={[
						{
							type: type,
							width: resolvedWidth,
							height: resolvedHeight
						}
					]}
					title={title}
					alt={alt}
					className={className}
					style={{
						...(type === ShimmerElementType.circle ? { borderRadius: "50%" } : {}),
						overflow: "hidden"
					}}
				/>
			)}
		</span>
	);
};
