import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { FormSpace } from "../../common/node/space/space.form";
import { useSpaceForm } from "../../../hooks/space/useSpaceForm";
import { ServiceHub } from "../../../service";
import { ApplicationRoutePaths } from "../../router";

/**
 * Re-usable Space creation form
 *
 * @returns React.FC
 */
export const SpaceForm: React.FC = () => {
	const dispatch = useDispatch();
	const spacesFormDataAPI = useRef(ServiceHub.spaceAdminAPI.start());
	const { spaceFormData, isNewSpace } = useSpaceForm();
	const spacesAPI = useRef(ServiceHub.spacesAPI.start());
	const spaceFormService = useRef(ServiceHub.spaceForm.start());

	/**
	 * Executes the necessary commands for Creating a Space
	 */
	const createSpace = () => {
		spacesFormDataAPI.current.createSpace(spaceFormData).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}

			if (!result) {
				// Redirect the user to the dashboard
				ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.dashboard());
				return;
			}

			spaceFormService.current.resetData();
			spacesAPI.current.getSpaces().then((result) => spacesAPI.current.setSpaces(result));
			ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.spaceManage());
		});
	};

	/**
	 * Executes the necessary commands for Edit a Space,
	 */
	const editSpace = () => {
		spacesFormDataAPI.current.editSpace(spaceFormData.id, spaceFormData).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
			if (!result) {
				// Redirect the user to the dashboard
				ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.dashboard());
				return;
			}

			spaceFormService.current.resetData();
			spacesAPI.current.getSpaces().then((result) => spacesAPI.current.setSpaces(result));
			ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.spaceManage());
		});
	};

	function onSubmit(event: React.FormEvent<HTMLFormElement>) {
		if (event.preventDefault) event.preventDefault();

		if (isNewSpace) {
			createSpace();
		} else {
			editSpace();
		}

		return;
	}

	return !spaceFormData ? null : <FormSpace onSubmit={onSubmit} />;
};
