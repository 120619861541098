import React, { useCallback } from "react";
import { IconButton } from "../../button";

/**
 *
 */
export interface IUserListPrimaryActionProps {
	title: string;
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
	variation?: undefined | "primary" | "secondary";
	iconName?: undefined | string;
}

/**
 *
 * @param props
 * @returns
 */
export const UserListPrimaryAction: React.FC<IUserListPrimaryActionProps> = (props) => {
	const memoPrimaryActionClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
		return typeof props?.onClick === "function" ? props.onClick(event) : null;
	};

	return (
		<IconButton
			text={props.title}
			iconName={props?.iconName}
			onClick={memoPrimaryActionClick}
			// variation={!props?.variation || props.variation === "primary" ? "primary" : "secondary"}
		/>
	);
};
