import BaseHttpAPI from "../base/BaseHttpAPI";
import { NodeActionTypes } from "../../contracts/models/strikeEnums";
import { NodeTopicUpdate, TopicRequest } from "../../models";
import { ServiceHub } from "../base/ServiceHub";

/**
 * Strike Topics (tags) Data management API.
 * To be best used by through ServiceHub.
 *
 * Consumes the following services:
 * - POST /nodeadmin/topics/create (create)
 * - PUT /node/topics/update (updateNodeTopics)
 */
export class TopicsAPIService extends BaseHttpAPI {
	constructor() {
		super("");
	}

	/**
	 * Creates a list of Topics in the services
	 *
	 * @param topics The collection of Topics to create
	 * @param actionType [Optional] If different from NodeActionTypes.NewTopic, please specify this parameter
	 *
	 * @returns A promise with success 200, or errors out
	 */
	async create(topics: string[], actionType: NodeActionTypes = NodeActionTypes.NewTopic): Promise<[]> {
		const userPrincipalName = await ServiceHub.userProfileAPI.start().GetCurrentUserUpn();

		return await this.post("nodeadmin/topics/create", new TopicRequest(topics, userPrincipalName, actionType));
	}

	/**
	 * Updates a list of Topics within a given Node, based on its ID.
	 *
	 * @param nodeId The Node ID to update the entry
	 * @param topics The collection of Topics to create
	 * @param actionType [Optional] If different from NodeActionTypes.NewTopic, please specify this parameter
	 *
	 * @returns A promise with success 200, or errors out
	 */
	async updateNodeTopics(
		nodeId: number,
		topics: string[],
		actionType: NodeActionTypes = NodeActionTypes.ReTag
	): Promise<[]> {
		const userPrincipalName = await ServiceHub.userProfileAPI.start().GetCurrentUserUpn();

		return await this.put("node/topics/update", new NodeTopicUpdate(nodeId, topics, userPrincipalName, actionType));
	}
}
