import { useSelector } from "react-redux";
import { appTreeViewSelectors } from "../../redux/selectors/appTreeView";

/**
 * Contract for the Tree Navigation hook.
 */
interface ITreeNavHook {
	treeNavSearchText: string;
}


/**
 * Hook to handle and processing TreeNav search feature data
 * @returns ITreeNavHook
 */
export const useTreeNavigationSearch = (): ITreeNavHook => {
	const treeNavSearchText = useSelector(appTreeViewSelectors.getSearchText);

	return {
		treeNavSearchText
	} as ITreeNavHook;
};
