import { Configuration, RedirectRequest, LogLevel } from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.includes("MSIE ") ? ua.search("MSIE ") : -1;
const msie11 = ua.includes("Trident/") ? ua.search("Trident/") : -1;
const msedge = ua.includes("Edge/") ? ua.search("Edge/") : -1;
const firefox = ua.includes("Firefox") ? ua.search("Firefox") : -1;
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

// Default Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		clientId: "64bd0c88-2d66-4227-a2bb-b1fcc3243289",
		authority: "https://login.windows.net/72f988bf-86f1-41af-91ab-2d7cd011db47",
		redirectUri: "/",
		postLogoutRedirectUri: "/"
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: isIE || isEdge || isFirefox
	},
	system: {
		allowNativeBroker: false, // Disables WAM Broker
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
					default:
						return;
				}
			}
		}
	}
};

// scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
	scopes: ["User.Read"],
};

// The endpoints for MS Graph API services
export const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
	graphUsersEndpoint: "https://graph.microsoft.com/v1.0/users"
};
