/**
 * Redux config to manage follow component state
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { tagsInitialState } from "../../models/slices";
import { TagsModeType } from "../../contracts/models/strikeEnums";

/**
 * Node Tags Management state layer, also named after a Redux Slice.
 * Save tagsMode(edit/view) and tags data as ids and names used to link a display.
 *
 */
export const tagsSlice = createSlice({
	name: AppSlices.tags,
	initialState: tagsInitialState,
	reducers: {
		setTagsMode: (state, action: PayloadAction<TagsModeType>) => {
			state.ui.tagsMode = action.payload;
		},
		setTagsIds: (state, action: PayloadAction<string[]>) => {
			state.data.tagsIds = action.payload;
		},
		setTagsNames: (state, action: PayloadAction<string[]>) => {
			state.data.tagsNames = action.payload;
		}
	}
});

export const { setTagsMode, setTagsIds, setTagsNames } = tagsSlice.actions;

export default tagsSlice.reducer;
