import { ServiceHub } from "../base/ServiceHub";

/**
 * The supported color schemes.
 */
export enum ColorScheme {
	RGB = "RGB",
	RGBA = "RGBA",
	HEX = "HEX",
	UNKNOWN = "UNKNOWN"
}

/**
 * Holds color information.
 * RGB scheme.
 */
declare type RGBObject = {
	r: number;
	g: number;
	b: number;
};

/**
 * Color Contrast detection service.
 *
 * Construction was based on ref from https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o.
 */
export class ContrastDetection {
	ColorContrastIndexes = {
		AA_Normal: 0.22222,
		AA_Large: 0.33333,
		AAA_Normal: 0.14285,
		AAA_Large: 0.22222
	};

	/**
	 * Detects and returns which color scheme a given nuance code is.
	 *
	 * @param colorCode The color code to be tested
	 * @returns The found color scheme
	 */
	detectColorScheme(colorCode: string): ColorScheme {
		if (!colorCode || colorCode.length === 0) {
			throw Error("Color detection cannot handle null or empty codes");
		}

		const upperCaserCode = colorCode.toUpperCase();

		if (upperCaserCode.startsWith("#")) {
			return ColorScheme.HEX;
		}

		if (upperCaserCode.startsWith(ColorScheme.RGBA)) {
			return ColorScheme.RGBA;
		}

		if (upperCaserCode.startsWith(ColorScheme.RGB)) {
			return ColorScheme.RGB;
		}

		return ColorScheme.UNKNOWN;
	}

	/**
	 * Checks whether a given param is an Hex-based color code.
	 *
	 * @param colorString The color string to check.
	 * @returns Boolean whether it is true or not.
	 */
	isHexColor(colorString: string): boolean {
		const colorScheme = this.detectColorScheme(colorString);

		return colorScheme === ColorScheme.HEX;
	}

	/**
	 * Checks whether a given param is an RGB-based color code.
	 *
	 * @param colorString The color string to check.
	 * @returns Boolean whether it is true or not.
	 */
	isRGBColor(colorString: string): boolean {
		const colorScheme = this.detectColorScheme(colorString);

		return colorScheme === ColorScheme.RGB;
	}

	/**
	 * Checks whether a given param is an RGBA-based color code.
	 *
	 * @param colorString The color string to check.
	 * @returns Boolean whether it is true or not.
	 */
	isRGBAColor(colorString: string): boolean {
		const colorScheme = this.detectColorScheme(colorString);

		return colorScheme === ColorScheme.RGBA;
	}

	/**
	 * Checks whether a given param is an Unknown color code.
	 *
	 * @param colorString The color string to check.
	 * @returns Boolean whether it is true or not.
	 */
	isUnknownColor(colorString: string): boolean {
		const colorScheme = this.detectColorScheme(colorString);

		return colorScheme === ColorScheme.UNKNOWN;
	}

	/**
	 * Converts a given source color into a RGB for comparisons.
	 *
	 * @param sourceColor The source color to test and convert
	 * @returns The resolved color code in the new scheme.
	 */
	prepareColor(sourceColor: string): null | RGBObject {
		let convertedColorCode: null | RGBObject;

		if (!sourceColor || sourceColor.length === 0) {
			throw Error("Color converter cannot handle null or empty codes");
		}

		const colorScheme: ColorScheme = this.detectColorScheme(sourceColor);

		switch (colorScheme) {
			case ColorScheme.HEX: {
				convertedColorCode = this.colorToRgb(sourceColor);
				break;
			}
			default: {
				break;
			}
		}

		return convertedColorCode;
	}

	/**
	 * Casts Hex/RGBA/any color schemes onto RGB.
	 * Base on https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o.
	 */
	colorToRgb(hexCode: string): null | RGBObject {
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

		if (this.isRGBColor(hexCode)) {
			// If already RGB color, will use its structure to calculate it
			const splitColors: string[] = hexCode.replace("rgb(", "").replace(")", "").split(",");

			return {
				r: parseInt(splitColors[0]),
				g: parseInt(splitColors[1]),
				b: parseInt(splitColors[2])
			};
		}

		if (this.isRGBAColor(hexCode)) {
			// If already RGBA color, will use its structure to calculate it
			const splitColors: string[] = hexCode.replace("rgba(", "").replace(")", "").split(",");

			return {
				r: parseInt(splitColors[0]),
				g: parseInt(splitColors[1]),
				b: parseInt(splitColors[2])
			};
		}

		const newHex = hexCode.replace(shorthandRegex, function (m, r, g, b) {
			return r + r + g + g + b + b;
		});

		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);

		return result?.length > 0
			? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16)
			}
			: null;
	}

	/**
	 * Calculates Contrast ratio between 2 given colors.
	 *
	 * @param colorA The first color from the comparison
	 * @param colorB Second color to compare
	 * @returns The Contrast ration between colors
	 */
	calculateContrast(colorA: string, colorB: string): number {
		// Casting color into correct schemes
		const colorArgb = this.colorToRgb(colorA);
		const colorBrgb = this.colorToRgb(colorB);

		if (!colorArgb || !colorBrgb) {
			throw new Error("Invalid or Unsupported color format");
		}

		// calculate the relative luminance
		const colorAluminance = this.luminance(colorArgb.r, colorArgb.g, colorArgb.b);
		const colorBluminance = this.luminance(colorBrgb.r, colorBrgb.g, colorBrgb.b);

		// calculate the color contrast ratio
		const ratio =
			colorAluminance > colorBluminance
				? (colorBluminance + 0.05) / (colorAluminance + 0.05)
				: (colorAluminance + 0.05) / (colorBluminance + 0.05);

		return ratio;
	}

	/**
	 * Calculates luminance for a given combination of Red-Green-Blue color tone.
	 *
	 * @param r Color's Red intensity
	 * @param g Color's Green intensity
	 * @param b Color's Blue intensity
	 * @returns The index of luminance for the given RGB-based color
	 */
	luminance(r: number, g: number, b: number): number {
		const a = [r, g, b].map(function (v) {
			v /= 255;
			return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
		});

		return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
	}

	/**
	 * Checks whether a given HTMLElement can color-contrast with a given Background-color.
	 *
	 * @param element The element to test the style.color property
	 * @param backgroundColor [Optional] The background color to be used during the test
	 * @param correctiveColor [Optional] The corrective color code to be used during the fix
	 */
	checkElementColorContrast(
		element: HTMLElement,
		backgroundColor?: undefined | string,
		correctionColor?: undefined | string
	) {
		const bodyComputedStyleMap = window.getComputedStyle(document.body);
		const bodyBackgroundColor: string =
			backgroundColor && backgroundColor.length > 0
				? backgroundColor
				: element.style.backgroundColor !== ""
				? element.style.backgroundColor
				: bodyComputedStyleMap.backgroundColor;
		const bodyCorrectiveColor: string =
			correctionColor && correctionColor.length > 0 ? correctionColor : bodyComputedStyleMap.color;

		try {
			//Defines the contrast ration between the 2 given colors
			const contrastRatio = this.calculateContrast(element.style.color, bodyBackgroundColor);

			// If any WCAG validation instance fails over
			if (
				contrastRatio > this.ColorContrastIndexes.AA_Normal ||
				contrastRatio > this.ColorContrastIndexes.AA_Large ||
				contrastRatio > this.ColorContrastIndexes.AAA_Normal ||
				contrastRatio > this.ColorContrastIndexes.AAA_Large
			) {
				// If minimum contrast ratio has not been achieved
				// Applies the correction to this item specifically
				element.style.setProperty("color", `${bodyCorrectiveColor}`);
			}
		} catch (ex) {
			// Process future logging
		}
	}
}
