import React, { useCallback, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NodeActivityCommentActions } from "./nodeActivity.comment.actions";
import { IStrikeNodesComments } from "../../../../contracts/models";
import { Button } from "../../button";
import nodeActivityConstants from "./constants";
import { NodeActivityCommentReply } from "./nodeActivity.comment.reply";
import { NodeActivityCommentBody } from "./nodeActivity.comment.body";
import { commentsSelectors } from "../../../../redux/selectors/comments";
import { NodeActionTypes, NodeType } from "../../../../contracts/models/strikeEnums";
import { setCommentsToShow, setReplyItemExpandedById } from "../../../../redux/reducers/comments";
import { ServiceHub } from "../../../../service";
import { NodeActivityCommentForm } from "./nodeActivity.comment.form";

/**
 * Interface for comments main component
 */
interface INodeActivityCommentProps {
	comments: IStrikeNodesComments[];
	parentId?: number;
	nodeType: NodeType;
	ariaLevel?: undefined | number;
}

/**
 * Main component to handle comments information
 * in a specific article/question
 * @param comments
 * @param parentId
 * @returns
 */
export const NodeActivityComment: React.FC<INodeActivityCommentProps> = ({
	comments,
	parentId,
	nodeType,
	ariaLevel = 1
}) => {
	const dispatch = useDispatch();
	const commentSvc = useRef(ServiceHub.commentAPI.start());
	const expandedId = useSelector(commentsSelectors.getReplyExpandedId);
	const rootComments = useSelector((state) => commentsSelectors.getRootNodeComments(state, parentId));
	const commentsToShow = useSelector(commentsSelectors.getCommentsToShow);
	const hideShowAllButton = useSelector((state) => commentsSelectors.getShouldHideAll(state, parentId));

	/**
	 * Ordering comments from oldest to newest
	 */
	const orderedRootComments = useMemo(
		() =>
			rootComments?.sort((firstObject, secondObject) => {
				const dateA = new Date(firstObject.CommentOn);
				const dateB = new Date(secondObject.CommentOn);
				return dateA.getTime() - dateB.getTime();
			}) ?? [],
		[rootComments]
	);

	/**
	 * Setting up ranges for comments to display above and below
	 * the 'show more comments' pagination
	 */
	const rootCommentsEndList = useMemo(() => {
		return !hideShowAllButton && orderedRootComments.length > nodeActivityConstants.maxOffset
			? orderedRootComments.slice(-nodeActivityConstants.maxOffset)
			: [];
	}, [hideShowAllButton, orderedRootComments]);
	const rootCommentsStartList = useMemo(() => {
		return !hideShowAllButton
			? orderedRootComments.slice(0, Math.min(orderedRootComments.length, commentsToShow))
			: orderedRootComments;
	}, [hideShowAllButton, orderedRootComments, commentsToShow]);

	/**
	 * Utility to expand the comment box for each comment
	 * when 'Reply' button clicked.
	 * @param itemId
	 */
	const handleReplyExpand = (itemId: number) => {
		dispatch(setReplyItemExpandedById(itemId === expandedId ? null : itemId));
	};

	/**
	 * Method used for pagination triggered by
	 * 'show more comments' button
	 */
	const showMoreComments = () => {
		dispatch(
			setCommentsToShow(
				orderedRootComments.length <= commentsToShow
					? orderedRootComments.length
					: commentsToShow + nodeActivityConstants.maxOffset
			)
		);
	};

	const isEditing = useCallback(
		(comment: IStrikeNodesComments) => commentSvc.current.getEditingIds().includes(comment.CommentId),
		[commentSvc]
	);

	const renderCommentBody = useCallback(
		(comment: IStrikeNodesComments) => {
			const nodeActionType = comment.Type as NodeActionTypes;
			// const editHeadlineText = `Editing ${
			// 	comment.Type === NodeType.Question ? NodeActionTypes.Answer : NodeActionTypes.Comment
			// }`;

			let _headlineTextPart = "";

			switch (nodeActionType) {
				case NodeActionTypes.Answer: {
					_headlineTextPart = "Answer";
					break;
				}
				case NodeActionTypes.Comment: {
					_headlineTextPart = "Comment";
					break;
				}
				default: {
					_headlineTextPart = "Reply";
					break;
				}
			}

			_headlineTextPart = `Editing ${_headlineTextPart}:`;

			return isEditing(comment) ? (
				<div className={"node-activity-comment"}>
					<NodeActivityCommentForm
						headlineText={_headlineTextPart}
						buttonText={"Confirm"}
						actionType={nodeActionType}
						parentId={comment.ParentId}
						id={comment.CommentId}
						onCancel={async () => {
							ServiceHub.commentAPI.start().resetEditingIds();
						}}
					/>
				</div>
			) : (
				<NodeActivityCommentBody comment={comment} />
			);
		},
		[isEditing]
	);

	/**
	 * Reaching end of the levels
	 */
	if (orderedRootComments.length === 0) {
		return null;
	}

	return (
		<div className="node-activity-comment-container" tabIndex={0} aria-label="Comments">
			<ul>
				{rootCommentsStartList.length > 0
					? rootCommentsStartList.map((comment, index) => {
							const editing = isEditing(comment);

							return (
								<li
									key={comment.CommentId}
									id={comment.CommentId.toString()}
									className={`node-activity-comment-wrapper ${
										hideShowAllButton && rootCommentsStartList.length - 1 === index
											? "last-comment"
											: ""
									}`}
									aria-label={`Comment number: ${index + 1}. Author: ${comment.CommentByName}.`}
								>
									{renderCommentBody(comment)}
									<NodeActivityCommentActions
										likesNumber={comment.LikeCount}
										likeByUsers={comment.LikeByUsers}
										handleReplyExpand={handleReplyExpand}
										id={comment.CommentId}
										nodeType={comment.Type}
										isEditing={editing}
									/>
									{expandedId === comment.CommentId ? (
										<NodeActivityCommentReply
											commentId={comment.CommentId}
											actionType={NodeActionTypes.AnswerToComment}
										/>
									) : null}
									<NodeActivityComment
										comments={comments}
										parentId={comment.CommentId}
										nodeType={nodeType}
										ariaLevel={ariaLevel + 1}
									/>
								</li>
							);
						})
					: null}
				{!hideShowAllButton && orderedRootComments.length > commentsToShow ? (
					<li>
						<div className="node-activity-comments-more-border"></div>
						<div className="node-activity-comments-more">
							<Button variation="secondary" text="Show more comments" onClick={showMoreComments} />
						</div>
					</li>
				) : null}
				{!hideShowAllButton && rootCommentsEndList.length > 0
					? rootCommentsEndList.map((comment, index) => {
							const editing = isEditing(comment);

							return (
								<li
									key={comment.CommentId}
									id={comment.CommentId.toString()}
									className={`node-activity-comment-wrapper ${
										rootCommentsEndList.length - 1 === index ? "last-comment" : ""
									}`}
								>
									{renderCommentBody(comment)}
									<NodeActivityCommentActions
										likesNumber={comment.LikeCount}
										likeByUsers={comment.LikeByUsers}
										handleReplyExpand={handleReplyExpand}
										id={comment.CommentId}
										nodeType={comment.Type}
										isEditing={editing}
									/>
									{expandedId === comment.CommentId ? (
										<NodeActivityCommentReply
											commentId={comment.CommentId}
											actionType={NodeActionTypes.AnswerToComment}
										/>
									) : null}
									<NodeActivityComment
										comments={comments}
										parentId={comment.CommentId}
										nodeType={nodeType}
										ariaLevel={ariaLevel + 1}
									/>
								</li>
							);
						})
					: null}
			</ul>
		</div>
	);
};
