import { useCallback, useEffect, useRef } from "react";

import { appTreeViewSelectors } from "../../redux/selectors/appTreeView";

import { useSelector } from "react-redux";
import { NodeTreeItem } from "../../contracts";
import store from "../../redux/store";
import { AppTreeViewAPI } from "../../service/app/AppTreeViewAPI";

/**
 * Contract for typing the hook return state
 */
export interface ITreeViewStateHook {
	expandedItems: string[];
	loaded: boolean;
	loading: boolean;
	rawData: NodeTreeItem[];
	getIsExpanded: (itemId: string) => boolean;
	setRawData: (items: NodeTreeItem[]) => void;
	setExpandedItems: (expandedItemIds: string[]) => void;
	setLoaded: (isLoaded: boolean) => void;
	setLoading: (isLoading: boolean) => void;
	resetData: () => void;
	reset: () => void;
}

/**
 * Principal Hook to manage the LinkTreeView state in the application
 *
 * @param viewNodeId
 */
export function useTreeViewState(viewNodeId: string): ITreeViewStateHook {
	const treeViewAPI = useRef(new AppTreeViewAPI());
	const isMounted = useRef(false);
	const lastViewNodeId = useRef(viewNodeId);
	const treeViewExpanded = useSelector(appTreeViewSelectors.getExpanded);
	const treeViewLoaded = useSelector(appTreeViewSelectors.getIsLoaded);
	const treeViewRawData = useSelector(appTreeViewSelectors.getData);
	const treeViewLoading = useSelector(appTreeViewSelectors.getLoading);

	const getIsExpanded = useCallback((itemId: string) => {
		return appTreeViewSelectors.getIsExpanded(store.getState(), itemId);
	}, []);

	const setRawData = useCallback((items: NodeTreeItem[]) => {
		treeViewAPI.current.setRawData(items);
	}, []);

	const setExpandedItems = useCallback((expandedItemIds: string[]) => {
		treeViewAPI.current.setExpandedItems(expandedItemIds);
	}, []);

	const setLoaded = useCallback((isLoaded: boolean) => {
		treeViewAPI.current.setLoaded(isLoaded);
	}, []);

	const setLoading = useCallback((isLoading: boolean) => {
		treeViewAPI.current.setLoading(isLoading);
	}, []);

	const resetData = useCallback(() => {
		treeViewAPI.current.resetData();
	}, []);

	const reset = useCallback(() => {
		treeViewAPI.current.reset();
	}, []);

	const fetchData = useCallback(async () => {
		setLoading(true);

		await treeViewAPI.current
			.getNodes(viewNodeId)
			.then((r) => {
				if (!r || r === null) {
					return;
				}

				setLoading(false);
				setLoaded(true);
			})
			.catch((error) => {
				setLoading(false);
				setLoaded(false);
			});
	}, [viewNodeId]);

	useEffect(() => {
		if (viewNodeId && !treeViewLoaded && !treeViewLoading && treeViewRawData.length === 0) {
			fetchData();
		}
	}, [treeViewLoaded, treeViewLoading, treeViewAPI, viewNodeId, treeViewRawData]);

	useEffect(() => {
		if (lastViewNodeId.current !== viewNodeId) {
			lastViewNodeId.current = viewNodeId;
			reset();
			resetData();
		}
	}, [lastViewNodeId, viewNodeId]);

	useEffect(() => {
		isMounted.current = true;

		return () => {
			reset();
			resetData();
		};
	}, [isMounted]);

	return {
		expandedItems: treeViewExpanded,
		loaded: treeViewLoaded,
		loading: treeViewLoading,
		rawData: treeViewRawData,
		getIsExpanded,
		setRawData,
		setExpandedItems,
		setLoaded,
		setLoading,
		resetData,
		reset
	};
}
