import { base64Encode } from "../../lib/strikeLibrary";
import BaseHttpAPI from "../base/BaseHttpAPI";

/**
 * Microservice API for handling files within the App.
 * Sub-service of Application Resources context.
 */
export class AppFileAPI extends BaseHttpAPI {
	constructor() {
		super("storage");
	}

	/**
	 * Gets a Cover Image file from the backend, based on a given file-Path.
	 *
	 * @param filePath The file path to use
	 * @returns The found File
	 */
	async getCoverImageByURL(filePath: string): Promise<void | Blob> {
		const base64Encoded = base64Encode(filePath);

		return await this.getFile(`coverImage/${base64Encoded}`);
	}

	/**
	 * Gets a file from the backend, based on a given file-Path.
	 *
	 * @param filePath The file path to use
	 * @returns The found File
	 */
	async getAttachmentFileByURL(filePath: string): Promise<void | Blob> {
		const base64Encoded = base64Encode(filePath);

		return await this.getFile(`attachments/${base64Encoded}`);
	}

	/**
	 * Downloads a file by a given file path.
	 * It will be passing-through the Backend WebAPI
	 * for a file filtering and access authorization and control.
	 *
	 * @param filePath The file to be downloaded.
	 */
	async downloadFile(filePath: string): Promise<void> {
		if (!filePath || filePath === "") {
			throw new Error(`Unable to download a file with filePath '${filePath}'`);
		}

		// Downloads the file by its URL
		await this.getAttachmentFileByURL(filePath)
			.then((fileBlob) => {
				if (!fileBlob) {
					throw new Error(`An error occurred while downloading a file. It's empty.`);
				}

				const browserObjectURL = URL.createObjectURL(fileBlob);

				let anchorNode = document.createElement("a");
				anchorNode.style.display = "none";
				anchorNode.setAttribute("href", browserObjectURL);
				anchorNode.download = filePath;
				document.body.appendChild(anchorNode);

				// Clicking the anchor to execute the download of the file
				anchorNode.click();

				// Clean-up relay function after 200 seconds
				setTimeout(() => {
					// Cleans-up the URL for preventing memory-leakage
					URL.revokeObjectURL(browserObjectURL);
					document.body.removeChild(anchorNode);
					anchorNode = null;
				}, 200);

				return;
			})
			.catch((error) => {
				// handle error scenario
				throw error;
			});
	}

	/**
	 * Uploads a Cover Image to Strike's blob storage.
	 * Gets a string, which should be base-64 encoded to submit to the services.
	 *
	 * @param base64ImageContent
	 * @param onUploaded
	 */
	async coverImageUpload(
		base64ImageContent: string,
		fileName: string,
		onUploaded: (filePath: string) => void | Promise<void>
	) {
		const fData = new FormData();

		fData.append("file", base64ImageContent);
		fData.append("fileName", fileName);

		await this.post("coverImage/upload", fData).then((response) => {
			if (typeof onUploaded === "function") onUploaded(response.toString());
		});
	}

	/**
	 * Uploads an Attachment from a Node Body, as part of the HTML.
	 * Gets a string File Path which must be base-64 encoded, with the location of the stored file.
	 *
	 * @param base64ImageContent
	 * @param onUploaded
	 */
	async attachmentUpload(
		base64ImageContent: string,
		fileName: string,
		onUploaded: (filePath: string) => void | Promise<void>
	) {
		const fData = new FormData();

		fData.append("file", base64ImageContent);
		fData.append("fileName", fileName);

		await this.post("attachments/upload", fData).then((response) => {
			if (typeof onUploaded === "function") onUploaded(response.toString());
		});
	}
}
