import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	DropdownItemProps,
	DropdownOnSearchChangeData,
	DropdownProps,
	LabelProps,
	Dropdown as SemanticDropdown
} from "semantic-ui-react";
import { handleEscapeKeyDown } from "./shared/enhanceItems.function";

/**
 * Controls the input props of Strike's Dropdown.
 */
export interface IDropdownProps {
	// Semantic-UI
	options: DropdownItemProps[];
	onChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => Promise<void> | void;
	onSearchChange?:
		| undefined
		| ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData) => Promise<void> | void);
	renderLabel?: undefined | ((item: DropdownItemProps, index: number, defaultLabelProps: LabelProps) => any);
	onAddItem?: undefined | ((event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void);
	id?: undefined | string;
	title?: undefined | string;
	ariaLabel?: undefined | string;
	className?: undefined | string;
	selectedValue?: undefined | number | string | number[] | string[];
	disabled?: undefined | boolean;
	multiSelect?: undefined | boolean;
	placeholder?: undefined | string;
	required?: undefined | boolean;
	error?: undefined | boolean;
	loading?: undefined | boolean;
	fluid?: undefined | boolean;
	key?: undefined | number;
	search?: undefined | boolean;
	selection?: undefined | boolean;
	allowAdditions?: undefined | boolean;
	closeOnBlur?: undefined | boolean;
	closeOnEscape?: undefined | boolean;
	wrapSelection?: undefined | boolean;
	width?: undefined | number;
	alt?: undefined | string;
	useEnhancements?: undefined | boolean;
	skipTabIndexing?: undefined | boolean;
}

/**
 * Renders a Strike-standard Dropdown component.
 * Implements a Semantic UI Dropdown within.
 *
 * @param props IDropdownProps
 * @returns React.FC<IDropdownProps>
 */
export const Dropdown: React.FC<IDropdownProps> = (props) => {
	const prevSelectedValue = useRef([]);
	const dropdownRef = useRef(null);
	const abortController = useRef(new AbortController());

	/**
	 * Wraps the functional On Change event of the dropdown,
	 * to keep the enhancement functionality for the List Items while changing selections.
	 *
	 * @param event
	 * @param data
	 */
	const handleChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		if (typeof props?.onChange === "function") {
			props.onChange(event, data);
		}

		// Selects the last Remove button automatically,
		// After changing options - if any.
		// Otherwise, just skips the focus action.
		setTimeout(() => {
			const elements = document.querySelectorAll(`div[id=${props.id}] a.ui.label i.icon`);

			if (elements.length === 0) {
				return;
			}

			(elements.item(elements.length - 1) as HTMLElement).focus();
		}, 180);
	};

	/**
	 * Wraps the functional On Search Change of the dropdown,
	 * Keeping control of the item indexing and roles.
	 *
	 * @param event
	 * @param data
	 */
	const handleSearchChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData) => {
		if (typeof props?.onSearchChange === "function") {
			props.onSearchChange(event, data);
		}
	};

	/**
	 * Wraps the functional On Search Change of the dropdown,
	 * Keeping control of the item indexing and roles.
	 *
	 * @param event
	 * @param data
	 */
	const handleAddItem = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
		if (typeof props?.onAddItem === "function") {
			props?.onAddItem(event, data);
		}
	};

	/**
	 * Enhances the tab-indexing of the items listed below a .menu div.
	 * Also, enhances the control Items accessibility,
	 * By adding roles to .menu items. Finally,
	 * Enhances the accessibility for search Items focus trapping,
	 * Enabling tab-based navigation and enter-based selection.
	 */
	const enhanceSearchItems = useCallback(() => {
		const elements = document.querySelectorAll(`div[id=${props.id}] div.menu > div:not(.message)`);

		elements.forEach((el, index) => {
			if (!props?.skipTabIndexing && !el.hasAttribute("tabindex")) {
				el.setAttribute("tabindex", `0`);
			}

			// Sets the role of each option/item under .menu group,
			// Only for those missing it. Note: Adding a partial-list threshold cut-line.
			if (!el.hasAttribute("role")) {
				el.setAttribute("role", "option");
			}

			// Cut-down to 30 elements with events;
			if (index >= 30) return;

			/**
			 * Manages to include a handler function to an element, when focusing it, to control an emit a delegate on KeyDown.
			 * The handle
			 *
			 * @param onFocusEvent
			 */
			const onFocusIn = (onFocusInEvent) => {
				const onKeyDown = (eventOnKeyDown) => {
					const kbdEvent = eventOnKeyDown as KeyboardEvent;

					if (kbdEvent.key === "Enter") {
						(el as HTMLElement).click();
						kbdEvent.stopPropagation();
					}
				};

				// Clean-up any previous events, then sets-up new events listeners
				el.addEventListener("keydown", onKeyDown, true);

				/**
				 * Cleans-up the added event handlers
				 */
				const onFocusOut = (onFocusOutEvent) => {
					// return
				};

				// Clean-up any previous events, then sets-up new events listeners
				el.addEventListener("focusout", onFocusOut, true);
			};

			// If not using extra enhancements, skips event tracking
			if (props?.useEnhancements) {
				// Clean-up any previous events, then sets-up new events listeners
				el.addEventListener("focusin", onFocusIn, true);
			}
		});
	}, [props.id, props?.skipTabIndexing, props?.useEnhancements]);

	/**
	 * Enhances the accessibility for Item Labels,
	 * Enabling tab-based navigation.
	 */
	const enhanceItemLabelIconAccessibility = useCallback(() => {
		setTimeout(() => {
			const elements = document.querySelectorAll(
				`div[id=${props.id}] a.ui.label i.icon, div[id=${props.id}] a.ui.active.label i.icon`
			);
			elements.forEach((el, index) => {
				/**
				 * Manages to include a handler function to an element, when focusing it, to control an emit a delegate on KeyDown.
				 * The handle
				 *
				 * @param onFocusEvent
				 */
				const onFocusIn = (onFocusEvent) => {
					// If this event has not been tracked yet
					const onKeyDown = (onKeyDownEvent) => {
						const kbdEvent = onKeyDownEvent as KeyboardEvent;

						if (kbdEvent.key === "Enter") {
							(el as HTMLElement).click();
							kbdEvent.stopPropagation();

							setTimeout(() => {
								const elements = document.querySelectorAll(`div[id=${props.id}] a.ui.label i.icon`);

								if (elements.length === 0) {
									return;
								}

								(elements.item(elements.length - 1) as HTMLElement).focus();
							}, 180);
						}
					};

					// Clean-up any previous events, then sets-up new events listeners
					el.addEventListener("keydown", onKeyDown, true);

					/**
					 * Cleans-up the added event handlers
					 */
					const onFocusOut = (onFocusOutEvent) => {
						// return
					};

					// Clean-up any previous events, then sets-up new events listeners
					el.addEventListener("focusout", onFocusOut, true);
				};

				// If not using extra enhancements, skips event tracking
				if (props?.useEnhancements) {
					// Clean-up any previous events, then sets-up new events listeners
					el.addEventListener("focusin", onFocusIn, true);
				}

				// If no tabindex was given yet
				if (!props?.skipTabIndexing && !el.hasAttribute("tabindex")) {
					el.setAttribute("tabindex", `0`);
				}

				// If no tabindex was given yet
				if (!el.hasAttribute("aria-label")) {
					const parentText = el.parentElement?.textContent?.trim();
					if (parentText) {
						el.setAttribute("aria-label", `Remove item: ${parentText}`);
					}
				}

				// If no tabindex was given yet
				if (!el.hasAttribute("role")) {
					el.setAttribute("role", `button`);
				}

				// Clean-up undesired attributes/data
				if (el.hasAttribute("aria-hidden")) {
					el.removeAttribute("aria-hidden");
				}
			}, 180);
		});
	}, [props.id, props.selectedValue, props?.useEnhancements, props?.skipTabIndexing]);

	/**
	 * Enhancement for the Items Label accessibility review.
	 */
	const enhanceItemLabelAccessibility = useCallback(() => {
		const elements = document.querySelectorAll(
			`div[id=${props.id}] a.ui.label, div[id=${props.id}] a.ui.active.label`
		);
		elements.forEach((el, index) => {
			if (!el.hasAttribute("role")) {
				el.setAttribute("role", "presentation");
			}
		});
	}, [props.id, props.selectedValue]);

	/**
	 * Runs a clean-up of the focus functions given to the list-item icons.
	 */
	const cleanupItemLabelFocusTrap = () => {
		abortController.current.abort();

		return;
	};

	const generateHandlerEscapeKeyDown = useCallback((_element: HTMLElement) => {
		return (event) => handleEscapeKeyDown(event as KeyboardEvent, () => _element.blur());
	}, []);

	/**
	 * Enhances the Main (parent) component,
	 * by adding aria-controls and other missing accessibility aspects.
	 */
	const enhanceMasterComponents = () => {
		// 1. Accessibility: Prepares the group parent element, master one
		const masterElement = document.querySelector(`div[id=${props.id}]`);

		// Sets the missing aria-controls attribute,
		// if missing it.
		if (!masterElement.hasAttribute("aria-controls")) {
			masterElement.setAttribute("aria-controls", props.id);
		}

		// If not using extra enhancements, skips event tracking
		if (!props?.useEnhancements) {
			return;
		}

		// 2. Enhancements: Prepares the search input for manually supporting tab-back commands
		const inputEl = document.querySelector(`div[id=${props.id}] input`);

		const onKeyDown = (event: Event) => {
			const htmlInput = inputEl as HTMLElement;
			const gnFn = generateHandlerEscapeKeyDown(htmlInput);

			gnFn(event);
		};

		const onFocusOut = (onFocusOutEvent) => {
			// Return
		};

		const onFocusIn = (onFocusEvent) => {
			inputEl.addEventListener("keydown", onKeyDown, true);
			inputEl.addEventListener("focusout", onFocusOut, true);
		};

		inputEl.addEventListener("focusin", onFocusIn, true);
	};

	/**
	 * When initially loading,
	 * prepares the Dropdown for accessibility.
	 */
	const applyEnhancements = useCallback(() => {
		// Pre-aborts all previous signals passed
		abortController.current.abort();
		abortController.current = new AbortController();

		// General element enhancements
		enhanceMasterComponents();

		if (
			undefined !== props.selectedValue &&
			props.selectedValue !== null &&
			Array.isArray(props.selectedValue) &&
			props.selectedValue.length > 0 &&
			JSON.stringify(prevSelectedValue.current) !== JSON.stringify(props.selectedValue)
		) {
			// Selected items' enhancements
			enhanceItemLabelAccessibility();
			enhanceItemLabelIconAccessibility();
			prevSelectedValue.current = props.selectedValue;
		}

		/**
		 * Monitors the Loading state of the component, in order to trigger the review of
		 * the tab indexes and other search result items found.
		 * Search result list enhancements.
		 */
		if (!props.loading && props.options.length > 0) {
			enhanceSearchItems();
		}
	}, [
		abortController.current,
		props.loading,
		props.selectedValue,
		props.options,
		prevSelectedValue.current,
		enhanceItemLabelAccessibility,
		enhanceItemLabelIconAccessibility,
		enhanceSearchItems
	]);

	/**
	 * When initially loading,
	 * prepares the Dropdown for accessibility.
	 */
	useEffect(() => {
		applyEnhancements();
	}, [applyEnhancements]);

	useEffect(() => {
		return () => {
			cleanupItemLabelFocusTrap();
		};
	}, []);

	// Starts narrator

	const [narrator, setNarrator] = useState("");
	const delayTimeout = useRef(null);

	const handleFocus = (event) => {
		try {
			const parent = event.target;
			const siblingListbox = parent.parentElement.querySelector('[role="listbox"]');

			parent.setAttribute("aria-live", "off");
			parent.setAttribute("aria-relevant", "additions");

			parent ? parent.setAttribute("role", "listitem") : "";
			siblingListbox ? siblingListbox.setAttribute("role", "listitem") : "";

			const items = Array.from(siblingListbox.querySelectorAll(".item")) as HTMLElement[];
			let selectedIndex = items.findIndex((item) => item.classList.contains("selected"));

			if (parent && siblingListbox) {
				parent.addEventListener("keydown", (event) => {
					if (event.key === "ArrowDown") {
						selectedIndex = (selectedIndex + 1) % items.length;
					} else if (event.key === "ArrowUp") {
						selectedIndex = (selectedIndex - 1 + items.length) % items.length;
					}

					if (delayTimeout.current) {
						clearTimeout(delayTimeout.current);
					}

					delayTimeout.current = setTimeout(() => {
						try {
							setNarrator(`${items[selectedIndex].innerText}`);
						} catch {}
					}, 500);
				});

				items.forEach((item) => {
					item.addEventListener("mouseenter", (e) => {
						if (delayTimeout.current) {
							clearTimeout(delayTimeout.current);
						}
						delayTimeout.current = setTimeout(() => {
							try {
								setNarrator(`${item.innerText}`);
							} catch {}
						}, 500);
					});
				});
			}
		} catch {}
	};
	// End narrator

	return (
		<>
			<div
				aria-live="polite"
				style={{
					position: "absolute",
					left: "-9999px",
					width: "1px",
					height: "1px",
					overflow: "hidden"
				}}
			>
				{narrator}
			</div>

			<div>
				<SemanticDropdown
					onFocus={handleFocus}
					key={props?.key ?? undefined}
					fluid={props?.fluid ?? undefined}
					search={props?.search ?? undefined}
					selection={props?.selection ?? undefined}
					id={props.id}
					alt={props?.alt ?? undefined}
					value={
						!props.selectedValue
							? undefined
							: props.multiSelect
							? Array.isArray(props.selectedValue)
								? props.selectedValue
								: [props.selectedValue.toString()]
							: props.selectedValue
					}
					className={`${props?.className ?? ""}`}
					aria-label={props.ariaLabel}
					options={props.options}
					onChange={handleChange}
					disabled={props.disabled}
					multiple={props.multiSelect}
					placeholder={props.placeholder}
					error={props?.error ?? undefined}
					onSearchChange={handleSearchChange}
					loading={props?.loading ?? undefined}
					searchInput={{
						"aria-labelledby": props.id,
						"aria-autocomplete": "list",
						ref: dropdownRef
					}}
					width={props?.width ?? undefined}
					title={props?.title ?? undefined}
					onAddItem={handleAddItem}
					allowAdditions={props?.allowAdditions ?? undefined}
					closeOnBlur={props?.closeOnBlur ?? true}
					closeOnEscape={props?.closeOnEscape ?? undefined}
					wrapSelection={props?.wrapSelection ?? undefined}
					selectOnNavigation={false}
					openOnFocus
				/>
			</div>
		</>
	);
};
