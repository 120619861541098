//
// Source: STRIKE Community API
// Please do not modify unless the server API contract changes
//

import { NodeType } from "./strikeEnums";

export interface IStrikeNodeEntity {
	Id?: number;
	Title: string;
	Body: string;
	AuthName: string;
	AuthEmail: string;
	CreatedOn: string;
	CoverImageId?: null | number;
	CoverImagePath: string;
	Type: string;
	LikeCount?: number;
	LikeByUsers?: string;
	Base64CoverImage?: string;
	ContainerId?: null | number;
	ParentId?: null | number;
	ParentTitle?: null | string;
	TopicsName: string;
	IsPublished?: null | boolean;
	IsSpaceSticky?: null | boolean;
	IsSiteSticky?: null | boolean;
	IsPrivate?: null | boolean;
	IsDeleted?: null | boolean;
	IsFavourite?: null | boolean;
}

export interface INodeInfo {
	NodeId: number;
	Type: string;
	LastActivity_UserId: number;
	LastActivity_UserName: string;
	LastActivity_UserFullName: string;
	LastActivity_UserEmail: string;
	LastActivity_ActionType: string;
	LastActivity_ActionDate: Date;
	LastActivity_UserProfileImage: string;
	Title: string;
	Topics: string;
	TopicsId?: string;
	LikeCount: number;
	IsQuestionAnswered: boolean;
	AnswersCount: number;
	CoverImageThumbnail: string;
	AuthorName: string;
	AuthorEmail: string;
	IsFavourite?: boolean;
}

export interface IStrikeNodes {
	spaceName: string;
	nodes: INodeInfo[];
	totalRecordCount: number;
	SpaceCoverImageThumbnail?: string;
}

/**
 * Contract for Answers information for a specific Question node.
 */
export interface IStrikeNodesAnswer {
	Answer: string;
	AnswerBy: string;
	AnswerByEmail: string;
	AnswerAccepted: string;
	AnswerOn: Date;
}

/**
 * Contract for Event Attendee information to display in Check-in process.
 */
export interface IEventAttendees {
	Id: number;
	Email: string;
	TeamName: string;
	Name: string;
	Date: Date;
	CheckedIn: boolean;
	Confirmed: boolean;
}

/**
 * Contract for Event Registered Users Check-in process.
 */
export interface IEventRegisteredUsers {
	Id: number;
	Email: string;
	Name: string;
	RegistrationDate: string;
	CheckedIn: boolean;
	Confirmed: boolean;
	TeamName?: string;
	CheckedInCount: number;
	ConfirmedRegistrationCount: number;
	WaitingRegistrationCount: number;
	RSVPType: string;
	InPersonCount?: number;
	VirtualCount?: number;
}

/**
 * Contract for User to Check-In at specific event
 */
export interface IEventUserToCheckin {
	id: number;
	email: string;
}

/**
 * Contract for Node Comments for a specific node Id.
 */
export interface IStrikeNodesComments {
	NodeId: number;
	CommentId: number;
	ParentId: number;
	Title: string;
	CommentOn: string;
	CommentBy: string;
	CommentByName: string;
	CommentByEmail: string;
	LikeCount?: number;
	LikeByUsers?: string;
	Type?: string;
	AnswerAccepted?: boolean;
}

/**
 * Contract for user authoritables information.
 */
export interface IUserAuthoriablesModel {
	id: string;
	name: string;
	email: string;
}

/**
 * Contract for user activity information.
 */
export interface IUserActivityModel {
	actionType: string;
	ipAddress: string;
	nodeID: string;
	emailID: string;
}

/**
 * Contract for accept answer event.
 */
export interface IUserAcceptAnswer {
	actionType: string;
	ipAddress: string;
	nodeID: string;
	emailID: string;
	rootNodeID: string;
}

/**
 * Contract for Event form registration.
 */
export interface IEventFormInfo {
	userName: "string";
	userEmail: "string";
	rsvp: "string";
	typeOfRSVP: "string";
	isAzureEdge: "string";
	leaderName: "string";
}

export const INodeInfo_Default: INodeInfo = {
	NodeId: 1,
	Type: "",
	LastActivity_UserId: 101,
	LastActivity_UserName: "",
	LastActivity_UserFullName: "",
	LastActivity_UserEmail: "",
	LastActivity_ActionType: "",
	LastActivity_ActionDate: new Date("2024-02-13T10:30:00Z"),
	LastActivity_UserProfileImage: "",
	Title: "",
	Topics: "",
	LikeCount: 0,
	IsQuestionAnswered: false,
	AnswersCount: 0,
	CoverImageThumbnail: "",
	AuthorName: "",
	AuthorEmail: ""
};

export const IStrikeNodes_Default: IStrikeNodes = {
	spaceName: "",
	nodes: [],
	totalRecordCount: 1
};

const dummyNodes: INodeInfo[] = [];

const dummyStrikeNodes: IStrikeNodes = {
	spaceName: "",
	nodes: dummyNodes,
	totalRecordCount: dummyNodes.length
};

export interface IStrikeAuthoringApiResponseError {
	ResponseStatus: number;
	ErrorMessage: string;
	ValidationErrors: string[];
}

export interface IStrikeAuthoringApiResponse<T> {
	Data: T;
	ResponseStatus: number;
	ErrorMessage: string;
	ValidationErrors: string[];
}

// This mimics the Axios response type to decouple the API from taking a hard dependency on Axios library. See AxiosResponse type for details
export interface IAxiosErrorResponseData {
	data: IStrikeAuthoringApiResponseError;
	status: number;
	statusText: string;
	headers: any;
	request?: any;
}

// This mimics the Axios response type to decouple the API from taking a hard dependency on Axios library. See AxiosError type for details
export interface IAxiosErrorResponse {
	code?: string;
	request?: any;
	response: IAxiosErrorResponseData;
}

/**
 * Contract Defined to get the Topics from DB
 */
export interface IStrikeTopicNode {
	TopicNodes: IStrikeTopics[];
	TotalRecordCount: number;
}

/**
 * Contract of every Topic
 */
export interface IStrikeTopics {
	TopicID: number;
	Title: string;
	NodeCount: number;
}

/*
 * Contract for Comments Form Data.
 */
export interface ICommentFormData {
	id: string;
	parentId: null | string;
	type: NodeType;
	body: string;
}

/**
 * Contract for Sticky Nodes
 */
export interface IStrikeNodeSticky {
	NodeId: number;
	Title: string;
	Type: string;
	URLTitle: string;
}

/**
 * Contract for Breadcrum
 */
export interface IStrikeNodeBreadcrumb {
	NodeId: number;
	Title: string;
	Key: number;
	URLTitle: string;
	Type: string;
	ParentId: number;
	IsCurrentItem: boolean;
}
