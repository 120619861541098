import { ServiceHub } from "../../service";
import { useNodeActionsSlice } from "../node/useNodeActions";

/**
 * Return type contract for the Share Content hook.
 */
export interface IShareContentHook {
	isOpen: boolean;
	isSubmitting: boolean;
	onOpen: () => void;
	onDismiss: () => void;
	onComplete: () => void;
	onSubmitting: () => void;
	onError: () => void;
}

/**
 * A Share Content Feature hook which
 * enables the start, dismiss, and complete processes of Strike App Share.
 *
 * @returns
 */
export const useShareContent = (): IShareContentHook => {
	const NodeActions = useNodeActionsSlice();

	/**
	 * Starts the share process, by opening the Right panel of it
	 */
	function onShareContentStart() {
		ServiceHub.nodeAPI.setShareContentOpen(true);
	}

	/**
	 * Used for when dismiss function is necessary.
	 */
	function onCloseShareContent() {
		ServiceHub.nodeAPI.setShareContentOpen(false);
	}

	/**
	 * Handles the Submitting State of the Share Content box.
	 */
	function onShareContentSubmitting() {
		ServiceHub.nodeAPI.setShareContentSubmitting(true);
	}

	/**
	 * Handles the completion of the Share Content methods.
	 */
	function onShareContentComplete() {
		ServiceHub.nodeAPI.setShareContentOpen(false);
		ServiceHub.nodeAPI.setShareContentSubmitting(false);
	}

	/**
	 * When Sharing Content fails, some actions are required.
	 * Invoke this function to handle it.
	 */
	function onShareContentError() {
		ServiceHub.nodeAPI.setShareContentSubmitting(false);
	}

	return {
		isOpen: NodeActions.isOpenShareContent,
		isSubmitting: NodeActions.isSubmittingShareContent,
		onOpen: onShareContentStart,
		onDismiss: onCloseShareContent,
		onSubmitting: onShareContentSubmitting,
		onComplete: onShareContentComplete,
		onError: onShareContentError
	};
};
