import { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ServiceHub } from "../../service";

/**
 * Provides a React-integrated approach to react-router-dom's Navigate Function.
 * Sets the navigate function from the Hook into the
 * AppManagementAPI service layer, to be consumed via .navigate(to, options?).
 *
 * @returns NavigateFunction
 */
export const useNavigateHelper: () => NavigateFunction = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if (ServiceHub.appManagementAPI.getNavigate() === null) {
			// Setting the function from the Hook into the AppManagementAPI service layer
			ServiceHub.appManagementAPI.setNavigate(navigate);
		}
	}, [navigate]);

	return ServiceHub.appManagementAPI.getNavigate();
};
