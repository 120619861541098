import React, { useRef } from "react";

import { useSelector } from "react-redux";
import { followSelectors } from "../../../redux/selectors/follow";
import { UserListBoxContributors } from "../user/listBox/userList.contributors";

/**
 * Controls the properties of the FollowerSection component.
 */
export interface ITopContributorsSectionProps {
	title: string;
}

/**
 * Reusable Node/Section Top Contributors users data.
 *
 * @param props The configuration props.
 * @returns React.FC<ITopContributorsSectionProps>
 */
export const TopContributorsSection: React.FC<ITopContributorsSectionProps> = (props) => {
    const topContributors = useSelector(followSelectors.getTopContributors);
	const memoTopContributors = topContributors?.map((contributor) => contributor.UserUPN);

	return (
		<>
			{
				<UserListBoxContributors
					title={props.title}
					usersUpn={memoTopContributors}
				/>
			}
		</>
	);
};