import { ISliceNames } from "../contracts/slices";

/**
 * Centralized dictionary of Slices used within the App.
 */
export const AppSlices: ISliceNames = {
	appManagement: "appManagement",
	appTreeView: "appTreeView",
	articleForm: "articleForm",
	nodes: "nodes",
	auth: "auth",
	comments: "comments",
	spaces: "spaces",
	filter: "filter",
	follow: "follow",
	tags: "tags",
	spaceForm: "spaceForm",
	eventForm: "eventForm",
	eventNotificationTemplates: "eventNotificationTemplates"
};
