import store from "../../redux/store";
import { MessageType } from "../../enums";
import { setMessage, setMessageIsHtml, setMessageOpen, setMessageText } from "../../redux/reducers/appManagement";

/**
 * Application Global Messaging service.
 * Can show/hide messages based on 4-types:
 * * Success
 * * Error
 * * Warning
 * * Info
 */
export class AppMessageService {
	success(text: string): void {
		store.dispatch(setMessage({ text, type: MessageType.success, open: true }));
	}

	error(text: string): void {
		store.dispatch(setMessage({ text, type: MessageType.error, open: true }));
	}

	warning(text: string): void {
		store.dispatch(setMessage({ text, type: MessageType.warning, open: true }));
	}

	info(text: string): void {
		store.dispatch(setMessage({ text, type: MessageType.info, open: true }));
	}

	close(): void {
		store.dispatch(setMessageOpen(false));
	}

	hide(): void {
		this.close();
	}

	setMessageText(text: string) {
		store.dispatch(setMessageText(text));
	}

	setMessageIsHtml(isHtml: boolean) {
		store.dispatch(setMessageIsHtml(isHtml));
	}
}

/**
 * App Global Message service singleton instance.
 */
export const MessageServiceInstance: AppMessageService = new AppMessageService();
