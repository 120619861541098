import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "@coherence-design-system/modal";
import { Icon } from "semantic-ui-react";
import { CoverImageType } from "../../../../enums";
import { CoverImageFieldIds, CoverImageLabels } from "./coverImage.gallery.strings";
import { CoverImageGallery } from "./coverImage.gallery";
import { useImageGallery } from "../../../../hooks";

/**
 * Controls the input of the Cover Images parent Modal.
 */
export interface ICoverImageModal {
	open?: undefined | boolean;
	onClose?: undefined | (() => void);
	title?: undefined | string;
	onDefault?: undefined | (() => void);
	onUpload?: undefined | ((event: React.FormEvent<HTMLInputElement>) => void);
	onSelectFromGallery?: undefined | ((itemId: string | number, item: string) => void);
	selectedImage?: undefined | string;
	accepts?: undefined | string[];
}

/**
 * Renders the Cover Image parent Modal component.
 * Used within the Article/Question form for Cover Image selection:
 * - Using default image.
 * - Selecting from a pre-existing image gallery.
 * - Uploading a new image.
 *
 * By default, accepts "image/png", "image/jpeg", and "image/gif".
 *
 * @param props ICoverImageModal
 * @returns React.FC<ICoverImageModal>
 */
export const CoverImageModal: React.FC<ICoverImageModal> = (props) => {
	const defaultAccepts = ["image/png", "image/jpeg", "image/gif", "image/svg+xml"];
	const imageGalleryControls = useImageGallery();
	const [selectedMode, setSelectedMode] = useState("");
	const resolvedAccepts = useMemo(() => {
		return defaultAccepts.concat(props?.accepts ?? []).join(",");
	}, []);
	const resolvedTitle = useMemo(() => {
		return props?.title
			? props.title
			: selectedMode === CoverImageType.Gallery.toString()
			? CoverImageLabels.actions.galleryTitle
			: CoverImageLabels.actions.coverSelectorTitle;
	}, [props.title, selectedMode]);

	/**
	 * Interaction from the buttons in the cover image mode selector.
	 *
	 * @param event React.MouseEvent<HTMLDivElement>
	 */
	const onClickModeSelectorItem = (event: React.MouseEvent<HTMLDivElement>) => {
		const nextMode = event.currentTarget.id;

		setSelectedMode(nextMode);

		switch (nextMode) {
			case CoverImageType.Gallery.toString(): {
				// Handled by state management
				break;
			}
			case CoverImageType.Upload.toString(): {
				document.getElementById(CoverImageFieldIds.uploadCoverImageInput).click();
				break;
			}
			default:
				props?.onDefault();
				props?.onClose();
		}
	};

	useEffect(() => {
		if (!props.open) {
			setSelectedMode("");
		}
	}, [props.open]);

	useEffect(() => {
		// Pre-loads the gallery list with default selections,
		// To deliver a better visual effect with images already present
		imageGalleryControls.queryGallery(
			imageGalleryControls.coverImagesListControl.currentPage,
			imageGalleryControls.coverImagesListControl.pageLength
		);
	}, []);

	const strings = {
		coverImageOptions: {
			default: "Use default Cover Image",
			gallery: "Pick it from the Gallery",
			upload: "Upload from this Device"
		}
	};

	return props.open ? (
		<Modal
			height={"fixed"}
			className="modal-small"
			isOpen={props.open ?? false}
			onDismiss={props.onClose}
			title={resolvedTitle}
		>
			{selectedMode === CoverImageType.Gallery.toString() ? (
				<CoverImageGallery onSelect={props.onSelectFromGallery} />
			) : (
				<div className="gallery-container" tabIndex={0} aria-label="Form: Select a Cover Image">
					<div className="cover-mode-selector">
						<div
							className="cover-mode-item"
							onClick={onClickModeSelectorItem}
							id={`${CoverImageType.Default}`}
							tabIndex={0}
							aria-label={`Option 1. ${strings.coverImageOptions.default}`}
							role="button"
						>
							<Icon name="file image outline" />
							<div>{strings.coverImageOptions.default}</div>
						</div>
						<div
							className="cover-mode-item"
							onClick={onClickModeSelectorItem}
							id={`${CoverImageType.Gallery}`}
							tabIndex={0}
							aria-label={`Option 2. ${strings.coverImageOptions.gallery}`}
							role="button"
						>
							<Icon name="table" />
							<div>{strings.coverImageOptions.gallery}</div>
						</div>
						<div
							className="cover-mode-item"
							onClick={onClickModeSelectorItem}
							id={`${CoverImageType.Upload}`}
							tabIndex={0}
							aria-label={`Option 3. ${strings.coverImageOptions.upload}`}
							role="button"
						>
							<Icon name="upload" />
							<div>{strings.coverImageOptions.upload}</div>
						</div>
					</div>
					<input
						className="visibility-hidden"
						id={CoverImageFieldIds.uploadCoverImageInput}
						type="file"
						onChange={props.onUpload}
						accept={resolvedAccepts}
					/>
				</div>
			)}
		</Modal>
	) : null;
};
