import { ICoverImageInfo, INodeQueryResponse, ISpaceItem } from "../../contracts";
import { IArticleFormData } from "../../contracts/article/articleForm";
import { NodeActionTypes, NodeType } from "../../contracts/models/strikeEnums";
import { base64Encode } from "../../lib/strikeLibrary";
import { NodePreprocessingAPI } from "../base/NodePreprocessingAPI";
import { ServiceHub } from "../base/ServiceHub";

/**
 * Article Data management API. Used by the Article Form.
 */
export class ArticleDataAPI extends NodePreprocessingAPI {
	constructor() {
		super("node");
	}

	/**
	 * Searches articles based on text criteria.
	 *
	 * @param spaceId Scoped Space (Container) of the items to search within
	 * @param criteria String to search based on
	 * @returns Promise<INodeQueryResponse[]>
	 */
	async searchArticles(spaceId: number | string, criteria: string): Promise<INodeQueryResponse[]> {
		const encodedSearchText = base64Encode(criteria);

		return await this.get(`searchNodes/${spaceId}/${encodedSearchText}`);
	}

	/**
	 * Gets all spaces listed to the auth user account.
	 *
	 * @returns Collection of Space entries.
	 */
	async getSpaces(): Promise<ISpaceItem[]> {
		return await this.get("getSpaceEntities");
	}

	/**
	 * Gets a cover image list from the services.
	 *
	 * @param page The current page being looked for
	 * @param pageSize The size of the page retrieving
	 * @returns Information regarding the Cover images, with total count
	 */
	async getCoverImages(
		page: number,
		pageSize: number = 15
	): Promise<{ CoverImages: ICoverImageInfo[]; TotalRecordCount: number }> {
		return await this.post("getCoverImages", { pageIndex: page, pageLimit: pageSize });
	}

	/**
	 * Gets a list of Miscellaneous configurations from Services.
	 *
	 * @returns List of configs.
	 */
	async getMiscellaneous(): Promise<[]> {
		return await this.get("getMiscellaneousEntities");
	}

	/**
	 * Creates a Node with provided information, and specified type.
	 * Also, prepares the body to be transferred by using base-64 string encoding.
	 * As a precaution, also uploads any base-64 images detected in the body
	 * to be provided via the storage services.
	 *
	 * @param data The Node data to request creation.
	 * @param type The Node type to request update.
	 * @param actionType The Node Action Type to log.
	 */
	async createNode(data: IArticleFormData, type: NodeType, actionType: NodeActionTypes): Promise<IArticleFormData> {
		// const appFileAPI = ServiceHub.appFileAPI.start();
		const dataCopy = { ...data };

		dataCopy.type = type;
		dataCopy.actionType = actionType;
		dataCopy.authorUpn = await ServiceHub.userProfileAPI.start().GetCurrentUserUpn();

		//Detects any body HTML base-64 image to upload it to services
		//As well as prepares it for transfer as a base-64 encoded string.
		dataCopy.body = base64Encode(await this.detectBodyImages(dataCopy.body));
		await this.processCoverImage(dataCopy);

		return await this.post("createcommunication", dataCopy)
			.then((result: any) => {
				if (result?.HasError) {
					throw Error(result.ErrorMessage);
				}

				// TODO: Show a toast success message
				return result.Value;
			})
			.catch((error) => {
				const errorObject = error?.response?.data?.errors ?? null;
				const errorProps = errorObject !== null ? Object.keys(errorObject) : [];
				const errorMessages = errorObject !== null ? Object.values(errorObject) : [];

				const bindErrorInfo = () =>
					errorProps.map((prop, index) => {
						return `${prop}: ${errorMessages[index]}`;
					});

				const errorMessage = errorObject !== null ? bindErrorInfo() : error.message;

				ServiceHub.message.error(errorMessage);

				throw Error(errorMessage);
			});
	}

	/**
	 * Creates a Node of type Question in the services.
	 * Uses createNode behind to process data.
	 *
	 * @param data The Question data to store
	 */
	async createQuestion(data: IArticleFormData): Promise<IArticleFormData> {
		return await this.createNode(
			data,
			NodeType.Question,
			data.id !== null && data.id !== "" ? NodeActionTypes.Revise : NodeActionTypes.Ask
		);
	}

	// /**
	//  * Updates a Question based on provided info.
	//  * Uses updateNode behind to process data.
	//  *
	//  * @param data The Question data to store.
	//  */
	// async updateQuestion(data: IArticleFormData): Promise<IArticleFormData> {
	// 	return await this.updateNode(data, NodeType.Question, NodeActionTypes.Revise);
	// }

	/**
	 * Creates a Node of type Comment in the services.
	 * Uses createNode behind to process data.
	 *
	 * @param data The Question data to store
	 */
	async createComment(data: IArticleFormData): Promise<IArticleFormData> {
		return await this.createNode(data, NodeType.Comment, NodeActionTypes.Comment);
	}

	// /**
	//  * Updates a Comment based on provided info.
	//  * Uses updateNode behind to process data.
	//  *
	//  * @param data The Question data to store.
	//  */
	// async updateComment(data: IArticleFormData): Promise<IArticleFormData> {
	// 	return await this.updateNode(data, NodeType.Comment, NodeActionTypes.Comment);
	// }

	/**
	 * Creates a Node of type Answer To Comment in the services.
	 * Uses createNode behind to process data.
	 *
	 * @param data The Question data to store
	 */
	async createAnswerToComment(data: IArticleFormData): Promise<IArticleFormData> {
		return await this.createNode(data, NodeType.Comment, NodeActionTypes.AnswerToComment);
	}

	// /**
	//  * Updates an Answer to Comment based on provided info.
	//  * Uses updateNode behind to process data.
	//  *
	//  * @param data The Question data to store.
	//  */
	// async updateAnswerToComment(data: IArticleFormData): Promise<IArticleFormData> {
	// 	return await this.updateNode(data, NodeType.Comment, NodeActionTypes.AnswerToComment);
	// }

	/**
	 * Creates a Node of type Answer in the services.
	 * Uses createNode behind to process data.
	 *
	 * @param data The Question data to store
	 */
	async createAnswer(data: IArticleFormData): Promise<IArticleFormData> {
		return await this.createNode(data, NodeType.Answer, NodeActionTypes.Answer);
	}

	// /**
	//  * Updates an Answer based on provided info.
	//  * Uses updateNode behind to process data.
	//  *
	//  * @param data The Question data to store.
	//  */
	// async updateAnswer(data: IArticleFormData): Promise<IArticleFormData> {
	// 	return await this.updateNode(data, NodeType.Answer, NodeActionTypes.Answer);
	// }

	/**
	 * Creates a Node of type Answer To Comment in the services.
	 * Uses createNode behind to process data.
	 *
	 * @param data The Question data to store
	 */
	async createCommentToAnswer(data: IArticleFormData): Promise<IArticleFormData> {
		return await this.createNode(data, NodeType.Answer, NodeActionTypes.CommentToAnswer);
	}

	// /**
	//  * Updates an Answer to Comment based on provided info.
	//  * Uses updateNode behind to process data.
	//  *
	//  * @param data The Question data to store.
	//  */
	// async updateCommentToAnswer(data: IArticleFormData): Promise<IArticleFormData> {
	// 	return await this.updateNode(data, NodeType.Answer, NodeActionTypes.CommentToAnswer);
	// }
}
