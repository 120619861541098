import React, { useRef } from "react";
import { FormEventFields } from "./event.form.fields";
import { useEventForm } from "../../../hooks/event/useEventForm";
import { ServiceHub } from "../../../service";
import { ApplicationRoutePaths } from "../../router";

/**
 * Re-usable Event creation form
 *
 * @returns React.FC
 */
export const EventForm: React.FC = () => {
	const eventManageAPI = useRef(ServiceHub.eventManageAPI.start());
	const eventFormService = useRef(ServiceHub.eventForm.start());
	const { isNewEvent, eventFormData } = useEventForm();

	/**
	 * Handling Event creation sending eventformData to API
	 */
	const createEvent = () => {
		eventManageAPI.current.createEvent(eventFormData).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}

			if (!result) {
				// Redirect the user to the event dashboard
				ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
				return;
			}

			ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
			eventFormService.current.resetData();
		});
	};

	/**
	 * Handling Edit event passing new data to API
	 */
	const editEvent = () => {
		eventManageAPI.current.editEvent(eventFormData.id, eventFormData).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
			if (!result) {
				// Redirect the user to the dashboard
				ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.dashboard());
				return;
			}
			ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
			eventFormService.current.resetData();
		});
	};

	/**
	 * Swicth to select Create or Edit
	 * @param event
	 * @returns
	 */
	function onSubmit(event: React.FormEvent<HTMLFormElement>) {
		if (event.preventDefault) event.preventDefault();

		if (isNewEvent) {
			createEvent();
		} else {
			editEvent();
		}

		return;
	}

	return !eventFormData ? null : <FormEventFields onSubmit={onSubmit} />;
};
