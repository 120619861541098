import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceHub } from "../../service";
import { authSelectors } from "../../redux/selectors/auth";
import {
	setAccessRequested,
	setAccessRequesting,
	setCanAccessCreateArticleComponents
} from "../../redux/reducers/auth";
import store from "../../redux/store";
import { NodeType } from "../../contracts/models/strikeEnums";

/**
 * Defines the return types for Access Control hook.
 */
export interface IAccessControlInfo {
	canAccess: boolean;
	userId: string;
	requesting: boolean;
	requested: boolean;
	canUserAccessNodeType: (nodeType: NodeType | string) => boolean;
}

/**
 * Checks whether the user can access Node Admin functions.
 * In this case, restriction is controlled by backend.
 * Components should use this hook for deciding on whether to render, or not.
 *
 * @returns Access Control object result.
 */
export const useNodeAdminAccessControl = (): IAccessControlInfo => {
	const dispatch = useDispatch();
	const service = useRef(ServiceHub.nodeAdminAPI.start());
	const userId = useSelector(authSelectors.getUserId);
	const canAccess = useSelector(authSelectors.getCanAccessCreateArticleComponents);
	const requestingAccess = useSelector(authSelectors.getAccessRequesting);
	const requestedAccess = useSelector(authSelectors.getAccessRequested);

	/**
	 * Tests whether the access is restricted to a given NodeType item.
	 */
	const isRestrictedNodeType = useCallback((nodeType: NodeType | string) => {
		const restrictedTypes = [NodeType.Kbentry, null];
		const foundType =
			nodeType === null
				? null
				: typeof nodeType === "string"
				? Object.values(NodeType).find((fType) => fType === nodeType)
				: NodeType[nodeType];

		return restrictedTypes.includes(foundType);
	}, []);

	/**
	 * Consumes from the access control whether the user can
	 * access the Node Admin routes and data.
	 * Also, consider that the NodeType which subjects to the
	 * rule should be NodeType.Kbentry only.
	 */
	const canUserAccessNodeType = useCallback(
		(nodeType: NodeType | string) => {
			const isRestricted = isRestrictedNodeType(nodeType);

			return !isRestricted || (isRestricted && canAccess);
		},
		[isRestrictedNodeType, canAccess]
	);

	useEffect(() => {
		/**
		 * Requests the service for Access to Node Admin functions,
		 * which will consume data from api.
		 */
		async function checkAccess() {
			if (!requestingAccess && !requestedAccess) {
				dispatch(setAccessRequesting(true));

				const result = await service.current.reviewAccess();

				store.dispatch(setCanAccessCreateArticleComponents(result));
				dispatch(setAccessRequested(true));
				dispatch(setAccessRequesting(false));
			}
		}

		checkAccess();
	}, [service, requestingAccess, requestedAccess]);

	return { canAccess, userId, requesting: requestingAccess, requested: requestedAccess, canUserAccessNodeType };
};
