import React, { useCallback, useEffect, useRef, useState } from "react";
import { DivEventHandler, InputTextEventHandler, RichTextInput } from "../../text";
import { useSelector, useDispatch } from "react-redux";
import { commentsSelectors } from "../../../../redux/selectors/comments";
import {
	setAddCommentToList,
	setCommentText,
	setFormSubmitting,
	setReplyItemExpandedById,
	setToggleCommentForm,
	setBodySourceMode,
	setToggleCommentQuestionForm
} from "../../../../redux/reducers/comments";
import { NodeActionTypes } from "../../../../contracts/models/strikeEnums";
import { IArticleFormData } from "../../../../contracts/article/articleForm";
import { ServiceHub } from "../../../../service";
import { useAuthUser, useHtmlVirtualization } from "../../../../hooks";
import { IStrikeNodesComments } from "../../../../contracts/models";
import { FormActions } from "../../form";

/**
 * Properties scope of Node Activity Comment form.
 */
export interface INodeActivityCommentFormProps {
	headlineText: string;
	buttonText: string;
	id?: undefined | string | number;
	parentId?: number;
	mainComment?: boolean;
	actionType?: NodeActionTypes;
	tabIndex?: undefined | number;
	onCancel?: undefined | (() => Promise<void>);
	isNew?: undefined | boolean;
	shadow?: undefined | boolean;
}

/**
 * Comment Form component.
 *
 * @param props
 * @returns
 */
export const NodeActivityCommentForm: React.FC<INodeActivityCommentFormProps> = ({ shadow = false, ...props }) => {
	const dispatch = useDispatch();
	const commentSvc = useRef(ServiceHub.commentAPI.start());
	const bodySourceMode = useSelector(commentsSelectors.getFormBodySourceMode);
	const expandedId = useSelector(commentsSelectors.getReplyExpandedId);
	const commentText = useSelector(commentsSelectors.getCommentText);
	const isOpenToggleForm = useSelector(commentsSelectors.getToggledCommentForm);
	const isOpenToggleQuestionForm = useSelector(commentsSelectors.getToggledCommentQuestionForm);
	const nodeId = useSelector(commentsSelectors.getNodeId);
	const previousNodeId = useRef(nodeId);
	const parentId = props.parentId?.toString() ?? nodeId;
	const userData = useAuthUser();
	const virtualHtml = useHtmlVirtualization(commentText);
	const [preprocessedHtmlBody, setPreprocessedHtmlBody] = useState<null | string>(null);
	const getPreprocessedHtml = useCallback(
		(htmlData: string) =>
			ServiceHub.nodeAPI.prepareBodyImages(htmlData, (result) => {
				setPreprocessedHtmlBody(result);
			}),
		[]
	);

	// Tracks the edited HTML and renders it with proper images displaying
	useEffect(() => {
		if (!preprocessedHtmlBody) {
			getPreprocessedHtml(virtualHtml.virtualHtml);
		}
	}, [preprocessedHtmlBody, virtualHtml.virtualHtml]);

	useEffect(() => {
		// Checks nodeId changed, invoke the update commentText.
		if (nodeId !== previousNodeId.current) {
			previousNodeId.current = nodeId;
			virtualHtml.onClearAll();
		}
	}, [nodeId, previousNodeId.current]);

	const submitDisabled = useSelector(commentsSelectors.getPostButtonDisabled);

	const onAddedComment = useCallback(
		(addedComment: any) => {
			dispatch(
				setAddCommentToList({
					AnswerAccepted: false,
					CommentBy: userData.userPrincipalName,
					CommentByEmail: userData.userEmail,
					CommentByName: userData.userDisplayName,
					CommentId: parseInt(addedComment.Id.toString()),
					NodeId: parseInt(addedComment.ParentId.toString()),
					ParentId: parseInt(addedComment.ParentId.toString()),
					Title: addedComment.Body,
					CommentOn: new Date().toISOString().replace("Z", ""), // TODO: Review the use of UTC time
					Type: props?.actionType,
					LikeByUsers: "",
					LikeCount: 0
				} as IStrikeNodesComments)
			);

			// In case the added node is of type comment/answer
			// Scrolls to the added element
			setTimeout(() => {
				dispatch(setCommentText(""));

				// Apply the following scroll functionality to those CommentToAnswer and AnswerToComment NodeActionTypes
				if ([NodeActionTypes.CommentToAnswer, NodeActionTypes.AnswerToComment].includes(props?.actionType)) {
					dispatch(
						setReplyItemExpandedById(
							props.parentId.toString() === expandedId ? null : props.parentId.toString()
						)
					);
				}

				// Apply the following scroll functionality to those Comment and Answer NodeActionTypes
				if ([NodeActionTypes.Comment, NodeActionTypes.Answer].includes(props?.actionType)) {
					if (isOpenToggleForm) {
						dispatch(setToggleCommentForm());
					}
					if (isOpenToggleQuestionForm) {
						dispatch(setToggleCommentQuestionForm());
					}
				}
			}, 150);
		},
		[userData, props?.actionType, expandedId]
	);

	const onChangeCommentText = useCallback(
		(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();

			virtualHtml.onChange(typeof event === "string" ? event : event.currentTarget?.value);
			dispatch(setCommentText(typeof event === "string" ? event : event.currentTarget.value));
		},
		[virtualHtml.onChange]
	);

	const onChangeCommentTextDiv = useCallback((event: React.FocusEvent<HTMLElement>) => {
		dispatch(setCommentText(typeof event === "string" ? event : event.currentTarget.innerText));
	}, []);

	/**
	 * query Model to pass Form Comment Data
	 */
	function getQueryFormCommentData(rootId: string, _parentId: string, body: string): IArticleFormData {
		const response = {
			id: !props?.isNew && props?.id ? `${props.id}` : null,
			parentId: _parentId,
			commentNodeId: rootId,
			body: body
		};

		return response as IArticleFormData;
	}

	const onSubmitComment = useCallback(
		(event) => {
			event.preventDefault();

			dispatch(setFormSubmitting(true));

			let commentFormData: IArticleFormData = getQueryFormCommentData(nodeId, parentId, commentText);

			let serviceCall: Promise<IArticleFormData>;

			switch (props.actionType) {
				case NodeActionTypes.Comment: {
					serviceCall = ServiceHub.articleDataAPI.start().createComment(commentFormData);
					break;
				}
				case NodeActionTypes.Answer: {
					serviceCall = ServiceHub.articleDataAPI.start().createAnswer(commentFormData);
					break;
				}
				case NodeActionTypes.CommentToAnswer: {
					serviceCall = ServiceHub.articleDataAPI.start().createCommentToAnswer(commentFormData);
					break;
				}
				case NodeActionTypes.AnswerToComment: {
					serviceCall = ServiceHub.articleDataAPI.start().createAnswerToComment(commentFormData);
					break;
				}
				default: {
					// serviceCall = ServiceHub.articleDataAPI.start().createNode(commentFormData, props.nodeType);
					break;
				}
			}

			serviceCall
				.then((added: any) => {
					if (!added.Id) {
						throw Error("An error occurred while posting the comment");
					}

					if (props.isNew) {
						onAddedComment(added);
					} else {
						(async () => {
							await commentSvc.current.setCommentById(added.Id, {
								Title: added.Body
							} as IStrikeNodesComments);
							await commentSvc.current.resetEditingIds();
							dispatch(setCommentText(""));
							virtualHtml.onClearAll();
						})();
					}
				})
				.catch((ex) => {
					ServiceHub.message.error(ex);
				})
				.finally(() => {
					dispatch(setFormSubmitting(false));
				});

			return;
		},
		[nodeId, parentId, commentText]
	);

	const onCancel = () => {
		dispatch(setCommentText(""));
		// Apply the following scroll functionality to those CommentToAnswer and AnswerToComment NodeActionTypes
		if ([NodeActionTypes.CommentToAnswer, NodeActionTypes.AnswerToComment].includes(props?.actionType)) {
			dispatch(
				setReplyItemExpandedById(props.parentId.toString() === expandedId ? null : props.parentId.toString())
			);
		}

		// Apply the following scroll functionality to those Comment and Answer NodeActionTypes
		if ([NodeActionTypes.Comment, NodeActionTypes.Answer].includes(props?.actionType)) {
			dispatch(setToggleCommentForm()); // this is needed only for main comment/answers
		}

		if (typeof props.onCancel === "function") {
			props.onCancel();
		}
	};

	const editModeAriaLabel =
		(props?.actionType === NodeActionTypes.Answer
			? "Answer"
			: props?.actionType === NodeActionTypes.Comment
			? "Comment"
			: "Reply") + " edit mode selector";

	const formAriaLabel =
		props?.actionType === NodeActionTypes.Answer
			? "New Answer"
			: props?.actionType === NodeActionTypes.Comment
			? "New Comment"
			: "New Reply";

	return (
		<form
			onSubmit={onSubmitComment}
			className={`form-container ${shadow ? "box-shadow" : ""}`}
			tabIndex={0}
			aria-label={formAriaLabel}
			role="form"
		>
			<div className="form-field full-width">
				<div className="form-field-header">
					<label>
						<h3 tabIndex={0} aria-label={`Form Title: ${props.headlineText}`} role="heading">
							{props.headlineText}
						</h3>
					</label>
					<div className="button-group" aria-label={editModeAriaLabel} role="group">
						<button
							type="button"
							onClick={() => dispatch(setBodySourceMode(false))}
							className={`${!bodySourceMode ? "selected" : ""}`}
							aria-label={`Action: Set render Preview Mode.${!bodySourceMode ? " Selected." : ""}`}
						>
							Preview
						</button>
						<button
							type="button"
							onClick={() => dispatch(setBodySourceMode(true))}
							className={`${bodySourceMode ? "selected" : ""}`}
							aria-label={`Action: Set render HTML Source Mode.${bodySourceMode ? " Selected." : ""}`}
						>
							Source
						</button>
					</div>
				</div>
				<div className="form-control">
					<RichTextInput
						id={`commentText-${bodySourceMode ? "source" : "virtual"}`}
						className="node-activity-comment-input"
						sourceMode={bodySourceMode}
						value={
							!bodySourceMode && virtualHtml.virtualHtml
								? preprocessedHtmlBody === null
									? virtualHtml.virtualHtml
									: preprocessedHtmlBody
								: virtualHtml.originalHtml
						}
						onChange={
							bodySourceMode
								? (onChangeCommentTextDiv as DivEventHandler)
								: (onChangeCommentText as InputTextEventHandler)
						}
						onBlur={(event) => {
							virtualHtml.onSyncOriginal(event.currentTarget?.innerHTML, (finalHtml: string) => {
								dispatch(setCommentText(finalHtml));
							});
						}}
					/>
				</div>
			</div>
			<FormActions
				className="node-activity-comment-button"
				actions={[
					{
						type: "submit",
						text: props.buttonText,
						disabled: submitDisabled
					},
					{
						type: "button",
						variation: "secondary",
						className: "cancel",
						text: "Cancel",
						onClick: onCancel
					}
				]}
			/>
		</form>
	);
};
