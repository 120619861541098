import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterSelectors } from "../../redux/selectors/filter";
import { useParams, useSearchParams } from "react-router-dom";
import { ServiceHub } from "../../service";
import { IStrikeNodes, QueryNodeModel } from "../../contracts/models";
import { NodeType, UserDashboardsFiltersType } from "../../contracts/models/strikeEnums";
import { setIsDashboardLoading, setNodeList, setNodesLoading } from "../../redux/reducers/filter";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import { spacesSelectors } from "../../redux/selectors/spaces";
import { spacesActions } from "../../redux/reducers/spaces";

/**
 * Contract for the Filter Slice hook.
 */
interface IFilterSliceHook {
	searchTopics: string;
	searchTitle: string;
	isFilterExpanded: boolean;
	isLoading: boolean;
	isLoaded: boolean;
	pageIndex: number;
	onSearch: (pageIndex: number, title: string, topics: string, type?: NodeType, filterBy?: UserDashboardsFiltersType) => void;
	getNodesList: (queryNodeModel: QueryNodeModel) => void;
	searchTopic: string;
	nodeList: IStrikeNodes;
	isNodesLoading: boolean;
	dashboardButtonTitle: string;
	dashboardNavigation: NodeType;
	sortByOption: string;
	userCustomDashboard: UserDashboardsFiltersType;
	topicsResetKey: number;
	isDashboardLoading: boolean;
	spaceCoverImage: string;
	spaceCoverImageLoaded: boolean;
	spaceName: string;
	spaceDescription: string;
	spaceBanner: string;
	isSpaceDetailsLoaded: boolean;
	getSpaceIsolatedData: (spaceId: string) => void;
	setSpaceCoverImageLoaded: (value: boolean) => void;
	setSpaceDetailsLoaded: (value: boolean) => void;
}

/**
 * Hook to use Filtering globally
 *
 * @returns ISpaceSliceHook
 */
export const useFilterSlice = (): IFilterSliceHook => {
	const params = useParams();
	const [searchParams] = useSearchParams();
	const nodeId = useMemo(() => (params?.id ? Number(params.id) : null), [params.id]);
	const searchTopic = useMemo(() => searchParams.get("topics"), [searchParams]);
	const searchTopics: string = useSelector(filterSelectors.getSearchTopics);
	const searchTitle: string = useSelector(filterSelectors.getSearchTitle);
	const isFilterExpanded: boolean = useSelector(filterSelectors.getToggleExpanderFilter);
	const pageIndex: number = useSelector(filterSelectors.getPageIndex);
	const isLoading: boolean = useSelector(filterSelectors.getFilterLoading);
	const isLoaded: boolean = useSelector(filterSelectors.getFilterLoaded);
	const nodeList: IStrikeNodes = useSelector(filterSelectors.getNodeList);
	const isNodesLoading: boolean = useSelector(filterSelectors.getNodesLoading);
	const dashboardButtonTitle: string = useSelector(filterSelectors.getDashboardButtonTitle);
	const dashboardNavigation: NodeType = useSelector(filterSelectors.getDashboardNavigation);
	const userCustomDashboard: UserDashboardsFiltersType = useSelector(filterSelectors.getUserCustomDashboard);
	const topicsResetKey: number = useSelector(filterSelectors.getTopicsResetKey);
	const isDashboardLoading: boolean = useSelector(filterSelectors.getIsDashboardLoading);
	const sortByOption: string = useSelector(filterSelectors.getSortByOption);
	const spaceCoverImage: string = useSelector(spacesSelectors.getSpaceCoverImage);
	const spaceCoverImageLoaded: boolean = useSelector(spacesSelectors.getSpaceCoverImageLoaded);
	const spaceName: string = useSelector(spacesSelectors.getSpaceName);
	const spaceDescription: string = useSelector(spacesSelectors.getSpaceDescription);
	const spaceBanner: string = useSelector(spacesSelectors.getSpaceBanner);
	const isSpaceDetailsLoaded: boolean = useSelector(spacesSelectors.getSpaceDetailsLoaded);
	const dispatch = useDispatch();

	/**
	 * Prepare query object to get node list
	 * @param pageIndex
	 * @param title
	 * @param topics
	 * @param type
	 */
	const onSearch = (pageIndex: number, title: string, topics: string, type: NodeType, filterBy: UserDashboardsFiltersType): void => {
		let queryModel = ServiceHub.nodeAPI.getQueryNodeObject(nodeId, pageIndex, title, topics, searchTopic, type, undefined, userCustomDashboard);
		getNodesList(queryModel);
	};

	/**
	 * Calling API endpoint to get all nodes based on the query
	 * @param queryNodeModel
	 */
	const getNodesList = async (queryNodeModel: QueryNodeModel) => {
		let res = await NodeAPIServiceInstance.getAll(queryNodeModel);
		if (await res.nodes) {
			dispatch(setNodeList(res));
			dispatch(setNodesLoading(false));
			dispatch(setIsDashboardLoading(false));
		}
	};

	/**
	 * Utility to convert space image string path to data image
	 * @param imagePath
	 */
	const getSpaceCoverImageData = (imagePath: string) => {
		ServiceHub.appFileAPI
			.start()
			.getCoverImageByURL(imagePath)
			.then((result) => {
				const imageData = result ? URL.createObjectURL(result) : null;
				dispatch(spacesActions.setSpaceCoverImage(imageData));
				dispatch(spacesActions.setSpaceCoverImageLoaded(true));
			});
	};

	/**
	 * Flag to control image loading on Space Box Details
	 * @param value
	 */
	const setSpaceCoverImageLoaded = (value: boolean) => {
		dispatch(spacesActions.setSpaceCoverImageLoaded(value));
	};

	/**
	 * Flag to control details data loaded Space Box Details
	 * @param value
	 */
	const setSpaceDetailsLoaded = (value: boolean) => {
		dispatch(spacesActions.setSpaceDetailsLoaded(value));
	};

	/**
	 * Get specific isolated space data re-using Space Management endpoint
	 * @param spaceId
	 */
	const getSpaceIsolatedData = (spaceId: string) => {
		NodeAPIServiceInstance.getSpace(spaceId).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
			dispatch(spacesActions.setSpaceName(result.Value.Name));
			if (result.Value.CoverImagePath) {
				getSpaceCoverImageData(result.Value.CoverImagePath);
			} else {
				dispatch(spacesActions.resetSpaceCoverImage());
			}
			if (result.Value.Description) {
				dispatch(spacesActions.setSpaceDescription(result.Value.Description));
			} else {
				dispatch(spacesActions.resetSpaceDescription());
			}
			if (result.Value.Banner) {
				dispatch(spacesActions.setSpaceBanner(result.Value.Banner));
			} else {
				dispatch(spacesActions.resetSpaceBanner());
			}
			dispatch(spacesActions.setSpaceDetailsLoaded(true));
		});
	};

	return {
		searchTopics,
		searchTitle,
		isFilterExpanded,
		isLoading,
		isLoaded,
		pageIndex,
		onSearch,
		getNodesList,
		searchTopic,
		nodeList,
		isNodesLoading,
		dashboardButtonTitle,
		dashboardNavigation,
		userCustomDashboard,
		topicsResetKey,
		isDashboardLoading,
		spaceCoverImage,
		spaceCoverImageLoaded,
		spaceName,
		spaceDescription,
		spaceBanner,
		isSpaceDetailsLoaded,
		getSpaceIsolatedData,
		setSpaceCoverImageLoaded,
		setSpaceDetailsLoaded,
		sortByOption
	};
};
