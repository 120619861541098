import { useRef, useEffect } from "react";

/**
 * Global Abort Controller to use and call from
 * needed components across the app.
 */
export const useAbortController = () => {
	const abortController = useRef<AbortController | null>(null);

	const resetAbortController = () => {
		abortController.current = new AbortController();
	};

	const abortRequest = () => {
		abortController.current?.abort();
	};

	useEffect(() => {
		resetAbortController();
		return () => {
			abortController.current?.abort();
		};
	}, []);

	return {
		abortController: abortController.current,
		abortRequest,
		resetAbortController
	};
};
