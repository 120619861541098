import React from "react";
import { Shimmer } from "@fluentui/react";

export interface IListShimmerProps {
	size?: number;
	width?: number;
}

/**
 * List-generic Shimmer build-up.
 *
 * @returns React.FunctionComponent
 */
export const ListShimmer: React.FC<IListShimmerProps> = ({ width = 650, size = 15, ...props }) => {
	return (
		<>
			<div className="shimmer title">
				<Shimmer width="150px" />
			</div>
			{Array.from({ length: size }, (v, k) => k).map((q) => {
				return (
					<div key={q}>
						<div className="shimmer">
							<Shimmer width={`${width}px`} />
						</div>
					</div>
				);
			})}
		</>
	);
};
