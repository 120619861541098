import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NodeActivityComment } from "./nodeActivity.comment";
import { setNodeCommentsTotal, setNodeId } from "../../../../redux/reducers/comments";
import { commentsSelectors } from "../../../../redux/selectors/comments";
import { NodeActivityActions } from "./nodeActivity.actions";
import { NodeType } from "../../../../contracts/models/strikeEnums";
import { ServiceHub } from "../../../../service";

/**
 * Contract for Comment Section component
 */
export interface INodeActivityProps {
	nodeId: number;
	type: NodeType;
}

/**
 * Node Activity component
 * Like and Comment actions
 *
 * @param props
 * @returns
 */
export const NodeActivity: React.FC<INodeActivityProps> = (props) => {
	const dispatch = useDispatch();
	const allNodeComments = useSelector(commentsSelectors.getOrderedNodeComments);
	const nodeActivityAriaLabel = useMemo(() => {
		switch (props.type) {
			case NodeType.Kbentry: {
				return "User interactions in this Article";
			}
			case NodeType.Question: {
				return "User interactions in this Question";
			}
			case NodeType.Comment: {
				return "User Comment";
			}
			case NodeType.Answer: {
				return "User Answer";
			}
			case NodeType.Topic: {
				return "Topic";
			}
			default: {
				return "Interactions from All users";
			}
		}
	}, [props.type]);

	const [dataLoaded, setDataLoaded] = useState(false);

	useEffect(() => {
		if (!dataLoaded) {
			setDataLoaded(true);
			dispatch(setNodeCommentsTotal(allNodeComments.length));
			dispatch(setNodeId(props.nodeId.toString()));
		}
	}, [props.nodeId]);

	// Used for general clean-up when this component unmounts
	useEffect(
		() => () => {
			// Clears the editing comment IDs
			const commentSvc = ServiceHub.commentAPI.start();
			commentSvc.resetEditingIds();
			commentSvc.setFormText("");
			commentSvc.setFormNodeId(null);
		},
		[]
	);

	return (
		<>
			<div className="node-activity" aria-label={nodeActivityAriaLabel} role="region">
				<NodeActivityActions nodeId={props.nodeId} type={props.type} />
			</div>
			{props?.type !== NodeType.Question ? (
				<div className="node-activity-form-comment-wrapper">
					<NodeActivityComment comments={allNodeComments} parentId={props.nodeId} nodeType={props.type} />
				</div>
			) : null}
		</>
	);
};
