import React from "react";
import { UserPhotoFollower } from "../userPhotoFollower";

/**
 * Contract for the input props of the User List Box photo item.
 */
export interface IUserListBoxPhoto {
	userUpn: string;
	userName: string;
}

/**
 * User List Box photo item.
 *
 * @param props IUserListBoxPhoto
 * @returns React.FC<IUserListBoxPhoto>
 */
export const UserListBoxPhoto: React.FC<IUserListBoxPhoto> = (props) => (
	<UserPhotoFollower
		key={props.userUpn}
		size="sm"
		title={props.userName}
		height={28}
		width={28}
		userPrincipalName={props.userUpn}
	/>
);
