import { IAuthSlice } from "../../../contracts/slices/auth/IAuthSlice";

/**
 * The Initial State of Node Management slice.
 */
export const authInitialState: IAuthSlice = {
	user: {
		displayName: "",
		principalName: "",
		id: "",
		email: "",
		authoriableId: 0
	},
	canAccessCreateArticleComponents: false,
	accessRequesting: false,
	accessRequested: false,
	isSuperAdmin: false,
	superAccessRequesting: false,
	superAccessRequested: false
};
