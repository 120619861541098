import { TagsModeType } from "../../../contracts/models/strikeEnums";
import { ITagsSlice } from "../../../contracts/slices";

/**
 * The Initial State of Node Management slice.
 */
export const tagsInitialState: ITagsSlice = {
	data: {
		tagsIds: [],
		tagsNames: []
	},
	ui: {
		tagsMode: TagsModeType.View
	}
};
