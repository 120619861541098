import React from "react";
import { DropdownItemProps, Form } from "semantic-ui-react";

/**
 * Custom Reusable MultiSelect component.
 * Currently used as part of the edit/delete tags in a node detail.
 */
export interface ICustomMultiSelectProps {
	handleItemEdit: (event: any, data: any) => void;
	handleAddItem: (event: any, data: any) => void;
	options: DropdownItemProps[];
	selectedOptions: string[];
	canAdd?: boolean;
}

/**
 * Reusable component to multiselect options
 */
export const CustomMultiSelect: React.FC<ICustomMultiSelectProps> = (props) => {
	return (
		<Form.Select
			placeholder=""
			width={16}
			fluid
			search
			selection
			multiple
			className="search-box multi-select"
			options={props.options}
			onChange={props.handleItemEdit}
			onAddItem={props.handleAddItem}
			value={props.selectedOptions}
			tabIndex={0}
			allowAdditions={props.canAdd}
		/>
	);
};
