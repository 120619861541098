import React, { useEffect } from "react";
import { ApplicationRoutePaths } from "../../../router";
import { Link } from "react-router-dom";
import Constants from "../../../../constants/Constants";
import defaultThumb from "../../../../strike-video-default.png";
import { SpaceTypes } from "../../../../contracts/models/strikeEnums";

/**
 * Interface for DashboardImage Box
 */
interface IDashboardImageBox {
}

/**
 * Image with link component to show on main dashboards
 * @param props
 * @returns
 */
export const DashboardImageBox: React.FC<IDashboardImageBox> = (props) => {

    useEffect(() => {
	}, []);

	return (
        <div className="dashboard-image-wrapper">
            <div className="dashboard-image-container">
                <Link to={ApplicationRoutePaths.spaces(SpaceTypes.OnlineCourses)}>
                    <img
                        src={defaultThumb}
                        alt="Main Dashboard image"
                        title="Main Dashboard image"
                        tabIndex={0}
                        aria-label="Main Dashboard image"
                        role="img"
                    />
                </Link>
            </div>
            <div className="dashboard-image-bottom-banner">
                <Link to={ApplicationRoutePaths.spaces(SpaceTypes.OnlineCourses)}>{Constants.dashboardImage.bannerText} &gt;</Link>
            </div>
        </div>
	);
};