// General Id that is not available
export const ID_NOT_AVAILABLE = -1;

// Number of entries per page on the strike review table
export const TABLE_PAGE_SIZE = 15;

// Initial page number for the strike review table
export const TABLE_INITIAL_PAGE_NUMBER = 1;

// Delay timer for input in ms
export const INPUT_DELAY = 1000;

export const MAXIMUM_PAGE_COUNT = 10;

// Tiles View
export const TILES_PER_PAGE = 30;
