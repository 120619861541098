/**
 * Ids of Fields used on Article Form component.
 */
export const CoverImageFieldIds = {
	uploadCoverImageInput: "uploadCoverImageInput"
};

/**
 * Labels used on Article Form component.
 */
export const CoverImageLabels = {
	actions: {
		coverSelectorTitle: "Pick a Cover Image",
		galleryTitle: "Select Image from Gallery"
	}
};

/**
 * Placeholders used on Article Form component.
 */
export const CoverImagePlaceholders = {
	defaultCoverImageUrl: "/img/strikeAppLogo.png"
};
