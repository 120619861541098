import React from "react";
import { NodesList } from "./nodes.list";

/**
 * Dashboard Component, in which all nodes are displayed, serves as a platform to see entire comunities, courses, etc
 */
const NodesDashboard = (): React.ReactElement => {
	return <NodesList />;
};

export default NodesDashboard;
