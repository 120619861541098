import React, { useEffect } from "react";
import { QuestionsNodesList } from "./questions.nodelist";

/**
 * Dashboard Component, in which all nodes are displayed, serves as a platform to see entire comunities, courses, etc
 */
const QuestionsDashboard = (): React.ReactElement => {
	useEffect(() => {
		document.title = 'Questions - Strike Community'
	}, [])
	return <QuestionsNodesList />;
};

export default QuestionsDashboard;
