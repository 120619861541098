import * as React from "react";
import { ShowArticle } from "./show.article";

/**
 * Component Article which servs a platform for the content to appear on the screen
 */
const Article = (): React.ReactElement => {
	React.useEffect(()=>{
		document.title='Article - Strike Community'
	},[]);
	return <ShowArticle />;
};

export default Article;
