import store from "../../redux/store";
import { IArticleFormData } from "../../contracts/article/articleForm";
import { articleFormActions } from "../../redux/reducers/articleForm";
import { articleFormSelectors } from "../../redux/selectors/articleForm";
import { ICoverImageInfo, IListControl, INodeQueryResponse, ISpaceItem } from "../../contracts";
import { nodesSelectors } from "../../redux/selectors/nodes";
import { NodeRevisionsResponseModel } from "../../contracts/models";

/**
 * Article Form microservice, which allows interacting with the Redux state layer.
 */
export class ArticleFormService {
	setData(data: IArticleFormData): void {
		store.dispatch(articleFormActions.setData(data));
	}

	setQueriedParentArticles(queried: boolean): void {
		store.dispatch(articleFormActions.setQueriedParentArticles(queried));
	}

	setQueryingParentArticles(querying: boolean): void {
		store.dispatch(articleFormActions.setQueryingParentArticles(querying));
	}

	setQueriedSpaces(queried: boolean): void {
		store.dispatch(articleFormActions.setQueriedSpaces(queried));
	}

	setQueryingSpaces(querying: boolean): void {
		store.dispatch(articleFormActions.setQueryingSpaces(querying));
	}

	setQueriedCoverImageTypes(queried: boolean): void {
		store.dispatch(articleFormActions.setQueriedCoverImageTypes(queried));
	}

	setQueryingCoverImageTypes(querying: boolean): void {
		store.dispatch(articleFormActions.setQueryingCoverImageTypes(querying));
	}

	setQueriedCoverImages(queried: boolean): void {
		store.dispatch(articleFormActions.setQueriedCoverImages(queried));
	}

	setQueryingCoverImages(querying: boolean): void {
		store.dispatch(articleFormActions.setQueryingCoverImages(querying));
	}

	setQueriedTopics(queried: boolean): void {
		store.dispatch(articleFormActions.setQueriedTopics(queried));
	}

	setQueryingTopics(querying: boolean): void {
		store.dispatch(articleFormActions.setQueryingTopics(querying));
	}

	setQueriedNode(queried: boolean): void {
		store.dispatch(articleFormActions.setQueriedNode(queried));
	}

	setQueryingNode(querying: boolean): void {
		store.dispatch(articleFormActions.setQueryingNode(querying));
	}

	setParentArticlesList(items: INodeQueryResponse[]): void {
		store.dispatch(articleFormActions.setParentArticlesList(items));
	}

	setSpacesList(items: ISpaceItem[]): void {
		store.dispatch(articleFormActions.setSpacesList(items));
	}

	setCoverImageTypesList(items: []): void {
		store.dispatch(articleFormActions.setCoverImageTypesList(items));
	}

	setCoverImagesList(items: []): void {
		store.dispatch(articleFormActions.setCoverImagesList(items));
	}

	setCoverImagesListControl(coverImagesControl: IListControl<ICoverImageInfo>): void {
		store.dispatch(articleFormActions.setCoverImagesListControl(coverImagesControl));
	}

	setCoverImagesListPage(newPage: number): void {
		store.dispatch(articleFormActions.setCoverImagesListPage(newPage));
	}

	setGalleryImagePathById(imageId: string, imagePath: string): void {
		store.dispatch(articleFormActions.setGalleryImagePathById({ id: imageId, path: imagePath }));
	}

	setTopicsList(items: string[]): void {
		store.dispatch(articleFormActions.setTopicsList(items));
	}

	setLoaded(loaded: boolean): void {
		store.dispatch(articleFormActions.setLoaded(loaded));
	}

	setLoading(loading: boolean): void {
		store.dispatch(articleFormActions.setLoading(loading));
	}

	setParentArticleQuery(query: string): void {
		store.dispatch(articleFormActions.setParentArticleQuery(query));
	}

	setCoverImageGalleryOpen(open: boolean): void {
		store.dispatch(articleFormActions.setCoverImageGalleryOpen(open));
	}

	setTopicsText(text: string): void {
		store.dispatch(articleFormActions.setTopicsText(text));
	}

	setBodySourceMode(sourceMode: boolean): void {
		store.dispatch(articleFormActions.setBodySourceMode(sourceMode));
	}

	setExpandedSpaceById(spaceId: number, expanded: boolean): void {
		store.dispatch(articleFormActions.setExpandedSpaceById({ spaceId, expanded }));
	}

	setSubmitting(submitting: boolean): void {
		store.dispatch(articleFormActions.setSubmitting(submitting));
	}

	getData(): IArticleFormData {
		return articleFormSelectors.getData(store.getState());
	}

	getParentArticlesList(): INodeQueryResponse[] {
		return articleFormSelectors.getParentArticlesList(store.getState());
	}

	getExpandedSpaceById(spaceId: number): boolean {
		return articleFormSelectors.getExpandedSpacesById(store.getState(), spaceId);
	}

	getSpacesList(): ISpaceItem[] {
		return articleFormSelectors.getSpacesList(store.getState());
	}

	getSpaceById(itemId: string | number): ISpaceItem {
		return articleFormSelectors.getSpaceById(store.getState(), itemId);
	}

	getCoverImagesList(): ICoverImageInfo[] {
		return articleFormSelectors.getCoverImagesList(store.getState());
	}

	getCoverImagesListControl(): IListControl<ICoverImageInfo> {
		return articleFormSelectors.getCoverImagesListControl(store.getState());
	}

	getCoverImagesListPage(): number {
		return articleFormSelectors.getCoverImagesListPage(store.getState());
	}

	getCoverImagesListTotal(): number {
		return articleFormSelectors.getCoverImagesListTotal(store.getState());
	}

	getCoverImageTypesList(): [] {
		return articleFormSelectors.getCoverImageTypesList(store.getState());
	}

	getGalleryImagePathById(imageId: string): string {
		return articleFormSelectors.getGalleryImagePathById(store.getState(), imageId);
	}

	getTopicsList(): string[] {
		return articleFormSelectors.getTopicsList(store.getState());
	}

	getQueryingParentArticles(): boolean {
		return articleFormSelectors.getQueryingParentArticles(store.getState());
	}

	getQueryingSpaces(): boolean {
		return articleFormSelectors.getQueryingSpaces(store.getState());
	}

	getQueryingCoverImageTypes(): boolean {
		return articleFormSelectors.getQueryingCoverImageTypes(store.getState());
	}

	getQueryingCoverImages(): boolean {
		return articleFormSelectors.getQueryingCoverImages(store.getState());
	}

	getQueryingTopics(): boolean {
		return articleFormSelectors.getQueryingTopics(store.getState());
	}

	getQueryingNode(): boolean {
		return articleFormSelectors.getQueryingNode(store.getState());
	}

	getQueriedParentArticles(): boolean {
		return articleFormSelectors.getQueriedParentArticles(store.getState());
	}

	getQueriedSpaces(): boolean {
		return articleFormSelectors.getQueriedSpaces(store.getState());
	}

	getQueriedCoverImageTypes(): boolean {
		return articleFormSelectors.getQueriedCoverImageTypes(store.getState());
	}

	getQueriedCoverImages(): boolean {
		return articleFormSelectors.getQueriedCoverImages(store.getState());
	}

	getQueriedTopics(): boolean {
		return articleFormSelectors.getQueriedTopics(store.getState());
	}

	getQueriedNode(): boolean {
		return articleFormSelectors.getQueriedNode(store.getState());
	}

	getLoading(): boolean {
		return articleFormSelectors.getLoading(store.getState());
	}

	getLoaded(): boolean {
		return articleFormSelectors.getLoaded(store.getState());
	}

	getParentArticleQuery(): string {
		return articleFormSelectors.getParentArticleQuery(store.getState());
	}

	getCoverImageGalleryOpen(): boolean {
		return articleFormSelectors.getCoverImageGalleryOpen(store.getState());
	}

	getTopicsText(): string {
		return articleFormSelectors.getTopicsText(store.getState());
	}

	getBodySourceMode(): boolean {
		return articleFormSelectors.getBodySourceMode(store.getState());
	}

	getSubmitting(): boolean {
		return articleFormSelectors.getSubmitting(store.getState());
	}

	reset(): void {
		store.dispatch(articleFormActions.reset());
	}

	resetData(): void {
		store.dispatch(articleFormActions.resetData());
	}

	/**
	 * Resets the Article (Node) form configuration.
	 */
	resetFormConfig(): void {
		store.dispatch(articleFormActions.resetFormConfig());
	}

	getRevisionById(itemId: string | number): NodeRevisionsResponseModel {
		return nodesSelectors.getRevisionById(store.getState(), itemId);
	}
}
