import React from "react";
import { Shimmer } from "@fluentui/react";

/**
 * Article-specific Shimmer build-up.
 *
 * @returns React.FunctionComponent
 */
export const ArticleImgShimmer: React.FunctionComponent = () => {
	return (
		<>
			<div className="shimmer group article-img-shimmer">
				<Shimmer width="60%" height={46} />
			</div>
		</>
	);
};
