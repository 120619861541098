import React from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { NodeActionTypes, NodeType } from "../../../../contracts/models/strikeEnums";
import { NodeActivityCommentForm } from "./nodeActivity.comment.form";
import { useSelector } from "react-redux";
import { commentsSelectors } from "../../../../redux/selectors/comments";

/**
 * Interface for comments main actions component
 */
interface INodeActivityMainCommentForm {
	nodeId: number;
	type: NodeType;
}

/**
 * Comments Actions component
 * to handle Node Like, Comment and Show toggle.
 * Also, to consider that it self-hides when there are editing Ids in the state layer.
 *
 * @param props
 * @returns
 */
export const NodeActivityMainCommentForm: React.FC<INodeActivityMainCommentForm> = (props) => {
	const isQuestion = props.type === NodeType.Question;
	const editingIds = useSelector(commentsSelectors.getEditingIds);

	return editingIds.length === 0 ? (
		<>
			<div className="node-activity-main-actions node-activity-main-comment-form">
				<div>
					<Pivot aria-label="Comment Form" linkFormat="tabs" className="node-activity-main-comment-form-tabs">
						<PivotItem headerText={isQuestion ? "Answer" : "Comment"} itemIcon="CommentAdd">
							<NodeActivityCommentForm
								headlineText={props.type === NodeType.Question ? "Add an Answer:" : "Add a Comment:"}
								buttonText={props.type === NodeType.Question ? "Post Answer" : "Post Comment"}
								actionType={
									props.type === NodeType.Question ? NodeActionTypes.Answer : NodeActionTypes.Comment
								}
								parentId={props.nodeId}
								mainComment
								tabIndex={0}
								isNew
							/>
						</PivotItem>
						{isQuestion ? (
							<PivotItem headerText="Comment" itemIcon="CommentAdd">
								<NodeActivityCommentForm
									headlineText="Add a Comment:"
									buttonText="Post Comment"
									actionType={NodeActionTypes.Comment}
									parentId={props.nodeId}
									mainComment
									tabIndex={0}
									isNew
								/>
							</PivotItem>
						) : null}
					</Pivot>
				</div>
			</div>
		</>
	) : null;
};
