// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.node-summary-grid-author {
	min-height: 2.5em;
}

.node-summary-grid-image {
	width: 100%;
}

.node-summary-grid-list-item {
	box-sizing: border-box;
	max-width: 410px;
}

@media (max-width: 800px) {
	.node-list-summary-grid-container {
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	}
}

@media (max-width: 500px) {
	.node-list-summary-grid-container {
		grid-template-columns: 1fr;
	}
	.node-summary-grid-list-item {
		box-sizing: border-box;
		max-width: 100%;
	}
}

.node-summary-grid-content-container {
	padding: 15px 15px 0 15px;
}

.node-summary-grid-title-wrapper {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 3em;
	line-height: 1.5em;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/node.summary.grid.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;AAClB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,sBAAsB;CACtB,gBAAgB;AACjB;;AAEA;CACC;EACC,2DAA2D;CAC5D;AACD;;AAEA;CACC;EACC,0BAA0B;CAC3B;CACA;EACC,sBAAsB;EACtB,eAAe;CAChB;AACD;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,oBAAoB;CACpB,4BAA4B;CAC5B,qBAAqB;CACrB,gBAAgB;CAChB,uBAAuB;CACvB,WAAW;CACX,kBAAkB;AACnB","sourcesContent":[".node-summary-grid-author {\r\n\tmin-height: 2.5em;\r\n}\r\n\r\n.node-summary-grid-image {\r\n\twidth: 100%;\r\n}\r\n\r\n.node-summary-grid-list-item {\r\n\tbox-sizing: border-box;\r\n\tmax-width: 410px;\r\n}\r\n\r\n@media (max-width: 800px) {\r\n\t.node-list-summary-grid-container {\r\n\t\tgrid-template-columns: repeat(auto-fit, minmax(150px, 1fr));\r\n\t}\r\n}\r\n\r\n@media (max-width: 500px) {\r\n\t.node-list-summary-grid-container {\r\n\t\tgrid-template-columns: 1fr;\r\n\t}\r\n\t.node-summary-grid-list-item {\r\n\t\tbox-sizing: border-box;\r\n\t\tmax-width: 100%;\r\n\t}\r\n}\r\n\r\n.node-summary-grid-content-container {\r\n\tpadding: 15px 15px 0 15px;\r\n}\r\n\r\n.node-summary-grid-title-wrapper {\r\n\tdisplay: -webkit-box;\r\n\t-webkit-box-orient: vertical;\r\n\t-webkit-line-clamp: 2;\r\n\toverflow: hidden;\r\n\ttext-overflow: ellipsis;\r\n\theight: 3em;\r\n\tline-height: 1.5em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
