import { AuthenticationResult, EventMessage, EventType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { IAzureAdConfigOptions } from "../contracts";
import { graphConfig, loginRequest, msalConfig } from "./authConfig";

let publicClientApplication: PublicClientApplication;

type GraphData = {
	displayName: string;
	jobTitle: string;
	mail: string;
	businessPhones: string[];
	officeLocation: string;
};

/**
 * An MSAL Service wrapper class.
 */
export class MsalService {
	/**
	 * Provides an access to the current instance of the MSAL in the app.
	 *
	 * @returns The PublicClientApplication instantiated.
	 */
	public static Get(): PublicClientApplication {
		return publicClientApplication;
	}

	/*
	 * Initialize MSAL PublicClientApplication Object
	 */
	public static PublicClientApplication = (
		devMode: boolean,
		azureConfigs: IAzureAdConfigOptions
	): PublicClientApplication => {
		if (
			azureConfigs.clientId &&
			azureConfigs.authority &&
			azureConfigs.redirectUri &&
			azureConfigs.postLogoutRedirectUri
		) {
			msalConfig.auth.clientId = azureConfigs.clientId;
			msalConfig.auth.authority = azureConfigs.authority;
			msalConfig.auth.redirectUri = azureConfigs.redirectUri;
			msalConfig.auth.postLogoutRedirectUri = azureConfigs.postLogoutRedirectUri;
			msalConfig.system = devMode
				? {
						loggerOptions: {
							loggerCallback(loglevel, message, containsPii) {
								if (containsPii) {
									return;
								}
								switch (loglevel) {
									case LogLevel.Error:
										console.error(message);
										return;
									case LogLevel.Info:
										console.info(message);
										return;
									case LogLevel.Verbose:
										console.debug(message);
										return;
									case LogLevel.Warning:
										console.warn(message);
										return;
								}
							},
							piiLoggingEnabled: false,
							logLevel: LogLevel.Verbose
						}
					}
				: msalConfig.system;
			msalConfig.system.allowRedirectInIframe = true;
		} else {
			throw Error("No azure active directory configuration! Verify azure active directory configuration.");
		}

		let msalInstance = new PublicClientApplication(msalConfig);

		msalInstance.addEventCallback((event: EventMessage) => {
			if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
				const payload = event.payload as AuthenticationResult;
				const account = payload.account;
				msalInstance.setActiveAccount(account);
			}
		});

		/**
		 * When using redirect APIs, handleRedirectPromise must be invoked when returning from the redirect.
		 * This ensures that the token response from the server is properly handled and temporary cache entries are cleaned up.
		 * */
		msalInstance
			.handleRedirectPromise()
			.then((response) => {
				if (response !== null) {
					msalInstance.setActiveAccount(response.account);
				} else {
					// need to call getAccount here?
					const currentAccounts = msalInstance.getAllAccounts();
					if (!currentAccounts || currentAccounts.length < 1) {
						return;
					} else if (currentAccounts.length > 1) {
						// Add choose account code here
						// Need to revisit here
						const activeAccount = currentAccounts[0];
						msalInstance.setActiveAccount(activeAccount);
					} else if (currentAccounts.length === 1) {
						const activeAccount = currentAccounts[0];
						msalInstance.setActiveAccount(activeAccount);
					}
				}
			})
			.catch((err) => {
				console.error(err);
				throw Error("Error in login! Verify azure active directory configuration.");
			});

		publicClientApplication = msalInstance;

		return publicClientApplication;
	};

	/*
	 * Get login user details from the graph API
	 */
	public static async GetCurrentUser(): Promise<any | null> {
		let graphData: GraphData;

		await MsalService.GetLoginUserDetailsFromGrapAPI().then((response) => {
			return (graphData = response);
		});

		return graphData;
	}

	/*
	 * Get login user account detail
	 */
	public static GetLoginUserAccounts = () => {
		const { accounts } = useMsal();
		const account = useAccount(accounts[0] || {});
		return account;
	};

	/*
	 * Validate the login user is authenticated
	 */
	public static IsAuthenticated = () => {
		const isAuthenticatedLogin = useIsAuthenticated();
		return isAuthenticatedLogin;
	};

	/*
	 * Get login interaction is in progress or all interactions are complete
	 */
	public static GetInteractionStatus = () => {
		const { inProgress } = useMsal();
		return inProgress;
	};

	/*
	 * Silently acquire an access token for a given set of scopes.
	 */
	public static GetAccessToken = async (graphApiParam: any, forceRefresh: boolean) => {
		let accessToken;

		const account = publicClientApplication.getActiveAccount();
		if (!account) {
			throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
		}

		await publicClientApplication
			.acquireTokenSilent({
				...graphApiParam,
				account: account,
				forceRefresh: forceRefresh
			})
			.then((response) => {
				accessToken = response.accessToken;
			})
			.catch((error) => {
				console.log(error);
				if (
					error.errorCode === "consent_required" ||
					error.errorCode === "interaction_required" ||
					error.errorCode === "login_required"
				) {
					return publicClientApplication.acquireTokenPopup(loginRequest).then((resp) => resp.accessToken);
				} else {
					throw Error("Error in acquireTokenSilent! {" + error + "}.");
				}
			});

		if (!accessToken)
			throw Error(
				"Error in acquireTokenSilent! Verify a user has been signed in and setActiveAccount has been called."
			);

		return accessToken;
	};

	private static async GetLoginUserDetailsFromGrapAPI() {
		const accessToken = await MsalService.GetAccessToken(loginRequest, true);

		const headers = new Headers();
		const bearer = `Bearer ${accessToken}`;

		headers.append("Authorization", bearer);

		const options = {
			method: "GET",
			headers: headers
		};

		return fetch(graphConfig.graphMeEndpoint, options)
			.then((response) => response.json())
			.catch((error) => console.log(error));
	}
}
