import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { nodesSelectors } from "../../../../redux/selectors/nodes";
import { utcToLocalDateTimeString } from "../../../../lib/strikeLibrary";
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react";
import { ServiceHub } from "../../../../service";
import { NodeRevisionsResponseModel } from "../../../../contracts/models";
import { RichTextNode } from "../richTextNode";
import { Button } from "../../button";

/**
 * Interface for Node Revisions Component
 */
interface INodeRevisionsProps {

}

/**
 * Component to show Node Revisions
 * @param props
 * @returns
 */
export const NodeRevisions: React.FC<INodeRevisionsProps> = (props) => {
    const nodeRevisions = useSelector(nodesSelectors.getNodeRevisions);
    const isRevisionsLoaded = useSelector(nodesSelectors.getRevisionsLoaded);
    const nodeService = useRef(ServiceHub.articleForm.start());
    const [revisitionItemDetail, setRevisionItemDetail] = useState<NodeRevisionsResponseModel>(undefined)

    const openRevisionDetail = (nodeId: number) => {
        const foundRevision = nodeService.current.getRevisionById(nodeId);
        setRevisionItemDetail(foundRevision);
        document.getElementById("node-revisions-table").style.display = "none";
        document.getElementById("node-revisions-list-detail").style.display = "block";
    }

    const backToRevisionList = () => {
        document.getElementById("node-revisions-table").style.display = "block";
        document.getElementById("node-revisions-list-detail").style.display = "none";
    }

    const columns: IColumn[] = [
		{ key: "column1", name: "Date", fieldName: "RevisionDate", minWidth: 120, maxWidth: 120, isResizable: true, onRender: (item) => {
            return utcToLocalDateTimeString(item.RevisionDate);
        } },
		{ key: "column2", name: "Title", fieldName: "Title", minWidth: 220, maxWidth: 220, isResizable: true, onRender: (item) => {
            return <button className="node-revisions-button-selection" onClick={() => openRevisionDetail(item.NodeId)}>{item.Title ? item.Title : 'Click here to see this comment revision'}</button>;
        } },
		{ key: "column3", name: "Author", fieldName: "AuthorName", minWidth: 100, maxWidth: 100, isResizable: true },
	];
	return (
		<div className="node-revisions-wrapper">
            {isRevisionsLoaded && nodeRevisions.length > 0 ?
            <div id="node-revisions-table">
                <DetailsList
                    items={nodeRevisions}
                    columns={columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionPreservedOnEmptyClick={false}
                    selectionMode={SelectionMode.none}
                    checkButtonAriaLabel="select row"
                    className="node-revisions-list" 
                />
            </div>
            : isRevisionsLoaded ?
            <p className="alert alert-info" role="alert">
				There is no revisions available for this node at this moment.
			</p> : null
            }
            <div id="node-revisions-list-detail">
                <div className="node-revisions-list-detail-wrapper">
                    <Button text="Back To List" onClick={backToRevisionList} />
                    {revisitionItemDetail ?
                    <>
                    <div className="node-revisions-list-detail-header">
                        <div> <strong>Date: </strong>
                        {utcToLocalDateTimeString(revisitionItemDetail.RevisionDate)} </div>
      
                        <div> <strong>Author Name: </strong>
                        {revisitionItemDetail.AuthorName} </div>
                    
                        <div> <strong>Author Email: </strong>
                        {revisitionItemDetail.AuthorEmail} </div>
                  
                        {revisitionItemDetail.Title !== '' ? <div> <strong>Title: </strong>
                        {revisitionItemDetail.Title} </div> : null}
                    </div>
                    <div className="node-revisions-list-detail-row">
                        <strong className="node-revisions-list-detail-header">Content</strong>
                        <RichTextNode>{revisitionItemDetail.Body}</RichTextNode> 
                    </div>
                    </>
                    : null}
                </div>
            </div>
		</div>
	);
};
