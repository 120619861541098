import React from "react";

import { IStrikeNodeEntity } from "../../../../contracts/models";
import { ArticleImgShimmer } from "../../shimmer";

/**
 * Controls the input props of Cover Display Image component.
 */
export interface ICoverDisplayImageProps {
	node: IStrikeNodeEntity;
	loading?: undefined | boolean;
	noShimmer?: undefined | boolean;
}

/**
 * Reusable Cover Image Display component.
 * To be used for representing the Cover image rendering a sample of it.
 *
 * @param props ICoverDisplayImageProps
 * @returns React.FC<ICoverDisplayImageProps>
 */
export const CoverDisplayImage: React.FC<ICoverDisplayImageProps> = (props) => {
	const { node } = props;

	return props?.loading && (undefined === props?.noShimmer || !props?.noShimmer) ? (
		<ArticleImgShimmer />
	) : node?.Base64CoverImage !== "" && node?.Base64CoverImage !== null ? (
		<div className="article-cover-image" tabIndex={0} aria-label={`Cover Image: ${node.Title}`} role="img">
			<img src={node?.Base64CoverImage} alt={node.Title} className="img-fluid" />
		</div>
	) : null;
};
