// Necessary interface imports
import { RouteObject } from "react-router-dom";
import { RoutePathStrings } from "./applicationRoutePaths";
import NodesDashboard from "../../dashboard/dashboard";
import SpaceEditPage from "../../space/edit.space";
import SpaceManage from "../../space/manage.space";

// Page Component imports

/**
 * Article Route Map.
 * Used internally to organize groups of similar RouteObjects.
 */
export const SpaceRoutes: RouteObject[] = [
	// Space Dashboard
	{
		path: RoutePathStrings.spaces,
		Component: NodesDashboard
	},
	// Space Management
	{
		path: RoutePathStrings.spaceManage,
		Component: SpaceManage
	},
	// Create Space
	{
		path: RoutePathStrings.spaceCreate,
		Component: SpaceEditPage
	},
	// Edit Space
	{
		path: RoutePathStrings.spaceEdit,
		Component: SpaceEditPage
	}
];
