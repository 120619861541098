import React from "react";
import { DetailsList, IColumn, SelectionMode, IGroupHeaderProps } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { ApplicationRoutePaths } from "../router";
import { IconButton } from "../common/button";
import { useSpaceForm } from "../../hooks/space/useSpaceForm";
import { useEffect } from "react";
import { ISpaceItem } from "../../contracts";
import { useRef } from "react";
import { ServiceHub } from "../../service";
import { ConfirmationMessage } from "../common/message";
import { UnauthorizedPage } from "../common/page";
import { ListShimmer } from "../common/shimmer/list/shimmer.list";
import { useSuperAdminAccessControl } from "../../hooks/auth/useSuperAdminAccessControl";

export interface SpaceList extends ISpaceItem {
	name: string;
}

const SpaceManage: React.FC = () => {


	useEffect(() => {
		document.title = 'Manage spaces - Strike Community'
	}, []);

	const {
		spaces,
		spaceGroups,
		spaceItems,
		isOpenDeleteSpaceConfirmation,
		SpaceToDelete,
		SpaceIdToDelete,
		isManageHeaderLoaded,
		isManageHeaderLoading,
		isManageListLoaded,
		isManageListLoading
	} = useSpaceForm();
	const spaceFormService = useRef(ServiceHub.spaceForm.start());
	const spacesFormDataAPI = useRef(ServiceHub.spaceAdminAPI.start());
	const spacesAPI = useRef(ServiceHub.spacesAPI.start());
	const { isSuperAdmin, requested, requesting } = useSuperAdminAccessControl();

	/**
	 * Mapping title to space name property used for component
	 * @param data
	 * @returns
	 */
	const mapNameProperty = (data: ISpaceItem[]): SpaceList[] => {
		return data.map((item) => ({
			...item,
			name: item.Title // Map Title to name to display space Name
		}));
	};

	useEffect(() => {
		if (spaces) {
			const transformedData = spaceFormService.current.transFormData(spaces);
			spaceFormService.current.setSpaceManagerItemsList(mapNameProperty(transformedData.itemsList));
			spaceFormService.current.setSpaceManagerGroupsList(transformedData.headingList);
		}
	}, [spaces, isManageHeaderLoaded, isManageHeaderLoading, isManageListLoaded, isManageListLoading]);

	const navigate = useNavigate();

	/**
	 * Creates the structure for the List
	 */
	const columns: IColumn[] = [
		{
			key: "name",
			name: "Space Name",
			fieldName: "name",
			minWidth: 350,
			maxWidth: 500,
			isResizable: true
		},
		{
			key: "actions",
			name: "",
			minWidth: 80,
			maxWidth: 90,
			onRender: (item: SpaceList) => (
				<div className="space-manager-actions-column">
					<IconButton iconName="Edit" ariaLabel="Edit" onClick={() => handleEdit(item.Id.toString())} />
					<IconButton
						iconName="Delete"
						ariaLabel="Delete"
						onClick={() => handleRemove(item.Id.toString(), item.Title)}
					/>
				</div>
			)
		}
	];

	/**
	 * Method called once the edit icon button its clicked
	 * @param id
	 */
	const handleEdit = (id: string) => {
		spaceFormService.current.resetData();
		navigate(ApplicationRoutePaths.spaceEdit(id));
	};

	/**
	 * Method called once the delete icon button its clicked
	 * @param id
	 * @param spaceName
	 */
	const handleRemove = (id: string, spaceName: string) => {
		spaceFormService.current.setSpaceManagerSpaceToDelete(spaceName);
		spaceFormService.current.setSpaceManagerSpaceIdToDelete(id);
		spaceFormService.current.setIsOpenSpaceManagerDeleteConfirmation(true);
	};

	/**
	 * Method called to redirect user to create space form
	 */
	const handleAddSpace = () => {
		navigate(ApplicationRoutePaths.spaceCreate());
		spaceFormService.current.resetData();
		spaceFormService.current.resetSelectedParentId();
	};

	/**
	 * Calling API endpoint to delete space and show fresh list back
	 */
	const onDeleteConfirmation = () => {
		spacesFormDataAPI.current.deleteSpace(SpaceIdToDelete).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
			if (!result) {
				// Redirect the user to the dashboard
				ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.dashboard());
				return;
			}

			spacesAPI.current.getSpaces().then((result) => spacesAPI.current.setSpaces(result));
			spaceFormService.current.onCloseSpaceManagerDeleteConfirmation();
		});
	};

	/**
	 * Custom space header accesibility friendly
	 * It shows Parent space with count
	 * @param props
	 * @returns
	 */
	const onRenderGroupHeader = (props: IGroupHeaderProps) => {
		return (
			<div className="space-manager-group-header" data-is-focusable="true">
				{props.group.name} ({props.group.count})
			</div>
		);
	};

	/**
	 * Loading temp component to show shimmer while list its loading
	 * @returns
	 */
	const renderLoading = () => {
		return (
			<div className={`row`}>
				<div className="col-md-12 col-lg-6 col-xl-8" tabIndex={0} aria-label="Manage Space List">
					<div className="space-manager-container">
						<ListShimmer />
					</div>
				</div>
			</div>
		);
	};

	/**
	 * Shows unauthorized component
	 * @returns
	 */
	const renderUnauthorized = () => {
		return <UnauthorizedPage />;
	};

	if (!requested && (requesting || isManageListLoading)) {
		return renderLoading();
	}

	if (isSuperAdmin)
		return (
			<>
				<div className={`row`}>
					<div className="col-md-12 col-lg-6 col-xl-8" tabIndex={0} aria-label="Manage Space List">
						<div className="space-manager-container">
							<div className="space-manager-header">
								<h1 aria-label="Manage Spaces" role="heading" tabIndex={0}>
									Manage Spaces
								</h1>
								<IconButton
									text="Create Space"
									iconName="CircleAddition"
									onClick={handleAddSpace}
									className="space-manager-add-button"
								/>
							</div>
							<div className="space-manager-list">
								<DetailsList
									items={spaceItems}
									groups={spaceGroups}
									columns={columns}
									setKey="set"
									groupProps={{
										onRenderHeader: onRenderGroupHeader
									}}
									layoutMode={0}
									selectionMode={SelectionMode.none}
									ariaLabelForSelectionColumn="Toggle selection"
									ariaLabelForGrid="Space Manage List"
									focusZoneProps={{
										allowTabKey: true
									}}
									isHeaderVisible={false}
								/>
							</div>
						</div>
					</div>
				</div>
				<ConfirmationMessage
					isOpen={isOpenDeleteSpaceConfirmation}
					onClose={spaceFormService.current.onCloseSpaceManagerDeleteConfirmation}
					onAction={onDeleteConfirmation}
					title={"Delete Space"}
					text={"Are you sure you want to delete " + SpaceToDelete + " space?"}
				/>
			</>
		);

	if (!isSuperAdmin && isManageHeaderLoaded && !isManageHeaderLoading && isManageListLoaded && !isManageListLoading) {
		return renderUnauthorized();
	}
};

export default SpaceManage;
