import { RouteObject, createBrowserRouter } from "react-router-dom";
import { ApplicationRoutes } from "./applicationRoutes";

/**
 * Middleware Component to transform the Application fixture-based route table
 * into a Browser-type Router, to be consume by the app as a provider.
 */
export const BrowserRouter = createBrowserRouter(
	Object.keys(ApplicationRoutes).map((routeName) => {
		return {
			...ApplicationRoutes[routeName]
		} as RouteObject;
	})
);
