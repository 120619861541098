import React, { useEffect, useState } from "react";
import { Icon } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { INPUT_DELAY, IStrikeTopicNode, QueryTopicModel, TILES_PER_PAGE } from "../../contracts/models";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import { StrikePagination } from "../pagination/pagination";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { IStrikeTopicNode_Default } from "../../models/TopicNode";
import { ApplicationRoutePaths } from "../router";
import { Divider, Message } from "semantic-ui-react";
import { TopicCallout } from "./topicCallout";
import { ServiceHub } from "../../service";
import { TopicsShimmer } from "./topicShimmer";
import { StickyNodes } from "../stickyNodes/stickyNodes";

interface Props {}

/**
 * Topics List in which all the Topics are displayed in the screen in form of grid
 * there's a seach bar in which the Topic can be filtered
 * additionally, is possible to navigate to the Topic Community clicking inside of the tiles
 */
export const TopicList: React.FunctionComponent<Props> = () => {
	// data
	const [strikeTopicRawData, setStrikeTopicRawData] = useState<IStrikeTopicNode>(IStrikeTopicNode_Default);
	// management
	const [isLoading, setIsLoading] = useState(false);
	// pagination
	const [pageIndex, setPageIndex] = useState(1);
	// filter search
	const [searchText, setSearchText] = useState("");
	// navigation
	const navigate = useNavigate();
	// callout / tooltip
	const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
	const iconId = useId("callout-button");
	const labelId = useId("callout-label");
	const descriptionId = useId("callout-description");

	/**
	 * From Tiles, which serves as a navigation to the specific Topic Comunity
	 */
	const handleTileClick = (topicID: number, title: string) => {
		navigate(ApplicationRoutePaths.topics(topicID.toString(), title));
		ServiceHub.filterAPI.start().resetTopicsFilters();
	};

	useEffect(() => {
		setIsLoading(true);
		getStrikeTopicObject("", pageIndex, TILES_PER_PAGE);
	}, []);

	const getStrikeTopicObject = async (name: string, pageIndex: number, pageLimit: number) => {
		let queryModel = {
			name: name,
			pageIndex: pageIndex,
			pageLimit: pageLimit
		} as QueryTopicModel;

		try {
			const res = await NodeAPIServiceInstance.queryTopics(queryModel);
			if (res) {
				setStrikeTopicRawData(res);
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
			console.error("Error getting connecting to database", error);
		}
	};

	/**
	 * Manage the Page Change functionality
	 */
	const onPageChange = (pageIndex: number): void => {
		setPageIndex(pageIndex);
		getStrikeTopicObject(searchText, pageIndex, TILES_PER_PAGE);
	};

	/**
	 *
	 * @params function to be executed and time to be awaited
	 * Debounce implementation
	 */

	const debounce = (func: any, wait: number) => {
		let timeout;

		return function executedFunction(...args) {
			const later = () => {
				clearTimeout(timeout);
				func(...args);
			};

			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		};
	};

	/**
	 * Complementary to onFilterChanged
	 */
	const debouncedSearch = debounce((title: string) => {
		// remembers the search in the state to avoid conflicts when switching page and getting the new Topics from db
		setSearchText(title);
		getStrikeTopicObject(title, 1, TILES_PER_PAGE);
	}, INPUT_DELAY);

	/**
	 * Triggers the search
	 */
	const onFilterChanged = (event): void => {
		let title = event.target.value;
		debouncedSearch(title);
	};

	return (
		<div className="strike-node-wrapper">
			<h2 className="topic-spaces-dashboard-title" tabIndex={0} role="heading">
				Topics Dashboard
			</h2>
			<Icon
				iconName="info"
				className="topic-spaces-dashboard-icon"
				id={iconId}
				onMouseEnter={toggleIsCalloutVisible}
				onMouseLeave={toggleIsCalloutVisible}
				onFocus={toggleIsCalloutVisible}
				tabIndex={0}
				aria-hidden={false}
			/>

			{isCalloutVisible && (
				<TopicCallout
					toggleIsCalloutVisible={toggleIsCalloutVisible}
					labelId={labelId}
					descriptionId={descriptionId}
					iconId={iconId}
				/>
			)}

			<Divider />

			<input
				className="form-control search-box topics-spaces-filter"
				placeholder="Filter by name"
				onChange={onFilterChanged}
				type="search"
			/>

			<StickyNodes className="mt-2" />

			{!isLoading ? (
				strikeTopicRawData.TopicNodes.length !== 0 ? (
					<>
						<div className="topics-spaces-list-container">
							{strikeTopicRawData.TopicNodes.map((space) => (
								<button
									key={space.TopicID}
									type="button"
									className="topics-spaces-list-item"
									onClick={() => handleTileClick(space.TopicID, space.Title)}
								>
									<h4>{space.Title}</h4>
									<span className="tile-used-count">
										Used <strong>{space.NodeCount}</strong> time{space.NodeCount > 1 && "s"}
									</span>
								</button>
							))}
						</div>
						<div className="strike-pagination-topics-spaces">
							<StrikePagination
								totalPages={strikeTopicRawData.TotalRecordCount}
								onPageChange={onPageChange}
							/>
						</div>
					</>
				) : (
					<Message content={`No records found.`} />
				)
			) : (
				<TopicsShimmer />
			)}
		</div>
	);
};
