import React, { useEffect } from "react";
import { TopicList } from "./topic.list";

/**
 * Topics Dashboard, accessible by the route: "/topics"
 */
const TopicDashborad = (): React.ReactElement => {
	useEffect(()=>{
		document.title='Topic - Strike Community'
	})
	return <TopicList />;
};

export default TopicDashborad;
