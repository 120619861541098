import React from "react";
import { UserListPrimaryAction } from "./userList.primaryAction";
import { UserListBoxContent } from "./userList.content";
import { appManagementSelectors } from "../../../../redux/selectors/appManagement";
import { useSelector } from "react-redux";
import { IconButton } from "../../button";

/**
 * Controls the Properties assignable to UserListBox component.
 */
export interface IUserListBox {
	title: string;
	primaryActionTitle: string;
	favoriteActionTitle: string;
	primaryActionIcon?: undefined | string;
	primaryActionClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
	secondaryActionClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
	primaryActionVariation?: undefined | "primary" | "secondary";
	usersUpn: string[];
	baselineText?: undefined | string | React.ReactElement;
	hideEmptyRecordsMessage?: undefined | boolean;
}

/**
 * Renders a standard User Profile list box,
 * for using with context-specific user listing.
 * E.g. Followers Box
 *
 * @param props IUserListBox
 * @returns React.FC<IUserListBox>
 */
export const UserListBox: React.FC<IUserListBox> = (props) => {
	const isHighZoomLevel = useSelector(appManagementSelectors.getIsHighZoomLevel);

	const onFavoriteButtonClick = () => {

	}

	return (
		<div
			className={`list-box ${isHighZoomLevel ? "high-zoom-level" : ""}`}
			tabIndex={0}
			aria-label={`Interactions: ${props.title}`}
		>
			<div className="list-box-top">
				<IconButton
					text={props.favoriteActionTitle}
					iconName='Heart'
					onClick={props.secondaryActionClick}
					className="list-box-top-action-favorite-button"
				/>
				<div className="list-box-top-action">
					<UserListPrimaryAction
						title={props.primaryActionTitle}
						onClick={props.primaryActionClick}
						variation={props?.primaryActionVariation}
						iconName={props?.primaryActionIcon}
					/>
				</div>
			</div>
			<div className="ui divider" />
			<div className="list-box-content">
				<UserListBoxContent usersUpn={props.usersUpn} hideEmptyRecordsMessage={props.hideEmptyRecordsMessage} />
			</div>
			{props.baselineText ? (
				<div className="list-box-baseline" tabIndex={0} role="status">
					{props.baselineText}
				</div>
			) : null}
		</div>
	);
};
