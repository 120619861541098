import React from "react";
import { Button } from "../../common/button";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../../ui/ErrorPage";
import { ApplicationRoutePaths } from "../../router";

/**
 * Component which is used for displaying a Not Found situation to the user.
 *
 * @returns React.FC
 */
export const ArticleError: React.FC = () => {
	const navigate = useNavigate();
	const onReturn = () => {
		navigate(ApplicationRoutePaths.root());
	};

	return (
		<div className="error-page">
			<ErrorPage
				header={"We're Sorry but we didn't find the Article you're looking for..."}
				isHtml={true}
				message={`
                Please, hover the Dashboard for any another Article, or Question you might be looking for.
                You can also contact us at
                &nbsp;<a href="mailto:strikeonlineteam@microsoft.com">
                    <b>strikeonlineteam</b>
                </a>
            `}
			/>

			<Button className="error-page-button" text="Back to Dashboard" onClick={onReturn} />
		</div>
	);
};
