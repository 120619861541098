import React from "react";
import {
	DropdownItemProps,
	DropdownOnSearchChangeData,
	DropdownProps,
	Dropdown as SemanticCombo
} from "semantic-ui-react";

/**
 * Controls the input props of Strike's ComboBox.
 */
export interface IComboBoxProps {
	options: DropdownItemProps[];
	onChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => Promise<void> | void;
	onType?:
		| undefined
		| ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData) => Promise<void> | void);
	canAdd?: undefined | boolean;
	id?: undefined | string;
	ariaLabel?: undefined | string;
	className?: undefined | string;
	selectedValue?: undefined | string | string[] | number | number[];
	disabled?: undefined | boolean;
	multiSelect?: undefined | boolean;
	placeholder?: undefined | string;
}

/**
 * Renders a Strike-standard ComboBox component.
 * Implements a Semantic UI ComboBox within.
 *
 * @param props IComboBoxProps
 * @returns React.FC<IComboBoxProps>
 */
export const ComboBox: React.FC<IComboBoxProps> = (props) => {
	return (
		<SemanticCombo
			fluid
			search
			selection
			allowAdditions={props.canAdd}
			id={props.id}
			value={props.selectedValue}
			className={`form-control ${props?.className ?? ""}`}
			aria-label={props.ariaLabel}
			options={props.options}
			disabled={props.disabled}
			multiple={props.multiSelect}
			placeholder={props.placeholder}
			onChange={props.onChange}
			onSearchChange={props.onType}
			aria-labelledby={props.id}
		/>
	);
};
