import React, { useEffect, useState } from "react";
import { IStrikeTopicNode, QueryTopicModel } from "../../../../contracts/models";
import { NodeAPIServiceInstance } from "../../../../service/NodeAPI";
import { IStrikeTopicNode_Default } from "../../../../models/TopicNode";
import { ServiceHub } from "../../../../service";
import { ApplicationRoutePaths } from "../../../router";
import { useNavigate } from "react-router-dom";

/**
 * Interface for Space Details Box component
 */
interface IPopularTopicsBox {
}

/**
 * Box to show Popular Topics
 * @param props
 * @returns
 */
export const PopularTopicsBox: React.FC<IPopularTopicsBox> = (props) => {
    const [isPopularTopicsLoading, setIsPopularTopicsLoading] = useState<boolean>(false);
    const [strikePopularTopics, setStrikePopularTopics] = useState<IStrikeTopicNode>(IStrikeTopicNode_Default);
    const navigate = useNavigate();

    /**
	 * From Tiles, which serves as a navigation to the specific Topic Comunity
	 */
	const handleTileClick = (topicID: number, title: string) => {
		navigate(ApplicationRoutePaths.topics(topicID.toString(), title));
		ServiceHub.filterAPI.start().resetTopicsFilters();
	};

    const getPopularTopics = async (name: string) => {
		let queryPopularTopicsModel = {
			name: name,
			pageIndex: 1,
			pageLimit: 60
		} as QueryTopicModel;

		try {
			const res = await NodeAPIServiceInstance.queryTopics(queryPopularTopicsModel);
			if (res) {
				setStrikePopularTopics(res);
				console.log('topics ' + JSON.stringify(res))
				setIsPopularTopicsLoading(false);
			}
		} catch (error) {
			setIsPopularTopicsLoading(false);
			console.error("Error getting connecting to database", error);
		}
	};

    useEffect(() => {
        setIsPopularTopicsLoading(true)
        getPopularTopics("");
	}, []);

	return (
		isPopularTopicsLoading ? (
			<div>Loading...</div>
		) : (
			strikePopularTopics.TopicNodes.length > 0 ? (
				<div className="popular-topics-box" tabIndex={-1} aria-label="Popular Topics Box">
					<div className="popular-topics-box-headline-wrapper" tabIndex={-1}>
						<div className="popular-topics-box-headline" tabIndex={-1}>
							<h4 tabIndex={-1}>Popular Topics</h4>
						</div>
					</div>
					<div className="ui divider" />
					<div className="popular-topics-box-tags">
						{strikePopularTopics.TopicNodes.map((space) => (
							<button
								key={space.TopicID}
								type="button"
								title={space.Title}
								aria-label={space.Title}
								className="badge bg-soft-secondary strike-tag-button"
								onClick={() => handleTileClick(space.TopicID, space.Title)}
							>
								<span>{space.Title}</span>
							</button>
						))}
					</div>
				</div>
			) : null
		)
	);
};
