/**
 * Redux config to manage filtering data
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { filterInitialState } from "../../models/slices/";
import { IStrikeNodes } from "../../contracts/models";
import { NodeType, UserDashboardsFiltersType } from "../../contracts/models/strikeEnums";

/**
 * Node Comments Management state layer, also named after a Redux Slice.
 *
 */
export const filterSlice = createSlice({
	name: AppSlices.filter,
	initialState: filterInitialState,
	reducers: {
		setNodeList: (state, action: PayloadAction<IStrikeNodes>) => {
			state.data.nodeList = action.payload;
		},
		setSearchTopics: (state, action: PayloadAction<string>) => {
			state.data.searchTopics = action.payload;
		},
		resetSearchTopics: (state) => {
			state.data.searchTopics = filterInitialState.data.searchTopics;
		},
		setSearchTitle: (state, action: PayloadAction<string>) => {
			state.data.searchTitle = action.payload;
		},
		resetSearchTitle: (state) => {
			state.data.searchTitle = filterInitialState.data.searchTitle;
		},
		setToggleExpanderFilter: (state) => {
			state.ui.expanded = !state.ui.expanded;
		},
		setFilterLoading: (state, action: PayloadAction<boolean>) => {
			state.ui.loading = action.payload;
		},
		setFilterLoaded: (state, action: PayloadAction<boolean>) => {
			state.ui.loaded = action.payload;
		},
		setPageIndex: (state, action: PayloadAction<number>) => {
			state.data.pageIndex = action.payload;
		},
		setNodesLoading: (state, action: PayloadAction<boolean>) => {
			state.ui.nodesLoading = action.payload;
		},
		setDashboardButtonTitle: (state, action: PayloadAction<string>) => {
			state.ui.dashboardButtonTitle = action.payload;
		},
		resetDashboardButtonTitle: (state) => {
			state.ui.dashboardButtonTitle = filterInitialState.ui.dashboardButtonTitle;
		},
		setDashboardNavigation: (state, action: PayloadAction<NodeType>) => {
			state.ui.dashboardNavigation = action.payload;
		},
		resetTopicsValues: (state) => {
			state.ui.topicsResetKey = state.ui.topicsResetKey + 1;
		},
		setSpaceSelectedValue: (state, action: PayloadAction<any>) => {
			state.ui.spaceSelectedValue = action.payload;
		},
		resetSpaceSelectedValue: (state) => {
			state.ui.spaceSelectedValue = filterInitialState.ui.spaceSelectedValue;
		},
		setIsFilterVisible: (state, action: PayloadAction<boolean>) => {
			state.ui.isFilterVisible = action.payload;
		},
		setIsDashboardLoading: (state, action: PayloadAction<boolean>) => {
			state.ui.isDashboardLoading = action.payload;
		},
		setUserDashboard: (state, action: PayloadAction<UserDashboardsFiltersType>) => {
			state.ui.userCustomDashboard = action.payload;
		},
		resetUserDashboard: (state) => {
			state.ui.userCustomDashboard = filterInitialState.ui.userCustomDashboard;
		},
		setSortByOption: (state, action: PayloadAction<string>) => {
			state.ui.sortByOption = action.payload;
		},
		resetSortByOption: (state) => {
			state.ui.sortByOption = filterInitialState.ui.sortByOption;
		},
	}
});

export const {
	setSearchTopics,
	setSearchTitle,
	resetSearchTopics,
	resetSearchTitle,
	setToggleExpanderFilter,
	setFilterLoading,
	setFilterLoaded,
	setPageIndex,
	setNodeList,
	setNodesLoading,
	setDashboardButtonTitle,
	resetDashboardButtonTitle,
	setDashboardNavigation,
	resetTopicsValues,
	setSpaceSelectedValue,
	resetSpaceSelectedValue,
	setIsFilterVisible,
	setIsDashboardLoading,
	setUserDashboard,
	resetUserDashboard,
	setSortByOption,
	resetSortByOption
} = filterSlice.actions;

export default filterSlice.reducer;
