import React from "react";
import { useShareContent } from "../../../hooks";
import { CustomPanel } from "../panel/Panel";
import { ShareContentForm } from "./share.content.form";
import { NodeType } from "../../../contracts/models/strikeEnums";

/**
 * The contract for the input props of the
 * Share Content Panel component.
 */
export interface IShareContentPanelProps {
	panelTitle?: undefined | string;
	formTitle: undefined | string;
	nodeType: NodeType;
}

/**
 * Reusable Share Content Panel structure,
 * wrapping ShareContentForm component within.
 *
 * @param props IShareContentPanelProps
 * @returns ShareContentPanel component.
 */
export const ShareContentPanel: React.FC<IShareContentPanelProps> = (props) => {
	const shareContent = useShareContent();
	const resolvedPanelTitle = props?.panelTitle ?? "Share Content";
	const resolvedFormTitle = props?.formTitle ?? undefined;

	return (
		<CustomPanel
			isOpen={shareContent.isOpen}
			onDismiss={shareContent.onDismiss}
			headerText={resolvedPanelTitle}
			closeOnOutClick
		>
			<ShareContentForm nodeType={props.nodeType} shareTitle={resolvedFormTitle} />
		</CustomPanel>
	);
};
