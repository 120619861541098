import React, { useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFilterSlice } from "../../hooks/filter/useFilterSlice";
import { IconButton } from "@fluentui/react";
import { ServiceHub } from "../../service";
import { FilterActionTypes, NodeType } from "../../contracts/models/strikeEnums";
import { UserDashboardsDropdown } from "./filter.user.custom.dashboards";

interface IFilterButtonsProps {}

/**
 * Renders a grid of buttons to filter nodes by
 * Latest update, most viewed, created by and most voted
 *
 * @param props Supported properties are IFilterButtonProps.
 * @returns React.FunctionComponent<IFilterButtonProps>
 */
export const FilterButtons: React.FunctionComponent<IFilterButtonsProps> = (props) => {
	const params = useParams();
	const filterData = useFilterSlice();
	const searchTopics = filterData.searchTopics;
	const filterButtonOption = filterData.sortByOption;
	const searchTopic = filterData.searchTopic;
	const searchTitleValue = filterData.searchTitle;
	const dashboardNavigation = filterData.dashboardNavigation;
	const userCustomDashboard = filterData.userCustomDashboard;
	const strikeNodes = filterData.nodeList;
	const filterAPI = useRef(ServiceHub.filterAPI.start());


	/**
	 * handle filter button option
	 * @param option
	 */
		const handleFilterByButtonOption = (event: React.MouseEvent<HTMLButtonElement>, option: string) => {
			if (event.currentTarget.classList.contains("selected")) {
				filterAPI.current.setsortByOption('')
			} else {
				filterAPI.current.setsortByOption(option)
			}
		};

	/**
	 * prepare dashboard type to pass in the query call
	 */
	const currentDashboard = useMemo(() => {
		if (dashboardNavigation === 'question') {
			return NodeType.Question
		} else if (dashboardNavigation === 'kbentry') {
			return NodeType.Kbentry
		} else {
			return undefined;
		}
	}, [dashboardNavigation]);

	/**
	 * Utility method to process filter type and get nodes
	 * @param filterType 
	 */
	const handleButtonFilterSelection = (sortBy: FilterActionTypes) => {
		let queryModel = ServiceHub.nodeAPI.getQueryNodeObject(
			Number(params.id),
			1,
			searchTitleValue,
			searchTopics,
			searchTopic,
			currentDashboard,
			sortBy,
			userCustomDashboard

		);
		filterData.getNodesList(queryModel);
	}

    /**
     * Generic method to pass filter call
     * @param ev 
     * @param filterType 
     * @param option 
     */
    const handleFilterButtonClick = (ev, filterType: FilterActionTypes, option: string) => {
        handleFilterByButtonOption(ev, option);
        handleButtonFilterSelection(filterType);
    };

	return (
		<>
			<div id="filter-buttons-bar">
				<div className="container">
					<div className="justify-content-center row">
							<div className="col-lg-12 filter-buttons-wrapper">
								<div className="filter-buttons-dashboards">
									<UserDashboardsDropdown />
								</div>
								{strikeNodes.nodes.length !== 0 ?
								<div className="filter-buttons-sort">
									<label className="filter-buttons-label">Sort by: </label>
									<IconButton iconProps={{ iconName: "WorldClock" }} className={`main-filter-buttons ${
											filterButtonOption === "latest" ? "selected" : ""
										}`} onClick={(ev) => handleFilterButtonClick(ev, FilterActionTypes.Updated, 'latest')} 
										ariaLabel={"Latest"}
														title="Latest"
														alt="Latest"/>
									<IconButton iconProps={{ iconName: "RedEye" }}  className={`main-filter-buttons ${
											filterButtonOption === "viewed" ? "selected" : ""
										}`} onClick={(ev) => handleFilterButtonClick(ev, FilterActionTypes.MostViewed, 'viewed')} ariaLabel={"Most Viewed"}
										title="Most Viewed"
										alt="Most Viewed"/>
									<IconButton iconProps={{ iconName: "BuildQueueNew" }}  className={`main-filter-buttons ${
											filterButtonOption === "created" ? "selected" : ""
										}`} onClick={(ev) => handleFilterButtonClick(ev, FilterActionTypes.Created, 'created')} ariaLabel={"Created On"}
										title="Created On"
										alt="Created On"/>
									<IconButton iconProps={{ iconName: "FavoriteStar" }}  className={`main-filter-buttons ${
											filterButtonOption === "voted" ? "selected" : ""
										}`} onClick={(ev) => handleFilterButtonClick(ev, FilterActionTypes.Votes, 'voted')} ariaLabel={"Most Voted"}
										title="Most Voted"
										alt="Most Voted"/>
								</div> : null}
							</div>
					</div>
				</div>
			</div>
		</>
	);
};
