import React from "react";
import { ButtonProps, IconProps, Button as SemanticButton, SemanticShorthandItem } from "semantic-ui-react";

/**
 * Controls the possible variations of the button
 */
declare type ButtonVariation = "primary" | "secondary";
/**
 * Controls the possible types of the button
 */
declare type ButtonType = "submit" | "button" | "reset";

/**
 * Controls the Strike general Button input props as per
 * contract.
 */
export interface IButtonProps {
	text: string;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => void | Promise<void>;
	type?: undefined | ButtonType;
	className?: undefined | string;
	disabled?: undefined | boolean;
	icon?: undefined | SemanticShorthandItem<IconProps>;
	variation?: undefined | ButtonVariation;
	loading?: undefined | boolean;
}

/**
 * Renders a wrapped Semantic UI button for the application.
 *
 * @param props Props to process for the button
 * @returns React.FC<IButtonProps>
 */
export const Button: React.FC<IButtonProps> = (props) => {
	const conditions = {
		primary: !props?.variation || props.variation === "primary",
		secondary: props?.variation === "secondary",
		type: props?.type ?? "button",
		variation: props?.variation ?? "primary",
		onClick: props?.onClick ?? undefined,
		disabled: props?.disabled ?? undefined,
		loading: props?.loading ?? undefined
	};

	return (
		<SemanticButton
			{...props}
			type={conditions.type}
			variation={conditions.variation}
			primary={conditions.primary}
			secondary={conditions.secondary}
			onClick={conditions.onClick}
			disabled={conditions.disabled}
			loading={conditions.loading}
		>
			{props.text}
		</SemanticButton>
	);
};
