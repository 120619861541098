import React from "react";
import { EventAllowUsersForm } from "./event.allowusers.form";
import { useSearchParams } from "react-router-dom";

/**
 * Controls the input of the Share Content
 * Form component.
 */
interface IEventAllowUsersProps {}

/**
 * The Share Content form,
 * Used to share content at any state of the application.
 *
 * @param props IShareContentFormProps
 * @returns React.FC<IShareContentFormProps>
 */
export const EventAllowUsers: React.FC<IEventAllowUsersProps> = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const eventId = searchParams.get("eventId");
	const userType = searchParams.get("userType");

	return (
		<>
			<EventAllowUsersForm eventId={eventId ? eventId : null} userType={userType ? userType : null} />
		</>
	);
};
