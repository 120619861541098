/**
 * Definition of selectors
 * for User Auth.
 */
export const authSelectors = {
	getUserDisplayName: (state) => {
		return state.auth.user.displayName;
	},
	getUserPrincipalName: (state) => {
		return state.auth.user.principalName ?? "";
	},
	getUserEmail: (state) => {
		return state.auth.user.email ?? "";
	},
	getUserId: (state) => {
		return state.auth.user.id ?? "";
	},
	getCanAccessCreateArticleComponents: (state): boolean => {
		return state.auth.canAccessCreateArticleComponents ?? false;
	},
	getAuthoriableId: (state) => {
		return state.auth.user.authoriableId ?? "";
	},
	getAccessRequesting: (state): boolean => {
		return state.auth.accessRequesting;
	},
	getAccessRequested: (state): boolean => {
		return state.auth.accessRequested;
	},
	getIsSuperAdminAccess: (state): boolean => {
		return state.auth.isSuperAdmin ?? false;
	},
	getSuperAccessRequesting: (state): boolean => {
		return state.auth.superAccessRequesting;
	},
	getSuperAccessRequested: (state): boolean => {
		return state.auth.superAccessRequested;
	}
};
