import React, { useMemo } from "react";
import { useImageGallery } from "../../../../hooks";
import { LightPagination } from "../../../pagination";
import { Loader } from "semantic-ui-react";

/**
 * Controls the input of the Cover Images Gallery modal.
 */
export interface ICoverImageGalleryProps {
	onSelect?: undefined | ((itemId: string | number, item: string) => void);
}

/**
 * Renders the Cover Image Gallery component.
 * Used within the Article/Question form for Cover Image selection from a pre-existing image gallery.
 *
 * @param props ICoverImageGalleryProps
 * @returns React.FC<ICoverImageGalleryProps>
 */
export const CoverImageGallery: React.FC<ICoverImageGalleryProps> = (props) => {
	const imageGalleryControls = useImageGallery();
	const totalPages = useMemo(() => {
		return (
			1 +
			Math.floor(
				imageGalleryControls.coverImagesListControl.totalCount /
					imageGalleryControls.coverImagesListControl.pageLength
			)
		);
	}, [
		imageGalleryControls.coverImagesListControl.totalCount,
		imageGalleryControls.coverImagesListControl.pageLength
	]);

	return (
		<div className="gallery-container" tabIndex={0} aria-label="Cover Image Gallery Tab">
			<div className="gallery-image-list" tabIndex={0} aria-label="Cover Image List">
				{imageGalleryControls?.coverImagesList?.map((coverItem, index) => {
					return (
						<div
							key={coverItem.Id}
							id={coverItem.Id}
							className="gallery-image-list-item"
							onClick={() => props.onSelect && props.onSelect(coverItem.Id, coverItem.CoverImagePath)}
							tabIndex={0}
							aria-label={`Cover Image Item ${index + 1}: ${coverItem.Name}`}
						>
							{!imageGalleryControls.imagePathGallery?.imagePath[coverItem.Id] ? (
								<Loader
									className="gallery-image-list-item-loader"
									key={`loader-${coverItem.Id}`}
									active
									inline
								/>
							) : (
								<img
									src={imageGalleryControls.imagePathGallery.imagePath[coverItem.Id]}
									alt={coverItem.Name}
									title={coverItem.Name}
								/>
							)}
						</div>
					);
				})}
			</div>
			<div className="gallery-list-pagination">
				<LightPagination
					totalPages={totalPages}
					onPageChange={imageGalleryControls.onPageChange}
					pageLength={imageGalleryControls.coverImagesListControl.pageLength}
					page={imageGalleryControls.coverImagesListControl.currentPage}
				/>
			</div>
		</div>
	);
};
