/**
 * Redux Store which define and control the state of the app
 */
import { combineReducers } from "@reduxjs/toolkit";

// App Slices dictionary, which maintains the ref to all reducers
import { AppSlices } from "./slices";

// App Reducer imports
import appManagementReducer from "./reducers/appManagement";
import nodesReducer from "./reducers/nodes";
import authReducer from "./reducers/auth";
import articleFormReducer from "./reducers/articleForm";
import appTreeViewReducer from "./reducers/appTreeView";
import commentsReducer from "./reducers/comments";
import spacesReducer from "./reducers/spaces";
import filterReducer from "./reducers/filter";
import followReducer from "./reducers/follow";
import tagsReducer from "./reducers/tags";
import spaceFormReducer from "./reducers/spaceForm";
import eventFormReducer from "./reducers/eventForm";
import eventNotificationTemplatesReducer from "./reducers/eventNotificationTemplates";

/**
 * Prepares the application reducer kit,
 * Combining them together.
 */
export const rootReducer = combineReducers({
	[AppSlices.auth]: authReducer,
	[AppSlices.appManagement]: appManagementReducer,
	[AppSlices.articleForm]: articleFormReducer,
	[AppSlices.appTreeView]: appTreeViewReducer,
	[AppSlices.nodes]: nodesReducer,
	[AppSlices.comments]: commentsReducer,
	[AppSlices.spaces]: spacesReducer,
	[AppSlices.filter]: filterReducer,
	[AppSlices.follow]: followReducer,
	[AppSlices.tags]: tagsReducer,
	[AppSlices.spaceForm]: spaceFormReducer,
	[AppSlices.eventForm]: eventFormReducer,
	[AppSlices.eventNotificationTemplates]: eventNotificationTemplatesReducer
});

/**
 * Exports the RootState type for better state structuring.
 */
export type RootState = ReturnType<typeof rootReducer>;
