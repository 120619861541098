import React from "react";

/**
 * Controls the props of Event Message component
 * Switching text content based on type.
 */
interface IShowEventMessageProps {
	type?: string;
	eventType?: string;
	wailistEnabled?: boolean;
}

/**
 * Event Message component to show confirmation
 * or registered message to the user.
 *
 * @param props IShowEventMessageProps
 * @returns React.FC<IShowEventMessageProps>
 */
export const ShowEventMessage: React.FC<IShowEventMessageProps> = (props) => {
	const message =
		props.type === "registered"
			? "Your response to this event has already been recorded. If you have any questions, please reach out to the StrikeOnline team."
			: props.eventType === "Virtual"
				? "Your RSVP has been successfully registered."
				: props.wailistEnabled
					? "Your RSVP has been successfully registered. You can modify your RSVP before the event, but changing it after confirmation will place you on the waitlist if you decide to re-attend."
					: "Your RSVP has been successfully registered.";

	return (
		<div
			className="event-thankyou alert alert-success"
			role="alert"
			aria-live="assertive"
		>
			{message}
		</div>
	);
};
