/**
 * Labels used on Space Form component.
 */
export const SpaceFormLabels = {
	top: {
		pageSpaceCreate: "Create Space",
		pageSpaceEdit: "Edit Space"
	},
	validation: {
		parentId: "Parent Space was not selected",
		name: "Name is required"
	}
};

/**
 * Placeholders used on Space Form component.
 */
export const SpaceFormPlaceholders = {
	space: {
		parentSpace: "Select Parent Space from the list",
		name: "Start typing your Space's name",
		description: "Write your Space's description here"
	}
};
