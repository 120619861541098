/**
 * Redux config to manage the app
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { articleFormInitialState } from "../../models/slices";
import { IArticleFormData } from "../../contracts/article/articleForm";
import { ICoverImageInfo, IListControl, INodeQueryResponse, ISpaceItem } from "../../contracts";

/**
 * Article Form state management layer, also named after a Redux Slice.
 */
export const articleFormSlice = createSlice({
	name: AppSlices.articleForm,
	initialState: articleFormInitialState,
	reducers: {
		setData: (state, action: PayloadAction<IArticleFormData>) => {
			state.data = action.payload;
		},
		resetData: (state) => {
			state.data = articleFormInitialState.data;
		},
		setParentArticlesList: (state, action: PayloadAction<INodeQueryResponse[]>) => {
			state.list.parentArticles = action.payload;
		},
		setSpacesList: (state, action: PayloadAction<ISpaceItem[]>) => {
			state.list.spaces = action.payload;
		},
		setCoverImageTypesList: (state, action: PayloadAction<[]>) => {
			state.list.coverImageTypes = action.payload;
		},
		setCoverImagesList: (state, action: PayloadAction<[]>) => {
			state.list.coverImages.items = action.payload;
		},
		setCoverImagesListPage: (state, action: PayloadAction<number>) => {
			state.list.coverImages.currentPage = action.payload;
		},
		setCoverImagesListControl: (state, action: PayloadAction<IListControl<ICoverImageInfo>>) => {
			state.list.coverImages = action.payload;
		},
		setGalleryImagePathById: (state, action: PayloadAction<{ id: string; path: string }>) => {
			state.gallery = {
				imagePath: {
					...state.gallery.imagePath,
					[action.payload.id]: action.payload.path
				}
			};
		},
		setTopicsList: (state, action: PayloadAction<string[]>) => {
			state.list.topics = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setLoaded: (state, action: PayloadAction<boolean>) => {
			state.loaded = action.payload;
		},
		setQueryingParentArticles: (state, action: PayloadAction<boolean>) => {
			state.querying.parentArticles = action.payload;
		},
		setQueryingSpaces: (state, action: PayloadAction<boolean>) => {
			state.querying.spaces = action.payload;
		},
		setQueryingCoverImageTypes: (state, action: PayloadAction<boolean>) => {
			state.querying.coverImageTypes = action.payload;
		},
		setQueryingCoverImages: (state, action: PayloadAction<boolean>) => {
			state.querying.coverImages = action.payload;
		},
		setQueryingTopics: (state, action: PayloadAction<boolean>) => {
			state.querying.topics = action.payload;
		},
		setQueryingNode: (state, action: PayloadAction<boolean>) => {
			state.querying.node = action.payload;
		},
		setQueriedParentArticles: (state, action: PayloadAction<boolean>) => {
			state.queried.parentArticles = action.payload;
		},
		setQueriedSpaces: (state, action: PayloadAction<boolean>) => {
			state.queried.spaces = action.payload;
		},
		setQueriedCoverImageTypes: (state, action: PayloadAction<boolean>) => {
			state.queried.coverImageTypes = action.payload;
		},
		setQueriedCoverImages: (state, action: PayloadAction<boolean>) => {
			state.queried.coverImages = action.payload;
		},
		setQueriedTopics: (state, action: PayloadAction<boolean>) => {
			state.queried.topics = action.payload;
		},
		setQueriedNode: (state, action: PayloadAction<boolean>) => {
			state.queried.node = action.payload;
		},
		setParentArticleQuery: (state, action: PayloadAction<string>) => {
			state.parentArticleQuery = action.payload;
		},
		setCoverImageGalleryOpen: (state, action: PayloadAction<boolean>) => {
			state.coverImageGalleryOpen = action.payload;
		},
		setTopicsText: (state, action: PayloadAction<string>) => {
			state.topicsText = action.payload;
		},
		setBodySourceMode: (state, action: PayloadAction<boolean>) => {
			state.bodySourceMode = action.payload;
		},
		setExpandedSpaceById: (state, action: PayloadAction<{ spaceId: number; expanded: boolean }>) => {
			if (action.payload.expanded && !state.expandedSpaces.includes(action.payload.spaceId)) {
				state.expandedSpaces = [...state.expandedSpaces, action.payload.spaceId];
			} else if (!action.payload.expanded && state.expandedSpaces.includes(action.payload.spaceId)) {
				state.expandedSpaces = state.expandedSpaces.filter((item) => item !== action.payload.spaceId);
			}
		},
		setSubmitting: (state, action: PayloadAction<boolean>) => {
			state.submitting = action.payload;
		},
		resetExpandedSpaces: (state) => {
			state.expandedSpaces = articleFormInitialState.expandedSpaces;
		},
		clearGalleryImagePaths: (state) => {
			state.gallery = articleFormInitialState.gallery;
		},
		reset: (state) => {
			state.list = articleFormInitialState.list;
			state.queried = articleFormInitialState.queried;
			state.querying = articleFormInitialState.querying;
			state.loading = articleFormInitialState.loading;
			state.loaded = articleFormInitialState.loaded;
			state.gallery = articleFormInitialState.gallery;
			state.coverImageGalleryOpen = articleFormInitialState.coverImageGalleryOpen;
			state.topicsText = articleFormInitialState.topicsText;
			state.parentArticleQuery = articleFormInitialState.parentArticleQuery;
			state.expandedSpaces = articleFormInitialState.expandedSpaces;
			state.submitting = articleFormInitialState.submitting;
		},
		resetFormConfig: (state) => {
			state.bodySourceMode = articleFormInitialState.bodySourceMode;
			state.coverImageGalleryOpen = articleFormInitialState.coverImageGalleryOpen;
			state.data = articleFormInitialState.data;
			state.loaded = articleFormInitialState.loaded;
			state.loading = articleFormInitialState.loading;
			state.submitting = articleFormInitialState.submitting;
		}
	}
});

export const articleFormActions = articleFormSlice.actions;

export default articleFormSlice.reducer;
