import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { ServiceHub } from "../../service";
import { FollowType } from "../../enums";
import { IFollowRelation } from "../../contracts";
import { useAuthUser } from "../auth/useAuthUser";
import { followSelectors } from "../../redux/selectors/follow";

/**
 * Contract for consuming the retrieved followers
 * list.
 */
export interface IUseFollowersHook {
	followers: IFollowRelation[];
	type: FollowType;
	querying: boolean;
	queried: boolean;
	currentUserFollowing: boolean;
	refresh: () => Promise<void>;
}

/**
 * Handles the preparation of a list of Followers,
 * To a given Topic, Space, or Node.
 * It uses the ID of the item to query for the collection.
 *
 * @param nodeId The Node ID having its followers queried
 * @param followType The Type of Node being Followed/Unfollowed
 * @returns IUseFollowersHook
 */
export const useFollowers = (nodeId: number | string, followType: FollowType) => {
	// Local states
	const queryTimeoutController = useRef<null | NodeJS.Timeout>(null);
	const previousNodeId = useRef(null);
	const followService = useRef(ServiceHub.followAPI.start());

	// Selectors
	const followers = useSelector(followSelectors.getFollowers);
	const querying = useSelector(followSelectors.getQuerying);
	const queried = useSelector(followSelectors.getQueried);
	const nodeIsFavourite = useSelector(followSelectors.getIsFavourite);
	const currentUser = useAuthUser();
	const currentUserFollowing = useSelector((state) =>
		followSelectors.getCurrentUserFollowing(state, currentUser.userPrincipalName)
	);

	/**
	 * Executes query for followers
	 */
	const queryFollowers = async () => {
		const abortController = new AbortController();

		queryTimeoutController.current = setTimeout(async () => {
			if (!querying && !queried) {
				followService.current.setQuerying(true);

				// Stores the current querying ID
				previousNodeId.current = nodeId;

				ServiceHub.nodeAPI.getFollowers(nodeId, followType, abortController);
			}
		}, 500);
	};

	/**
	 * Refreshes the internal component data
	 */
	const refresh = async () => {
		resetFollowers();
		followService.current.setQueried(false);
		followService.current.setFollowers([]);
		queryFollowers();
	};

	/**
	 * Resets the count of displaying followers to default
	 */
	const resetFollowers = (): void => {
		followService.current.resetFollowersToShow();
	};

	// Will monitor and refresh the data,
	// when the last ID is different from the current
	useEffect(() => {
		async function checkNodeIdChanged() {
			if (previousNodeId.current !== null && previousNodeId.current !== nodeId) {
				refresh();
			}
		}

		checkNodeIdChanged();

		() => {
			if (queryTimeoutController.current) clearTimeout(queryTimeoutController.current);
		};
	}, [previousNodeId, nodeId]);

	// Does the initial data load for the hook
	useEffect(() => {
		queryFollowers();

		() => {
			if (queryTimeoutController.current) clearTimeout(queryTimeoutController.current);
		};
	}, [querying, queried]);

	// Clean-up useEffect
	useEffect(
		() => () => {
			followService.current.resetAll();
		},
		[]
	);

	return { followers, type: followType, querying, queried, nodeIsFavourite, currentUserFollowing, refresh };
};
