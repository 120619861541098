import * as React from "react";
import { initializeIcons } from "@fluentui/react";
import { QueryClient, QueryClientProvider } from "react-query";

import Footer from "../Footer/Footer";
import HeaderMenu from "../Header/header.menu";
import BannerWelcome from "../banner/banner.welcome";
import { Outlet } from "react-router-dom";
import { useNavigateHelper } from "../../hooks";
import { LayoutSharedComponents } from "./global/layout.shared";
import { useSelector } from "react-redux";
import { appManagementSelectors } from "../../redux/selectors/appManagement";
import { FilterBar } from "../filter/filter.wrapper";

initializeIcons();

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
});

/**
 * Main Application Layout component
 *
 * @returns React.FC
 */
const AppLayout: React.FC = () => {
	// This will prepare the global app navigator
	// By keeping a Navigation singleton accessible
	// via ServiceHub.appManagement.navigate.
	useNavigateHelper();

	/**
	 * Flag which's used to track banner and apply margin on the page container
	 * @returns boolean
	 */
	const bannerVisible = useSelector(appManagementSelectors.getBannerVisible);

	return (
		<div className="d-flex flex-column">
			<HeaderMenu />
			<div className="container page-banner">
				<BannerWelcome />
			</div>
			<FilterBar />
			<div className={`container page-container ${bannerVisible ? "" : "page-container-banner-closed"} `}>
				<QueryClientProvider client={queryClient}>
					<Outlet />
				</QueryClientProvider>
			</div>
			<Footer />
			<LayoutSharedComponents />
		</div>
	);
};

export default AppLayout;
