import { INodeTopicUpdate } from "../../contracts";
import { NodeActionTypes } from "../../contracts/models/strikeEnums";

/**
 * Provides easy creation of Node Topic update Request objects.
 * Supports bulk-edit.
 */
export class NodeTopicUpdate implements INodeTopicUpdate {
	nodeId: number;
	actionType: NodeActionTypes;
	topics: string[];
	userUPN: string;

	constructor(nodeId: number, topics: string[], userUPN: string, actionType: NodeActionTypes) {
		this.nodeId = nodeId;
		this.actionType = actionType;
		this.topics = topics;
		this.userUPN = userUPN;
	}
}
