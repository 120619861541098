import React, { useEffect } from "react";
import { SpaceForm } from "./shared/space.form";

/**
 * Component to serves as wrapper for Space Form component
 * @returns SpaceForm component
 */
const SpaceEditPage: React.FC = () => {
	useEffect(()=>{
		document.title='Space - Strike Community'
	})
	return <SpaceForm />;
};

export default SpaceEditPage;
