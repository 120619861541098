import React from "react";
import { ContextualMenu, Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { Button } from "../button";
import Constants from "../../../constants/Constants";
import { ServiceHub } from "../../../service";
import { ApplicationRoutePaths } from "../../router";
import { useDispatch } from "react-redux";
import { setIsOpenActionPostMessage, setIsOpenDeleteConfirmation } from "../../../redux/reducers/nodes";
import { useNodeActionsSlice } from "../../../hooks/node/useNodeActions";

const modalPropsStyles = {
	main: {
		selectors: {
			["@media (max-width: 480px)"]: {
				maxWidth: "450px"
			},
			["@media (min-width: 992px)"]: {
				maxWidth: "500px"
			}
		}
	},
	footer: { display: "flex", justifyContent: "center" }
};

const modalFooterPropsStyles = {
	actions: { display: "flex", justifyContent: "right", marginTop: "30px" }
};

/**
 * Global Confirmation Message component contract.
 */
export interface IActionPostMessageProps {
	blockScreen?: undefined | boolean;
	draggable?: undefined | boolean;
	onClose?: () => void;
	text: string;
	title: string;
}

/**
 * Confirmation message component.
 * Activation based on parent props.
 *
 * @param props IConfirmationMessageProps
 * @returns React.FC<IConfirmationMessageProps>
 */
export const ActionPostMessage: React.FC<IActionPostMessageProps> = (props) => {
	const dispatch = useDispatch();
	const ActionPostData = useNodeActionsSlice();
	const isOpenActionPostMessage = ActionPostData.isOpenActionPostMessage;

	const dragOptions = React.useMemo(
		() => ({
			moveMenuItemText: "Move",
			closeMenuItemText: "Close",
			menu: ContextualMenu
		}),
		[]
	);
	const modalProps = React.useMemo(
		() => ({
			isBlocking: props.blockScreen ?? true,
			styles: modalPropsStyles,
			dragOptions: props.draggable ? dragOptions : undefined
		}),
		[props]
	);

	const dialogContentProps = React.useMemo(
		() => ({
			type: DialogType.close,
			title: props.title,
			subText: props.text
		}),
		[props.title, props.text]
	);

	const onReturn = () => {
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.root());
		dispatch(setIsOpenActionPostMessage(false));
	};

	return (
		<Dialog
			hidden={!isOpenActionPostMessage}
			onDismiss={props.onClose}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<DialogFooter styles={modalFooterPropsStyles}>
				<Button text="Ok" variation="primary" onClick={onReturn} />
			</DialogFooter>
		</Dialog>
	);
};
