import React, { useMemo } from "react";
import { utcToLocalDateTimeString } from "../../../../lib/strikeLibrary";
import { UserPhoto } from "../../user";
import { AuthorMentionShimmer } from "../../shimmer/author/shimmer.authorMention";
import { authorMentionShared } from "./shared";

/**
 * Regulates the Article Author Mention component props.
 */
export interface IAuthorMentionProps {
	authEmail: string;
	authName: string;
	createdOn: string;
}

/**
 * Standard re-usable Article Author mentioning
 * component.
 *
 * @param props
 * @returns React.FC<IAuthorMentionProps>
 */
export const AuthorMention: React.FC<IAuthorMentionProps> = (props) => {
	const resolvedCreatedDate = useMemo(() => {
		if (!props.createdOn) return "";

		const articleDate = new Date(props.createdOn);

		return utcToLocalDateTimeString(articleDate);
	}, [props]);
	const authorMentionAriaLabel = useMemo(() => {
		return `Author: ${props.authName}, Created on: ${resolvedCreatedDate}`;
	}, [props.authName, resolvedCreatedDate]);

	return (
		<div className="post-author">
			{!props.authEmail ? (
				<AuthorMentionShimmer />
			) : (
				<>
					<div className="post-author-content" aria-label={authorMentionAriaLabel} tabIndex={0}>
						<UserPhoto
							userPrincipalName={props.authEmail}
							height={authorMentionShared.userIconHeight}
							width={authorMentionShared.userIconWidth}
							className="post-author-content-photo"
							size="md"
						/>
						<div className="post-author-content-details">
							<div>
								by&nbsp;
								<a href={`mailto:${props.authEmail}`}>{props.authName}</a>
							</div>
							&nbsp;on&nbsp;
							<div tabIndex={0} aria-label={`Posted on: ${resolvedCreatedDate}`} role="presentation">{resolvedCreatedDate}</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
