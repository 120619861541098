import { IEventNotificationTemplatesSlice } from "../../../contracts/slices";
import { EventNotificationTemplateType } from "../../../enums";

/**
 * Event Notification Template Management redux slice.
 */
export const eventNotificationTemplatesInitialState: IEventNotificationTemplatesSlice = {
	list: [],
	form: {
		id: null,
		type: "",
		subject: "",
		emailContent: "",
		eventId: 0,
		isActive: true
	},
	ui: {
		bodySourceMode: false,
		editing: false,
		selectedType: EventNotificationTemplateType.MeetingInvite,
		submitting: false,
		eventName: ""
	}
};
