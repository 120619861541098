import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AppFileAPI } from "../../service/app/AppFileAPI";
import LoaderIndicator from "../loaderIndicator/loaderIndicator";

/**
 * The properties to contract for the use of the File Downloader component.
 */
export interface IFileDownloadPageProps {}

/**
 * Page for handling file download operations,
 * separately.
 *
 * @param props The acceptable props IFileDownloadPageProps.
 * @returns React.FC<IFileDownloadPageProps>
 */
const FileDownloadPage: React.FC<IFileDownloadPageProps> = (props) => {
	const params = useParams();
	const fileSvc = useRef(new AppFileAPI());
	const [downloading, setDownloading] = useState(false);
	const [downloaded, setDownloaded] = useState(false);

	async function onDownload() {
		setDownloading(true);

		await fileSvc.current
			.downloadFile(params.filePath)
			.then(() => {
				setDownloaded(true);
				setDownloading(false);

				setTimeout(() => {
					// Tries going back the history, for direct navigation
					window.history.back();
					// Or closing the window, if newly opened
					window.close();
				}, 200);
			})
			.catch((error) => {
				setDownloading(false);
				// TODO: Throw error message
			});
	}

	useEffect(() => {
		async function downloadFile() {
			if (params.filePath && !downloading && !downloaded) {
				onDownload();
			}
		}

		downloadFile();
	}, [params, downloading, fileSvc]);

	return (
		<div className="page-section">
			<section className="loading-container">
				<LoaderIndicator visible />
				<h4>Thanks for waiting while we download your file.</h4>
				<div>
					In case your download doesn't start automatically, please{" "}
					<a href={`#`} onClick={onDownload}>
						click here
					</a>
					.
				</div>
				<div>Once the download is completed, this page should close itself.</div>
			</section>
		</div>
	);
};

export default FileDownloadPage;
