import React from "react";
import { ArticleForm } from "./shared/article.form";

/**
 * Page for rendering the ArticleForm ready for a
 * new entry, as a component initial state. Also,
 * allows for rendering with pre-loaded data, in that sense,
 * the route param should be identifying the respective Node.
 *
 * @returns React.FC
 */
const EditArticlePage: React.FC = () => {
	return <ArticleForm />;
};

export default EditArticlePage;
