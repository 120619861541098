import React from "react";
import ErrorPage from "../../ui/ErrorPage";
import { Button } from "../button";
import { ApplicationRoutePaths } from "../../router";
import { ServiceHub } from "../../../service";

/**
 * Reusable Unauthorized access page.
 *
 * @returns React.FC
 */
export const UnauthorizedPage: React.FC = () => {
	const onReturn = () => {
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.root());
	};

	return (
		<div className="error-page strike-node-wrapper">
			<ErrorPage
				isHtml={true}
				header={"Not Authorized"}
				message={`
                You can contact
                &nbsp;<a href="mailto:strikeonlineteam@microsoft.com"><b>Strikeonline Team</b></a>
					for granting an Authorization.
            `}
			/>
			<Button className="error-page-button" text="Back to Dashboard" onClick={onReturn} />
		</div>
	);
};
