import React, { useEffect, useMemo, useRef, useState } from "react";
import { ServiceHub } from "../../../service";

/**
 * Contract for the Rich Text component.
 */
export interface IRichTextNodeProps {
	children: any | string;
}

/**
 * Reusable component to render rich text html
 *
 * @param props
 * @returns
 */
export const RichTextNode: React.FC<IRichTextNodeProps> = (props) => {
	const contrastDetectorService = useRef(ServiceHub.contrastDetection.start());
	const [hasRendered, setHasRendered] = useState(false);

	/**
	 * Checks and individual element's contrasting correctly
	 * against a foreground color.
	 */
	function checkElementContrast(element: HTMLElement) {
		let containerElement = document.querySelector(".strike-rich-text-node");
		let backgroundColor: string;
		let correctiveColor: string;

		// container element not yet found
		if (containerElement === null) {
			containerElement = document.body;
			const computedStyleMap = containerElement.computedStyleMap();
			backgroundColor = computedStyleMap.get("background-color").toString();
			correctiveColor = computedStyleMap.get("color").toString();
		} else {
			const computedStyleMap = window.getComputedStyle(document.body);
			backgroundColor = computedStyleMap.backgroundColor;
			correctiveColor = computedStyleMap.color;
		}

		// If any failure is detected, auto-correction is applied based on an element's correction color
		contrastDetectorService.current.checkElementColorContrast(element, backgroundColor, correctiveColor);
	}

	/**
	 * Identifies elements while iterating an HTMLCollection,
	 * which might need specific contrast ratio check
	 */
	function iterateContent(elements: HTMLCollection) {
		// Step 0: Contrast color checking the components
		for (let i = 0; i < elements.length; i++) {
			const child = elements[i] as HTMLElement;

			if (child instanceof HTMLElement && child.style.color && (!child.style.backgroundColor || child.style.backgroundColor === "")) {
				checkElementContrast(child);
			}

			if (child.children && child.children.length > 0) {
				iterateContent(child.children);
			}
		}
	}

	/**
	 * Pre-processes and resolves any pending children processing,
	 * before displaying to the end-user.
	 */
	const resolvedChildren = useMemo(() => {
		if (Array.isArray(props.children)) {
			return props.children.filter((line) => {
				if (line.trim() !== "") return line;
			});
		}

		if (props.children === "") {
			return "";
		}

		// Contrast detection mechanism application
		const parser = new DOMParser();
		const htmlString = props.children;
		const doc = parser.parseFromString(htmlString, "text/html");
		const divElement = doc.body; // Get the inner element

		// Runs through the content, checking for text
		// editor aspects and subjects which might need real-time adjustment
		iterateContent(divElement.children);

		if (!hasRendered) {
			setHasRendered(true);
		}

		return divElement.innerHTML;
	}, [props.children, hasRendered]);

	// Prepares the main aria-label content
	const ariaLabel = useMemo(() => {
		return `Body Section content: ${resolvedChildren}`;
	}, [resolvedChildren]);

	useEffect(() => {
		/**
		 * Handles the functions to be triggered when the theme is changed by the user.
		 *
		 * @param param0
		 */
		const handleChangeThemeColor = ({ matches }) => {
			if (matches) {
				// Switched to dark mode
				setHasRendered(false);
			} else {
				// Switched to light mode
			}

			setHasRendered(false);
		};

		// Instantiates a theme color checker monitor
		window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handleChangeThemeColor);

		return () => {
			// Removes the theme checker monitor
			window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", handleChangeThemeColor);
		};
	}, []);

	return resolvedChildren ? (
		<div
			className="strike-rich-text-node"
			dangerouslySetInnerHTML={{ __html: resolvedChildren.toString() }}
			aria-label={ariaLabel}
			role="region"
			tabIndex={0}
		/>
	) : null;
};
