import { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { ServiceHub } from "../../service";
import { articleFormSelectors } from "../../redux/selectors/articleForm";

/**
 * Handles the Node Cover Image Gallery selection.
 *
 * @returns A handset of Image Gallery controls.
 */
export const useImageGallery = () => {
	const articleFormService = useRef(ServiceHub.articleForm.start());
	const imagePathGallery = useSelector(articleFormSelectors.getGallery);
	const coverImagesListControl = useSelector(articleFormSelectors.getCoverImagesListControl);
	const coverImagesList = useSelector(articleFormSelectors.getCoverImagesList);

	/**
	 * Queries for objects from the gallery
	 */
	const queryGallery = useCallback(
		async (page: number, length: number) => {
			await ServiceHub.articleDataAPI
				.start()
				.getCoverImages(page, length)
				.then((result) => {
					articleFormService.current.setCoverImagesListControl({
						items: result.CoverImages,
						currentPage: page,
						totalCount: result.TotalRecordCount,
						pageLength: length
					});

					result.CoverImages.forEach((coverItem) => {
						if (imagePathGallery.imagePath[coverItem.Id]) return;

						// Image not yet available, download from storage
						ServiceHub.appFileAPI
							.start()
							.getCoverImageByURL(coverItem.CoverImagePath)
							.then((imageDownloadResult) => {
								const imageData = imageDownloadResult ? URL.createObjectURL(imageDownloadResult) : null;

								articleFormService.current.setGalleryImagePathById(coverItem.Id, imageData);
							});
					});
				});
		},
		[imagePathGallery, articleFormService]
	);

	/**
	 * Handles the page change command for a pagination kit.
	 *
	 * @param pageIndex The next page index
	 * @param pageSize The page size to query from service
	 */
	const onPageChange = useCallback(
		(pageIndex: number, pageSize: number) => {
			articleFormService.current.setCoverImagesListControl({
				items: coverImagesListControl.items,
				currentPage: pageIndex,
				totalCount: coverImagesListControl.pageLength,
				pageLength: pageSize
			});
			queryGallery(pageIndex, pageSize);
		},
		[queryGallery, coverImagesListControl, articleFormService]
	);

	return {
		coverImagesList,
		coverImagesListControl,
		imagePathGallery,
		onPageChange,
		queryGallery
	};
};
