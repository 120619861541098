import React from "react";
import { Button, IButtonProps } from "../button";

/**
 * The FormField properties to control its input.
 */
export interface IFormActionProps {
	actions: IButtonProps[];
	className?: undefined | string;
}

/**
 * Form bottom Actions component. Should be used within a form element,
 * by passing properties to it. Mainly it should also be the last component in the tree.
 *
 * @param props Properties to enable the FormActions component.
 * @returns React.FC<IFormActionProps>
 */
export const FormActions: React.FC<IFormActionProps> = (props) => {
	const conditions = {
		className: `form-actions ${props?.className ?? ""}`
	};

	return (
		<div className={conditions.className}>
			{props.actions.map((action, index) => {
				return (
					<Button
						key={index}
						type={action?.type ?? "button"}
						variation={action?.variation ?? "primary"}
						className={action?.className ?? undefined}
						text={action.text}
						onClick={action?.onClick ?? undefined}
						disabled={action?.disabled ?? undefined}
					/>
				);
			})}
		</div>
	);
};
