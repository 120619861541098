import React from "react";
import { EventForm } from "./shared/event.form";

/**
 * Component to serves as wrapper for Event Form component
 * @returns EventForm component
 */
const EventEditPage: React.FC = () => {
	return <EventForm />;
};

export default EventEditPage;
