/**
 * Possible System message types.
 * Based on 4-level alerting system, with semaphore colors.
 */
export enum MessageType {
	success = "success",
	warning = "warning",
	error = "error",
	info = "info"
}
