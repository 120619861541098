import React from "react";
import { CommandBar, DirectionalHint, IButtonProps, ICommandBarItemProps } from "@fluentui/react";

/**
 * Contract for the input props of the Standard Contextual Menu.
 */
export interface IContextMenuProps {
	items: ICommandBarItemProps[];
	ariaLabel?: undefined | string;
	title?: undefined | string;
}

/**
 * Strike Standard Context Menu, which allows
 * to wrap and single-way apply customization
 * to Fluent UI's Command Bar.
 *
 * @param props IContextMenuProps
 * @returns React.FC<IContextMenuProps>
 */
export const ContextMenu: React.FC<IContextMenuProps> = (props) => {
	const overflowProps: IButtonProps = {
		ariaLabel: props?.ariaLabel ?? "Actions",
		title: props?.title ?? "Actions",
		menuProps: {
			items: undefined,
			directionalHint: DirectionalHint.bottomRightEdge
		}
	};

	return (
		<CommandBar
			items={props.items}
			aria-label={props.ariaLabel}
			tabIndex={0}
			overflowButtonProps={overflowProps}
			onReduceData={() => undefined}
		/>
	);
};
