import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ContextualMenu, Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { appManagementSelectors } from "../../../redux/selectors/appManagement";
import store from "../../../redux/store";
import { setMessageOpen } from "../../../redux/reducers/appManagement";
import { MessageType } from "../../../enums";
import { Button } from "../button";

const modalPropsStyles = { main: { maxWidth: 450 } };

/**
 * Global Message component contract.
 */
export interface IMessageProps {
	blockScreen?: undefined | boolean;
	draggable?: undefined | boolean;
}

/**
 * Re-usable global message component.
 * Operates its state by selecting the Redux state layer.
 * Activation should be done via dispatch.
 *
 * @param props IMessageProps
 * @returns React.FC<IMessageProps>
 */
export const Message: React.FC<IMessageProps> = (props) => {
	// const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
	const dragOptions = React.useMemo(
		() => ({
			moveMenuItemText: "Move",
			closeMenuItemText: "Close",
			menu: ContextualMenu
		}),
		[]
	);
	const modalProps = React.useMemo(
		() => ({
			isBlocking: props.blockScreen ?? true,
			styles: modalPropsStyles,
			dragOptions: props.draggable ? dragOptions : undefined
		}),
		[props]
	);

	const messageText = useSelector(appManagementSelectors.getMessageText);
	const messageOpen = useSelector(appManagementSelectors.getMessageOpen);
	const messageIsHtml = useSelector(appManagementSelectors.getMessageIsHtml);
	const messageType = useSelector(appManagementSelectors.getMessageType);

	const messageTitle = useMemo(() => {
		switch (messageType) {
			case MessageType.info: {
				return "Info";
			}
			case MessageType.error: {
				return "Error";
			}
			case MessageType.success: {
				return "Success";
			}
			default:
				return "Warning";
		}
	}, [messageType]);

	const dialogContentProps = React.useMemo(
		() => ({
			type: DialogType.close,
			title: messageTitle,
			subText: messageText
		}),
		[messageTitle, messageText]
	);

	const onClose = () => {
		store.dispatch(setMessageOpen(false));
	};

	return (
		<Dialog
			hidden={!messageOpen}
			onDismiss={onClose}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<DialogFooter>
				<Button text="Ok" onClick={onClose} />
			</DialogFooter>
		</Dialog>
	);
};
