import React, { useEffect, useRef } from "react";
import { AppMenuAPI } from "../../../service/app/AppMenuAPI";
import { MenuComponentByType } from "./menuComponentByType";

/**
 * WIP: Component for building the menu as a whole mechanism.
 *
 * @param props
 * @returns
 */
function MenuBuilder(props): React.ReactElement {
	const appManagementService = useRef(new AppMenuAPI());

	useEffect(() => {
		if (appManagementService.current) {
			appManagementService.current.getMenu();
		}
	}, []);

	return (
		<>
			<MenuComponentByType />
		</>
	);
}

export { MenuBuilder };
