/**
 * Redux Store which define and control the state of the app
 */
import { configureStore } from "@reduxjs/toolkit";

// App Reducer imports
import { rootReducer } from "./rootState";

export default configureStore({
	reducer: rootReducer
});
