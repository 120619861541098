/**
 * Redux config to manage the app
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { nodeInitialState } from "../../models/slices";
import StrikeNode from "../../models/StrikeNode";
import { NodeRevisionsResponseModel } from "../../contracts/models";
import { ISecurityGroupMembersResponse } from "../../contracts/slices/node/askToExperts";

/**
 * Node Management state layer, also named after a Redux Slice.
 */
export const nodesSlice = createSlice({
	name: AppSlices.nodes,
	initialState: nodeInitialState,
	reducers: {
		setList: (state, action: PayloadAction<StrikeNode[]>) => {
			state.list = action.payload;
		},
		setQuerying: (state, action: PayloadAction<{ userUpn: string; querying: boolean }>) => {
			state.querying = {
				...state.querying,
				[action.payload.userUpn]: action.payload.querying
			};
		},
		setQueried: (state, action: PayloadAction<{ userUpn: string; queried: boolean }>) => {
			state.queried = {
				...state.queried,
				[action.payload.userUpn]: action.payload.queried
			};
		},
		setPhotoByUserUpn: (state, action: PayloadAction<{ userUpn: string; photoUrl: string | boolean }>) => {
			state.photos = {
				...state.photos,
				[action.payload.userUpn]: action.payload.photoUrl
			};
		},
		reset: (state) => {
			state.list = nodeInitialState.list;
			state.queried = nodeInitialState.queried;
			state.querying = nodeInitialState.querying;
			state.photos = nodeInitialState.photos;
		},
		setIsOpenDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
			state.ui.isOpenDeleteConfirmation = action.payload;
		},
		setIsOpenStickConfirmation: (state, action: PayloadAction<boolean>) => {
			state.ui.isOpenStickConfirmation = action.payload;
		},
		setIsOpenShareContent: (state, action: PayloadAction<boolean>) => {
			state.ui.isOpenShareContent = action.payload;
		},
		setIsSubmittingShareContent: (state, action: PayloadAction<boolean>) => {
			state.ui.isSubmittingShareContent = action.payload;
		},
		setIsActionConfirmed: (state, action: PayloadAction<boolean>) => {
			state.ui.isActionConfirmed = action.payload;
		},
		setDeleteConfirmationModalTitle: (state, action: PayloadAction<string>) => {
			state.ui.deleteConfirmationModalTitle = action.payload;
		},
		setDeleteConfirmationModalSubText: (state, action: PayloadAction<string>) => {
			state.ui.deleteConfirmationModalSubText = action.payload;
		},
		setDeleteConfirmationModalButtonText: (state, action: PayloadAction<string>) => {
			state.ui.deleteConfirmationModalButtonText = action.payload;
		},
		setConfirmationModalAction: (state, action: PayloadAction<() => void>) => {
			state.ui.confirmationModalAction = action.payload;
		},
		setIsOpenActionPostMessage: (state, action: PayloadAction<boolean>) => {
			state.ui.isOpenActionPostMessage = action.payload;
		},
		setIsChangeSpacePanelOpen: (state, action: PayloadAction<boolean>) => {
			state.ui.isChangeSpacePanelOpen = action.payload;
		},
		setIsRevisionsPanelOpen: (state, action: PayloadAction<boolean>) => {
			state.ui.isRevisionsPanelOpen = action.payload;
		},
		setStickSpaceStatus: (state, action: PayloadAction<boolean>) => {
			state.ui.isNodeSpaceSticky = action.payload;
		},
		setStickSiteStatus: (state, action: PayloadAction<boolean>) => {
			state.ui.isNodeSiteSticky = action.payload;
		},
		setPrivateStatus: (state, action: PayloadAction<boolean>) => {
			state.ui.isNodePrivate = action.payload;
		},
		setIsOpenPrivateConfirmation: (state, action: PayloadAction<boolean>) => {
			state.ui.isOpenPrivateConfirmation = action.payload;
		},
		setDeletedStatus: (state, action: PayloadAction<boolean>) => {
			state.ui.isNodeDeleted = action.payload;
		},
		setNodeDetailAuthorEmail: (state, action: PayloadAction<string>) => {
			state.node.authorEmail = action.payload;
		},
		setNodeRevisions: (state, action: PayloadAction<NodeRevisionsResponseModel[]>) => {
			state.node.revisions = action.payload;
		},
		resetNodeRevisions: (state) => {
			state.node.revisions = nodeInitialState.node.revisions;
		},
		setRevisionsLoaded: (state, action: PayloadAction<boolean>) => {
			state.node.revisionsLoaded = action.payload;
		},
		//saving node report reason id on global state
		setSelectedReportReason: (state, action: PayloadAction<null | number | string>) => {
			state.node.reportReasonId = action.payload;
		},
		//saving node report reason value id on global state
		setReportReasonValueId: (state, action: PayloadAction<string>) => {
			state.node.reportReasonValueId = action.payload;
		},
		//reset node report reason id on global state
		resetReportReasonValueId: (state) => {
			state.node.reportReasonValueId = nodeInitialState.node.reportReasonValueId;
		},
		//saving node report reason option text on global state
		setReportReasonValueOption: (state, action: PayloadAction<string>) => {
			state.node.reportReasonValueOption = action.payload;
		},
		//saving array of node report reasons options coming from db
		setReportReasonsOptionsData: (state, action: PayloadAction<string[]>) => {
			state.node.reportReasonsData = action.payload;
		},
		setQuestionExperts: (state, action: PayloadAction<ISecurityGroupMembersResponse[]>) => {
			state.node.questionExperts = action.payload;
		},
	}
});

export const {
	setList,
	setQuerying,
	setQueried,
	setPhotoByUserUpn,
	reset,
	setIsOpenDeleteConfirmation,
	setIsOpenStickConfirmation,
	setIsActionConfirmed,
	setDeleteConfirmationModalTitle,
	setDeleteConfirmationModalSubText,
	setDeleteConfirmationModalButtonText,
	setConfirmationModalAction,
	setIsOpenActionPostMessage,
	setIsChangeSpacePanelOpen,
	setIsRevisionsPanelOpen,
	setStickSpaceStatus,
	setStickSiteStatus,
	setIsOpenShareContent,
	setIsSubmittingShareContent,
	setIsOpenPrivateConfirmation,
	setPrivateStatus,
	setDeletedStatus,
	setNodeDetailAuthorEmail,
	setNodeRevisions,
	resetNodeRevisions,
	setRevisionsLoaded,
	setSelectedReportReason,
	setReportReasonValueId,
	setReportReasonValueOption,
	setReportReasonsOptionsData,
	resetReportReasonValueId,
	setQuestionExperts
} = nodesSlice.actions;

export default nodesSlice.reducer;
