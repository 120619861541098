import React from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { Message, MessageHeader } from "semantic-ui-react";
import { appManagementSelectors } from "../../redux/selectors/appManagement";
import { ServiceHub } from "../../service";

/**
 * Application Welcome Banner.
 *
 * @returns React.ReactElement
 */
const BannerWelcome = (): React.ReactElement => {
	let welcomeBanner: React.JSX.Element;

	const handleDismiss = () => {
		ServiceHub.appManagementAPI.setBannerVisible(false);
	};

	const loaderVisible = useSelector(appManagementSelectors.getBannerVisible);

	if (loaderVisible) {
		welcomeBanner = (
			<Message positive onDismiss={handleDismiss} className="page-banner-message">
				<MessageHeader>Welcome to the New Chapter of STRIKE Central!</MessageHeader>
				<p>
					<b>Phase 1 Launch: </b>Dive into our enhanced platform with read-only access to all your essential
					security content. This is just the start of our journey to bring you a more secure, scalable, and
					user-friendly experience. Stay ahead — explore the initial enhancements and learn what's coming
					next:&nbsp;
					<Link to={`/articles/12761/strike-central-platform-update-march-2024`} rel="noopener noreferrer">
						<b>here</b>
					</Link>
					<br />
					For site support, please contact{" "}
					<a href="mailto:strikeonlineteam@microsoft.com">
						<b>strikeonlineteam</b>
					</a>
				</p>
			</Message>
		);
	} else {
		welcomeBanner = <div></div>;
	}

	return <div>{welcomeBanner}</div>;
};

export default BannerWelcome;
