import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { eventNotificationTemplatesSelectors } from "../../redux/selectors/eventNotificationTemplates";
import { IEventNotificationTemplateRequest } from "../../contracts/models";
import { IEventNotificationTemplate } from "../../contracts";
import { useDispatch } from "react-redux";
import { eventNotificationTemplatesActions } from "../../redux/reducers/eventNotificationTemplates";
import { ServiceHub } from "../../service";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { ApplicationRoutePaths } from "../../components/router";
import { useStore } from "react-redux";

/**
 * Contract for the Event Notification Template Manager.
 */
export interface IEventNotificationTemplatesHook {
	list: IEventNotificationTemplate[];
	form: null | IEventNotificationTemplateRequest;
	isSubmitting: boolean;
	isEditing: boolean;
	isNew: boolean;
	isLoaded: boolean;
	isLoading: boolean;
	bodySourceMode: boolean;
	selectedType: string;
	eventName: null | string;
	changedTypes: string[];
	onLoad: () => Promise<void>;
	onSave: () => Promise<void>;
	onChangeProp: (propName: string, value: string | number | boolean) => void;
	resetAll: () => void;
	resetForm: () => void;
	resetList: () => void;
	onDiscard: () => void;
	storeListItem: (itemToStore: null | IEventNotificationTemplateRequest) => void;
	setForm: (formData: null | IEventNotificationTemplateRequest) => void;
	setFormContentSourceMode: (sourceMode: boolean) => void;
	setFormSelectedTemplateType: (templateType: string) => void;
}

/**
 * Event Notification Template Manager hook.
 * Provides access to the Form,
 * State, data, mode, etc.
 *
 * @returns
 */
export const useEventNotificationTemplates = (): IEventNotificationTemplatesHook => {
	const form = useSelector(eventNotificationTemplatesSelectors.getForm);
	const isSubmitting = useSelector(eventNotificationTemplatesSelectors.getFormIsSubmitting);
	const isEditing = useSelector(eventNotificationTemplatesSelectors.getFormIsEditing);
	const bodySourceMode = useSelector(eventNotificationTemplatesSelectors.getFormBodySourceMode);
	const selectedType = useSelector(eventNotificationTemplatesSelectors.getFormSelectedType);
	const list = useSelector(eventNotificationTemplatesSelectors.getList);
	const eventName = useSelector(eventNotificationTemplatesSelectors.getEventName);

	const store = useStore();
	const dispatch = useDispatch();
	const params = useParams();
	const eventService = useRef(ServiceHub.eventManageAPI.start());
	const [isLoading, setIsLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [changedTypes, setChangedTypes] = useState([]);

	const isNew = useMemo(() => {
		return form && (!form.id || form.id === 0);
	}, [form.id]);

	const onValidateForm = (itemToValidate: IEventNotificationTemplateRequest) => {
		// Checking eventId is present (id in the URL)
		if (!params.id) {
			return false;
		} else if (!itemToValidate.subject || itemToValidate.subject === "") {
			return false;
		} else if (!itemToValidate.emailContent || itemToValidate.emailContent === "") {
			return false;
		}

		return true;
	};

	const onValidateItem = (itemToValidate: IEventNotificationTemplate) => {
		// Checking eventId is present (id in the URL)
		if (!params.id) {
			return false;
		} else if (!itemToValidate.Subject || itemToValidate.Subject === "") {
			return false;
		} else if (!itemToValidate.EmailContent || itemToValidate.EmailContent === "") {
			return false;
		}

		return true;
	};

	const onDiscard = () => {
		ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
	};

	const onSave = async (): Promise<void> => {
		const saveOperations: Promise<boolean>[] = [];

		// Stores the form data bak to the list, prior to save
		storeListItem(form);

		// Gets the latest list of items for storing
		const latestList = eventNotificationTemplatesSelectors.getList(store.getState());

		latestList.forEach((typeName) => {
			const itemToSave = latestList.find((item) => item.Type === typeName.Type);

			// Copying the data from other state pieces, before save processing
			const dataCopy = {
				id: itemToSave.Id,
				eventId: !itemToSave.EventId || itemToSave.EventId === 0 ? parseInt(params.id) : itemToSave.EventId,
				type: !itemToSave.Type || itemToSave.Type === "" ? selectedType : itemToSave.Type,
				subject: itemToSave.Subject,
				emailContent: itemToSave.EmailContent,
				isActive: itemToSave.IsActive
			};

			if (!dataCopy.id || dataCopy.id === 0) {
				saveOperations.push(eventService.current.createNotificationTemplate(dataCopy));
			} else {
				saveOperations.push(eventService.current.updateNotificationTemplate(dataCopy.id, dataCopy));
			}
		});

		// Once all save operations are completed
		await Promise.all(saveOperations).then(() => {
			ServiceHub.appManagementAPI.navigate(ApplicationRoutePaths.eventSearch());
		});
	};

	const onLoad = async (): Promise<void> => {
		if (!params.id || isLoading) return;

		setIsLoading(true);

		eventService.current.getEvent(params.id).then((result: any) => {
			if (result instanceof Error) {
				ServiceHub.message.error(result.message);
				throw result;
			}
			dispatch(eventNotificationTemplatesActions.setEventName(result.Title));
		});

		eventService.current.getNotificationTemplates(params.id).then((items) => {
			if (Array.isArray(items)) {
				dispatch(eventNotificationTemplatesActions.setEventNotificationTemplateList(items));
				setIsLoaded(true);
				setIsLoading(false);
			}

			// TODO: report loading error?
		});
	};

	const onChangeProp = (propName: string, value: string | number | boolean) => {
		dispatch(eventNotificationTemplatesActions.setForm({ ...form, [propName]: value }));
	};

	const setForm = (formData: null | IEventNotificationTemplateRequest) => {
		dispatch(eventNotificationTemplatesActions.setForm(formData));
	};

	const setFormContentSourceMode = (sourceMode: boolean) => {
		dispatch(eventNotificationTemplatesActions.setFormContentSourceMode(sourceMode));
	};

	const setFormSelectedTemplateType = (templateType: string) => {
		dispatch(eventNotificationTemplatesActions.setFormSelectedTemplateType(templateType));
	};

	const storeListItem = (itemToStore: IEventNotificationTemplateRequest) => {
		const _list = [...list];
		let isContentDirty = false;

		const changedItemIndex = _list.findIndex((item) => item.Type === itemToStore.type);

		const copiedContent = {
			Id: itemToStore.id,
			EventId: !itemToStore?.eventId || itemToStore?.eventId === 0 ? parseInt(params.id) : itemToStore?.eventId,
			Type: !itemToStore?.type || itemToStore?.type === "" ? selectedType : itemToStore?.type,
			Subject: itemToStore.subject,
			EmailContent: itemToStore.emailContent,
			IsActive: itemToStore.isActive
		};

		if (changedItemIndex < 0) {
			_list.push(copiedContent);
			if (copiedContent.Subject !== "" || copiedContent.EmailContent !== "") {
				isContentDirty = true;
			}
		} else {
			// If the content is being edited, then it might not in fact have changed at all
			if (
				_list[changedItemIndex].Subject !== itemToStore.subject ||
				_list[changedItemIndex].EmailContent !== itemToStore.emailContent ||
				_list[changedItemIndex].IsActive !== itemToStore.isActive
			) {
				isContentDirty = true;
			}
			_list[changedItemIndex] = copiedContent;
		}

		// Marks the selectedType as changed
		if (isContentDirty && !changedTypes.includes(selectedType)) {
			setChangedTypes([...changedTypes, selectedType]);
		}

		dispatch(eventNotificationTemplatesActions.setEventNotificationTemplateList(_list));
	};

	const resetForm = () => {
		dispatch(eventNotificationTemplatesActions.resetForm());
	};

	const resetAll = () => {
		dispatch(eventNotificationTemplatesActions.reset());
		resetList();
	};

	const resetList = () => {
		dispatch(eventNotificationTemplatesActions.resetEventNotificationTemplateList());
		setIsLoaded(false);
		setIsLoading(false);
	};

	return {
		list,
		form,
		isSubmitting,
		isEditing,
		isNew,
		isLoaded,
		isLoading,
		bodySourceMode,
		selectedType,
		eventName,
		changedTypes,
		onLoad,
		onChangeProp,
		onSave,
		resetAll,
		resetForm,
		resetList,
		setForm,
		storeListItem,
		setFormContentSourceMode,
		setFormSelectedTemplateType,
		onDiscard
	};
};
